/* eslint-disable no-console */

import {EventEmitter} from 'events';
import IntlMessageFormat from 'intl-messageformat';

import {getMessage, setUpdateRules, FormattedMessage} from './parse';

import type {Rules} from 'simple-markdown';

// NOTE: New locales/languages go here
// NOTE(faulty): This is a hack around how our version of `intl-messageformat`
// defines locale-data. This has been fixed in newer versions, but that upgrade
// path is non-trivial, since we're more than 3 years out of date now.
// Doing this here rather than `@app/i18n` since it's directly tied to the dependency
// and likely affects other surfaces as well.
// This should probably stay in sync with `discord_app/i18n/locales`.
// See https://github.com/formatjs/intl-messageformat/issues/130#issuecomment-209073915
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
global.IntlMessageFormat = IntlMessageFormat;
require('intl-messageformat/dist/locale-data/bg.js');
require('intl-messageformat/dist/locale-data/cs.js');
require('intl-messageformat/dist/locale-data/da.js');
require('intl-messageformat/dist/locale-data/de.js');
require('intl-messageformat/dist/locale-data/el.js');
require('intl-messageformat/dist/locale-data/en.js');
require('intl-messageformat/dist/locale-data/es.js');
require('intl-messageformat/dist/locale-data/fi.js');
require('intl-messageformat/dist/locale-data/fr.js');
require('intl-messageformat/dist/locale-data/hr.js');
require('intl-messageformat/dist/locale-data/hu.js');
require('intl-messageformat/dist/locale-data/it.js');
require('intl-messageformat/dist/locale-data/ja.js');
require('intl-messageformat/dist/locale-data/ko.js');
require('intl-messageformat/dist/locale-data/lt.js');
require('intl-messageformat/dist/locale-data/nl.js');
require('intl-messageformat/dist/locale-data/no.js');
require('intl-messageformat/dist/locale-data/pl.js');
require('intl-messageformat/dist/locale-data/pt.js');
require('intl-messageformat/dist/locale-data/ro.js');
require('intl-messageformat/dist/locale-data/ru.js');
require('intl-messageformat/dist/locale-data/sv.js');
require('intl-messageformat/dist/locale-data/th.js');
require('intl-messageformat/dist/locale-data/tr.js');
require('intl-messageformat/dist/locale-data/uk.js');
require('intl-messageformat/dist/locale-data/vi.js');
require('intl-messageformat/dist/locale-data/zh.js');
require('intl-messageformat/dist/locale-data/hi.js');
// @ts-expect-error
delete global.IntlMessageFormat;

if (typeof Intl === 'undefined') {
  require('intl');
}

const DEFAULT_LOCALE = 'en-US';

export interface Language {
  name: string;
  englishName: string;
  code: string;
  enabled: boolean;
}

export interface Locale {
  value: string;
  name: string;
  localizedName: string;
}

export interface Messages {
  [key: string]: string | Messages;
}

interface ParsedMessages {
  [key: string]: string | FormattedMessage<any> | ParsedMessages;
}

export type GetMessages = (locale: string) => Promise<Messages> | Messages;

export type DidSetLocale = (newLocale: string, oldLocale: string) => void;

export function getSystemLocale(): string {
  let locale: string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore For some reason this is required for dev-cli jsdoc types
  if (__MOBILE__) {
    const rn = require('react-native');
    const deviceLocaleManager =
      rn.Platform.OS === 'android'
        ? // turbo module
          // eslint-disable-next-line import/no-unresolved -- native apps must specify dep in package.json
          require('@discordapp/rtn-codegen/js/NativeDeviceLocaleManager').default
        : // TODO: migrate iOS to turbo modules
          rn.NativeModules.LocalizationManager;

    if (deviceLocaleManager != null) {
      locale = deviceLocaleManager.getConstants().Language;
    }
  } else {
    const browserChosenLanguage = Array.isArray(navigator.languages) ? navigator.languages[0] : null;
    // @ts-expect-error Doesn't know about these fallback values.
    locale = browserChosenLanguage || navigator.language || navigator.browserLanguage || navigator.userLanguage;
  }
  return locale ?? '';
}

interface ProviderContext {
  messages: Messages;
  defaultMessages: Messages;
  locale: string;
}

type ProviderGetParsedMessages = (
  context: ProviderContext,
  key: string,
  recurse: (context: ProviderContext) => ParsedMessages,
) => string | FormattedMessage<any> | ParsedMessages;

abstract class Provider<T> {
  protected _context: ProviderContext = {
    messages: {},
    defaultMessages: {},
    locale: DEFAULT_LOCALE,
  };
  protected _parsedMessages: ParsedMessages = {};
  protected _getParsedMessages: ProviderGetParsedMessages;

  constructor(getParsedMessages: ProviderGetParsedMessages) {
    this._getParsedMessages = getParsedMessages;
  }

  getMessages(): T {
    return this._parsedMessages as any as T;
  }

  abstract refresh(context: ProviderContext): void;
}

class LazyPropertyProvider<T> extends Provider<T> {
  refresh(context: ProviderContext) {
    this._context = context;
    this._refresh(context, this._parsedMessages);
  }

  _refresh = (context: ProviderContext, target: ParsedMessages = {}) => {
    Object.keys(context.defaultMessages).forEach((key) => {
      Object.defineProperty(target, key, {
        configurable: true,
        get: () => {
          delete target[key];
          return (target[key] = this._getParsedMessages(context, key, this._refresh));
        },
      });
    });
    return target;
  };
}

class ProxyProvider<T> extends Provider<T> {
  constructor(getParsedMessages: ProviderGetParsedMessages) {
    super(getParsedMessages);
    this._parsedMessages = this._createProxy(this._context);
  }

  refresh(context: ProviderContext) {
    Object.assign(this._context, context);
    Object.keys(this._parsedMessages).forEach((key) => {
      delete this._parsedMessages[key];
    });
  }

  _createProxy = (context: ProviderContext = this._context) =>
    new Proxy(
      {},
      {
        get: (target: ParsedMessages, key: string) =>
          target[key] || (target[key] = this._getParsedMessages(context, key, this._createProxy)),
      },
    );
}

export class I18N<T> extends EventEmitter {
  readonly Messages: T;
  loadPromise: Promise<void> = Promise.resolve();

  initialLanguageLoad: Promise<void>;
  private resolveLanguageLoaded: () => void = () => {};

  private _languages: Language[] = [];
  private _provider: Provider<T>;
  private _chosenLocale: string = '';
  // Prevents a race condition where an asynchronously loaded language could be
  // applied after another language was requested and applied in that time.
  private _requestedLocale: string | undefined;

  private _getMessages: GetMessages;

  constructor({
    initialLocale,
    getMessages,
    getLanguages,
  }: {
    initialLocale?: string;
    getMessages: GetMessages;
    getLanguages: () => Language[];
  }) {
    super();

    this.initialLanguageLoad = new Promise((resolve, reject) => {
      this.resolveLanguageLoaded = resolve;
    });

    // @ts-expect-error TS doesn't know about __addLocaleData.
    if (Intl.__addLocaleData) {
      // @ts-expect-error TS doesn't know about __addLocaleData.
      Intl.__addLocaleData(require('intl/locale-data/json/en.json'));
    }

    this._languages = getLanguages();
    this._provider =
      window.Proxy != null
        ? new ProxyProvider(this._getParsedMessages)
        : new LazyPropertyProvider(this._getParsedMessages);
    this.Messages = this._provider.getMessages();

    this._getMessages = getMessages;

    // Check if the provided locale is considered valid by Intl's formatters. If we don't do this, it will lead to
    // errors later when formatting messages if an invalid locale is provided.
    try {
      new Intl.NumberFormat(initialLocale, {});
      this.setLocale(initialLocale || this.getDefaultLocale());
    } catch (e) {
      // Intl doesn't like this locale's format, fall back to default
      this.setLocale(this.getDefaultLocale());
    }

    this.on('newListener', this._handleNewListener);
  }

  updateMessagesForExperiment(locale: string, callback: (messages: Messages) => Record<string, string>) {
    const messages = this._fetchMessages(locale);
    if (messages instanceof Promise) {
      messages.then((fetchedMessages) => {
        this._applyMessagesForLocale(callback(fetchedMessages), locale);
      });
      return;
    }

    this._applyMessagesForLocale(callback(messages), locale);
  }

  setLocale(locale: string) {
    if (this._chosenLocale === locale) return;

    this._requestedLocale = locale;

    const prevLocale = this._chosenLocale;
    this._chosenLocale = locale;

    this.loadPromise = this._loadMessagesForLocale(locale);

    this.emit('locale', this._chosenLocale, prevLocale);
  }

  setUpdateRules(newRules: (rules: Rules<any>) => Rules<any>) {
    setUpdateRules(newRules);
  }

  getLanguages(): Language[] {
    return this._languages;
  }

  getAvailableLocales(): Locale[] {
    return this._languages
      .filter(({enabled}) => enabled)
      .map(({code, name}) => ({
        value: code,
        name,
        // @ts-expect-error These should exist on messages and if they don't it will fallback.
        localizedName: this.Messages[code] ?? name,
      }))
      .sort(({name: a}, {name: b}) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
  }

  getLocale(): string {
    return this._chosenLocale;
  }

  getLocaleInfo(): Language | undefined {
    return this._languages.find((language) => language.code === this._chosenLocale);
  }

  getDefaultLocale(): string {
    const locale = getSystemLocale() ?? DEFAULT_LOCALE;

    const locales = this._languages.filter(({enabled}) => enabled).map(({code}) => code);

    if (locales.includes(locale)) {
      return locale;
    }

    const language = locale.split('-');
    if (locales.includes(language[0])) {
      return language[0];
    }

    if (language[0] === 'zh' && language.length > 1 && language[1] === 'Hant') {
      return locales.find((locale) => locale === 'zh-TW') ?? DEFAULT_LOCALE;
    }

    return locales.find((locale) => locale.split('-')[0] === language[0]) ?? DEFAULT_LOCALE;
  }

  private _loadMessagesForLocale(locale: string): Promise<void> {
    const messages = this._fetchMessages(locale);
    if (messages instanceof Promise) {
      return messages.then((messages) => this._applyMessagesForLocale(messages, locale));
    }
    this._applyMessagesForLocale(messages, locale);
    return Promise.resolve();
  }

  private _applyMessagesForLocale(
    messages: Messages,
    locale: string,
    defaultMessages: Messages = this._findMessages(DEFAULT_LOCALE),
  ) {
    if (this._requestedLocale !== locale) return;

    this._provider.refresh({
      messages,
      defaultMessages,
      locale,
    });

    this.resolveLanguageLoaded();
  }

  private _getParsedMessages = (
    {messages, defaultMessages, locale}: ProviderContext,
    key: string,
    recurse: (context: ProviderContext) => ParsedMessages,
  ): string | FormattedMessage<any> | ParsedMessages => {
    let message = messages[key] || defaultMessages[key];
    if (typeof message === 'object') {
      return recurse({
        messages: message,
        defaultMessages: defaultMessages[key] as Messages,
        locale,
      });
    } else {
      try {
        return getMessage(message, locale);
      } catch (e) {
        console.warn(`Failed parsing intl key '${String(key)}' in locale '${locale}' defaulting to English`, e);
        message = defaultMessages[key];
        if (typeof message === 'string') {
          return getMessage(message, locale);
        }
      }
    }
    return '';
  };

  private _findMessages(locale: string): Messages {
    const messages = this._fetchMessages(locale);
    if (messages instanceof Promise) {
      throw new Error('Messages are still loading.');
    }
    return messages;
  }

  private _fetchMessages(locale: string): Promise<Messages> | Messages {
    // if messages are unable to load, fallback to base varient, then DEFAULT_LOCALE
    // if we are loading the DEFAULT_LOCALE, throw error instead of infinite looping
    const fallback =
      locale === DEFAULT_LOCALE
        ? () => {
            throw new Error(`Error Loading ${DEFAULT_LOCALE}`);
          }
        : () => {
            console.warn('Unsupported Locale', locale);
            if (locale.indexOf('-') === -1) {
              // use default if already base varient (i.e. 'en')
              return this._fetchMessages(DEFAULT_LOCALE);
            } else {
              // move to base varient if dialect (i.e. 'en-US' => 'en')
              return this._fetchMessages(locale.split('-')[0]);
            }
          };

    try {
      const messages = this._getMessages(locale);
      return messages instanceof Promise ? messages.catch(fallback) : messages;
    } catch (e) {
      return fallback();
    }
  }

  private _handleNewListener = (event: string) => {
    switch (event) {
      case 'locale':
        this.emit(event, this._chosenLocale);
        break;
    }
  };
}
