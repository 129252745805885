import * as React from 'react';
import {useStateFromStores} from '@discordapp/flux';
import {Storage} from '@discordapp/storage';

import ExperimentStore from '@developers/stores/ExperimentStore';
import UserStore from '@developers/stores/UserStore';
import Anchor from '@developers/uikit/Anchor';
import {track} from '@developers/utils/TrackingAnalyticsUtils';
import Notice, {NoticeColors, NoticeCloseButton, PrimaryCTANoticeButton} from './Notice';

import {
  NoticeTypes,
  AnalyticsEvents,
  Links,
  DEVELOPER_SURVEY_VISIBILITY_KEY,
  Experiments,
  ExperimentBuckets,
} from '@developers/Constants';
import {Messages} from '@developers/i18n';

const SURVEY_NOTICE_NAME = 'developer_pitch_vote';

function useShouldShowSurvey() {
  const isInExperiment = useStateFromStores(
    [ExperimentStore],
    () => {
      const experiment = ExperimentStore.getEligibleExperiment(Experiments.DEVELOPER_SURVEY_NAGBAR);
      return experiment != null && experiment.bucket !== ExperimentBuckets.CONTROL;
    },
    [],
  );
  return isInExperiment && Storage.get(DEVELOPER_SURVEY_VISIBILITY_KEY) !== false;
}

export default function DeveloperSurveyNagbar() {
  const shouldShowSurvey = useShouldShowSurvey();
  const [hidden, setHidden] = React.useState(false);

  const user = useStateFromStores([UserStore], () => UserStore.user);

  React.useEffect(() => {
    if (user != null && hidden) {
      track(AnalyticsEvents.SURVEY_NOTICE_VIEWED, {
        user_id: user.id,
        notice_name: SURVEY_NOTICE_NAME,
      });
    }
  }, [hidden, user]);

  if (user == null || hidden || !shouldShowSurvey) {
    return null;
  }

  const handleNoticeClick = () => {
    track(AnalyticsEvents.SURVEY_NOTICE_CLICKED, {
      user_id: user.id,
      notice_name: SURVEY_NOTICE_NAME,
    });
    dismissPrompt();
  };

  const dismissPrompt = () => {
    Storage.set(DEVELOPER_SURVEY_VISIBILITY_KEY, false);
    setHidden(true);
  };

  const surveyUrl = `${Links.DEVELOPER_SURVEY}?user_id=${user.id}`;

  return (
    <Notice color={NoticeColors.DEFAULT}>
      <NoticeCloseButton onClick={dismissPrompt} />
      {Messages.DeveloperExperienceSurvey.DEVELOPER_EXPERIENCE_SURVEY_PITCH_VOTE}
      <PrimaryCTANoticeButton noticeType={NoticeTypes.SURVEY_NAG}>
        <Anchor href={surveyUrl} target="_blank" onClick={handleNoticeClick}>
          {Messages.DeveloperExperienceSurvey.DEVELOPER_EXPERIENCE_SURVEY_PITCH_VOTE_CTA}
        </Anchor>
      </PrimaryCTANoticeButton>
    </Notice>
  );
}
