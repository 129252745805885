import {Storage} from '@discordapp/storage';

import {TOKENS_KEY, TOKEN_KEY} from '../Constants';

// Encrypted tokens are in the format ENCRYPTED_PREFIX:base64_encrypted_token
// DON'T CHANGE THIS! Changing this will log out all users with encrypted tokens
const ENCRYPTED_PREFIX = 'dQw4w9WgXcQ:';

// Object.fromEntries is not supported on Chrome < 73
function fromEntries(entries: any): any {
  return [...entries].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
}

interface SafeStorage {
  isEncryptionAvailable(): boolean;
  encryptString(plainText: string | null | undefined): string | null;
  decryptString(encrypted: string | null | undefined): string | null;
}

let safeStorage: SafeStorage | null = null;
const DiscordNative = window.DiscordNative;
if (DiscordNative != null) {
  safeStorage = DiscordNative.safeStorage;
}

let isEncryptedMode = false;

// always decrypted
let token: string | null | undefined;
let tokens: {[tokenKey: string]: string} = {};

// always encrypted, if possible
let storedToken: string | null | undefined;
let storedTokens: {[tokenKey: string]: string} = {};

// if storage is hidden, we do not persist changes
let isStorageHidden: boolean = false;

let hasInitialized = false;

function storeTokens() {
  if (isStorageHidden) {
    Storage.remove(TOKEN_KEY);
    Storage.remove(TOKENS_KEY);
    return;
  }

  if (storedToken != null) {
    Storage.set(TOKEN_KEY, storedToken);
  } else {
    Storage.remove(TOKEN_KEY);
  }
  Storage.set(TOKENS_KEY, storedTokens);
}

function maybeDecryptToken(maybeEncryptedToken: string | null | undefined): {
  decryptedToken: string | null;
  wasEncrypted: boolean;
} {
  if (maybeEncryptedToken == null || maybeEncryptedToken.length === 0) {
    return {decryptedToken: null, wasEncrypted: false};
  }

  if (safeStorage?.isEncryptionAvailable() && maybeEncryptedToken.startsWith(ENCRYPTED_PREFIX)) {
    return {
      decryptedToken: safeStorage.decryptString(maybeEncryptedToken.substring(ENCRYPTED_PREFIX.length)),
      wasEncrypted: true,
    };
  }

  return {decryptedToken: maybeEncryptedToken, wasEncrypted: false};
}

function maybeEncryptToken(token: string) {
  if (safeStorage?.isEncryptionAvailable() && !token.startsWith(ENCRYPTED_PREFIX)) {
    return `${ENCRYPTED_PREFIX}${safeStorage.encryptString(token)}`;
  }

  return token;
}

export function init() {
  if (hasInitialized) {
    return;
  }

  storedToken = Storage.get(TOKEN_KEY);
  storedTokens = Storage.get(TOKENS_KEY) || {};

  const {decryptedToken, wasEncrypted} = maybeDecryptToken(storedToken);
  isEncryptedMode = wasEncrypted;
  token = decryptedToken;

  tokens = fromEntries(
    Object.entries(storedTokens)
      .map(([tokenKey, tkn]) => {
        const {decryptedToken, wasEncrypted} = maybeDecryptToken(tkn);
        isEncryptedMode = wasEncrypted || isEncryptedMode;
        return [tokenKey, decryptedToken];
      })
      .filter(([_, v]) => v != null),
  );

  hasInitialized = true;
}

export function getToken(tokenId?: string | undefined | null): string | null | undefined {
  // Mobile calls TokenManager.init because storage is async
  if (!__MOBILE__) {
    init();
  }

  if (tokenId != null) {
    return tokens[tokenId];
  }

  return token;
}

export function setToken(newToken: string | null | undefined, tokenId?: string | undefined | null) {
  if (newToken == null) {
    removeToken(tokenId);
    return;
  }

  token = newToken;
  if (tokenId != null) {
    tokens[tokenId] = newToken;
  }

  if (isEncryptedMode) {
    encryptAndStoreTokens();
  } else {
    storedToken = token;
    storedTokens = tokens;

    storeTokens();
  }
}

export function hideToken() {
  if (isStorageHidden) {
    return;
  }
  isStorageHidden = true;

  storeTokens();
}

export function showToken() {
  if (!isStorageHidden) {
    return;
  }
  isStorageHidden = false;

  storeTokens();
}

/**
 * @return {boolean} true if token was removed, false otherwise
 */
export function removeToken(tokenKey?: string | undefined | null): boolean {
  let tokenToDelete = token;
  if (tokenKey != null) {
    tokenToDelete = tokens[tokenKey];
    delete tokens[tokenKey];
    delete storedTokens[tokenKey];
  }

  // only clear the main token if it's the one being removed
  if (tokenToDelete === token) {
    token = null;
    storedToken = null;
  }

  storeTokens();

  return tokenToDelete != null;
}

export function encryptAndStoreTokens() {
  if (!safeStorage?.isEncryptionAvailable()) {
    storedToken = token;
    storedTokens = tokens;
  } else {
    if (token != null) {
      storedToken = maybeEncryptToken(token);
    }
    storedTokens = fromEntries(Object.entries(tokens).map(([tokenKey, tkn]) => [tokenKey, maybeEncryptToken(tkn)]));
    isEncryptedMode = true;
  }

  storeTokens();
}
