import * as React from 'react';

export function usePrevious<T>(value: T, currValue: T | null = null) {
  const ref = React.useRef<T | null>(currValue);
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;
