/***************************************************************
 *
 * This is a generated file. Do not directly edit.
 * Run: clyde gen analytics
 *
 **************************************************************/

/* eslint-disable */

import {WebAnalyticsEvents} from '@discordapp/common/Constants';
import type {StandardAnalyticsLocation} from '@discordapp/analytics-utils'
export interface TrackCreatorStorePageCtaClicked extends TrackBase {
  guild_id?: string | null;
  guild_name?: string | null;
  slug?: string | null;
  cta?: string | null;
}

export interface TrackBase {
  event?: string | null;
  event_type?: string | null;
  event_id?: string | null;
  event_source?: string | null;
  timestamp?: any | null;
  user_id?: string | null;
  domain?: string | null;
  client_uuid?: string | null;
  client_track_timestamp?: any | null;
  client_send_timestamp?: any | null;
  fluent_id?: string | null;
  freight_hostname?: string | null;
  freight_id?: string | null;
  ip?: string | null;
  day?: number | null;
  chosen_locale?: string | null;
  detected_locale?: string | null;
  variant?: string | null;
  user_is_authenticated?: boolean | null;
  accessibility_support_enabled?: boolean | null;
  accessibility_features?: number | null;
  user_is_bot?: boolean | null;
  browser_user_agent?: string | null;
  browser?: string | null;
  browser_version?: string | null;
  device?: string | null;
  device_advertiser_id?: string | null;
  device_id?: string | null;
  cfduid?: string | null;
  cfduid_signed?: boolean | null;
  device_vendor_id?: string | null;
  os?: string | null;
  os_version?: string | null;
  os_sdk_version?: string | null;
  os_arch?: string | null;
  system_locale?: string | null;
  client_build_number?: number | null;
  release_channel?: string | null;
  client_version?: string | null;
  distro?: string | null;
  window_manager?: string | null;
  client_event_source?: string | null;
  client_performance_cpu?: number | null;
  client_performance_memory?: number | null;
  mp_keyword?: string | null;
  mp_keyword_current?: string | null;
  referrer?: string | null;
  referrer_current?: string | null;
  referring_domain?: string | null;
  referring_domain_current?: string | null;
  search_engine?: string | null;
  search_engine_current?: string | null;
  utm_campaign?: string | null;
  utm_campaign_current?: string | null;
  utm_content?: string | null;
  utm_content_current?: string | null;
  utm_medium?: string | null;
  utm_medium_current?: string | null;
  utm_source?: string | null;
  utm_source_current?: string | null;
  utm_term?: string | null;
  utm_term_current?: string | null;
  location?: string | StandardAnalyticsLocation | null;
  city?: string | null;
  country_code?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  region_code?: string | null;
  time_zone?: string | null;
  isp?: string | null;
  cpu_core_count?: number | null;
  rendered_locale?: string | null;
  accepted_languages?: Array<string> | null;
  accepted_languages_weighted?: Array<string> | null;
  primary_accepted_language?: string | null;
  client_heartbeat_session_id?: string | null;
  native_build_number?: number | null;
  design_id?: number | null;
  app_arch?: string | null;
  client_app_state?: string | null;
  client_rtc_state?: string | null;
  uptime_process_renderer?: number | null;
  uptime_app?: number | null;
  client_app_id?: string | null;
  client_app_public_flags?: number | null;
}

export interface TrackCreatorStorePageViewed extends TrackBase {
  guild_id?: string | null;
  guild_name?: string | null;
  slug?: string | null;
}

export interface TrackLocationStackMetadata {
  location_stack?: Array<string> | null;
}

export interface TrackSeoAggregatorPageCtaClicked extends TrackBase {
  category_id?: number | null;
  category_name?: string | null;
  guild_id?: string | null;
  query?: string | null;
  cta?: string | null;
}

export interface TrackSeoAggregatorPageViewed extends TrackBase {
  category_id?: number | null;
  category_name?: string | null;
  num_guilds?: string | null;
}

export interface TrackSeoServerLandingPageCtaClicked extends TrackBase {
  guild_id?: string | null;
  guild_name?: string | null;
  slug?: string | null;
  cta?: string | null;
}

export interface TrackSeoServerLandingPageViewed extends TrackBase {
  guild_id?: string | null;
  guild_name?: string | null;
  slug?: string | null;
}

export interface TrackDeepLinkClicked extends TrackBase {
  fingerprint?: string | null;
  attempt_id?: string | null;
  source?: string | StandardAnalyticsLocation | null;
  invite_code?: string | null;
  guild_template_code?: string | null;
  destination?: string | null;
}

export interface TrackMktgVideoPlayed extends TrackBase, TrackLocationMetadata, TrackMarketingSite {
  page_name?: string | null;
  video_url?: string | null;
}

export interface TrackMarketingSite {
  marketing_variant?: string | null;
}

export interface TrackLocationMetadata {
  location_page?: string | null;
  location_section?: string | null;
  location_object?: string | null;
  location_object_type?: string | null;
  location_promotion_id?: number | null;
}

export interface TrackMktgPageCtaClicked extends TrackBase, TrackLocationMetadata {
  page_name?: string | null;
  rpc_connected?: boolean | null;
}

export interface TrackMktgWarframeCtaClicked extends TrackBase {
  desktop_probably_open?: boolean | null;
}

export interface TrackMktgApplicationStep extends TrackBase, TrackMarketingSite {
  application_type?: string | null;
  page_name?: string | null;
  step?: string | null;
}

export interface TrackMainNavigationMenu extends TrackBase, TrackMarketingSite {
  linkclicked?: string | null;
  googleanalytics?: boolean | null;
  page_name?: string | null;
  static_experiment_uuid?: string | null;
}

export interface TrackGameClickedLanding extends TrackBase {
  game?: string | null;
}

export interface TrackChangeMarketingLocale extends TrackBase, TrackMarketingSite {
  from?: string | null;
  to?: string | null;
}

export interface TrackMktgHypesquadFormOpened extends TrackBase, TrackMarketingSite {
}

export interface TrackMktgHypesquadFormSubmitted extends TrackBase, TrackMarketingSite {
  referred_by_code?: string | null;
}

export interface TrackDownloadApp extends TrackBase, TrackMarketingSite {
  has_e_mail?: boolean | null;
  platform?: string | null;
  ptb?: boolean | null;
  referring_location?: string | null;
  released?: boolean | null;
  page_name?: string | null;
  static_experiment_uuid?: string | null;
  qr_code?: boolean | null;
}

export interface TrackClickLandingCta extends TrackBase, TrackMarketingSite {
  buttonstate?: string | null;
  buttontype?: string | null;
  page_name?: string | null;
  static_experiment_uuid?: string | null;
}

export interface TrackMktgPageViewed extends TrackBase, TrackMarketingSite {
  page_name?: string | null;
  previous_page_name?: string | null;
  previous_link_location?: string | null;
  query_referrer?: string | null;
  has_session?: boolean | null;
  static_experiment_uuid?: string | null;
}

export interface TrackExperimentSaveExposureFailed extends TrackBase {
  module?: string | null;
  call?: string | null;
}

export interface TrackExperimentGuildTriggered extends TrackBase, TrackLocationStackMetadata {
  name?: string | null;
  bucket?: number | null;
  revision?: number | null;
  guild_id?: string | null;
  linked_user_experiment_name?: string | null;
  linked_user_experiment_bucket?: number | null;
  linked_user_experiment_revision?: number | null;
  hash_result?: number | null;
  excluded?: boolean | null;
  exposure_type?: string | null;
}

export interface TrackExperimentUserTriggered extends TrackBase, TrackLocationStackMetadata {
  name?: string | null;
  bucket?: number | null;
  revision?: number | null;
  population?: number | null;
  context_type?: string | null;
  context_guild_id?: string | null;
  context_channel_id?: string | null;
  hash_result?: number | null;
  excluded?: boolean | null;
  exposure_type?: string | null;
}

export interface TrackImpressionMetadata {
  sequence_id?: string | null;
  impression_name?: string | null;
  impression_type?: string | null;
  impression_group?: string | null;
}

export interface TrackNetworkMetadata {
  url?: string | null;
  request_method?: string | null;
  status_code?: number | null;
  error_code?: number | null;
  error_message?: string | null;
}

export interface TrackGuild {
  guild_id?: string | null;
  guild_size_total?: number | null;
  guild_member_num_roles?: number | null;
  guild_member_perms?: string | null;
  guild_num_channels?: number | null;
  guild_num_text_channels?: number | null;
  guild_num_voice_channels?: number | null;
  guild_num_roles?: number | null;
  guild_is_vip?: boolean | null;
  is_member?: boolean | null;
  num_voice_channels_active?: number | null;
  is_owner?: boolean | null;
}

export interface TrackChannel {
  channel_id?: string | null;
  channel_type?: number | null;
  channel_size_total?: number | null;
  channel_size_online?: number | null;
  channel_member_perms?: string | null;
  channel_hidden?: boolean | null;
  channel_static_route?: string | null;
}

export interface AnalyticsSchemaNameMap {
  [WebAnalyticsEvents.EXPERIMENT_USER_TRIGGERED]: TrackExperimentUserTriggered;
  [WebAnalyticsEvents.EXPERIMENT_GUILD_TRIGGERED]: TrackExperimentGuildTriggered;
  [WebAnalyticsEvents.EXPERIMENT_SAVE_EXPOSURE_FAILED]: TrackExperimentSaveExposureFailed;
  [WebAnalyticsEvents.MKTG_PAGE_VIEWED]: TrackMktgPageViewed;
  [WebAnalyticsEvents.CLICK_LANDING_CTA]: TrackClickLandingCta;
  [WebAnalyticsEvents.DOWNLOAD_APP]: TrackDownloadApp;
  [WebAnalyticsEvents.MKTG_HYPESQUAD_FORM_SUBMITTED]: TrackMktgHypesquadFormSubmitted;
  [WebAnalyticsEvents.MKTG_HYPESQUAD_FORM_OPENED]: TrackMktgHypesquadFormOpened;
  [WebAnalyticsEvents.CHANGE_MARKETING_LOCALE]: TrackChangeMarketingLocale;
  [WebAnalyticsEvents.GAME_CLICKED_LANDING]: TrackGameClickedLanding;
  [WebAnalyticsEvents.MAIN_NAVIGATION_MENU]: TrackMainNavigationMenu;
  [WebAnalyticsEvents.MKTG_APPLICATION_STEP]: TrackMktgApplicationStep;
  [WebAnalyticsEvents.MKTG_WARFRAME_CTA_CLICKED]: TrackMktgWarframeCtaClicked;
  [WebAnalyticsEvents.MKTG_PAGE_CTA_CLICKED]: TrackMktgPageCtaClicked;
  [WebAnalyticsEvents.MKTG_VIDEO_PLAYED]: TrackMktgVideoPlayed;
  [WebAnalyticsEvents.DEEP_LINK_CLICKED]: TrackDeepLinkClicked;
  [WebAnalyticsEvents.SEO_PAGE_VIEWED]: TrackSeoServerLandingPageViewed;
  [WebAnalyticsEvents.SEO_PAGE_CTA_CLICKED]: TrackSeoServerLandingPageCtaClicked;
  [WebAnalyticsEvents.SEO_AGGREGATOR_PAGE_VIEWED]: TrackSeoAggregatorPageViewed;
  [WebAnalyticsEvents.SEO_AGGREGATOR_CTA_CLICKED]: TrackSeoAggregatorPageCtaClicked;
  [WebAnalyticsEvents.LOCATION_STACK_METADATA]: TrackLocationStackMetadata;
  [WebAnalyticsEvents.CREATOR_STORE_PAGE_VIEWED]: TrackCreatorStorePageViewed;
  [WebAnalyticsEvents.CREATOR_STORE_PAGE_CTA_CLICKED]: TrackCreatorStorePageCtaClicked;
}

export interface TrackImpressionActivities extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionActivitiesHappeningNow extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  n_activities?: number | null;
}

export interface TrackImpressionActivityBookmarkShareModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
}

export interface TrackImpressionActivityBoostingUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  source_section?: string | null;
  tier?: number | null;
}

export interface TrackImpressionActivityDetails extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
}

export interface TrackImpressionActivityNitroUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  source_section?: string | null;
}

export interface TrackImpressionActivityShareMomentModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  activity_session_id?: string | null;
}

export interface TrackImpressionActivityShelf extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  source_section?: string | null;
}

export interface TrackImpressionActivityShelfSelectChannel extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  source_section?: string | null;
}

export interface TrackImpressionAppLauncherHomeActivityItem extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionAppLauncherItem extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  section_name?: string | null;
  section_position?: number | null;
  source?: string | null;
}

export interface TrackImpressionAppLauncherSearchResultsItem extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  command_id?: string | null;
  search_results_position?: number | null;
  query?: string | null;
  source?: string | null;
}

export interface TrackImpressionAppLauncherSection extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  section_name?: string | null;
  num_items?: number | null;
  num_visible_items?: number | null;
  source?: string | null;
}

export interface TrackImpressionApplicationLibrary extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionApplicationStore extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionAprilPremiumMarketingDecoClaimedModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionAvatarUpload extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionChannelAddInfo extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionChannelAddMembers extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionChannelCallVideoGrid extends TrackBase {
  channel_id?: string | null;
  guild_id?: string | null;
  total_participants?: number | null;
  can_invite?: boolean | null;
  is_afk_channel?: boolean | null;
  channel_user_limit?: number | null;
}

export interface TrackImpressionChannelCallVideoGridView extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  total_participants?: number | null;
  can_invite?: boolean | null;
  is_afk_channel?: boolean | null;
  channel_user_limit?: number | null;
}

export interface TrackImpressionChannelViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionClipEditorViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionClipGalleryViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  number_of_clips_loaded?: number | null;
}

export interface TrackImpressionClydeAiProfileEmbedViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  clyde_profile_id?: string | null;
}

export interface TrackImpressionCollectiblesExpiryModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionContactSyncContactInvites extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionContactSyncInputName extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionContactSyncStart extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionContactSyncSuggestions extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionCreatorPromoPageGuildHeaderUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionDirectoryAddGuildConfirmation extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionDiscoverability extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionDropsQuestCompletion extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  drops_quest_id?: string | null;
  game_id?: string | null;
}

export interface TrackImpressionDropsQuestEnrollment extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  drops_quest_id?: string | null;
  game_id?: string | null;
}

export interface TrackImpressionEmbeddedActivityHappeningNow extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  activity_session_id?: string | null;
}

export interface TrackImpressionEnableCreatorMonetizationAcceptTermsLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  creator_monetization_enable_request_id?: string | null;
  is_eligible?: boolean | null;
  ineligible_reasons?: Array<string> | null;
}

export interface TrackImpressionEnableCreatorMonetizationCreateRequestLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  is_eligible?: boolean | null;
  is_application_pending?: boolean | null;
  monetization_requirements_json?: string | null;
}

export interface TrackImpressionEnableCreatorMonetizationGuildHeaderUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionEnableCreatorMonetizationWaitlistLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionFriendRequestsSpamInbox extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionFriends extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGameConsoleDeviceList extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGdmSettingsInvites extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGlobalDiscovery extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddAcceptInvite extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddChannelPrompt extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddCustomize extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddGuildInvite extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddIntentSelection extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddJoin extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildAddLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildChannel extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildCreateModalJoin extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildDiscovery extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildInvite extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  invite_guild_id?: string | null;
  invite_channel_id?: string | null;
}

export interface TrackImpressionGuildInviteLinkSettings extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildInviteSearch extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  invite_guild_id?: string | null;
  invite_channel_id?: string | null;
}

export interface TrackImpressionGuildMemberVerification extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildPermanentLinksUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildProductListingEmbed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  guild_product_listing_id?: string | null;
  has_entitlement?: boolean | null;
}

export interface TrackImpressionGuildProductListingInfoModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  guild_product_listing_id?: string | null;
  has_entitlement?: boolean | null;
}

export interface TrackImpressionGuildRoleSubscriptionStorePageGuildSettings extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsAnalytics extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsAuditLog extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsAuditLogV2 extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsBans extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsBoostStatus extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsChannels extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsClyde extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsCommunityOverview extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsCommunityWelcome extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsDiscovery extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsDiscoveryLandingPage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsEmoji extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsEnableCommunity extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsIntegration extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsInvites extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsMemberVerification extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsMembers extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsModeration extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsOverview extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsPartner extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsRoles extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsSafety extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsSecurity extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsSoundboard extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsStickers extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsTemplate extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsVanityUrl extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsWebhooks extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildSettingsWidget extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildShopEmbed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildShopPage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  product_id?: string | null;
  tab?: string | null;
}

export interface TrackImpressionGuildShopUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  passed_in_guild_id?: string | null;
}

export interface TrackImpressionGuildTransferOwnership extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  to_user_id?: string | null;
}

export interface TrackImpressionGuildTransferOwnershipConfirmEmailCode extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildTransferOwnershipConfirmSmsCode extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionGuildsEmptyNux extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHdStreamingPopout extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHotspot extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  hotspot_location?: string | null;
}

export interface TrackImpressionHubCreateGuildCustomize extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHubCreateGuildTemplate extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHubEmailSignup extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  has_invite?: boolean | null;
}

export interface TrackImpressionHubEmailVerificationPage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHubExistingGuildChoose extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHubExistingGuildCustomize extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionHubWaitlistSignup extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionInviteAccept extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  invite_code?: string | null;
  deeplink_attempt_id?: string | null;
}

export interface TrackImpressionLocalizedPricingUpsellViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  action_location?: string | null;
}

export interface TrackImpressionMessagesEmptyNux extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionMultiAccountSwitchLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionNewUserIntentStart extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionNitroTab extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  badge_decorator?: number | null;
  has_premium?: boolean | null;
}

export interface TrackImpressionNotificationCenterLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata {
  empty?: boolean | null;
}

export interface TrackImpressionNotificationSettingUnreadNudge extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionPerkDiscoverabilityCard extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  name?: string | null;
}

export interface TrackImpressionPollEditorViewed extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionPomeloLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  source?: string | null;
  one_click_flow?: boolean | null;
}

export interface TrackImpressionPremiumGuildSubscriptionMarketingPage extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionPremiumMarketingBanner extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  banner_id?: string | null;
}

export interface TrackImpressionPremiumMarketingSurface extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionPremiumMarketingTenureRewardCard extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  user_tenure_reward_id?: string | null;
  tenure_reward_id?: string | null;
  reward_type?: string | null;
  redeemable_at?: any | null;
  reward_status?: number | null;
}

export interface TrackImpressionPushNotificationPreprompt extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  has_faux_alert?: boolean | null;
}

export interface TrackImpressionPushNotificationReactivationPrompt extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  action_location?: string | null;
}

export interface TrackImpressionQuestHome extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionQuestsLandingPage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  quest_id?: number | null;
}

export interface TrackImpressionRequestReviewModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata {
}

export interface TrackImpressionRoleCreateAddMembers extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleCreateDisplay extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleCreatePermissions extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleSubscriptionEmojiUpsell extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  emoji_id?: string | null;
  emoji_guild_id?: string | null;
}

export interface TrackImpressionRoleSubscriptionInitialSetupModalLanding extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleSubscriptionInitialSetupModalTierStep extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleSubscriptionListingTemplateSelector extends TrackBase, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionRoleSubscriptionListingUpsellErrorPage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  error_page_type?: string | null;
}

export interface TrackImpressionShelfActivityDetails extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  application_id?: string | null;
  source_section?: string | null;
}

export interface TrackImpressionSnowsgiving extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionSoundboardPopout extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  media_session_id?: string | null;
  source?: string | null;
  guild_sound_count?: number | null;
}

export interface TrackImpressionStageDiscovery extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUrfConfirmEmailCode extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUrfEnterEmail extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailChangeCollectReasons extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailChangeComplete extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailChangeEnterEmail extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  email_verified?: boolean | null;
}

export interface TrackImpressionUserAccountEmailChangeSendCode extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailChangeVerifyCode extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailChangeWarning extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountEmailResendVerificationEmail extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAccountPasswordVerify extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserActionRequired extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  verification_type?: string | null;
  verification_types?: Array<string> | null;
}

export interface TrackImpressionUserAddPhone extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserAgeGate extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  existing_user?: boolean | null;
}

export interface TrackImpressionUserAgeGateUnderage extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  existing_user?: boolean | null;
}

export interface TrackImpressionUserAgreements extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  required_action?: string | null;
}

export interface TrackImpressionUserClydeAiConsentModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserLogin extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  password_only?: boolean | null;
}

export interface TrackImpressionUserPasswordlessCodeEntry extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserPasswordlessIntro extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserRegisterAccountInformation extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserRegisterIdentity extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserRegistration extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  step?: string | null;
}

export interface TrackImpressionUserSettingsConnections extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  source?: string | null;
}

export interface TrackImpressionUserVerificationModal extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  step?: string | null;
}

export interface TrackImpressionUserVerifyPassword extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserVerifyPhone extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserWelcome extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionUserYouScreen extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
}

export interface TrackImpressionViewPanelDevtools extends TrackBase, TrackChannel, TrackGuild, TrackImpressionMetadata, TrackLocationMetadata, TrackLocationStackMetadata {
  panel?: string | null;
}

export interface TrackNetworkActionAppleJwtTokenCreate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  operation?: string | null;
  request_identifier?: string | null;
  jwt_token_exists?: boolean | null;
  subscription_items_json_string?: string | null;
  currency?: string | null;
  request_country_code?: string | null;
  subscription_id?: string | null;
  sku_id?: string | null;
}

export interface TrackNetworkActionAuthSessionsLoggedOut extends TrackBase {
  session_ids?: Array<string> | null;
  num_session_ids?: number | null;
}

export interface TrackNetworkActionAuthorizeIp extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionAuthorizePayment extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionBugReportSubmit extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  priority?: number | null;
  asana_inbox_id?: string | null;
}

export interface TrackNetworkActionChannelCreate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  is_private?: boolean | null;
  channel_type?: number | null;
  channel_id?: string | null;
  parent_id?: string | null;
  guild_id?: string | null;
}

export interface TrackNetworkActionDetectableApplicationsFetch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  sent_etag?: string | null;
  received_etag?: string | null;
}

export interface TrackNetworkActionDirectoryGuildEntryCreate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  directory_channel_id?: string | null;
  guild_id?: string | null;
  primary_category_id?: number | null;
}

export interface TrackNetworkActionDirectoryGuildEntryDelete extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  directory_channel_id?: string | null;
  guild_id?: string | null;
}

export interface TrackNetworkActionEmailSettingsFetch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  initialized?: boolean | null;
}

export interface TrackNetworkActionEmailSettingsUpdate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  category?: string | null;
  value?: boolean | null;
  categories?: Array<string> | null;
  values?: Array<boolean> | null;
}

export interface TrackNetworkActionEmbeddedActivitiesFetchShelf extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  guild_id?: string | null;
}

export interface TrackNetworkActionEmbeddedActivitiesLaunch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  guild_id?: string | null;
  channel_id?: string | null;
  application_id?: string | null;
  session_id?: string | null;
}

export interface TrackNetworkActionForgotPassword extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionGuildCreate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  template_name?: string | null;
  is_community_intent?: boolean | null;
}

export interface TrackNetworkActionGuildTransferOwnership extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  guild_id?: string | null;
  verification_type?: string | null;
}

export interface TrackNetworkActionGuildTransferOwnershipSendCode extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  guild_id?: string | null;
  is_resend?: boolean | null;
}

export interface TrackNetworkActionHubEmailVerify extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionHubEmailVerifySend extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  has_matching_guild?: boolean | null;
}

export interface TrackNetworkActionHubWaitlistSignup extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  is_edu_email?: boolean | null;
}

export interface TrackNetworkActionInviteResolve extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  resolved?: boolean | null;
  code?: string | null;
  authenticated?: boolean | null;
  guild_id?: string | null;
  channel_id?: string | null;
  channel_type?: number | null;
  inviter_id?: string | null;
  size_total?: number | null;
  size_online?: number | null;
  invite_type?: string | null;
  destination_user_id?: string | null;
  user_banned?: boolean | null;
  input_value?: string | null;
}

export interface TrackNetworkActionInviteRevoke extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  uses?: number | null;
  max_uses?: number | null;
  max_age?: number | null;
  invite_type?: number | null;
}

export interface TrackNetworkActionLoginRequestSmsToken extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionNcmecReportClose extends TrackBase {
  ncmec_batch_report_id?: number | null;
  ncmec_cybertip_id?: string | null;
  ncmec_task_id?: string | null;
  reported_user_id?: string | null;
  reported_attachment_ids?: Array<number> | null;
  archive_id?: string | null;
  submit_status?: string | null;
}

export interface TrackNetworkActionNcmecReportableContentCreate extends TrackBase {
  ncmec_batch_report_id?: number | null;
  reported_user_id?: string | null;
  reportable_content_id?: any | null;
  is_self_generated?: boolean | null;
  attachment_hash?: string | null;
  approximate_age?: number | null;
  status?: string | null;
  reportable_content_id_json?: string | null;
}

export interface TrackNetworkActionNotificationCenterItemDelete extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  notification_center_id?: string | null;
  acked?: boolean | null;
  item_type?: string | null;
}

export interface TrackNetworkActionNotificationCenterPageFetch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  notification_center_id?: string | null;
  page?: number | null;
  items?: Array<string> | null;
  item_count?: number | null;
}

export interface TrackNetworkActionPomeloAttempt extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  requested_username?: string | null;
}

export interface TrackNetworkActionPomeloCreate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  one_click_flow?: boolean | null;
}

export interface TrackNetworkActionQuestHeartbeat extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  quest_id?: string | null;
  terminal?: boolean | null;
  is_overlay?: boolean | null;
  stack_trace?: string | null;
  is_playtime_eligible?: boolean | null;
}

export interface TrackNetworkActionStreamNotify extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserAcceptAgreements extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserAccountEmailChangeSendCode extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  is_resend?: boolean | null;
}

export interface TrackNetworkActionUserAccountEmailChangeVerifyCode extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserBulkRelationshipsUpdate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserClydeAiConsentUpdated extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  consent?: boolean | null;
}

export interface TrackNetworkActionUserCommunicationDisabledUpdate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  guild_id?: string | null;
  target_user_id?: string | null;
  duration?: number | null;
  reason?: string | null;
  communication_disabled_until?: any | null;
}

export interface TrackNetworkActionUserConnectionsUpdate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  name?: string | null;
  friend_sync?: boolean | null;
  visibility?: boolean | null;
  show_activity?: boolean | null;
}

export interface TrackNetworkActionUserContactsSync extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserLogin extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  invite_code?: string | null;
  is_multi_account?: boolean | null;
}

export interface TrackNetworkActionUserLoginMfa extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserLoginMfaSms extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserLoginPasswordless extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserLogout extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserPasswordlessInfoFetch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserPasswordlessLoginCode extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  retry?: boolean | null;
}

export interface TrackNetworkActionUserRegister extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  invite_code?: string | null;
  promotional_email_opt_in?: boolean | null;
  promotional_email_pre_checked?: boolean | null;
  used_username_suggestion?: boolean | null;
  was_unique_username?: boolean | null;
}

export interface TrackNetworkActionUserRegisterDeviceToken extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserRegisterPhone extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserResetPassword extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  mfa?: boolean | null;
}

export interface TrackNetworkActionUserSettingsUpdate extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  key?: string | null;
  value?: string | null;
}

export interface TrackNetworkActionUserSurveyFetch extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  key?: string | null;
}

export interface TrackNetworkActionUserSurveySeen extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
  key?: string | null;
}

export interface TrackNetworkActionUserUnregisterDeviceToken extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserVerify extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserVerifyPhone extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionUserVerifyResend extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface TrackNetworkActionWebauthnRegister extends TrackBase, TrackLocationMetadata, TrackNetworkMetadata {
}

export interface StandardAnalyticsSchemaNameMap {
  [ImpressionNames.ACTIVITIES]: TrackImpressionActivities;
  [ImpressionNames.ACTIVITIES_HAPPENING_NOW]: TrackImpressionActivitiesHappeningNow;
  [ImpressionNames.ACTIVITY_BOOKMARK_SHARE_MODAL]: TrackImpressionActivityBookmarkShareModal;
  [ImpressionNames.ACTIVITY_BOOSTING_UPSELL]: TrackImpressionActivityBoostingUpsell;
  [ImpressionNames.ACTIVITY_DETAILS]: TrackImpressionActivityDetails;
  [ImpressionNames.ACTIVITY_NITRO_UPSELL]: TrackImpressionActivityNitroUpsell;
  [ImpressionNames.ACTIVITY_SHARE_MOMENT_MODAL]: TrackImpressionActivityShareMomentModal;
  [ImpressionNames.ACTIVITY_SHELF]: TrackImpressionActivityShelf;
  [ImpressionNames.ACTIVITY_SHELF_SELECT_CHANNEL]: TrackImpressionActivityShelfSelectChannel;
  [ImpressionNames.APP_LAUNCHER_HOME_ACTIVITY_ITEM]: TrackImpressionAppLauncherHomeActivityItem;
  [ImpressionNames.APP_LAUNCHER_ITEM]: TrackImpressionAppLauncherItem;
  [ImpressionNames.APP_LAUNCHER_SEARCH_RESULTS_ITEM]: TrackImpressionAppLauncherSearchResultsItem;
  [ImpressionNames.APP_LAUNCHER_SECTION]: TrackImpressionAppLauncherSection;
  [ImpressionNames.APPLICATION_LIBRARY]: TrackImpressionApplicationLibrary;
  [ImpressionNames.APPLICATION_STORE]: TrackImpressionApplicationStore;
  [ImpressionNames.APRIL_PREMIUM_MARKETING_DECO_CLAIMED_MODAL]: TrackImpressionAprilPremiumMarketingDecoClaimedModal;
  [ImpressionNames.AVATAR_UPLOAD]: TrackImpressionAvatarUpload;
  [ImpressionNames.CHANNEL_ADD_INFO]: TrackImpressionChannelAddInfo;
  [ImpressionNames.CHANNEL_ADD_MEMBERS]: TrackImpressionChannelAddMembers;
  [ImpressionNames.CHANNEL_CALL_VIDEO_GRID]: TrackImpressionChannelCallVideoGrid;
  [ImpressionNames.CHANNEL_CALL_VIDEO_GRID_VIEW]: TrackImpressionChannelCallVideoGridView;
  [ImpressionNames.CHANNEL_VIEWED]: TrackImpressionChannelViewed;
  [ImpressionNames.CLIP_EDITOR_VIEWED]: TrackImpressionClipEditorViewed;
  [ImpressionNames.CLIP_GALLERY_VIEWED]: TrackImpressionClipGalleryViewed;
  [ImpressionNames.CLYDE_AI_PROFILE_EMBED_VIEWED]: TrackImpressionClydeAiProfileEmbedViewed;
  [ImpressionNames.COLLECTIBLES_EXPIRY_MODAL]: TrackImpressionCollectiblesExpiryModal;
  [ImpressionNames.CONTACT_SYNC_CONTACT_INVITES]: TrackImpressionContactSyncContactInvites;
  [ImpressionNames.CONTACT_SYNC_INPUT_NAME]: TrackImpressionContactSyncInputName;
  [ImpressionNames.CONTACT_SYNC_START]: TrackImpressionContactSyncStart;
  [ImpressionNames.CONTACT_SYNC_SUGGESTIONS]: TrackImpressionContactSyncSuggestions;
  [ImpressionNames.CREATOR_PROMO_PAGE_GUILD_HEADER_UPSELL]: TrackImpressionCreatorPromoPageGuildHeaderUpsell;
  [ImpressionNames.DIRECTORY_ADD_GUILD_CONFIRMATION]: TrackImpressionDirectoryAddGuildConfirmation;
  [ImpressionNames.DISCOVERABILITY]: TrackImpressionDiscoverability;
  [ImpressionNames.DROPS_QUEST_COMPLETION]: TrackImpressionDropsQuestCompletion;
  [ImpressionNames.DROPS_QUEST_ENROLLMENT]: TrackImpressionDropsQuestEnrollment;
  [ImpressionNames.EMBEDDED_ACTIVITY_HAPPENING_NOW]: TrackImpressionEmbeddedActivityHappeningNow;
  [ImpressionNames.ENABLE_CREATOR_MONETIZATION_ACCEPT_TERMS_LANDING]: TrackImpressionEnableCreatorMonetizationAcceptTermsLanding;
  [ImpressionNames.ENABLE_CREATOR_MONETIZATION_CREATE_REQUEST_LANDING]: TrackImpressionEnableCreatorMonetizationCreateRequestLanding;
  [ImpressionNames.ENABLE_CREATOR_MONETIZATION_GUILD_HEADER_UPSELL]: TrackImpressionEnableCreatorMonetizationGuildHeaderUpsell;
  [ImpressionNames.ENABLE_CREATOR_MONETIZATION_WAITLIST_LANDING]: TrackImpressionEnableCreatorMonetizationWaitlistLanding;
  [ImpressionNames.FRIEND_REQUESTS_SPAM_INBOX]: TrackImpressionFriendRequestsSpamInbox;
  [ImpressionNames.FRIENDS]: TrackImpressionFriends;
  [ImpressionNames.GAME_CONSOLE_DEVICE_LIST]: TrackImpressionGameConsoleDeviceList;
  [ImpressionNames.GDM_SETTINGS_INVITES]: TrackImpressionGdmSettingsInvites;
  [ImpressionNames.GLOBAL_DISCOVERY]: TrackImpressionGlobalDiscovery;
  [ImpressionNames.GUILD_ADD_ACCEPT_INVITE]: TrackImpressionGuildAddAcceptInvite;
  [ImpressionNames.GUILD_ADD_CHANNEL_PROMPT]: TrackImpressionGuildAddChannelPrompt;
  [ImpressionNames.GUILD_ADD_CUSTOMIZE]: TrackImpressionGuildAddCustomize;
  [ImpressionNames.GUILD_ADD_GUILD_INVITE]: TrackImpressionGuildAddGuildInvite;
  [ImpressionNames.GUILD_ADD_INTENT_SELECTION]: TrackImpressionGuildAddIntentSelection;
  [ImpressionNames.GUILD_ADD_JOIN]: TrackImpressionGuildAddJoin;
  [ImpressionNames.GUILD_ADD_LANDING]: TrackImpressionGuildAddLanding;
  [ImpressionNames.GUILD_CHANNEL]: TrackImpressionGuildChannel;
  [ImpressionNames.GUILD_CREATE_MODAL_JOIN]: TrackImpressionGuildCreateModalJoin;
  [ImpressionNames.GUILD_DISCOVERY]: TrackImpressionGuildDiscovery;
  [ImpressionNames.GUILD_INVITE]: TrackImpressionGuildInvite;
  [ImpressionNames.GUILD_INVITE_LINK_SETTINGS]: TrackImpressionGuildInviteLinkSettings;
  [ImpressionNames.GUILD_INVITE_SEARCH]: TrackImpressionGuildInviteSearch;
  [ImpressionNames.GUILD_MEMBER_VERIFICATION]: TrackImpressionGuildMemberVerification;
  [ImpressionNames.GUILD_PERMANENT_LINKS_UPSELL]: TrackImpressionGuildPermanentLinksUpsell;
  [ImpressionNames.GUILD_PRODUCT_LISTING_EMBED]: TrackImpressionGuildProductListingEmbed;
  [ImpressionNames.GUILD_PRODUCT_LISTING_INFO_MODAL]: TrackImpressionGuildProductListingInfoModal;
  [ImpressionNames.GUILD_ROLE_SUBSCRIPTION_STORE_PAGE_GUILD_SETTINGS]: TrackImpressionGuildRoleSubscriptionStorePageGuildSettings;
  [ImpressionNames.GUILD_SETTINGS_ANALYTICS]: TrackImpressionGuildSettingsAnalytics;
  [ImpressionNames.GUILD_SETTINGS_AUDIT_LOG]: TrackImpressionGuildSettingsAuditLog;
  [ImpressionNames.GUILD_SETTINGS_AUDIT_LOG_V2]: TrackImpressionGuildSettingsAuditLogV2;
  [ImpressionNames.GUILD_SETTINGS_BANS]: TrackImpressionGuildSettingsBans;
  [ImpressionNames.GUILD_SETTINGS_BOOST_STATUS]: TrackImpressionGuildSettingsBoostStatus;
  [ImpressionNames.GUILD_SETTINGS_CHANNELS]: TrackImpressionGuildSettingsChannels;
  [ImpressionNames.GUILD_SETTINGS_CLYDE]: TrackImpressionGuildSettingsClyde;
  [ImpressionNames.GUILD_SETTINGS_COMMUNITY_OVERVIEW]: TrackImpressionGuildSettingsCommunityOverview;
  [ImpressionNames.GUILD_SETTINGS_COMMUNITY_WELCOME]: TrackImpressionGuildSettingsCommunityWelcome;
  [ImpressionNames.GUILD_SETTINGS_DISCOVERY]: TrackImpressionGuildSettingsDiscovery;
  [ImpressionNames.GUILD_SETTINGS_DISCOVERY_LANDING_PAGE]: TrackImpressionGuildSettingsDiscoveryLandingPage;
  [ImpressionNames.GUILD_SETTINGS_EMOJI]: TrackImpressionGuildSettingsEmoji;
  [ImpressionNames.GUILD_SETTINGS_ENABLE_COMMUNITY]: TrackImpressionGuildSettingsEnableCommunity;
  [ImpressionNames.GUILD_SETTINGS_INTEGRATION]: TrackImpressionGuildSettingsIntegration;
  [ImpressionNames.GUILD_SETTINGS_INVITES]: TrackImpressionGuildSettingsInvites;
  [ImpressionNames.GUILD_SETTINGS_LANDING]: TrackImpressionGuildSettingsLanding;
  [ImpressionNames.GUILD_SETTINGS_MEMBER_VERIFICATION]: TrackImpressionGuildSettingsMemberVerification;
  [ImpressionNames.GUILD_SETTINGS_MEMBERS]: TrackImpressionGuildSettingsMembers;
  [ImpressionNames.GUILD_SETTINGS_MODERATION]: TrackImpressionGuildSettingsModeration;
  [ImpressionNames.GUILD_SETTINGS_OVERVIEW]: TrackImpressionGuildSettingsOverview;
  [ImpressionNames.GUILD_SETTINGS_PARTNER]: TrackImpressionGuildSettingsPartner;
  [ImpressionNames.GUILD_SETTINGS_ROLES]: TrackImpressionGuildSettingsRoles;
  [ImpressionNames.GUILD_SETTINGS_SAFETY]: TrackImpressionGuildSettingsSafety;
  [ImpressionNames.GUILD_SETTINGS_SECURITY]: TrackImpressionGuildSettingsSecurity;
  [ImpressionNames.GUILD_SETTINGS_SOUNDBOARD]: TrackImpressionGuildSettingsSoundboard;
  [ImpressionNames.GUILD_SETTINGS_STICKERS]: TrackImpressionGuildSettingsStickers;
  [ImpressionNames.GUILD_SETTINGS_TEMPLATE]: TrackImpressionGuildSettingsTemplate;
  [ImpressionNames.GUILD_SETTINGS_VANITY_URL]: TrackImpressionGuildSettingsVanityUrl;
  [ImpressionNames.GUILD_SETTINGS_WEBHOOKS]: TrackImpressionGuildSettingsWebhooks;
  [ImpressionNames.GUILD_SETTINGS_WIDGET]: TrackImpressionGuildSettingsWidget;
  [ImpressionNames.GUILD_SHOP_EMBED]: TrackImpressionGuildShopEmbed;
  [ImpressionNames.GUILD_SHOP_PAGE]: TrackImpressionGuildShopPage;
  [ImpressionNames.GUILD_SHOP_UPSELL]: TrackImpressionGuildShopUpsell;
  [ImpressionNames.GUILD_TRANSFER_OWNERSHIP]: TrackImpressionGuildTransferOwnership;
  [ImpressionNames.GUILD_TRANSFER_OWNERSHIP_CONFIRM_EMAIL_CODE]: TrackImpressionGuildTransferOwnershipConfirmEmailCode;
  [ImpressionNames.GUILD_TRANSFER_OWNERSHIP_CONFIRM_SMS_CODE]: TrackImpressionGuildTransferOwnershipConfirmSmsCode;
  [ImpressionNames.GUILDS_EMPTY_NUX]: TrackImpressionGuildsEmptyNux;
  [ImpressionNames.HD_STREAMING_POPOUT]: TrackImpressionHdStreamingPopout;
  [ImpressionNames.HOTSPOT]: TrackImpressionHotspot;
  [ImpressionNames.HUB_CREATE_GUILD_CUSTOMIZE]: TrackImpressionHubCreateGuildCustomize;
  [ImpressionNames.HUB_CREATE_GUILD_TEMPLATE]: TrackImpressionHubCreateGuildTemplate;
  [ImpressionNames.HUB_EMAIL_SIGNUP]: TrackImpressionHubEmailSignup;
  [ImpressionNames.HUB_EMAIL_VERIFICATION_PAGE]: TrackImpressionHubEmailVerificationPage;
  [ImpressionNames.HUB_EXISTING_GUILD_CHOOSE]: TrackImpressionHubExistingGuildChoose;
  [ImpressionNames.HUB_EXISTING_GUILD_CUSTOMIZE]: TrackImpressionHubExistingGuildCustomize;
  [ImpressionNames.HUB_WAITLIST_SIGNUP]: TrackImpressionHubWaitlistSignup;
  [ImpressionNames.INVITE_ACCEPT]: TrackImpressionInviteAccept;
  [ImpressionNames.LOCALIZED_PRICING_UPSELL_VIEWED]: TrackImpressionLocalizedPricingUpsellViewed;
  [ImpressionNames.MESSAGES_EMPTY_NUX]: TrackImpressionMessagesEmptyNux;
  [ImpressionNames.MULTI_ACCOUNT_SWITCH_LANDING]: TrackImpressionMultiAccountSwitchLanding;
  [ImpressionNames.NEW_USER_INTENT_START]: TrackImpressionNewUserIntentStart;
  [ImpressionNames.NITRO_TAB]: TrackImpressionNitroTab;
  [ImpressionNames.NOTIFICATION_CENTER_LANDING]: TrackImpressionNotificationCenterLanding;
  [ImpressionNames.NOTIFICATION_SETTING_UNREAD_NUDGE]: TrackImpressionNotificationSettingUnreadNudge;
  [ImpressionNames.PERK_DISCOVERABILITY_CARD]: TrackImpressionPerkDiscoverabilityCard;
  [ImpressionNames.POLL_EDITOR_VIEWED]: TrackImpressionPollEditorViewed;
  [ImpressionNames.POMELO_LANDING]: TrackImpressionPomeloLanding;
  [ImpressionNames.PREMIUM_GUILD_SUBSCRIPTION_MARKETING_PAGE]: TrackImpressionPremiumGuildSubscriptionMarketingPage;
  [ImpressionNames.PREMIUM_MARKETING_BANNER]: TrackImpressionPremiumMarketingBanner;
  [ImpressionNames.PREMIUM_MARKETING_SURFACE]: TrackImpressionPremiumMarketingSurface;
  [ImpressionNames.PREMIUM_MARKETING_TENURE_REWARD_CARD]: TrackImpressionPremiumMarketingTenureRewardCard;
  [ImpressionNames.PUSH_NOTIFICATION_PREPROMPT]: TrackImpressionPushNotificationPreprompt;
  [ImpressionNames.PUSH_NOTIFICATION_REACTIVATION_PROMPT]: TrackImpressionPushNotificationReactivationPrompt;
  [ImpressionNames.QUEST_HOME]: TrackImpressionQuestHome;
  [ImpressionNames.QUESTS_LANDING_PAGE]: TrackImpressionQuestsLandingPage;
  [ImpressionNames.REQUEST_REVIEW_MODAL]: TrackImpressionRequestReviewModal;
  [ImpressionNames.ROLE_CREATE_ADD_MEMBERS]: TrackImpressionRoleCreateAddMembers;
  [ImpressionNames.ROLE_CREATE_DISPLAY]: TrackImpressionRoleCreateDisplay;
  [ImpressionNames.ROLE_CREATE_PERMISSIONS]: TrackImpressionRoleCreatePermissions;
  [ImpressionNames.ROLE_SUBSCRIPTION_EMOJI_UPSELL]: TrackImpressionRoleSubscriptionEmojiUpsell;
  [ImpressionNames.ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_LANDING]: TrackImpressionRoleSubscriptionInitialSetupModalLanding;
  [ImpressionNames.ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_TIER_STEP]: TrackImpressionRoleSubscriptionInitialSetupModalTierStep;
  [ImpressionNames.ROLE_SUBSCRIPTION_LISTING_TEMPLATE_SELECTOR]: TrackImpressionRoleSubscriptionListingTemplateSelector;
  [ImpressionNames.ROLE_SUBSCRIPTION_LISTING_UPSELL_ERROR_PAGE]: TrackImpressionRoleSubscriptionListingUpsellErrorPage;
  [ImpressionNames.SHELF_ACTIVITY_DETAILS]: TrackImpressionShelfActivityDetails;
  [ImpressionNames.SNOWSGIVING]: TrackImpressionSnowsgiving;
  [ImpressionNames.SOUNDBOARD_POPOUT]: TrackImpressionSoundboardPopout;
  [ImpressionNames.STAGE_DISCOVERY]: TrackImpressionStageDiscovery;
  [ImpressionNames.URF_CONFIRM_EMAIL_CODE]: TrackImpressionUrfConfirmEmailCode;
  [ImpressionNames.URF_ENTER_EMAIL]: TrackImpressionUrfEnterEmail;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_COLLECT_REASONS]: TrackImpressionUserAccountEmailChangeCollectReasons;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_COMPLETE]: TrackImpressionUserAccountEmailChangeComplete;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_ENTER_EMAIL]: TrackImpressionUserAccountEmailChangeEnterEmail;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE]: TrackImpressionUserAccountEmailChangeSendCode;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE]: TrackImpressionUserAccountEmailChangeVerifyCode;
  [ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_WARNING]: TrackImpressionUserAccountEmailChangeWarning;
  [ImpressionNames.USER_ACCOUNT_EMAIL_RESEND_VERIFICATION_EMAIL]: TrackImpressionUserAccountEmailResendVerificationEmail;
  [ImpressionNames.USER_ACCOUNT_PASSWORD_VERIFY]: TrackImpressionUserAccountPasswordVerify;
  [ImpressionNames.USER_ACTION_REQUIRED]: TrackImpressionUserActionRequired;
  [ImpressionNames.USER_ADD_PHONE]: TrackImpressionUserAddPhone;
  [ImpressionNames.USER_AGE_GATE]: TrackImpressionUserAgeGate;
  [ImpressionNames.USER_AGE_GATE_UNDERAGE]: TrackImpressionUserAgeGateUnderage;
  [ImpressionNames.USER_AGREEMENTS]: TrackImpressionUserAgreements;
  [ImpressionNames.USER_CLYDE_AI_CONSENT_MODAL]: TrackImpressionUserClydeAiConsentModal;
  [ImpressionNames.USER_LOGIN]: TrackImpressionUserLogin;
  [ImpressionNames.USER_PASSWORDLESS_CODE_ENTRY]: TrackImpressionUserPasswordlessCodeEntry;
  [ImpressionNames.USER_PASSWORDLESS_INTRO]: TrackImpressionUserPasswordlessIntro;
  [ImpressionNames.USER_REGISTER_ACCOUNT_INFORMATION]: TrackImpressionUserRegisterAccountInformation;
  [ImpressionNames.USER_REGISTER_IDENTITY]: TrackImpressionUserRegisterIdentity;
  [ImpressionNames.USER_REGISTRATION]: TrackImpressionUserRegistration;
  [ImpressionNames.USER_SETTINGS_CONNECTIONS]: TrackImpressionUserSettingsConnections;
  [ImpressionNames.USER_VERIFICATION_MODAL]: TrackImpressionUserVerificationModal;
  [ImpressionNames.USER_VERIFY_PASSWORD]: TrackImpressionUserVerifyPassword;
  [ImpressionNames.USER_VERIFY_PHONE]: TrackImpressionUserVerifyPhone;
  [ImpressionNames.USER_WELCOME]: TrackImpressionUserWelcome;
  [ImpressionNames.USER_YOU_SCREEN]: TrackImpressionUserYouScreen;
  [ImpressionNames.VIEW_PANEL_DEVTOOLS]: TrackImpressionViewPanelDevtools;
  [NetworkActionNames.APPLE_JWT_TOKEN_CREATE]: TrackNetworkActionAppleJwtTokenCreate;
  [NetworkActionNames.AUTH_SESSIONS_LOGGED_OUT]: TrackNetworkActionAuthSessionsLoggedOut;
  [NetworkActionNames.AUTHORIZE_IP]: TrackNetworkActionAuthorizeIp;
  [NetworkActionNames.AUTHORIZE_PAYMENT]: TrackNetworkActionAuthorizePayment;
  [NetworkActionNames.BUG_REPORT_SUBMIT]: TrackNetworkActionBugReportSubmit;
  [NetworkActionNames.CHANNEL_CREATE]: TrackNetworkActionChannelCreate;
  [NetworkActionNames.DETECTABLE_APPLICATIONS_FETCH]: TrackNetworkActionDetectableApplicationsFetch;
  [NetworkActionNames.DIRECTORY_GUILD_ENTRY_CREATE]: TrackNetworkActionDirectoryGuildEntryCreate;
  [NetworkActionNames.DIRECTORY_GUILD_ENTRY_DELETE]: TrackNetworkActionDirectoryGuildEntryDelete;
  [NetworkActionNames.EMAIL_SETTINGS_FETCH]: TrackNetworkActionEmailSettingsFetch;
  [NetworkActionNames.EMAIL_SETTINGS_UPDATE]: TrackNetworkActionEmailSettingsUpdate;
  [NetworkActionNames.EMBEDDED_ACTIVITIES_FETCH_SHELF]: TrackNetworkActionEmbeddedActivitiesFetchShelf;
  [NetworkActionNames.EMBEDDED_ACTIVITIES_LAUNCH]: TrackNetworkActionEmbeddedActivitiesLaunch;
  [NetworkActionNames.FORGOT_PASSWORD]: TrackNetworkActionForgotPassword;
  [NetworkActionNames.GUILD_CREATE]: TrackNetworkActionGuildCreate;
  [NetworkActionNames.GUILD_TRANSFER_OWNERSHIP]: TrackNetworkActionGuildTransferOwnership;
  [NetworkActionNames.GUILD_TRANSFER_OWNERSHIP_SEND_CODE]: TrackNetworkActionGuildTransferOwnershipSendCode;
  [NetworkActionNames.HUB_EMAIL_VERIFY]: TrackNetworkActionHubEmailVerify;
  [NetworkActionNames.HUB_EMAIL_VERIFY_SEND]: TrackNetworkActionHubEmailVerifySend;
  [NetworkActionNames.HUB_WAITLIST_SIGNUP]: TrackNetworkActionHubWaitlistSignup;
  [NetworkActionNames.INVITE_RESOLVE]: TrackNetworkActionInviteResolve;
  [NetworkActionNames.INVITE_REVOKE]: TrackNetworkActionInviteRevoke;
  [NetworkActionNames.LOGIN_REQUEST_SMS_TOKEN]: TrackNetworkActionLoginRequestSmsToken;
  [NetworkActionNames.NCMEC_REPORT_CLOSE]: TrackNetworkActionNcmecReportClose;
  [NetworkActionNames.NCMEC_REPORTABLE_CONTENT_CREATE]: TrackNetworkActionNcmecReportableContentCreate;
  [NetworkActionNames.NOTIFICATION_CENTER_ITEM_DELETE]: TrackNetworkActionNotificationCenterItemDelete;
  [NetworkActionNames.NOTIFICATION_CENTER_PAGE_FETCH]: TrackNetworkActionNotificationCenterPageFetch;
  [NetworkActionNames.POMELO_ATTEMPT]: TrackNetworkActionPomeloAttempt;
  [NetworkActionNames.POMELO_CREATE]: TrackNetworkActionPomeloCreate;
  [NetworkActionNames.QUEST_HEARTBEAT]: TrackNetworkActionQuestHeartbeat;
  [NetworkActionNames.STREAM_NOTIFY]: TrackNetworkActionStreamNotify;
  [NetworkActionNames.USER_ACCEPT_AGREEMENTS]: TrackNetworkActionUserAcceptAgreements;
  [NetworkActionNames.USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE]: TrackNetworkActionUserAccountEmailChangeSendCode;
  [NetworkActionNames.USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE]: TrackNetworkActionUserAccountEmailChangeVerifyCode;
  [NetworkActionNames.USER_BULK_RELATIONSHIPS_UPDATE]: TrackNetworkActionUserBulkRelationshipsUpdate;
  [NetworkActionNames.USER_CLYDE_AI_CONSENT_UPDATED]: TrackNetworkActionUserClydeAiConsentUpdated;
  [NetworkActionNames.USER_COMMUNICATION_DISABLED_UPDATE]: TrackNetworkActionUserCommunicationDisabledUpdate;
  [NetworkActionNames.USER_CONNECTIONS_UPDATE]: TrackNetworkActionUserConnectionsUpdate;
  [NetworkActionNames.USER_CONTACTS_SYNC]: TrackNetworkActionUserContactsSync;
  [NetworkActionNames.USER_LOGIN]: TrackNetworkActionUserLogin;
  [NetworkActionNames.USER_LOGIN_MFA]: TrackNetworkActionUserLoginMfa;
  [NetworkActionNames.USER_LOGIN_MFA_SMS]: TrackNetworkActionUserLoginMfaSms;
  [NetworkActionNames.USER_LOGIN_PASSWORDLESS]: TrackNetworkActionUserLoginPasswordless;
  [NetworkActionNames.USER_LOGOUT]: TrackNetworkActionUserLogout;
  [NetworkActionNames.USER_PASSWORDLESS_INFO_FETCH]: TrackNetworkActionUserPasswordlessInfoFetch;
  [NetworkActionNames.USER_PASSWORDLESS_LOGIN_CODE]: TrackNetworkActionUserPasswordlessLoginCode;
  [NetworkActionNames.USER_REGISTER]: TrackNetworkActionUserRegister;
  [NetworkActionNames.USER_REGISTER_DEVICE_TOKEN]: TrackNetworkActionUserRegisterDeviceToken;
  [NetworkActionNames.USER_REGISTER_PHONE]: TrackNetworkActionUserRegisterPhone;
  [NetworkActionNames.USER_RESET_PASSWORD]: TrackNetworkActionUserResetPassword;
  [NetworkActionNames.USER_SETTINGS_UPDATE]: TrackNetworkActionUserSettingsUpdate;
  [NetworkActionNames.USER_SURVEY_FETCH]: TrackNetworkActionUserSurveyFetch;
  [NetworkActionNames.USER_SURVEY_SEEN]: TrackNetworkActionUserSurveySeen;
  [NetworkActionNames.USER_UNREGISTER_DEVICE_TOKEN]: TrackNetworkActionUserUnregisterDeviceToken;
  [NetworkActionNames.USER_VERIFY]: TrackNetworkActionUserVerify;
  [NetworkActionNames.USER_VERIFY_PHONE]: TrackNetworkActionUserVerifyPhone;
  [NetworkActionNames.USER_VERIFY_RESEND]: TrackNetworkActionUserVerifyResend;
  [NetworkActionNames.WEBAUTHN_REGISTER]: TrackNetworkActionWebauthnRegister;
}

/**
 *
 * Enum of standardized impression event names
 * @readonly
 * @enum {string}
 */
export enum ImpressionNames {
  /** @member {string} */
  /** impression event: Tracking impressions of the Activities "Tab"/"Home" */
  ACTIVITIES = 'impression_activities',
  /** @member {string} */
  /** impression event: Tracking impressions of the "Happening Now" section in the /activities page */
  ACTIVITIES_HAPPENING_NOW = 'impression_activities_happening_now',
  /** @member {string} */
  /** impression event: This is fired when a the Activity Bookmark Share Modal is opened. The modal is opened (currently) when a user clicks on a "Share" button from an activity detail page */
  ACTIVITY_BOOKMARK_SHARE_MODAL = 'impression_activity_bookmark_share_modal',
  /** @member {string} */
  /** impression event: The user is shown an upsell for boosting to get activities */
  ACTIVITY_BOOSTING_UPSELL = 'impression_activity_boosting_upsell',
  /** @member {string} */
  /** impression event: Tracking impressions of Activity Details pages */
  ACTIVITY_DETAILS = 'impression_activity_details',
  /** @member {string} */
  /** impression event: The user is shown an upsell for nitro to get activities */
  ACTIVITY_NITRO_UPSELL = 'impression_activity_nitro_upsell',
  /** @member {string} */
  /** impression event: This is fired when a user sees the Activity Share Moment modal, in response to receiving the OPEN_SHARE_MOMENT_DILOG RPC command. */
  ACTIVITY_SHARE_MOMENT_MODAL = 'impression_activity_share_moment_modal',
  /** @member {string} */
  /** impression event: The user opens the activity shelf */
  ACTIVITY_SHELF = 'impression_activity_shelf',
  /** @member {string} */
  /** impression event: The user is shown a form for selecting a voice channel to play the selected activity in */
  ACTIVITY_SHELF_SELECT_CHANNEL = 'impression_activity_shelf_select_channel',
  /** @member {string} */
  /** impression event: represents a single activity item getting viewed in the app launcher home */
  APP_LAUNCHER_HOME_ACTIVITY_ITEM = 'impression_app_launcher_home_activity_item',
  /** @member {string} */
  /** impression event: represents an individual item (e.g. app) being viewed in app launcher */
  APP_LAUNCHER_ITEM = 'impression_app_launcher_item',
  /** @member {string} */
  /** impression event: represents an individual command or app being viewed in the app launcher search results list */
  APP_LAUNCHER_SEARCH_RESULTS_ITEM = 'impression_app_launcher_search_results_item',
  /** @member {string} */
  /** impression event: represents a section of recommendation items getting viewed in the app launcher home */
  APP_LAUNCHER_SECTION = 'impression_app_launcher_section',
  /** @member {string} */
  /** impression event: Purchased games from the game store. Page impression not currently tracked. */
  APPLICATION_LIBRARY = 'impression_application_library',
  /** @member {string} */
  /** impression event: The applications / games store front. Page impression not currently tracked. */
  APPLICATION_STORE = 'impression_application_store',
  /** @member {string} */
  /** impression event: april premium marketing deco claimed modal is shown */
  APRIL_PREMIUM_MARKETING_DECO_CLAIMED_MODAL = 'impression_april_premium_marketing_deco_claimed_modal',
  /** @member {string} */
  /** impression event: Avatar upload modal */
  AVATAR_UPLOAD = 'impression_avatar_upload',
  /** @member {string} */
  /** impression event: First step of channel creation (only step for public channel) */
  CHANNEL_ADD_INFO = 'impression_channel_add_info',
  /** @member {string} */
  /** impression event: Second step of channel creation to add channel access (for private channel only) */
  CHANNEL_ADD_MEMBERS = 'impression_channel_add_members',
  /** @member {string} */
  /** impression event: DEPRECATED, please use channel_call_video_grid_view instead */
  CHANNEL_CALL_VIDEO_GRID = 'impression_channel_call_video_grid',
  /** @member {string} */
  /** impression event: Track user and channel metrics when user views channel call video grid */
  CHANNEL_CALL_VIDEO_GRID_VIEW = 'impression_channel_call_video_grid_view',
  /** @member {string} */
  /** impression event: Impression of a channel */
  CHANNEL_VIEWED = 'impression_channel_viewed',
  /** @member {string} */
  /** impression event: Clip editor modal was opened */
  CLIP_EDITOR_VIEWED = 'impression_clip_editor_viewed',
  /** @member {string} */
  /** impression event: Clip gallery modal was opened */
  CLIP_GALLERY_VIEWED = 'impression_clip_gallery_viewed',
  /** @member {string} */
  /** impression event: Clyde AI share profile embed viewed */
  CLYDE_AI_PROFILE_EMBED_VIEWED = 'impression_clyde_ai_profile_embed_viewed',
  /** @member {string} */
  /** impression event: Event for when the collectibles expiry modal is shown */
  COLLECTIBLES_EXPIRY_MODAL = 'impression_collectibles_expiry_modal',
  /** @member {string} */
  /** impression event: View to invite contacts to Discord during contact sync flow */
  CONTACT_SYNC_CONTACT_INVITES = 'impression_contact_sync_contact_invites',
  /** @member {string} */
  /** impression event: View to enter or change name for contact sync */
  CONTACT_SYNC_INPUT_NAME = 'impression_contact_sync_input_name',
  /** @member {string} */
  /** impression event: Landing page of contact sync, where user starts the flow */
  CONTACT_SYNC_START = 'impression_contact_sync_start',
  /** @member {string} */
  /** impression event: View with resulting friend suggestions from contact sync */
  CONTACT_SYNC_SUGGESTIONS = 'impression_contact_sync_suggestions',
  /** @member {string} */
  /** impression event: Guild header upsell to inform the user about creator promo pages */
  CREATOR_PROMO_PAGE_GUILD_HEADER_UPSELL = 'impression_creator_promo_page_guild_header_upsell',
  /** @member {string} */
  /** impression event: add guild to a directory - confirmation step */
  DIRECTORY_ADD_GUILD_CONFIRMATION = 'impression_directory_add_guild_confirmation',
  /** @member {string} */
  /** impression event: Discoverability modal */
  DISCOVERABILITY = 'impression_discoverability',
  /** @member {string} */
  /** impression event: User views drop enrollment UX */
  DROPS_QUEST_COMPLETION = 'impression_drops_quest_completion',
  /** @member {string} */
  /** impression event: User views drop completion UX */
  DROPS_QUEST_ENROLLMENT = 'impression_drops_quest_enrollment',
  /** @member {string} */
  /** impression event: The user is shown a Happening Now card for an Embedded Activity */
  EMBEDDED_ACTIVITY_HAPPENING_NOW = 'impression_embedded_activity_happening_now',
  /** @member {string} */
  /** impression event: Accept terms page for enabling creator monetization */
  ENABLE_CREATOR_MONETIZATION_ACCEPT_TERMS_LANDING = 'impression_enable_creator_monetization_accept_terms_landing',
  /** @member {string} */
  /** impression event: Application page for enabling creator monetization */
  ENABLE_CREATOR_MONETIZATION_CREATE_REQUEST_LANDING = 'impression_enable_creator_monetization_create_request_landing',
  /** @member {string} */
  /** impression event: Guild header upsell to apply for creator monetization */
  ENABLE_CREATOR_MONETIZATION_GUILD_HEADER_UPSELL = 'impression_enable_creator_monetization_guild_header_upsell',
  /** @member {string} */
  /** impression event: Waitlist page for enabling creator monetization */
  ENABLE_CREATOR_MONETIZATION_WAITLIST_LANDING = 'impression_enable_creator_monetization_waitlist_landing',
  /** @member {string} */
  /** impression event: User has viewed their friend request spam inbox */
  FRIEND_REQUESTS_SPAM_INBOX = 'impression_friend_requests_spam_inbox',
  /** @member {string} */
  /** impression event: List of your friends. Page impression not currently tracked. */
  FRIENDS = 'impression_friends',
  /** @member {string} */
  /** impression event: User views the list of game consoles eligible for voice call transfer */
  GAME_CONSOLE_DEVICE_LIST = 'impression_game_console_device_list',
  /** @member {string} */
  /** impression event: Screen for managing active instant invites for GDMs */
  GDM_SETTINGS_INVITES = 'impression_gdm_settings_invites',
  /** @member {string} */
  /** impression event: Discover servers, apps, and games. Page impression not currently tracked. */
  GLOBAL_DISCOVERY = 'impression_global_discovery',
  /** @member {string} */
  /** impression event: accept invite page after entering invite link */
  GUILD_ADD_ACCEPT_INVITE = 'impression_guild_add_accept_invite',
  /** @member {string} */
  /** impression event: Prompt to create a custom text channel during guild creation */
  GUILD_ADD_CHANNEL_PROMPT = 'impression_guild_add_channel_prompt',
  /** @member {string} */
  /** impression event: Customize guild avatar and name during guild creation */
  GUILD_ADD_CUSTOMIZE = 'impression_guild_add_customize',
  /** @member {string} */
  /** impression event: invite friends after create guild */
  GUILD_ADD_GUILD_INVITE = 'impression_guild_add_guild_invite',
  /** @member {string} */
  /** impression event: Ask the user whether theyre creating a community or friends server during guild creation */
  GUILD_ADD_INTENT_SELECTION = 'impression_guild_add_intent_selection',
  /** @member {string} */
  /** impression event: Join a guild via an invite instead of creating a new one in guild add flow */
  GUILD_ADD_JOIN = 'impression_guild_add_join',
  /** @member {string} */
  /** impression event: Landing view of the guild add flow */
  GUILD_ADD_LANDING = 'impression_guild_add_landing',
  /** @member {string} */
  /** impression event: A text channel. May be a DM / GDM or guild text channel. Page impression not currently tracked. */
  GUILD_CHANNEL = 'impression_guild_channel',
  /** @member {string} */
  /** impression event: Impression on join step of guild creation modal */
  GUILD_CREATE_MODAL_JOIN = 'impression_guild_create_modal_join',
  /** @member {string} */
  /** impression event: Explore public servers. Page impression not currently tracked. */
  GUILD_DISCOVERY = 'impression_guild_discovery',
  /** @member {string} */
  /** impression event: Invite users to a guild */
  GUILD_INVITE = 'impression_guild_invite',
  /** @member {string} */
  /** impression event: Guild invite link settings */
  GUILD_INVITE_LINK_SETTINGS = 'impression_guild_invite_link_settings',
  /** @member {string} */
  /** impression event: Search users in guild invite sheet */
  GUILD_INVITE_SEARCH = 'impression_guild_invite_search',
  /** @member {string} */
  /** impression event: Rules a user has to agree to before they can join a server. Page impression not currently tracked. */
  GUILD_MEMBER_VERIFICATION = 'impression_guild_member_verification',
  /** @member {string} */
  /** impression event: Admin is shown the permanent invite link upsell for non-community guilds. */
  GUILD_PERMANENT_LINKS_UPSELL = 'impression_guild_permanent_links_upsell',
  /** @member {string} */
  /** impression event: When a user sees a guild product listing embed */
  GUILD_PRODUCT_LISTING_EMBED = 'impression_guild_product_listing_embed',
  /** @member {string} */
  /** impression event: When a user sees a guild product listing info modal */
  GUILD_PRODUCT_LISTING_INFO_MODAL = 'impression_guild_product_listing_info_modal',
  /** @member {string} */
  /** impression event: This impression tracks when a user access the store page settings for a guild. */
  GUILD_ROLE_SUBSCRIPTION_STORE_PAGE_GUILD_SETTINGS = 'impression_guild_role_subscription_store_page_guild_settings',
  /** @member {string} */
  /** impression event: Analytic insights for community servers */
  GUILD_SETTINGS_ANALYTICS = 'impression_guild_settings_analytics',
  /** @member {string} */
  /** impression event: Audit log for server activities */
  GUILD_SETTINGS_AUDIT_LOG = 'impression_guild_settings_audit_log',
  /** @member {string} */
  /** impression event: Guild Audit Log 2.0 */
  GUILD_SETTINGS_AUDIT_LOG_V2 = 'impression_guild_settings_audit_log_v2',
  /** @member {string} */
  /** impression event: List of banned members and ability to manage them */
  GUILD_SETTINGS_BANS = 'impression_guild_settings_bans',
  /** @member {string} */
  /** impression event: Server boost status */
  GUILD_SETTINGS_BOOST_STATUS = 'impression_guild_settings_boost_status',
  /** @member {string} */
  /** impression event: Manage server channels */
  GUILD_SETTINGS_CHANNELS = 'impression_guild_settings_channels',
  /** @member {string} */
  /** impression event: Setting for clyde */
  GUILD_SETTINGS_CLYDE = 'impression_guild_settings_clyde',
  /** @member {string} */
  /** impression event: Overview for communities */
  GUILD_SETTINGS_COMMUNITY_OVERVIEW = 'impression_guild_settings_community_overview',
  /** @member {string} */
  /** impression event: Community welcome setup */
  GUILD_SETTINGS_COMMUNITY_WELCOME = 'impression_guild_settings_community_welcome',
  /** @member {string} */
  /** impression event: Community server setting for the discovery directory */
  GUILD_SETTINGS_DISCOVERY = 'impression_guild_settings_discovery',
  /** @member {string} */
  /** impression event: Server settings discovery landing page tab */
  GUILD_SETTINGS_DISCOVERY_LANDING_PAGE = 'impression_guild_settings_discovery_landing_page',
  /** @member {string} */
  /** impression event: Manage emojis for server */
  GUILD_SETTINGS_EMOJI = 'impression_guild_settings_emoji',
  /** @member {string} */
  /** impression event: Enable server to become a community server */
  GUILD_SETTINGS_ENABLE_COMMUNITY = 'impression_guild_settings_enable_community',
  /** @member {string} */
  /** impression event: Setting for webhooks, followed channel, bots and apps */
  GUILD_SETTINGS_INTEGRATION = 'impression_guild_settings_integration',
  /** @member {string} */
  /** impression event: Manage active instant invites */
  GUILD_SETTINGS_INVITES = 'impression_guild_settings_invites',
  /** @member {string} */
  /** impression event: Server settings landing page */
  GUILD_SETTINGS_LANDING = 'impression_guild_settings_landing',
  /** @member {string} */
  /** impression event: Member verification setting for community servers */
  GUILD_SETTINGS_MEMBER_VERIFICATION = 'impression_guild_settings_member_verification',
  /** @member {string} */
  /** impression event: Manage server members */
  GUILD_SETTINGS_MEMBERS = 'impression_guild_settings_members',
  /** @member {string} */
  /** impression event: Server moderation settings */
  GUILD_SETTINGS_MODERATION = 'impression_guild_settings_moderation',
  /** @member {string} */
  /** impression event: Server overview settings such as server name */
  GUILD_SETTINGS_OVERVIEW = 'impression_guild_settings_overview',
  /** @member {string} */
  /** impression event: Manage parters for community servers */
  GUILD_SETTINGS_PARTNER = 'impression_guild_settings_partner',
  /** @member {string} */
  /** impression event: Manage server roles */
  GUILD_SETTINGS_ROLES = 'impression_guild_settings_roles',
  /** @member {string} */
  /** impression event: Server settings - safety setup */
  GUILD_SETTINGS_SAFETY = 'impression_guild_settings_safety',
  /** @member {string} */
  /** impression event: Manage 2FA for Moderation */
  GUILD_SETTINGS_SECURITY = 'impression_guild_settings_security',
  /** @member {string} */
  /** impression event: Manage soundboard for server */
  GUILD_SETTINGS_SOUNDBOARD = 'impression_guild_settings_soundboard',
  /** @member {string} */
  /** impression event: Manage stickers for server */
  GUILD_SETTINGS_STICKERS = 'impression_guild_settings_stickers',
  /** @member {string} */
  /** impression event: Generate server templates */
  GUILD_SETTINGS_TEMPLATE = 'impression_guild_settings_template',
  /** @member {string} */
  /** impression event: Manage vanity url for community servers */
  GUILD_SETTINGS_VANITY_URL = 'impression_guild_settings_vanity_url',
  /** @member {string} */
  /** impression event: Manage server webhooks */
  GUILD_SETTINGS_WEBHOOKS = 'impression_guild_settings_webhooks',
  /** @member {string} */
  /** impression event: Manage server widget */
  GUILD_SETTINGS_WIDGET = 'impression_guild_settings_widget',
  /** @member {string} */
  /** impression event: When a user see a server shop embed */
  GUILD_SHOP_EMBED = 'impression_guild_shop_embed',
  /** @member {string} */
  /** impression event: When a user opens the server shop page */
  GUILD_SHOP_PAGE = 'impression_guild_shop_page',
  /** @member {string} */
  /** impression event: When a user sees the server shop upsell */
  GUILD_SHOP_UPSELL = 'impression_guild_shop_upsell',
  /** @member {string} */
  /** impression event: Owner is attempting to transfer ownership of a server */
  GUILD_TRANSFER_OWNERSHIP = 'impression_guild_transfer_ownership',
  /** @member {string} */
  /** impression event: Owner was sent pincode via email (because they didn't have MFA set up) and must verify to complete transfer */
  GUILD_TRANSFER_OWNERSHIP_CONFIRM_EMAIL_CODE = 'impression_guild_transfer_ownership_confirm_email_code',
  /** @member {string} */
  /** impression event: Owner was sent pincode via SMS (because they didn't have MFA set up) and must verify to complete transfer */
  GUILD_TRANSFER_OWNERSHIP_CONFIRM_SMS_CODE = 'impression_guild_transfer_ownership_confirm_sms_code',
  /** @member {string} */
  /** impression event: Fired when the user sees the empty state for guilds, with join server / create server CTAs */
  GUILDS_EMPTY_NUX = 'impression_guilds_empty_nux',
  /** @member {string} */
  /** impression event: a popout in the voice channel for HD streaming has been viewed */
  HD_STREAMING_POPOUT = 'impression_hd_streaming_popout',
  /** @member {string} */
  /** impression event: Hotspot was viewed */
  HOTSPOT = 'impression_hotspot',
  /** @member {string} */
  /** impression event: create a new guild to add to a hub directory - customization step */
  HUB_CREATE_GUILD_CUSTOMIZE = 'impression_hub_create_guild_customize',
  /** @member {string} */
  /** impression event: create a new guild to add to a hub directory - choose template step */
  HUB_CREATE_GUILD_TEMPLATE = 'impression_hub_create_guild_template',
  /** @member {string} */
  /** impression event: modal where users submit their email to verify */
  HUB_EMAIL_SIGNUP = 'impression_hub_email_signup',
  /** @member {string} */
  /** impression event: page where we show email verfication when someone opens an invite */
  HUB_EMAIL_VERIFICATION_PAGE = 'impression_hub_email_verification_page',
  /** @member {string} */
  /** impression event: add an existing guild to hub directory - choose a guild step */
  HUB_EXISTING_GUILD_CHOOSE = 'impression_hub_existing_guild_choose',
  /** @member {string} */
  /** impression event: add an existing guild to hub directory - customize step */
  HUB_EXISTING_GUILD_CUSTOMIZE = 'impression_hub_existing_guild_customize',
  /** @member {string} */
  /** impression event: user viewed the hub waitlist upsell modal */
  HUB_WAITLIST_SIGNUP = 'impression_hub_waitlist_signup',
  /** @member {string} */
  /** impression event: Join server by accepting an invite. */
  INVITE_ACCEPT = 'impression_invite_accept',
  /** @member {string} */
  /** impression event: User viewed an upsell for localized pricing */
  LOCALIZED_PRICING_UPSELL_VIEWED = 'impression_localized_pricing_upsell_viewed',
  /** @member {string} */
  /** impression event: Fired when the user sees the empty state for messages, with add friends CTX */
  MESSAGES_EMPTY_NUX = 'impression_messages_empty_nux',
  /** @member {string} */
  /** impression event: Display multi account switch modal from manage accounts or login */
  MULTI_ACCOUNT_SWITCH_LANDING = 'impression_multi_account_switch_landing',
  /** @member {string} */
  /** impression event: display choose intent screen in the new user flow */
  NEW_USER_INTENT_START = 'impression_new_user_intent_start',
  /** @member {string} */
  /** impression event: This is fired on render for Nitro tab. */
  NITRO_TAB = 'impression_nitro_tab',
  /** @member {string} */
  /** impression event: Impression that fires when user lands on the notification center */
  NOTIFICATION_CENTER_LANDING = 'impression_notification_center_landing',
  /** @member {string} */
  /** impression event: Impression that fires when user see the notification setting unread nudge */
  NOTIFICATION_SETTING_UNREAD_NUDGE = 'impression_notification_setting_unread_nudge',
  /** @member {string} */
  /** impression event: Impression for the perk discoverability cards on Nitro Tab pages */
  PERK_DISCOVERABILITY_CARD = 'impression_perk_discoverability_card',
  /** @member {string} */
  /** impression event: Sent when the poll editor modal is viewed */
  POLL_EDITOR_VIEWED = 'impression_poll_editor_viewed',
  /** @member {string} */
  /** impression event: Landing on Pomelo modal */
  POMELO_LANDING = 'impression_pomelo_landing',
  /** @member {string} */
  /** impression event: Premium guild subscription marketing */
  PREMIUM_GUILD_SUBSCRIPTION_MARKETING_PAGE = 'impression_premium_guild_subscription_marketing_page',
  /** @member {string} */
  /** impression event: a premium marketing banner has been viewed */
  PREMIUM_MARKETING_BANNER = 'impression_premium_marketing_banner',
  /** @member {string} */
  /** impression event: Open premium marketing surface. */
  PREMIUM_MARKETING_SURFACE = 'impression_premium_marketing_surface',
  /** @member {string} */
  /** impression event: Impression for the tenure reward card on the premium marketing page */
  PREMIUM_MARKETING_TENURE_REWARD_CARD = 'impression_premium_marketing_tenure_reward_card',
  /** @member {string} */
  /** impression event: Prompt to ask user for push notification permission request */
  PUSH_NOTIFICATION_PREPROMPT = 'impression_push_notification_preprompt',
  /** @member {string} */
  /** impression event: Prompt to ask user for push notification permission request again */
  PUSH_NOTIFICATION_REACTIVATION_PROMPT = 'impression_push_notification_reactivation_prompt',
  /** @member {string} */
  /** impression event: Quest home page impression. */
  QUEST_HOME = 'impression_quest_home',
  /** @member {string} */
  /** impression event: Quests landing page */
  QUESTS_LANDING_PAGE = 'impression_quests_landing_page',
  /** @member {string} */
  /** impression event: Asks the user if they want to review the app on the iOS app store or google play store */
  REQUEST_REVIEW_MODAL = 'impression_request_review_modal',
  /** @member {string} */
  /** impression event: Third step of role creation for admins and owners - add members to role */
  ROLE_CREATE_ADD_MEMBERS = 'impression_role_create_add_members',
  /** @member {string} */
  /** impression event: First step of role creation - role name and display settings */
  ROLE_CREATE_DISPLAY = 'impression_role_create_display',
  /** @member {string} */
  /** impression event: Second step of role creation for admins and owners - permission presets */
  ROLE_CREATE_PERMISSIONS = 'impression_role_create_permissions',
  /** @member {string} */
  /** impression event: track when the upsell for a subscription role emoji has been viewed */
  ROLE_SUBSCRIPTION_EMOJI_UPSELL = 'impression_role_subscription_emoji_upsell',
  /** @member {string} */
  /** impression event: Fired when a creator opens the initial role subscription setup modal on mobile */
  ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_LANDING = 'impression_role_subscription_initial_setup_modal_landing',
  /** @member {string} */
  /** impression event: Fired when a creator reaches to the tier setup step on the role subscription setup modal */
  ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_TIER_STEP = 'impression_role_subscription_initial_setup_modal_tier_step',
  /** @member {string} */
  /** impression event: This impression tracks when a user accesses the role subscription listing template selector. */
  ROLE_SUBSCRIPTION_LISTING_TEMPLATE_SELECTOR = 'impression_role_subscription_listing_template_selector',
  /** @member {string} */
  /** impression event: Impression for role subscription upsell error page */
  ROLE_SUBSCRIPTION_LISTING_UPSELL_ERROR_PAGE = 'impression_role_subscription_listing_upsell_error_page',
  /** @member {string} */
  /** impression event: The user is shown details for a particular activity while the activity shelf is open */
  SHELF_ACTIVITY_DETAILS = 'impression_shelf_activity_details',
  /** @member {string} */
  /** impression event: Snowsgiving route impression. */
  SNOWSGIVING = 'impression_snowsgiving',
  /** @member {string} */
  /** impression event: Play sounds for a server */
  SOUNDBOARD_POPOUT = 'impression_soundboard_popout',
  /** @member {string} */
  /** impression event: Discovery for stage events. Page impression not currently tracked. */
  STAGE_DISCOVERY = 'impression_stage_discovery',
  /** @member {string} */
  /** impression event: Unauthenticated Report Form - user is entering an email pincode to verify email ownership */
  URF_CONFIRM_EMAIL_CODE = 'impression_urf_confirm_email_code',
  /** @member {string} */
  /** impression event: Unauthenticated Report Form - user is entering their email in order to submit a report */
  URF_ENTER_EMAIL = 'impression_urf_enter_email',
  /** @member {string} */
  /** impression event: Instruct users to choose the reason why they want to change their email */
  USER_ACCOUNT_EMAIL_CHANGE_COLLECT_REASONS = 'impression_user_account_email_change_collect_reasons',
  /** @member {string} */
  /** impression event: Final step of the email change flow instructing the user to verify their new email */
  USER_ACCOUNT_EMAIL_CHANGE_COMPLETE = 'impression_user_account_email_change_complete',
  /** @member {string} */
  /** impression event: Enter a new email */
  USER_ACCOUNT_EMAIL_CHANGE_ENTER_EMAIL = 'impression_user_account_email_change_enter_email',
  /** @member {string} */
  /** impression event: Step where the user can request a code to confirm they own a verified email address before they change it */
  USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE = 'impression_user_account_email_change_send_code',
  /** @member {string} */
  /** impression event: Form where user enters a code that was emailed to them to verify ownership of that email account */
  USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE = 'impression_user_account_email_change_verify_code',
  /** @member {string} */
  /** impression event: Educate users on common scams related to email changes and allow them to exit the change flow */
  USER_ACCOUNT_EMAIL_CHANGE_WARNING = 'impression_user_account_email_change_warning',
  /** @member {string} */
  /** impression event: Step where the the user lands, asking to go check email or click "resend email" or "change email" */
  USER_ACCOUNT_EMAIL_RESEND_VERIFICATION_EMAIL = 'impression_user_account_email_resend_verification_email',
  /** @member {string} */
  /** impression event: Last step of updating account information -- verifying with a password */
  USER_ACCOUNT_PASSWORD_VERIFY = 'impression_user_account_password_verify',
  /** @member {string} */
  /** impression event: user action require verification overlay */
  USER_ACTION_REQUIRED = 'impression_user_action_required',
  /** @member {string} */
  /** impression event: View for user to add a phone number to account */
  USER_ADD_PHONE = 'impression_user_add_phone',
  /** @member {string} */
  /** impression event: check to get the users age. typically on registration */
  USER_AGE_GATE = 'impression_user_age_gate',
  /** @member {string} */
  /** impression event: user has screened as underage during the age gate */
  USER_AGE_GATE_UNDERAGE = 'impression_user_age_gate_underage',
  /** @member {string} */
  /** impression event: When user see the user agreement modal/screen */
  USER_AGREEMENTS = 'impression_user_agreements',
  /** @member {string} */
  /** impression event: When a user views the Clyde AI consent modal. */
  USER_CLYDE_AI_CONSENT_MODAL = 'impression_user_clyde_ai_consent_modal',
  /** @member {string} */
  /** impression event: triggered by the app when a user views the login page */
  USER_LOGIN = 'impression_user_login',
  /** @member {string} */
  /** impression event: triggered by the app when a user views the page to enter a passwordless login code */
  USER_PASSWORDLESS_CODE_ENTRY = 'impression_user_passwordless_code_entry',
  /** @member {string} */
  /** impression event: triggered by the app when a user views the onboarding page for passwordless login */
  USER_PASSWORDLESS_INTRO = 'impression_user_passwordless_intro',
  /** @member {string} */
  /** impression event: collecting a new users account information (username, password, etc) */
  USER_REGISTER_ACCOUNT_INFORMATION = 'impression_user_register_account_information',
  /** @member {string} */
  /** impression event: the screen where we collect a new users identity (either phone or email signup) */
  USER_REGISTER_IDENTITY = 'impression_user_register_identity',
  /** @member {string} */
  /** impression event: The user registration flow */
  USER_REGISTRATION = 'impression_user_registration',
  /** @member {string} */
  /** impression event: User settings connections page */
  USER_SETTINGS_CONNECTIONS = 'impression_user_settings_connections',
  /** @member {string} */
  /** impression event: The user verification modal flow (aka required_actions) */
  USER_VERIFICATION_MODAL = 'impression_user_verification_modal',
  /** @member {string} */
  /** impression event: Verify password view */
  USER_VERIFY_PASSWORD = 'impression_user_verify_password',
  /** @member {string} */
  /** impression event: the phone verification view */
  USER_VERIFY_PHONE = 'impression_user_verify_phone',
  /** @member {string} */
  /** impression event: the user registration welcome landing screen */
  USER_WELCOME = 'impression_user_welcome',
  /** @member {string} */
  /** impression event: Fired when the user views their profile in the You Tab / You Screen */
  USER_YOU_SCREEN = 'impression_user_you_screen',
  /** @member {string} */
  /** impression event: The in-app devtools sidebar */
  VIEW_PANEL_DEVTOOLS = 'impression_view_panel_devtools',
}

export type ImpressionSchema = {
  impressionName?: ImpressionNames.ACTIVITIES;
  impressionProperties?: TrackImpressionActivities;
}
| {
  impressionName?: ImpressionNames.ACTIVITIES_HAPPENING_NOW;
  impressionProperties?: TrackImpressionActivitiesHappeningNow;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_BOOKMARK_SHARE_MODAL;
  impressionProperties?: TrackImpressionActivityBookmarkShareModal;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_BOOSTING_UPSELL;
  impressionProperties?: TrackImpressionActivityBoostingUpsell;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_DETAILS;
  impressionProperties?: TrackImpressionActivityDetails;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_NITRO_UPSELL;
  impressionProperties?: TrackImpressionActivityNitroUpsell;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_SHARE_MOMENT_MODAL;
  impressionProperties?: TrackImpressionActivityShareMomentModal;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_SHELF;
  impressionProperties?: TrackImpressionActivityShelf;
}
| {
  impressionName?: ImpressionNames.ACTIVITY_SHELF_SELECT_CHANNEL;
  impressionProperties?: TrackImpressionActivityShelfSelectChannel;
}
| {
  impressionName?: ImpressionNames.APP_LAUNCHER_HOME_ACTIVITY_ITEM;
  impressionProperties?: TrackImpressionAppLauncherHomeActivityItem;
}
| {
  impressionName?: ImpressionNames.APP_LAUNCHER_ITEM;
  impressionProperties?: TrackImpressionAppLauncherItem;
}
| {
  impressionName?: ImpressionNames.APP_LAUNCHER_SEARCH_RESULTS_ITEM;
  impressionProperties?: TrackImpressionAppLauncherSearchResultsItem;
}
| {
  impressionName?: ImpressionNames.APP_LAUNCHER_SECTION;
  impressionProperties?: TrackImpressionAppLauncherSection;
}
| {
  impressionName?: ImpressionNames.APPLICATION_LIBRARY;
  impressionProperties?: TrackImpressionApplicationLibrary;
}
| {
  impressionName?: ImpressionNames.APPLICATION_STORE;
  impressionProperties?: TrackImpressionApplicationStore;
}
| {
  impressionName?: ImpressionNames.APRIL_PREMIUM_MARKETING_DECO_CLAIMED_MODAL;
  impressionProperties?: TrackImpressionAprilPremiumMarketingDecoClaimedModal;
}
| {
  impressionName?: ImpressionNames.AVATAR_UPLOAD;
  impressionProperties?: TrackImpressionAvatarUpload;
}
| {
  impressionName?: ImpressionNames.CHANNEL_ADD_INFO;
  impressionProperties?: TrackImpressionChannelAddInfo;
}
| {
  impressionName?: ImpressionNames.CHANNEL_ADD_MEMBERS;
  impressionProperties?: TrackImpressionChannelAddMembers;
}
| {
  impressionName?: ImpressionNames.CHANNEL_CALL_VIDEO_GRID;
  impressionProperties?: TrackImpressionChannelCallVideoGrid;
}
| {
  impressionName?: ImpressionNames.CHANNEL_CALL_VIDEO_GRID_VIEW;
  impressionProperties?: TrackImpressionChannelCallVideoGridView;
}
| {
  impressionName?: ImpressionNames.CHANNEL_VIEWED;
  impressionProperties?: TrackImpressionChannelViewed;
}
| {
  impressionName?: ImpressionNames.CLIP_EDITOR_VIEWED;
  impressionProperties?: TrackImpressionClipEditorViewed;
}
| {
  impressionName?: ImpressionNames.CLIP_GALLERY_VIEWED;
  impressionProperties?: TrackImpressionClipGalleryViewed;
}
| {
  impressionName?: ImpressionNames.CLYDE_AI_PROFILE_EMBED_VIEWED;
  impressionProperties?: TrackImpressionClydeAiProfileEmbedViewed;
}
| {
  impressionName?: ImpressionNames.COLLECTIBLES_EXPIRY_MODAL;
  impressionProperties?: TrackImpressionCollectiblesExpiryModal;
}
| {
  impressionName?: ImpressionNames.CONTACT_SYNC_CONTACT_INVITES;
  impressionProperties?: TrackImpressionContactSyncContactInvites;
}
| {
  impressionName?: ImpressionNames.CONTACT_SYNC_INPUT_NAME;
  impressionProperties?: TrackImpressionContactSyncInputName;
}
| {
  impressionName?: ImpressionNames.CONTACT_SYNC_START;
  impressionProperties?: TrackImpressionContactSyncStart;
}
| {
  impressionName?: ImpressionNames.CONTACT_SYNC_SUGGESTIONS;
  impressionProperties?: TrackImpressionContactSyncSuggestions;
}
| {
  impressionName?: ImpressionNames.CREATOR_PROMO_PAGE_GUILD_HEADER_UPSELL;
  impressionProperties?: TrackImpressionCreatorPromoPageGuildHeaderUpsell;
}
| {
  impressionName?: ImpressionNames.DIRECTORY_ADD_GUILD_CONFIRMATION;
  impressionProperties?: TrackImpressionDirectoryAddGuildConfirmation;
}
| {
  impressionName?: ImpressionNames.DISCOVERABILITY;
  impressionProperties?: TrackImpressionDiscoverability;
}
| {
  impressionName?: ImpressionNames.DROPS_QUEST_COMPLETION;
  impressionProperties?: TrackImpressionDropsQuestCompletion;
}
| {
  impressionName?: ImpressionNames.DROPS_QUEST_ENROLLMENT;
  impressionProperties?: TrackImpressionDropsQuestEnrollment;
}
| {
  impressionName?: ImpressionNames.EMBEDDED_ACTIVITY_HAPPENING_NOW;
  impressionProperties?: TrackImpressionEmbeddedActivityHappeningNow;
}
| {
  impressionName?: ImpressionNames.ENABLE_CREATOR_MONETIZATION_ACCEPT_TERMS_LANDING;
  impressionProperties?: TrackImpressionEnableCreatorMonetizationAcceptTermsLanding;
}
| {
  impressionName?: ImpressionNames.ENABLE_CREATOR_MONETIZATION_CREATE_REQUEST_LANDING;
  impressionProperties?: TrackImpressionEnableCreatorMonetizationCreateRequestLanding;
}
| {
  impressionName?: ImpressionNames.ENABLE_CREATOR_MONETIZATION_GUILD_HEADER_UPSELL;
  impressionProperties?: TrackImpressionEnableCreatorMonetizationGuildHeaderUpsell;
}
| {
  impressionName?: ImpressionNames.ENABLE_CREATOR_MONETIZATION_WAITLIST_LANDING;
  impressionProperties?: TrackImpressionEnableCreatorMonetizationWaitlistLanding;
}
| {
  impressionName?: ImpressionNames.FRIEND_REQUESTS_SPAM_INBOX;
  impressionProperties?: TrackImpressionFriendRequestsSpamInbox;
}
| {
  impressionName?: ImpressionNames.FRIENDS;
  impressionProperties?: TrackImpressionFriends;
}
| {
  impressionName?: ImpressionNames.GAME_CONSOLE_DEVICE_LIST;
  impressionProperties?: TrackImpressionGameConsoleDeviceList;
}
| {
  impressionName?: ImpressionNames.GDM_SETTINGS_INVITES;
  impressionProperties?: TrackImpressionGdmSettingsInvites;
}
| {
  impressionName?: ImpressionNames.GLOBAL_DISCOVERY;
  impressionProperties?: TrackImpressionGlobalDiscovery;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_ACCEPT_INVITE;
  impressionProperties?: TrackImpressionGuildAddAcceptInvite;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_CHANNEL_PROMPT;
  impressionProperties?: TrackImpressionGuildAddChannelPrompt;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_CUSTOMIZE;
  impressionProperties?: TrackImpressionGuildAddCustomize;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_GUILD_INVITE;
  impressionProperties?: TrackImpressionGuildAddGuildInvite;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_INTENT_SELECTION;
  impressionProperties?: TrackImpressionGuildAddIntentSelection;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_JOIN;
  impressionProperties?: TrackImpressionGuildAddJoin;
}
| {
  impressionName?: ImpressionNames.GUILD_ADD_LANDING;
  impressionProperties?: TrackImpressionGuildAddLanding;
}
| {
  impressionName?: ImpressionNames.GUILD_CHANNEL;
  impressionProperties?: TrackImpressionGuildChannel;
}
| {
  impressionName?: ImpressionNames.GUILD_CREATE_MODAL_JOIN;
  impressionProperties?: TrackImpressionGuildCreateModalJoin;
}
| {
  impressionName?: ImpressionNames.GUILD_DISCOVERY;
  impressionProperties?: TrackImpressionGuildDiscovery;
}
| {
  impressionName?: ImpressionNames.GUILD_INVITE;
  impressionProperties?: TrackImpressionGuildInvite;
}
| {
  impressionName?: ImpressionNames.GUILD_INVITE_LINK_SETTINGS;
  impressionProperties?: TrackImpressionGuildInviteLinkSettings;
}
| {
  impressionName?: ImpressionNames.GUILD_INVITE_SEARCH;
  impressionProperties?: TrackImpressionGuildInviteSearch;
}
| {
  impressionName?: ImpressionNames.GUILD_MEMBER_VERIFICATION;
  impressionProperties?: TrackImpressionGuildMemberVerification;
}
| {
  impressionName?: ImpressionNames.GUILD_PERMANENT_LINKS_UPSELL;
  impressionProperties?: TrackImpressionGuildPermanentLinksUpsell;
}
| {
  impressionName?: ImpressionNames.GUILD_PRODUCT_LISTING_EMBED;
  impressionProperties?: TrackImpressionGuildProductListingEmbed;
}
| {
  impressionName?: ImpressionNames.GUILD_PRODUCT_LISTING_INFO_MODAL;
  impressionProperties?: TrackImpressionGuildProductListingInfoModal;
}
| {
  impressionName?: ImpressionNames.GUILD_ROLE_SUBSCRIPTION_STORE_PAGE_GUILD_SETTINGS;
  impressionProperties?: TrackImpressionGuildRoleSubscriptionStorePageGuildSettings;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_ANALYTICS;
  impressionProperties?: TrackImpressionGuildSettingsAnalytics;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_AUDIT_LOG;
  impressionProperties?: TrackImpressionGuildSettingsAuditLog;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_AUDIT_LOG_V2;
  impressionProperties?: TrackImpressionGuildSettingsAuditLogV2;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_BANS;
  impressionProperties?: TrackImpressionGuildSettingsBans;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_BOOST_STATUS;
  impressionProperties?: TrackImpressionGuildSettingsBoostStatus;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_CHANNELS;
  impressionProperties?: TrackImpressionGuildSettingsChannels;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_CLYDE;
  impressionProperties?: TrackImpressionGuildSettingsClyde;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_COMMUNITY_OVERVIEW;
  impressionProperties?: TrackImpressionGuildSettingsCommunityOverview;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_COMMUNITY_WELCOME;
  impressionProperties?: TrackImpressionGuildSettingsCommunityWelcome;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_DISCOVERY;
  impressionProperties?: TrackImpressionGuildSettingsDiscovery;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_DISCOVERY_LANDING_PAGE;
  impressionProperties?: TrackImpressionGuildSettingsDiscoveryLandingPage;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_EMOJI;
  impressionProperties?: TrackImpressionGuildSettingsEmoji;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_ENABLE_COMMUNITY;
  impressionProperties?: TrackImpressionGuildSettingsEnableCommunity;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_INTEGRATION;
  impressionProperties?: TrackImpressionGuildSettingsIntegration;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_INVITES;
  impressionProperties?: TrackImpressionGuildSettingsInvites;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_LANDING;
  impressionProperties?: TrackImpressionGuildSettingsLanding;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_MEMBER_VERIFICATION;
  impressionProperties?: TrackImpressionGuildSettingsMemberVerification;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_MEMBERS;
  impressionProperties?: TrackImpressionGuildSettingsMembers;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_MODERATION;
  impressionProperties?: TrackImpressionGuildSettingsModeration;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_OVERVIEW;
  impressionProperties?: TrackImpressionGuildSettingsOverview;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_PARTNER;
  impressionProperties?: TrackImpressionGuildSettingsPartner;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_ROLES;
  impressionProperties?: TrackImpressionGuildSettingsRoles;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_SAFETY;
  impressionProperties?: TrackImpressionGuildSettingsSafety;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_SECURITY;
  impressionProperties?: TrackImpressionGuildSettingsSecurity;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_SOUNDBOARD;
  impressionProperties?: TrackImpressionGuildSettingsSoundboard;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_STICKERS;
  impressionProperties?: TrackImpressionGuildSettingsStickers;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_TEMPLATE;
  impressionProperties?: TrackImpressionGuildSettingsTemplate;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_VANITY_URL;
  impressionProperties?: TrackImpressionGuildSettingsVanityUrl;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_WEBHOOKS;
  impressionProperties?: TrackImpressionGuildSettingsWebhooks;
}
| {
  impressionName?: ImpressionNames.GUILD_SETTINGS_WIDGET;
  impressionProperties?: TrackImpressionGuildSettingsWidget;
}
| {
  impressionName?: ImpressionNames.GUILD_SHOP_EMBED;
  impressionProperties?: TrackImpressionGuildShopEmbed;
}
| {
  impressionName?: ImpressionNames.GUILD_SHOP_PAGE;
  impressionProperties?: TrackImpressionGuildShopPage;
}
| {
  impressionName?: ImpressionNames.GUILD_SHOP_UPSELL;
  impressionProperties?: TrackImpressionGuildShopUpsell;
}
| {
  impressionName?: ImpressionNames.GUILD_TRANSFER_OWNERSHIP;
  impressionProperties?: TrackImpressionGuildTransferOwnership;
}
| {
  impressionName?: ImpressionNames.GUILD_TRANSFER_OWNERSHIP_CONFIRM_EMAIL_CODE;
  impressionProperties?: TrackImpressionGuildTransferOwnershipConfirmEmailCode;
}
| {
  impressionName?: ImpressionNames.GUILD_TRANSFER_OWNERSHIP_CONFIRM_SMS_CODE;
  impressionProperties?: TrackImpressionGuildTransferOwnershipConfirmSmsCode;
}
| {
  impressionName?: ImpressionNames.GUILDS_EMPTY_NUX;
  impressionProperties?: TrackImpressionGuildsEmptyNux;
}
| {
  impressionName?: ImpressionNames.HD_STREAMING_POPOUT;
  impressionProperties?: TrackImpressionHdStreamingPopout;
}
| {
  impressionName?: ImpressionNames.HOTSPOT;
  impressionProperties?: TrackImpressionHotspot;
}
| {
  impressionName?: ImpressionNames.HUB_CREATE_GUILD_CUSTOMIZE;
  impressionProperties?: TrackImpressionHubCreateGuildCustomize;
}
| {
  impressionName?: ImpressionNames.HUB_CREATE_GUILD_TEMPLATE;
  impressionProperties?: TrackImpressionHubCreateGuildTemplate;
}
| {
  impressionName?: ImpressionNames.HUB_EMAIL_SIGNUP;
  impressionProperties?: TrackImpressionHubEmailSignup;
}
| {
  impressionName?: ImpressionNames.HUB_EMAIL_VERIFICATION_PAGE;
  impressionProperties?: TrackImpressionHubEmailVerificationPage;
}
| {
  impressionName?: ImpressionNames.HUB_EXISTING_GUILD_CHOOSE;
  impressionProperties?: TrackImpressionHubExistingGuildChoose;
}
| {
  impressionName?: ImpressionNames.HUB_EXISTING_GUILD_CUSTOMIZE;
  impressionProperties?: TrackImpressionHubExistingGuildCustomize;
}
| {
  impressionName?: ImpressionNames.HUB_WAITLIST_SIGNUP;
  impressionProperties?: TrackImpressionHubWaitlistSignup;
}
| {
  impressionName?: ImpressionNames.INVITE_ACCEPT;
  impressionProperties?: TrackImpressionInviteAccept;
}
| {
  impressionName?: ImpressionNames.LOCALIZED_PRICING_UPSELL_VIEWED;
  impressionProperties?: TrackImpressionLocalizedPricingUpsellViewed;
}
| {
  impressionName?: ImpressionNames.MESSAGES_EMPTY_NUX;
  impressionProperties?: TrackImpressionMessagesEmptyNux;
}
| {
  impressionName?: ImpressionNames.MULTI_ACCOUNT_SWITCH_LANDING;
  impressionProperties?: TrackImpressionMultiAccountSwitchLanding;
}
| {
  impressionName?: ImpressionNames.NEW_USER_INTENT_START;
  impressionProperties?: TrackImpressionNewUserIntentStart;
}
| {
  impressionName?: ImpressionNames.NITRO_TAB;
  impressionProperties?: TrackImpressionNitroTab;
}
| {
  impressionName?: ImpressionNames.NOTIFICATION_CENTER_LANDING;
  impressionProperties?: TrackImpressionNotificationCenterLanding;
}
| {
  impressionName?: ImpressionNames.NOTIFICATION_SETTING_UNREAD_NUDGE;
  impressionProperties?: TrackImpressionNotificationSettingUnreadNudge;
}
| {
  impressionName?: ImpressionNames.PERK_DISCOVERABILITY_CARD;
  impressionProperties?: TrackImpressionPerkDiscoverabilityCard;
}
| {
  impressionName?: ImpressionNames.POLL_EDITOR_VIEWED;
  impressionProperties?: TrackImpressionPollEditorViewed;
}
| {
  impressionName?: ImpressionNames.POMELO_LANDING;
  impressionProperties?: TrackImpressionPomeloLanding;
}
| {
  impressionName?: ImpressionNames.PREMIUM_GUILD_SUBSCRIPTION_MARKETING_PAGE;
  impressionProperties?: TrackImpressionPremiumGuildSubscriptionMarketingPage;
}
| {
  impressionName?: ImpressionNames.PREMIUM_MARKETING_BANNER;
  impressionProperties?: TrackImpressionPremiumMarketingBanner;
}
| {
  impressionName?: ImpressionNames.PREMIUM_MARKETING_SURFACE;
  impressionProperties?: TrackImpressionPremiumMarketingSurface;
}
| {
  impressionName?: ImpressionNames.PREMIUM_MARKETING_TENURE_REWARD_CARD;
  impressionProperties?: TrackImpressionPremiumMarketingTenureRewardCard;
}
| {
  impressionName?: ImpressionNames.PUSH_NOTIFICATION_PREPROMPT;
  impressionProperties?: TrackImpressionPushNotificationPreprompt;
}
| {
  impressionName?: ImpressionNames.PUSH_NOTIFICATION_REACTIVATION_PROMPT;
  impressionProperties?: TrackImpressionPushNotificationReactivationPrompt;
}
| {
  impressionName?: ImpressionNames.QUEST_HOME;
  impressionProperties?: TrackImpressionQuestHome;
}
| {
  impressionName?: ImpressionNames.QUESTS_LANDING_PAGE;
  impressionProperties?: TrackImpressionQuestsLandingPage;
}
| {
  impressionName?: ImpressionNames.REQUEST_REVIEW_MODAL;
  impressionProperties?: TrackImpressionRequestReviewModal;
}
| {
  impressionName?: ImpressionNames.ROLE_CREATE_ADD_MEMBERS;
  impressionProperties?: TrackImpressionRoleCreateAddMembers;
}
| {
  impressionName?: ImpressionNames.ROLE_CREATE_DISPLAY;
  impressionProperties?: TrackImpressionRoleCreateDisplay;
}
| {
  impressionName?: ImpressionNames.ROLE_CREATE_PERMISSIONS;
  impressionProperties?: TrackImpressionRoleCreatePermissions;
}
| {
  impressionName?: ImpressionNames.ROLE_SUBSCRIPTION_EMOJI_UPSELL;
  impressionProperties?: TrackImpressionRoleSubscriptionEmojiUpsell;
}
| {
  impressionName?: ImpressionNames.ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_LANDING;
  impressionProperties?: TrackImpressionRoleSubscriptionInitialSetupModalLanding;
}
| {
  impressionName?: ImpressionNames.ROLE_SUBSCRIPTION_INITIAL_SETUP_MODAL_TIER_STEP;
  impressionProperties?: TrackImpressionRoleSubscriptionInitialSetupModalTierStep;
}
| {
  impressionName?: ImpressionNames.ROLE_SUBSCRIPTION_LISTING_TEMPLATE_SELECTOR;
  impressionProperties?: TrackImpressionRoleSubscriptionListingTemplateSelector;
}
| {
  impressionName?: ImpressionNames.ROLE_SUBSCRIPTION_LISTING_UPSELL_ERROR_PAGE;
  impressionProperties?: TrackImpressionRoleSubscriptionListingUpsellErrorPage;
}
| {
  impressionName?: ImpressionNames.SHELF_ACTIVITY_DETAILS;
  impressionProperties?: TrackImpressionShelfActivityDetails;
}
| {
  impressionName?: ImpressionNames.SNOWSGIVING;
  impressionProperties?: TrackImpressionSnowsgiving;
}
| {
  impressionName?: ImpressionNames.SOUNDBOARD_POPOUT;
  impressionProperties?: TrackImpressionSoundboardPopout;
}
| {
  impressionName?: ImpressionNames.STAGE_DISCOVERY;
  impressionProperties?: TrackImpressionStageDiscovery;
}
| {
  impressionName?: ImpressionNames.URF_CONFIRM_EMAIL_CODE;
  impressionProperties?: TrackImpressionUrfConfirmEmailCode;
}
| {
  impressionName?: ImpressionNames.URF_ENTER_EMAIL;
  impressionProperties?: TrackImpressionUrfEnterEmail;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_COLLECT_REASONS;
  impressionProperties?: TrackImpressionUserAccountEmailChangeCollectReasons;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_COMPLETE;
  impressionProperties?: TrackImpressionUserAccountEmailChangeComplete;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_ENTER_EMAIL;
  impressionProperties?: TrackImpressionUserAccountEmailChangeEnterEmail;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE;
  impressionProperties?: TrackImpressionUserAccountEmailChangeSendCode;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE;
  impressionProperties?: TrackImpressionUserAccountEmailChangeVerifyCode;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_CHANGE_WARNING;
  impressionProperties?: TrackImpressionUserAccountEmailChangeWarning;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_EMAIL_RESEND_VERIFICATION_EMAIL;
  impressionProperties?: TrackImpressionUserAccountEmailResendVerificationEmail;
}
| {
  impressionName?: ImpressionNames.USER_ACCOUNT_PASSWORD_VERIFY;
  impressionProperties?: TrackImpressionUserAccountPasswordVerify;
}
| {
  impressionName?: ImpressionNames.USER_ACTION_REQUIRED;
  impressionProperties?: TrackImpressionUserActionRequired;
}
| {
  impressionName?: ImpressionNames.USER_ADD_PHONE;
  impressionProperties?: TrackImpressionUserAddPhone;
}
| {
  impressionName?: ImpressionNames.USER_AGE_GATE;
  impressionProperties?: TrackImpressionUserAgeGate;
}
| {
  impressionName?: ImpressionNames.USER_AGE_GATE_UNDERAGE;
  impressionProperties?: TrackImpressionUserAgeGateUnderage;
}
| {
  impressionName?: ImpressionNames.USER_AGREEMENTS;
  impressionProperties?: TrackImpressionUserAgreements;
}
| {
  impressionName?: ImpressionNames.USER_CLYDE_AI_CONSENT_MODAL;
  impressionProperties?: TrackImpressionUserClydeAiConsentModal;
}
| {
  impressionName?: ImpressionNames.USER_LOGIN;
  impressionProperties?: TrackImpressionUserLogin;
}
| {
  impressionName?: ImpressionNames.USER_PASSWORDLESS_CODE_ENTRY;
  impressionProperties?: TrackImpressionUserPasswordlessCodeEntry;
}
| {
  impressionName?: ImpressionNames.USER_PASSWORDLESS_INTRO;
  impressionProperties?: TrackImpressionUserPasswordlessIntro;
}
| {
  impressionName?: ImpressionNames.USER_REGISTER_ACCOUNT_INFORMATION;
  impressionProperties?: TrackImpressionUserRegisterAccountInformation;
}
| {
  impressionName?: ImpressionNames.USER_REGISTER_IDENTITY;
  impressionProperties?: TrackImpressionUserRegisterIdentity;
}
| {
  impressionName?: ImpressionNames.USER_REGISTRATION;
  impressionProperties?: TrackImpressionUserRegistration;
}
| {
  impressionName?: ImpressionNames.USER_SETTINGS_CONNECTIONS;
  impressionProperties?: TrackImpressionUserSettingsConnections;
}
| {
  impressionName?: ImpressionNames.USER_VERIFICATION_MODAL;
  impressionProperties?: TrackImpressionUserVerificationModal;
}
| {
  impressionName?: ImpressionNames.USER_VERIFY_PASSWORD;
  impressionProperties?: TrackImpressionUserVerifyPassword;
}
| {
  impressionName?: ImpressionNames.USER_VERIFY_PHONE;
  impressionProperties?: TrackImpressionUserVerifyPhone;
}
| {
  impressionName?: ImpressionNames.USER_WELCOME;
  impressionProperties?: TrackImpressionUserWelcome;
}
| {
  impressionName?: ImpressionNames.USER_YOU_SCREEN;
  impressionProperties?: TrackImpressionUserYouScreen;
}
| {
  impressionName?: ImpressionNames.VIEW_PANEL_DEVTOOLS;
  impressionProperties?: TrackImpressionViewPanelDevtools;
};
/**
 *
 * Enum of standardized network_action event names
 * @readonly
 * @enum {string}
 */
export enum NetworkActionNames {
  /** @member {string} */
  /** network_action event: when user request to generate jwt token for apple IAP partner subscription request */
  APPLE_JWT_TOKEN_CREATE = 'network_action_apple_jwt_token_create',
  /** @member {string} */
  /** network_action event: endpoint triggered to log user out of several sessions */
  AUTH_SESSIONS_LOGGED_OUT = 'network_action_auth_sessions_logged_out',
  /** @member {string} */
  /** network_action event: authorize an IP Address as part of user verification */
  AUTHORIZE_IP = 'network_action_authorize_ip',
  /** @member {string} */
  /** network_action event: authorize a purchase token as part of a payment flow */
  AUTHORIZE_PAYMENT = 'network_action_authorize_payment',
  /** @member {string} */
  /** network_action event: when a bug report is submitted */
  BUG_REPORT_SUBMIT = 'network_action_bug_report_submit',
  /** @member {string} */
  /** network_action event: when user creates a new channel */
  CHANNEL_CREATE = 'network_action_channel_create',
  /** @member {string} */
  /** network_action event: Fired when the client fetches the list of detectable applications */
  DETECTABLE_APPLICATIONS_FETCH = 'network_action_detectable_applications_fetch',
  /** @member {string} */
  /** network_action event: when user adds a new guild entry to a directory */
  DIRECTORY_GUILD_ENTRY_CREATE = 'network_action_directory_guild_entry_create',
  /** @member {string} */
  /** network_action event: when user deletes a guild entry from a directory */
  DIRECTORY_GUILD_ENTRY_DELETE = 'network_action_directory_guild_entry_delete',
  /** @member {string} */
  /** network_action event: when a user loads their email settings */
  EMAIL_SETTINGS_FETCH = 'network_action_email_settings_fetch',
  /** @member {string} */
  /** network_action event: when a user updates their email settings */
  EMAIL_SETTINGS_UPDATE = 'network_action_email_settings_update',
  /** @member {string} */
  /** network_action event: when the embedded activities shelf is fetched */
  EMBEDDED_ACTIVITIES_FETCH_SHELF = 'network_action_embedded_activities_fetch_shelf',
  /** @member {string} */
  /** network_action event: when a user launches an embedded activity */
  EMBEDDED_ACTIVITIES_LAUNCH = 'network_action_embedded_activities_launch',
  /** @member {string} */
  /** network_action event: when a user clicks forgot password */
  FORGOT_PASSWORD = 'network_action_forgot_password',
  /** @member {string} */
  /** network_action event: when user creates a new guild */
  GUILD_CREATE = 'network_action_guild_create',
  /** @member {string} */
  /** network_action event: User requested to transfer ownership of a server they own to another server member. */
  GUILD_TRANSFER_OWNERSHIP = 'network_action_guild_transfer_ownership',
  /** @member {string} */
  /** network_action event: User requested a code to their current email so they can verify the transfer ownership operation. */
  GUILD_TRANSFER_OWNERSHIP_SEND_CODE = 'network_action_guild_transfer_ownership_send_code',
  /** @member {string} */
  /** network_action event: when a user tries to verify their email for a school hub */
  HUB_EMAIL_VERIFY = 'network_action_hub_email_verify',
  /** @member {string} */
  /** network_action event: Email verification for hubs */
  HUB_EMAIL_VERIFY_SEND = 'network_action_hub_email_verify_send',
  /** @member {string} */
  /** network_action event: Waitlist that weve signed up for */
  HUB_WAITLIST_SIGNUP = 'network_action_hub_waitlist_signup',
  /** @member {string} */
  /** network_action event: When a guild invite is resolved when user visits an invite link or the link is embeded in chat */
  INVITE_RESOLVE = 'network_action_invite_resolve',
  /** @member {string} */
  /** network_action event: User revokes any invite they manage */
  INVITE_REVOKE = 'network_action_invite_revoke',
  /** @member {string} */
  /** network_action event: requesting an SMS token for login */
  LOGIN_REQUEST_SMS_TOKEN = 'network_action_login_request_sms_token',
  /** @member {string} */
  /** network_action event: Triggered after attempting to finalize/cancel a report */
  NCMEC_REPORT_CLOSE = 'network_action_ncmec_report_close',
  /** @member {string} */
  /** network_action event: Triggered after attempting to upload a new NcmecReportableContent */
  NCMEC_REPORTABLE_CONTENT_CREATE = 'network_action_ncmec_reportable_content_create',
  /** @member {string} */
  /** network_action event: User deletes an item in the notification center */
  NOTIFICATION_CENTER_ITEM_DELETE = 'network_action_notification_center_item_delete',
  /** @member {string} */
  /** network_action event: User fetches a page of content in the notification center */
  NOTIFICATION_CENTER_PAGE_FETCH = 'network_action_notification_center_page_fetch',
  /** @member {string} */
  /** network_action event: endpoint to check if a username input is valid */
  POMELO_ATTEMPT = 'network_action_pomelo_attempt',
  /** @member {string} */
  /** network_action event: when we create a pomelo */
  POMELO_CREATE = 'network_action_pomelo_create',
  /** @member {string} */
  /** network_action event: When Quest sends a heartbeat */
  QUEST_HEARTBEAT = 'network_action_quest_heartbeat',
  /** @member {string} */
  /** network_action event: user notifying other users when they go live */
  STREAM_NOTIFY = 'network_action_stream_notify',
  /** @member {string} */
  /** network_action event: The user accepts the agreements */
  USER_ACCEPT_AGREEMENTS = 'network_action_user_accept_agreements',
  /** @member {string} */
  /** network_action event: User requested a code to their current email so they can verify ownership */
  USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE = 'network_action_user_account_email_change_send_code',
  /** @member {string} */
  /** network_action event: User entered a code to verify their current email */
  USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE = 'network_action_user_account_email_change_verify_code',
  /** @member {string} */
  /** network_action event: when user bulk updates their relationships with other users (e.g. sending multiple friend requests at once as a result of contact sync) */
  USER_BULK_RELATIONSHIPS_UPDATE = 'network_action_user_bulk_relationships_update',
  /** @member {string} */
  /** network_action event: Emitted when a user changes their consent status for Clyde AI */
  USER_CLYDE_AI_CONSENT_UPDATED = 'network_action_user_clyde_ai_consent_updated',
  /** @member {string} */
  /** network_action event: when a user, usually a moderator, disables or re-enables communication of another user ("timeout") */
  USER_COMMUNICATION_DISABLED_UPDATE = 'network_action_user_communication_disabled_update',
  /** @member {string} */
  /** network_action event: when user updates their external connections (e.g. contact sync) */
  USER_CONNECTIONS_UPDATE = 'network_action_user_connections_update',
  /** @member {string} */
  /** network_action event: when user syncs contacts */
  USER_CONTACTS_SYNC = 'network_action_user_contacts_sync',
  /** @member {string} */
  /** network_action event: when user logs in */
  USER_LOGIN = 'network_action_user_login',
  /** @member {string} */
  /** network_action event: when the user logs in with MFA */
  USER_LOGIN_MFA = 'network_action_user_login_mfa',
  /** @member {string} */
  /** network_action event: when the user logs in with SMS MFA */
  USER_LOGIN_MFA_SMS = 'network_action_user_login_mfa_sms',
  /** @member {string} */
  /** network_action event: when the user logs in via passwordless */
  USER_LOGIN_PASSWORDLESS = 'network_action_user_login_passwordless',
  /** @member {string} */
  /** network_action event: when the user logs out */
  USER_LOGOUT = 'network_action_user_logout',
  /** @member {string} */
  /** network_action event: Triggered when checking if a user is eligible for passwordless login */
  USER_PASSWORDLESS_INFO_FETCH = 'network_action_user_passwordless_info_fetch',
  /** @member {string} */
  /** network_action event: Requesting a passwordless login code for the user */
  USER_PASSWORDLESS_LOGIN_CODE = 'network_action_user_passwordless_login_code',
  /** @member {string} */
  /** network_action event: when new user registers */
  USER_REGISTER = 'network_action_user_register',
  /** @member {string} */
  /** network_action event: The device is registered to receive push notifications from given user. */
  USER_REGISTER_DEVICE_TOKEN = 'network_action_user_register_device_token',
  /** @member {string} */
  /** network_action event: Triggered on the client when a user submits their phone number during registration. */
  USER_REGISTER_PHONE = 'network_action_user_register_phone',
  /** @member {string} */
  /** network_action event: when a user resets their password */
  USER_RESET_PASSWORD = 'network_action_user_reset_password',
  /** @member {string} */
  /** network_action event: when a user updates their user settings */
  USER_SETTINGS_UPDATE = 'network_action_user_settings_update',
  /** @member {string} */
  /** network_action event: when we fetch survey data for an user */
  USER_SURVEY_FETCH = 'network_action_user_survey_fetch',
  /** @member {string} */
  /** network_action event: when a user sees a survey */
  USER_SURVEY_SEEN = 'network_action_user_survey_seen',
  /** @member {string} */
  /** network_action event: The device is unregistered to no longer receive push notifications for the given user. */
  USER_UNREGISTER_DEVICE_TOKEN = 'network_action_user_unregister_device_token',
  /** @member {string} */
  /** network_action event: when we verify a user before login */
  USER_VERIFY = 'network_action_user_verify',
  /** @member {string} */
  /** network_action event: Triggered by the client when a new user submits a verification code during phone verification. */
  USER_VERIFY_PHONE = 'network_action_user_verify_phone',
  /** @member {string} */
  /** network_action event: when we resend the user verification */
  USER_VERIFY_RESEND = 'network_action_user_verify_resend',
  /** @member {string} */
  /** network_action event: when the user attempts to complete a WebAuthn registration */
  WEBAUTHN_REGISTER = 'network_action_webauthn_register',
}

export type NetworkActionSchema = {
  networkActionName?: NetworkActionNames.APPLE_JWT_TOKEN_CREATE;
  networkActionProperties?: TrackNetworkActionAppleJwtTokenCreate;
}
| {
  networkActionName?: NetworkActionNames.AUTH_SESSIONS_LOGGED_OUT;
  networkActionProperties?: TrackNetworkActionAuthSessionsLoggedOut;
}
| {
  networkActionName?: NetworkActionNames.AUTHORIZE_IP;
  networkActionProperties?: TrackNetworkActionAuthorizeIp;
}
| {
  networkActionName?: NetworkActionNames.AUTHORIZE_PAYMENT;
  networkActionProperties?: TrackNetworkActionAuthorizePayment;
}
| {
  networkActionName?: NetworkActionNames.BUG_REPORT_SUBMIT;
  networkActionProperties?: TrackNetworkActionBugReportSubmit;
}
| {
  networkActionName?: NetworkActionNames.CHANNEL_CREATE;
  networkActionProperties?: TrackNetworkActionChannelCreate;
}
| {
  networkActionName?: NetworkActionNames.DETECTABLE_APPLICATIONS_FETCH;
  networkActionProperties?: TrackNetworkActionDetectableApplicationsFetch;
}
| {
  networkActionName?: NetworkActionNames.DIRECTORY_GUILD_ENTRY_CREATE;
  networkActionProperties?: TrackNetworkActionDirectoryGuildEntryCreate;
}
| {
  networkActionName?: NetworkActionNames.DIRECTORY_GUILD_ENTRY_DELETE;
  networkActionProperties?: TrackNetworkActionDirectoryGuildEntryDelete;
}
| {
  networkActionName?: NetworkActionNames.EMAIL_SETTINGS_FETCH;
  networkActionProperties?: TrackNetworkActionEmailSettingsFetch;
}
| {
  networkActionName?: NetworkActionNames.EMAIL_SETTINGS_UPDATE;
  networkActionProperties?: TrackNetworkActionEmailSettingsUpdate;
}
| {
  networkActionName?: NetworkActionNames.EMBEDDED_ACTIVITIES_FETCH_SHELF;
  networkActionProperties?: TrackNetworkActionEmbeddedActivitiesFetchShelf;
}
| {
  networkActionName?: NetworkActionNames.EMBEDDED_ACTIVITIES_LAUNCH;
  networkActionProperties?: TrackNetworkActionEmbeddedActivitiesLaunch;
}
| {
  networkActionName?: NetworkActionNames.FORGOT_PASSWORD;
  networkActionProperties?: TrackNetworkActionForgotPassword;
}
| {
  networkActionName?: NetworkActionNames.GUILD_CREATE;
  networkActionProperties?: TrackNetworkActionGuildCreate;
}
| {
  networkActionName?: NetworkActionNames.GUILD_TRANSFER_OWNERSHIP;
  networkActionProperties?: TrackNetworkActionGuildTransferOwnership;
}
| {
  networkActionName?: NetworkActionNames.GUILD_TRANSFER_OWNERSHIP_SEND_CODE;
  networkActionProperties?: TrackNetworkActionGuildTransferOwnershipSendCode;
}
| {
  networkActionName?: NetworkActionNames.HUB_EMAIL_VERIFY;
  networkActionProperties?: TrackNetworkActionHubEmailVerify;
}
| {
  networkActionName?: NetworkActionNames.HUB_EMAIL_VERIFY_SEND;
  networkActionProperties?: TrackNetworkActionHubEmailVerifySend;
}
| {
  networkActionName?: NetworkActionNames.HUB_WAITLIST_SIGNUP;
  networkActionProperties?: TrackNetworkActionHubWaitlistSignup;
}
| {
  networkActionName?: NetworkActionNames.INVITE_RESOLVE;
  networkActionProperties?: TrackNetworkActionInviteResolve;
}
| {
  networkActionName?: NetworkActionNames.INVITE_REVOKE;
  networkActionProperties?: TrackNetworkActionInviteRevoke;
}
| {
  networkActionName?: NetworkActionNames.LOGIN_REQUEST_SMS_TOKEN;
  networkActionProperties?: TrackNetworkActionLoginRequestSmsToken;
}
| {
  networkActionName?: NetworkActionNames.NCMEC_REPORT_CLOSE;
  networkActionProperties?: TrackNetworkActionNcmecReportClose;
}
| {
  networkActionName?: NetworkActionNames.NCMEC_REPORTABLE_CONTENT_CREATE;
  networkActionProperties?: TrackNetworkActionNcmecReportableContentCreate;
}
| {
  networkActionName?: NetworkActionNames.NOTIFICATION_CENTER_ITEM_DELETE;
  networkActionProperties?: TrackNetworkActionNotificationCenterItemDelete;
}
| {
  networkActionName?: NetworkActionNames.NOTIFICATION_CENTER_PAGE_FETCH;
  networkActionProperties?: TrackNetworkActionNotificationCenterPageFetch;
}
| {
  networkActionName?: NetworkActionNames.POMELO_ATTEMPT;
  networkActionProperties?: TrackNetworkActionPomeloAttempt;
}
| {
  networkActionName?: NetworkActionNames.POMELO_CREATE;
  networkActionProperties?: TrackNetworkActionPomeloCreate;
}
| {
  networkActionName?: NetworkActionNames.QUEST_HEARTBEAT;
  networkActionProperties?: TrackNetworkActionQuestHeartbeat;
}
| {
  networkActionName?: NetworkActionNames.STREAM_NOTIFY;
  networkActionProperties?: TrackNetworkActionStreamNotify;
}
| {
  networkActionName?: NetworkActionNames.USER_ACCEPT_AGREEMENTS;
  networkActionProperties?: TrackNetworkActionUserAcceptAgreements;
}
| {
  networkActionName?: NetworkActionNames.USER_ACCOUNT_EMAIL_CHANGE_SEND_CODE;
  networkActionProperties?: TrackNetworkActionUserAccountEmailChangeSendCode;
}
| {
  networkActionName?: NetworkActionNames.USER_ACCOUNT_EMAIL_CHANGE_VERIFY_CODE;
  networkActionProperties?: TrackNetworkActionUserAccountEmailChangeVerifyCode;
}
| {
  networkActionName?: NetworkActionNames.USER_BULK_RELATIONSHIPS_UPDATE;
  networkActionProperties?: TrackNetworkActionUserBulkRelationshipsUpdate;
}
| {
  networkActionName?: NetworkActionNames.USER_CLYDE_AI_CONSENT_UPDATED;
  networkActionProperties?: TrackNetworkActionUserClydeAiConsentUpdated;
}
| {
  networkActionName?: NetworkActionNames.USER_COMMUNICATION_DISABLED_UPDATE;
  networkActionProperties?: TrackNetworkActionUserCommunicationDisabledUpdate;
}
| {
  networkActionName?: NetworkActionNames.USER_CONNECTIONS_UPDATE;
  networkActionProperties?: TrackNetworkActionUserConnectionsUpdate;
}
| {
  networkActionName?: NetworkActionNames.USER_CONTACTS_SYNC;
  networkActionProperties?: TrackNetworkActionUserContactsSync;
}
| {
  networkActionName?: NetworkActionNames.USER_LOGIN;
  networkActionProperties?: TrackNetworkActionUserLogin;
}
| {
  networkActionName?: NetworkActionNames.USER_LOGIN_MFA;
  networkActionProperties?: TrackNetworkActionUserLoginMfa;
}
| {
  networkActionName?: NetworkActionNames.USER_LOGIN_MFA_SMS;
  networkActionProperties?: TrackNetworkActionUserLoginMfaSms;
}
| {
  networkActionName?: NetworkActionNames.USER_LOGIN_PASSWORDLESS;
  networkActionProperties?: TrackNetworkActionUserLoginPasswordless;
}
| {
  networkActionName?: NetworkActionNames.USER_LOGOUT;
  networkActionProperties?: TrackNetworkActionUserLogout;
}
| {
  networkActionName?: NetworkActionNames.USER_PASSWORDLESS_INFO_FETCH;
  networkActionProperties?: TrackNetworkActionUserPasswordlessInfoFetch;
}
| {
  networkActionName?: NetworkActionNames.USER_PASSWORDLESS_LOGIN_CODE;
  networkActionProperties?: TrackNetworkActionUserPasswordlessLoginCode;
}
| {
  networkActionName?: NetworkActionNames.USER_REGISTER;
  networkActionProperties?: TrackNetworkActionUserRegister;
}
| {
  networkActionName?: NetworkActionNames.USER_REGISTER_DEVICE_TOKEN;
  networkActionProperties?: TrackNetworkActionUserRegisterDeviceToken;
}
| {
  networkActionName?: NetworkActionNames.USER_REGISTER_PHONE;
  networkActionProperties?: TrackNetworkActionUserRegisterPhone;
}
| {
  networkActionName?: NetworkActionNames.USER_RESET_PASSWORD;
  networkActionProperties?: TrackNetworkActionUserResetPassword;
}
| {
  networkActionName?: NetworkActionNames.USER_SETTINGS_UPDATE;
  networkActionProperties?: TrackNetworkActionUserSettingsUpdate;
}
| {
  networkActionName?: NetworkActionNames.USER_SURVEY_FETCH;
  networkActionProperties?: TrackNetworkActionUserSurveyFetch;
}
| {
  networkActionName?: NetworkActionNames.USER_SURVEY_SEEN;
  networkActionProperties?: TrackNetworkActionUserSurveySeen;
}
| {
  networkActionName?: NetworkActionNames.USER_UNREGISTER_DEVICE_TOKEN;
  networkActionProperties?: TrackNetworkActionUserUnregisterDeviceToken;
}
| {
  networkActionName?: NetworkActionNames.USER_VERIFY;
  networkActionProperties?: TrackNetworkActionUserVerify;
}
| {
  networkActionName?: NetworkActionNames.USER_VERIFY_PHONE;
  networkActionProperties?: TrackNetworkActionUserVerifyPhone;
}
| {
  networkActionName?: NetworkActionNames.USER_VERIFY_RESEND;
  networkActionProperties?: TrackNetworkActionUserVerifyResend;
}
| {
  networkActionName?: NetworkActionNames.WEBAUTHN_REGISTER;
  networkActionProperties?: TrackNetworkActionWebauthnRegister;
};
