import * as Sentry from '@sentry/react';
import {Store} from '@discordapp/flux/Store';
import {Storage} from '@discordapp/storage';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import {fetchCachedUser} from '@developers/actions/UserActionCreators';

import {HAS_VIEWED_CHECKLIST_KEY, NOOP, UserFlags} from '@developers/Constants';
import type {ActionFor, Action} from '@developers/flow/Action';
import type {Guild, User, UserIdentityVerification} from '@developers/flow/Server';

let hasViewedChecklist = false;
let user: User | null;
let guildRecords: Guild[] | null;
let identityVerification: UserIdentityVerification | null;
let userGuildsFetched = false;

function handleLoadedUser({user: nextuser}: {user: User}) {
  user = nextuser;
  Sentry.getCurrentScope().setUser({id: nextuser.id, username: nextuser.username, email: nextuser.email ?? ''});
}

function handleLoadedGuilds({guilds}: ActionFor<'USER_GUILDS_FETCH_SUCCESS'>) {
  // TODO(beckwith) This is likely a bug. GuildRecord is missing a couple of properties that
  // appear to be used elsewhere in Guild.
  guildRecords = guilds as unknown as Guild[];
  userGuildsFetched = true;
}

function handleViewedChecklist() {
  Storage.set(HAS_VIEWED_CHECKLIST_KEY, true);
  hasViewedChecklist = true;
}

function handleIdentityVerificationUpdate(action: ActionFor<'USER_IDENTITY_VERIFICATION_FETCH_SUCCESS'>) {
  identityVerification = action.identityVerification;
}

function handleUserLogout() {
  user = null;
  guildRecords = null;
  userGuildsFetched = false;
  identityVerification = null;
  Sentry.getCurrentScope().setUser(null);
}

class UserStore extends Store<Action> {
  static displayName = 'UserStore';
  initialize() {
    // We don't need to handle any errors here.
    fetchCachedUser().catch(NOOP);
    hasViewedChecklist = Storage.get(HAS_VIEWED_CHECKLIST_KEY) === true;
  }

  get user() {
    return user;
  }

  get guilds() {
    return guildRecords;
  }

  getGuild(guildId: string | null | undefined) {
    return this.guilds != null ? this.guilds.find((guild) => guild.id === guildId) : null;
  }

  get isStaff() {
    if (user == null || user.flags == null) return false;
    return (user.flags & UserFlags.STAFF) !== 0;
  }

  get hasViewedChecklist() {
    return hasViewedChecklist;
  }

  get identityVerification() {
    return identityVerification;
  }

  get userGuildsFetched(): boolean {
    return userGuildsFetched;
  }
}

export default new UserStore(Dispatcher, {
  [ActionTypes.USER_FETCH_SUCCESS]: handleLoadedUser,
  [ActionTypes.USER_GUILDS_FETCH_SUCCESS]: handleLoadedGuilds,
  [ActionTypes.CHECKLIST_VIEWED]: handleViewedChecklist,
  [ActionTypes.USER_IDENTITY_VERIFICATION_FETCH_SUCCESS]: handleIdentityVerificationUpdate,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
