import {Store} from '@discordapp/flux/Store';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {ActionFor, Action} from '@developers/flow/Action';
import type {TeamMember, TeamId, UserId} from '@developers/flow/Server';

let teamMembersByTeam: Map<TeamId, Map<UserId, TeamMember>> = new Map();

function handleTeamMembersFetchSuccess({teamId, teamMembers}: ActionFor<'TEAM_MEMBERS_FETCH_SUCCESS'>): void {
  const teamMemberMap = new Map();
  teamMembers.forEach((member) => {
    teamMemberMap.set(member.user.id, member);
  });

  teamMembersByTeam.set(teamId, teamMemberMap);
}

function handleTeamMemberCreateSuccess({teamMember}: ActionFor<'TEAM_MEMBER_CREATE_SUCCESS'>): void {
  const teamId = teamMember.team_id;
  let teamMembers = teamMembersByTeam.get(teamId);

  if (teamMembers == null) {
    teamMembers = new Map();
  }

  teamMembers.set(teamMember.user.id, teamMember);
  teamMembersByTeam.set(teamId, teamMembers);
}

function handleTeamMemberUpdateSuccess({teamMember}: ActionFor<'TEAM_MEMBER_UPDATE_SUCCESS'>): void {
  const teamId = teamMember.team_id;
  let teamMembers = teamMembersByTeam.get(teamId);

  if (teamMembers == null) {
    teamMembers = new Map();
  }

  teamMembers.set(teamMember.user.id, teamMember);
  teamMembersByTeam.set(teamId, teamMembers);
}

function handleTeamMemberDeleteSuccess({teamId, userId}: ActionFor<'TEAM_MEMBER_DELETE_SUCCESS'>): void {
  const teamMembers = teamMembersByTeam.get(teamId);

  if (teamMembers == null) return;

  teamMembers.delete(userId);
  teamMembersByTeam.set(teamId, teamMembers);
}

function handleUserLogout() {
  teamMembersByTeam = new Map();
}

class TeamMemberStore extends Store<Action> {
  static displayName = 'TeamMemberStore';
  getTeamMembersByTeam(teamId: TeamId): Map<UserId, TeamMember> | null | undefined {
    return teamMembersByTeam.get(teamId);
  }
}

export default new TeamMemberStore(Dispatcher, {
  [ActionTypes.TEAM_FETCH_SUCCESS]({team}) {
    if (team.members != null) {
      const teamMemberMap = new Map();
      team.members.forEach((member) => {
        teamMemberMap.set(member.user.id, member);
      });

      teamMembersByTeam.set(team.id, teamMemberMap);
    }
  },
  [ActionTypes.TEAMS_FETCH_SUCCESS]({teams}) {
    teams.forEach((team) => {
      if (team.members != null) {
        const teamMemberMap = new Map();
        team.members.forEach((member) => {
          teamMemberMap.set(member.user.id, member);
        });

        teamMembersByTeam.set(team.id, teamMemberMap);
      }
    });
  },
  [ActionTypes.TEAM_MEMBERS_FETCH_SUCCESS]: handleTeamMembersFetchSuccess,
  [ActionTypes.TEAM_MEMBER_CREATE_SUCCESS]: handleTeamMemberCreateSuccess,
  [ActionTypes.TEAM_MEMBER_UPDATE_SUCCESS]: handleTeamMemberUpdateSuccess,
  [ActionTypes.TEAM_MEMBER_DELETE_SUCCESS]: handleTeamMemberDeleteSuccess,
  [ActionTypes.USER_LOGOUT]: handleUserLogout,
});
