import * as TokenManager from '@discordapp/common/lib/TokenManager';
import {getLoginPath} from '@discordapp/common/utils/PathUtils';
import {HTTPResponse, get, post} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import {APIError, APIErrorHandler} from '@developers/utils/ErrorUtils';
import {callMeMaybe} from '@developers/utils/PromiseUtils';
import {prependBasename} from '@developers/utils/RouterUtils';
import {isNullOrEmpty} from '@developers/utils/String';
import ActionTypes from './ActionTypes';

import {Endpoints, Routes, ROUTER_BASENAME} from '@developers/Constants';
import type {UserIdentityVerification} from '@developers/flow/Server';

let isUserAuthenticated: boolean | null = null;

export async function enforceUserAuthentication(redirectPath: string = Routes.APPLICATIONS): Promise<void> {
  if (isUserAuthenticated == null) {
    try {
      await fetchUser();
    } catch (res) {
      if (res.status === 401) {
        await logOut(redirectPath);
      }
    }
  }
  if (!isUserAuthenticated) {
    await logOut(redirectPath);
  }
}

export async function fetchUser(): Promise<void> {
  if (TokenManager.getToken() == null) {
    isUserAuthenticated = false;
    throw new Error('user unauthenticated');
  }

  try {
    const res = await get({
      url: Endpoints.USER_WITH_ANALYTICS_TOKEN,
      oldFormErrors: true,
    });
    isUserAuthenticated = true;
    Dispatcher.dispatch({
      type: ActionTypes.USER_FETCH_SUCCESS,
      user: res.body,
      analyticsToken: res.body['analytics_token'],
    });
  } catch (res) {
    isUserAuthenticated = false;
    throw res;
  }
}

const innerFetchCachedUser = callMeMaybe(fetchUser);

export function fetchCachedUser(): Promise<void> {
  return innerFetchCachedUser('authenticated-user');
}

let fetchGuildsPromise: Promise<void> | undefined;
export function fetchGuilds(): Promise<void> {
  // This endpoint has a very low rate limit (1/1s), so let's be very careful about
  // joining promises for the request.
  if (fetchGuildsPromise == null) {
    fetchGuildsPromise = get({
      url: Endpoints.USER_GUILDS,
      query: {with_counts: true},
      oldFormErrors: true,
    })
      .then((res) => {
        Dispatcher.dispatch({type: ActionTypes.USER_GUILDS_FETCH_SUCCESS, guilds: res.body});
        setTimeout(() => {
          fetchGuildsPromise = undefined;
        }, 3000);
      })
      .catch((err) => {
        fetchGuildsPromise = undefined;
        throw new APIError(err);
      });
  }
  return fetchGuildsPromise;
}

export async function logOut(returnPath?: string): Promise<void> {
  try {
    const token = TokenManager.getToken();
    if (!isNullOrEmpty(token)) {
      await post({
        url: Endpoints.LOGOUT,
        body: {token},
        oldFormErrors: true,
      });
    }
  } catch (e) {
    // if this throws it mostly doesn't matter, but lets log for posterity
    // eslint-disable-next-line no-console
    console.error(e);
  } finally {
    isUserAuthenticated = false;
    TokenManager.removeToken();
    Dispatcher.dispatch({type: ActionTypes.USER_LOGOUT});
    if (__DEV__) {
      window.location.href = `/developers${Routes.LOCAL_DEVELOPMENT_AUTH}?redirect=${encodeURIComponent(
        `/developers${returnPath ?? '/'}`,
      )}`;
    } else {
      window.location.href = getLoginPath(`/${ROUTER_BASENAME}${returnPath ?? ''}`);
    }
  }
}

export function setChecklistViewed(): void {
  Dispatcher.dispatch({type: ActionTypes.CHECKLIST_VIEWED});
}

export function exchangeHandoffToken(
  key: string,
  token: string,
): Promise<{
  token: string;
}> {
  return post({
    url: Endpoints.HANDOFF_EXCHANGE,
    body: {
      key,
      handoff_token: token,
    },
    oldFormErrors: true,
  })
    .then((res) => {
      const {token} = res.body;
      Dispatcher.dispatch({type: ActionTypes.UPDATE_TOKEN, token});
      return {token};
    })
    .catch(APIErrorHandler);
}

export function fetchUserIdentityVerification(): Promise<HTTPResponse> {
  return get({
    url: Endpoints.USER_IDENTITY_VERIFICATION,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({type: ActionTypes.USER_IDENTITY_VERIFICATION_FETCH_SUCCESS, identityVerification: res.body});
      return res.body;
    })
    .catch((res: HTTPResponse) => {
      if (res.status === 404) {
        Dispatcher.dispatch({
          type: ActionTypes.USER_IDENTITY_VERIFICATION_FETCH_SUCCESS,
          identityVerification: null,
        });
        return null;
      } else {
        return APIErrorHandler(res);
      }
    });
}

export function createUserIdentityVerification(): Promise<UserIdentityVerification> {
  return post({
    url: Endpoints.USER_IDENTITY_VERIFICATION,
    body: {return_url: `${location.origin}${prependBasename(Routes.POPUP_WINDOW_TRANSITION_CALLBACK)}`},
    oldFormErrors: true,
  })
    .then((res) => {
      Dispatcher.dispatch({type: ActionTypes.USER_IDENTITY_VERIFICATION_FETCH_SUCCESS, identityVerification: res.body});
      return res.body;
    })
    .catch(APIErrorHandler);
}
