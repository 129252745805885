import * as React from 'react';
import isEmpty from 'lodash/isEmpty.js';
import {ErrorCode, HTTPResponse, MessageOrResponse, V6OrEarlierAPIError} from '@discordapp/http-utils';
import {FormattedMessage} from '@discordapp/i18n';

import {FieldErrorList, FieldErrorListItem} from '@developers/uikit/FieldErrorList';
import {getErrorCodeMappings} from './ErrorCodeMap';

import {APIErrorCodes, Links} from '@developers/Constants';
import {Messages} from '@developers/i18n';

export enum ErrorTypes {
  COMMON = 'COMMON',
  TEAMS = 'TEAMS',
  FORMS = 'FORMS',
}

export class APIError extends V6OrEarlierAPIError<Record<string, number>, Record<string, string>> {
  errors?: Record<string, string>;
  constructor(messageOrError: MessageOrResponse, code?: ErrorCode | null, errorMessage?: string | null) {
    const message =
      errorMessage === undefined
        ? Messages.Common.GENERIC_API_ERROR.format({statusPageURL: Links.STATUS})
        : errorMessage;
    super(messageOrError, code, message!);
  }
}

function getMessageFromFieldErrors(error: APIError, header: string): React.ReactNode {
  const fields = error.fields ?? error.errors;
  if (isEmpty(fields)) return null;

  const errorItems = [];
  const errorCodeMappings = getErrorCodeMappings();

  for (const field in fields) {
    if (field === '_misc') {
      const misc = fields._misc.map((error, index) => <FieldErrorListItem key={index} message={error} />);
      errorItems.push(misc);
    } else if (field === '_errors') {
      // @ts-expect-error (AUTO)
      const misc = fields._errors.map(({code, message: fallbackMessage}, index) => {
        const message = errorCodeMappings[ErrorTypes.FORMS][code];
        return <FieldErrorListItem key={index} message={message != null ? message : fallbackMessage} />;
      });
      errorItems.push(misc);
    } else {
      errorItems.push(<FieldErrorListItem key={field} field={field} message={error.getFieldMessage(field)} />);
    }
  }

  return <FieldErrorList header={header}>{errorItems}</FieldErrorList>;
}

export const getMessageFromAPIError = (
  error: APIError,
  defaultErrorMessage: React.ReactNode,
  type: ErrorTypes = ErrorTypes.COMMON,
  fieldErrorListHeader: string = Messages.Errors.VALIDATION_ERRORS,
  formatArgs?: Record<string, string>,
): React.ReactNode => {
  if (error.code !== APIErrorCodes.UNKNOWN_ERROR && error.code !== APIErrorCodes.INVALID_FORM_BODY) {
    const errorCodeMappings = getErrorCodeMappings();
    let message = errorCodeMappings[type][error.code];
    if (message == null && type !== ErrorTypes.COMMON) {
      message = errorCodeMappings[ErrorTypes.COMMON][error.code];
    }

    if (message != null && message instanceof FormattedMessage) {
      try {
        message = message.format(formatArgs);
      } catch (_e) {
        message = null;
      }
    }

    if (message == null) {
      message = error.message;
    }

    return message == null ? defaultErrorMessage : message;
  }

  const fieldErrorMessages = getMessageFromFieldErrors(error, fieldErrorListHeader);
  return fieldErrorMessages == null ? defaultErrorMessage : fieldErrorMessages;
};

export const APIErrorHandler = (response: HTTPResponse | Error) => {
  if (response instanceof Error) {
    // Handle clicking outside of the MFA modal.
    // This is thrown as an exception but we shouldn't trigger most error handling for it
    //  by setting the message explicitly to `null`.
    if (response.message === 'cancelled') {
      throw new APIError('', null, null);
    }

    // This is likely an unhandled exception from the MFAModal, raise it normally.
    throw new APIError(response.message);
  }
  throw new APIError(response);
};
