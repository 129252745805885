import * as React from 'react';
import {Storage} from '@discordapp/storage';

import styles from './useTheme.module.css';

const THEME_KEY = 'devtheme';
const toggles = new Array<number>();
type Theme = 'light' | 'dark' | 'midnight' | 'darker';
const defaultTheme = window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
const initialTheme = Storage.get<Theme>(THEME_KEY) ?? defaultTheme;
toggleThemeHtml(initialTheme);

export function useTheme() {
  const [theme, setTheme] = React.useState<Theme>(initialTheme);

  React.useEffect(() => {
    function listenToThemeChange({matches}: MediaQueryListEvent) {
      const storedTheme = Storage.get<Theme>(THEME_KEY);
      if (storedTheme != null) return;

      if (matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listenToThemeChange);
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listenToThemeChange);
    };
  }, []);

  const toggleTheme = React.useCallback(() => {
    const now = Date.now();
    toggles.push(now);
    const isMidnight = toggles.filter((x) => now - x < 3000).length > 10;
    while (toggles.length > 10) {
      toggles.shift();
    }
    setTheme((currentTheme) => {
      const newTheme = isMidnight ? 'midnight' : currentTheme === 'light' ? 'dark' : 'light';
      Storage.set(THEME_KEY, newTheme);
      return newTheme;
    });
  }, []);

  React.useEffect(() => {
    document.documentElement.classList.add(styles.smoothTransition);
    toggleThemeHtml(theme);
    const timeoutId = setTimeout(() => {
      document.documentElement.classList.remove(styles.smoothTransition);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [theme]);

  return {theme, toggleTheme};
}

function toggleThemeHtml(theme: Theme) {
  document.documentElement.classList.toggle('theme-dark', theme === 'dark');
  document.documentElement.classList.toggle('theme-light', theme === 'light');
  document.documentElement.classList.toggle('theme-midnight', theme === 'midnight');
  document.documentElement.dataset.theme = theme === 'light' ? 'light' : 'dark'; // for algolia docsearch
}
