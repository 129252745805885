interface SkemaErrorItem {
  code: string;
  message: string;
}

interface SkemaErrorMap {
  [subError: string]: SkemaError;
}

type SkemaError = SkemaErrorMap & {
  _errors: SkemaErrorItem[];
};

const SKEMA_META_ERRORS_KEY = '_errors';

export function convertSkemaError(errors: SkemaError) {
  const newBody: Record<string, string[]> = {};

  for (const field in errors) {
    const value = errors[field];
    if (value == null) {
      continue;
    }

    if (field === SKEMA_META_ERRORS_KEY) {
      newBody['_misc'] = errors[field].map((error) => error.message);
    }

    if (Array.isArray(value)) {
      continue;
    }

    const fieldErrors = value[SKEMA_META_ERRORS_KEY];
    if (fieldErrors != null) {
      newBody[field] = fieldErrors.map((error) => error.message);
    } else {
      newBody[field] = [Object.keys(value)[0]];
    }
  }

  return newBody;
}
