/**
 * Shallow compare to objects with the option to ignore certain keys.
 */
export default function shallowEqual(
  a: Record<string, any> | undefined,
  b: Record<string, any> | undefined,
  ignore?: string[],
  /**
   * Will be called with a string for logging
   */
  logCallback?: (log: string) => void,
): boolean {
  if (a === b) {
    return true;
  }
  // If either a or b doesn't exist, than we can simply do an === check on them
  if (a === undefined || b === undefined) {
    return a === b;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    if (logCallback != null) {
      logCallback(`shallowEqual: unequal key lengths ${keysA.length} !=== ${keysB.length}`);
    }
    return false;
  }

  const failedKeys = [];
  for (let i = 0; i < keysA.length; i++) {
    const key = keysA[i];
    if (a[key] !== b[key] && (ignore == null || !ignore.includes(key))) {
      if (logCallback != null) {
        failedKeys.push(key);
      } else {
        return false;
      }
    }
  }

  if (logCallback != null && failedKeys.length > 0) {
    logCallback(`shallowEqual: unequal keys: ${failedKeys.join(', ')}`);
  }
  return failedKeys.length === 0;
}

export function areArraysShallowEqual(arr1: Readonly<any[]>, arr2: Readonly<any[]> | null | undefined): boolean {
  if (arr2 == null || arr1.length !== arr2.length) {
    return false;
  }

  return !arr1.some((value, index) => arr2[index] !== value);
}
