import {i18n} from '@developers/i18n';

export const getLocalizedLink = (localizedLinks: {default: string; [localeKey: string]: string}): string => {
  const normalizedLocale = i18n.getLocale().toLowerCase();

  if (normalizedLocale in localizedLinks) {
    return localizedLinks[normalizedLocale];
  }

  return localizedLinks.default;
};
