import {HTTPResponse, get, post, patch} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import {APIErrorHandler} from '@developers/utils/ErrorUtils';
import {prependBasename} from '@developers/utils/RouterUtils';
import ActionTypes from './ActionTypes';

import {Endpoints, Routes} from '@developers/Constants';
import type {Application, Team, TeamId, TeamPatch, UserIdentityVerification} from '@developers/flow/Server';

export const DEFAULT_PAYOUTS_PER_REQUEST = 48;

export function fetchTeams(): Promise<void> {
  return get({
    url: Endpoints.TEAMS,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAMS_FETCH_SUCCESS,
        teams: res.body,
      });
    })
    .catch(APIErrorHandler);
}

export async function fetchApplicationsForTeam(teamId: TeamId): Promise<Application[]> {
  const res = await get({
    url: Endpoints.TEAM_APPLICATIONS(teamId),
    oldFormErrors: true,
  }).catch(APIErrorHandler);

  return res.body;
}

export function fetchTeam(teamId: TeamId): Promise<void> {
  return get({
    url: Endpoints.TEAM(teamId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_FETCH_SUCCESS,
        team: res.body,
      });
    })
    .catch(APIErrorHandler);
}

export function acceptTeamInvitation(token: string): Promise<Team> {
  return post({
    url: Endpoints.ACCEPT_TEAM_INVITE,
    body: {token},
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const team = res.body;
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_INVITE_ACCEPTED,
        team: team,
      });
      return team;
    })
    .catch(APIErrorHandler);
}

export function createTeam(formData: Partial<Team>): Promise<Team> {
  return post({
    url: Endpoints.TEAMS,
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const team = res.body;
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_CREATE_SUCCESS,
        team,
      });
      return team;
    })
    .catch(APIErrorHandler);
}

export function updateTeam(teamId: TeamId, formData: TeamPatch): Promise<void> {
  return patch({
    url: Endpoints.TEAM(teamId),
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_UPDATE_SUCCESS,
        team: res.body,
      });
    })
    .catch(APIErrorHandler);
}

export function deleteTeam(teamId: TeamId): Promise<void> {
  return post({
    url: Endpoints.TEAM_DELETE(teamId),
    body: {},
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_DELETE_SUCCESS,
        teamId: teamId,
      });
    })
    .catch(APIErrorHandler);
}

export function fetchPayoutHistory(
  teamId: TeamId,
  before?: number | null,
  limit: number = DEFAULT_PAYOUTS_PER_REQUEST,
): Promise<void> {
  return get({
    url: Endpoints.TEAM_PAYOUT_HISTORY(teamId),
    query: {
      before: before != null ? before : undefined,
      limit,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_PAYOUT_HISTORY_FETCH_SUCCESS,
        teamId,
        before,
        payouts: res.body,
      });
    })
    .catch(APIErrorHandler);
}

export function fetchTeamIdentityVerification(teamId: string): Promise<HTTPResponse> {
  return get({
    url: Endpoints.TEAM_IDENTITY_VERIFICATION(teamId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS,
        teamId,
        identityVerification: res.body,
      });
      return res.body;
    })
    .catch((res: HTTPResponse) => {
      if (res.status === 404) {
        Dispatcher.dispatch({
          type: ActionTypes.TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS,
          teamId,
          identityVerification: null,
        });
        return null;
      } else {
        return APIErrorHandler(res);
      }
    });
}

export function createTeamIdentityVerification(teamId: string): Promise<UserIdentityVerification> {
  return post({
    url: Endpoints.TEAM_IDENTITY_VERIFICATION(teamId),
    body: {return_url: `${location.origin}${prependBasename(Routes.POPUP_WINDOW_TRANSITION_CALLBACK)}`},
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS,
        teamId,
        identityVerification: res.body,
      });
      return res.body;
    })
    .catch(APIErrorHandler);
}
