import {DepGraph} from 'dependency-graph';
import invariant from 'invariant';
import AppStartPerformance from '@discordapp/common/AppStartPerformance';
import * as DevtoolsExtension from '@discordapp/common/DevtoolsExtension';
import {Logger} from '@discordapp/common/Logger';
import {mark, measure} from '@discordapp/common/utils/profiling';

import Emitter from './Emitter';
import * as LastFewActions from './LastFewActions';
import {ActionLogger, TraceFunction} from './LoggingUtils';

import type {ActionType, ActionBase} from './FluxTypes';

// Certian "important" but relatively low volume actions are explicitly logged whenever they are dispatched
const LOGGED_ACTION_TYPES = new Set([
  'APP_STATE_UPDATE',
  'CLEAR_CACHES',
  'CONNECTION_CLOSED',
  'CONNECTION_OPEN',
  'CONNECTION_RESUMED',
  'LOGIN_SUCCESS',
  'LOGIN',
  'LOGOUT',
  'MESSAGE_SEND_FAILED',
  'PUSH_NOTIFICATION_CLICK',
  'RESET_SOCKET',
  'SESSION_START',
  'UPLOAD_FAIL',
  'WRITE_CACHES',
]);

const logger = new Logger('Flux');
const SLOW_DISPATCH_THRESHOLD = 100;
const APP_START_LOG_THRESHOLD = 10;

type ActionHandlerReturnValue = boolean | undefined | void | null;
export type ActionHandler<Action extends ActionBase> = (action: Action) => ActionHandlerReturnValue;
export type ActionHandlers<Action extends ActionBase> = {
  [A in Action as A['type']]?: ActionHandler<A>;
};
export type ActionMap<Action extends ActionBase> = {
  [A in Action as A['type']]: A;
};
type Interceptor<Action extends ActionBase> = (action: Action) => boolean;
type ChangeHandler<Action extends ActionBase> = (action: Action) => void;

interface OrderedActionHandler<Action extends Readonly<ActionBase>> {
  name: string;
  actionHandler: ActionHandler<Action>;
  storeDidChange: ChangeHandler<Action>;
}

interface SentryBreadcrumb {
  category?: string;
  message: string;
  data?: Record<string, any>;
}

interface SentryUtils {
  addBreadcrumb: (breadcrumb: SentryBreadcrumb) => void;
}

export type DispatchToken = string;

// const EMIT_TRACE_KEY = '__emit-changes';
const SUBSCRIPTIONS_TRACE_KEY = '__subscriptions';

export class Dispatcher<Action extends Readonly<ActionBase>> {
  _defaultBand: number;
  _interceptors: Array<Interceptor<Action>> = [];
  _subscriptions: {[actionType: string]: Set<(action: any) => any>} = {};
  _waitQueue: Array<() => void> = [];
  _processingWaitQueue: boolean = false;
  _currentDispatchActionType: ActionType | null = null;
  _actionHandlers = new ActionHandlersGraph<Action>();
  _sentryUtils?: SentryUtils = undefined;

  actionLogger: ActionLogger<Action>;
  private functionCache: Record<string, (action: any) => void> = {};

  constructor(defaultBand: number = 0, actionLogger?: ActionLogger<Action>, sentryUtils?: SentryUtils) {
    this._defaultBand = defaultBand;
    this._sentryUtils = sentryUtils;

    if (actionLogger != null) {
      this.actionLogger = actionLogger;
    } else if (typeof window !== 'undefined' && __VERBOSE_STORE_LOGGING__) {
      this.actionLogger = new ActionLogger({persist: true});
    } else {
      this.actionLogger = new ActionLogger();
    }

    this.actionLogger.on('trace', (_actionName, storeName, time) => {
      if (AppStartPerformance.isTracing && time >= APP_START_LOG_THRESHOLD) {
        AppStartPerformance.mark('🦥', storeName, time);
      }
    });
  }

  /**
   * Is this dispatcher currently dispatching?
   */
  isDispatching(): boolean {
    return this._currentDispatchActionType != null;
  }

  /**
   * Dispatches a action to all registered callbacks.
   */
  dispatch(action: Action): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._waitQueue.push(() => {
        try {
          // This function cache causes stack traces to have an entry in the trace with dispatch_ACTION_TYPE
          // which makes it easier for us to know which dispatch was causing a particular error.
          if (this.functionCache[action.type] == null) {
            this.functionCache[action.type] = (action: Action) => this._dispatchWithDevtools(action);
            setDisplayName(this.functionCache[action.type], 'dispatch_' + action.type);
          }
          this.functionCache[action.type](action);
          resolve();
        } catch (e) {
          reject(e);
        }
      });
      this.flushWaitQueue();
    });
  }

  private flushWaitQueue() {
    if (this._processingWaitQueue) return;
    try {
      this._processingWaitQueue = true;
      Emitter.isDispatching = true;
      // Two passes:
      // 1) (inner loop) Fully empties the wait queue, dispatching all queued actions
      // Then, we emit change events, which might add even more dispatches to be fired
      // So 2) (outer loop), after emitting, if there are more dispatches, then flush the queue again
      let count = 0;
      while (this._waitQueue.length > 0) {
        if (++count > 100) {
          const serializedActions = LastFewActions.serialize();
          logger.error('LastFewActions', serializedActions);
          this._sentryUtils?.addBreadcrumb({
            message: 'Dispatcher: Dispatch loop detected',
            data: {
              lastFewActions: serializedActions,
            },
          });
          throw Error('Dispatch loop detected, aborting');
        }

        while (this._waitQueue.length > 0) {
          const func = this._waitQueue.shift()!;
          func();
        }
        Emitter.emit();
      }
    } finally {
      this._processingWaitQueue = false;
      Emitter.isDispatching = false;
    }
  }

  private _dispatchWithDevtools(action: Action) {
    if (process.env.NODE_ENV === 'development') {
      const start = Date.now();
      try {
        this._dispatchWithLogging(action);
      } finally {
        DevtoolsExtension.logFluxAction(action, Date.now() - start);
      }
    } else {
      this._dispatchWithLogging(action);
    }
  }

  private _dispatchWithLogging(action: Action) {
    invariant(
      this._currentDispatchActionType == null,
      `Dispatch.dispatch(...): Cannot dispatch in the middle of a dispatch. Action: ${action.type} Already dispatching: ${this._currentDispatchActionType}`,
    );
    invariant(action.type, `Dispatch.dispatch(...) called without an action type`);

    if (LOGGED_ACTION_TYPES.has(action.type)) {
      logger.log(`Dispatching ${action.type}`);
    }

    mark(action.type);
    LastFewActions.add(action.type);

    const actionLog = this.actionLogger.log<ActionHandlerReturnValue>(action, (trace) => {
      try {
        this._currentDispatchActionType = action.type;
        this._dispatch(action, trace);
      } finally {
        this._currentDispatchActionType = null;
      }
    });

    if (actionLog.totalTime > SLOW_DISPATCH_THRESHOLD) {
      logger.verbose(`Slow dispatch on ${action.type}: ${actionLog.totalTime}ms`);
    }

    if (__VERBOSE_STORE_LOGGING__) {
      this.actionLogger.getLastActionMetrics(action.type);
    }

    try {
      measure(`DISPATCH[${action.type}]`, action.type);
    } catch (e) {
      /* ignored, can throw in dev if you dispatch the same action twice in a row */
    }
  }

  private _dispatch(action: Action, trace: TraceFunction<ActionHandlerReturnValue>) {
    for (const interceptor of this._interceptors) {
      if (interceptor(action)) return false;
    }

    const actionHandlers = this._actionHandlers.getOrderedActionHandlers(action);
    for (let i = 0, actionHandlerCount = actionHandlers.length; i < actionHandlerCount; i++) {
      const {name, actionHandler, storeDidChange} = actionHandlers[i];
      if (trace(name, () => actionHandler(action)) !== false) {
        storeDidChange(action);
      }
    }

    const subscriptions = this._subscriptions[action.type];
    if (subscriptions != null) {
      trace(SUBSCRIPTIONS_TRACE_KEY, () => {
        subscriptions.forEach((subscriber) => subscriber(action));
      });
    }
    return;
  }

  /**
   * Set interceptor that might consume actions.
   */
  addInterceptor(interceptor: Interceptor<Action>) {
    this._interceptors.push(interceptor);
  }

  /**
   * Joins the wait queue, which gets processed after every dispatch, if necessary.
   */
  wait(callback: () => any) {
    this._waitQueue.push(callback);
    this.flushWaitQueue();
  }

  subscribe<SubscriptionActionType extends ActionType>(
    actionType: SubscriptionActionType,
    callback: (action: ActionMap<Action>[SubscriptionActionType]) => unknown,
  ) {
    let subscriptions = this._subscriptions[actionType];
    if (subscriptions == null) {
      this._subscriptions[actionType] = subscriptions = new Set();
    }
    subscriptions.add(callback);
  }

  unsubscribe<SubscriptionActionType extends ActionType>(
    actionType: SubscriptionActionType,
    callback: (action: ActionMap<Action>[SubscriptionActionType]) => unknown,
  ) {
    const subscriptions = this._subscriptions[actionType];
    if (subscriptions != null) {
      subscriptions.delete(callback);
      if (subscriptions.size === 0) {
        delete this._subscriptions[actionType];
      }
    }
  }

  register(
    name: string,
    actionHandler: ActionHandlers<Action>,
    storeDidChange: ChangeHandler<Action>,
    band?: number,
    token?: string,
  ): DispatchToken {
    return this._actionHandlers.register(name, actionHandler, storeDidChange, band ?? this._defaultBand, token);
  }

  createToken() {
    return this._actionHandlers.createToken();
  }

  addDependencies(from: DispatchToken, tos: DispatchToken[]) {
    this._actionHandlers.addDependencies(from, tos);
  }
}

class ActionHandlersGraph<Action extends Readonly<ActionBase>> {
  _orderedActionHandlers: {
    [key: string]: Array<OrderedActionHandler<Action>>;
  } = {};
  _orderedCallbackTokens: DispatchToken[] | null = null;
  _lastID: number = 1;
  _dependencyGraph: DepGraph<{
    name: string;
    band: number;
    actionHandler: ActionHandlers<Action>;
    storeDidChange: ChangeHandler<Action>;
  }> = new DepGraph();

  getOrderedActionHandlers(action: Action) {
    return this._orderedActionHandlers[action.type] ?? this._computeOrderedActionHandlers(action.type);
  }

  /**
   * Registers a action handler to be invoked with every dispatched action. Returns a token
   * that can be used with `addDependencies`.
   */
  register(
    name: string,
    actionHandlers: ActionHandlers<Action>,
    storeDidChange: ChangeHandler<Action>,
    band: number,
    token: string = this.createToken(),
  ): DispatchToken {
    invariant(band >= 0 && Number.isInteger(band), 'band must be a non-negative integer.');

    const wrappedActionHandlers: ActionHandlers<Action> = {};
    for (const actionName in actionHandlers) {
      // @ts-expect-error
      const handler: ActionHandler<Action> = actionHandlers[actionName];
      const wrapper: ActionHandler<Action> = (action) => handler(action);
      setDisplayName(wrapper, `${name}_${actionName}`);
      // @ts-expect-error
      wrappedActionHandlers[actionName] = wrapper;
    }

    this._dependencyGraph.addNode(token, {name, band, actionHandler: wrappedActionHandlers, storeDidChange});
    this._addToBand(token, band);
    this._invalidateCaches();

    return token;
  }

  createToken() {
    return `ID_${this._lastID++}`;
  }

  /**
   * Marks a given dispatch token `from` as being dependent on the tokens in `to` executing first.
   */
  addDependencies(from: DispatchToken, tos: DispatchToken[]) {
    this._validateDependencies(from, tos);

    for (const to of tos) {
      this._dependencyGraph.addDependency(from, to);
    }

    this._invalidateCaches();
  }

  private _validateDependencies(from: DispatchToken, tos: DispatchToken[]) {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      for (const to of tos) {
        const {name: fromName, band: fromBand} = this._dependencyGraph.getNodeData(from);
        const {name: toName, band: toBand} = this._dependencyGraph.getNodeData(to);

        invariant(
          toBand <= fromBand,
          `
          cannot add dependency ${fromName} → ${toName} because ${toName} (band ${toBand}) will never execute before ${fromName} (band ${fromBand}).

          ${fromName} can only depend on items in the same or lower bands (bands 0..=${fromBand}).
          `,
        );
      }
    }
  }

  private _invalidateCaches() {
    this._orderedCallbackTokens = null;
    this._orderedActionHandlers = {};
  }

  /**
   * returns the `DispatchToken` for a given band. creates it if necessary.
   */
  private _bandToken(band: number): DispatchToken {
    const token = `band.${band}`;

    if (!this._dependencyGraph.hasNode(token)) {
      this._dependencyGraph.addNode(token, {name: token, band: band, actionHandler: {}, storeDidChange: () => {}});

      // band `n` must depend on band `n - 1`.
      if (band > 0) {
        this._dependencyGraph.addDependency(token, this._bandToken(band - 1));
      }
    }

    return token;
  }

  /**
   * performs all book keeping necessary to register `token` with the given `band`.
   *
   * bands are just virtual nodes that have a dependency to all tokens in their node; stores needing to wait on a
   * particular band simply need to add a dependency to it.
   */
  private _addToBand(token: DispatchToken, band: number) {
    // 1. add to current band.
    this._dependencyGraph.addDependency(this._bandToken(band), token);

    // 2. depend on previous band, so that we run after.
    if (band > 0) {
      this._dependencyGraph.addDependency(token, this._bandToken(band - 1));
    }
  }

  private _computeOrderedActionHandlers(actionType: Action['type']): Array<OrderedActionHandler<Action>> {
    const orderedCallbackTokens = this._orderedCallbackTokens ?? this._computeOrderedCallbackTokens();
    const actionHandlers: Array<OrderedActionHandler<Action>> = [];

    // For all the ordered tokens, compute the subset of functions that are relevant for the given
    // actionType.
    for (let i = 0, l = orderedCallbackTokens.length; i < l; i++) {
      const {name, actionHandler, storeDidChange} = this._dependencyGraph.getNodeData(orderedCallbackTokens[i]);
      const specificActionHandler = actionHandler[actionType];
      if (specificActionHandler != null) {
        actionHandlers.push({
          name,
          // @ts-expect-error TS doesn't seem to be able to assert that
          // `Action['type'] is a mapped key of `ActionHandlers`, and so it
          // resolves to an empty object rather than a generic handler type
          // Type 'NonNullable<ActionHandlers<Action>[Action["type"]]>' is not assignable to type 'ActionHandler<Action>'.
          actionHandler: specificActionHandler,
          storeDidChange,
        });
      }
    }

    this._orderedActionHandlers[actionType] = actionHandlers;
    return actionHandlers;
  }

  private _computeOrderedCallbackTokens(): DispatchToken[] {
    try {
      const orderedCallbackTokens = this._dependencyGraph.overallOrder();
      this._orderedCallbackTokens = orderedCallbackTokens;
      return orderedCallbackTokens;
    } catch (e) {
      if (e.cyclePath != null) {
        // Annotate the cycle path with the store names, so this error is useful.
        const cyclePath = e.cyclePath.map((token: any) => `${this._dependencyGraph.getNodeData(token).name}(${token})`);
        throw new Error(`Dependency Cycle Found: ${cyclePath.join(' -> ')}`);
      }

      throw e;
    }
  }
}

function setDisplayName(func: (...args: any[]) => unknown, displayName: string): void {
  if (__WEB__) {
    Object.defineProperty(func, 'name', {value: displayName});
  } else {
    // @ts-expect-error TS doesn't know about displayName
    func.displayName = displayName;
  }
}
