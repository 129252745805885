import * as React from 'react';
import classNames from 'classnames';
import {FocusRing} from 'react-focus-rings';
import {useLocation} from 'react-router-dom';
import {useStateFromStores} from '@discordapp/flux';

import * as UIActions from '@developers/actions/UIActions';
import UIStore from '@developers/stores/UIStore';
import ShowCta from './cta/ShowCta';

import {Routes} from '@developers/Constants';
import styles from './Sidebar.module.css';

interface SidebarProps {
  children: React.ReactNode;
  contentClassName?: string;
  innerWrapperClassName?: string;
}

const ctaPaths: Set<string> = new Set([Routes.APPLICATIONS, Routes.TEAMS, Routes.GUILDS, Routes.DEVELOPERS_DOCS]);

function Sidebar(props: SidebarProps) {
  const isMenuOpen = useStateFromStores([UIStore], () => UIStore.isMenuOpen);
  const {children, contentClassName, innerWrapperClassName} = props;
  const location = useLocation();
  return (
    <>
      <div className={classNames(styles.wrapper, {[styles.wrapperOpen]: isMenuOpen})}>
        <div className={styles.scroller}>
          <div className={classNames(styles.wrapperInner, innerWrapperClassName)}>
            <div className={classNames(styles.content, contentClassName)}>{children}</div>
            <div className={styles.ctasWithinContent}>
              {ctaPaths.has(location.pathname) && (
                <div className={styles.ctas}>
                  <ShowCta />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FocusRing>
        {/* eslint-disable-next-line @discordapp/discord/use-a11y-component */}
        <div
          className={classNames(styles.overlay, {[styles.overlayOpen]: isMenuOpen})}
          onClick={UIActions.closeMobileMenu}
        />
      </FocusRing>
    </>
  );
}

export default Sidebar;
