import {useStateFromStores} from '@discordapp/flux';

import ExperimentStore from '@developers/stores/ExperimentStore';

import {ExperimentBuckets, Experiments} from '@developers/Constants';

const useCanViewAllCommunityGuilds = () => {
  const viewAnalyticsExperiment = useStateFromStores([ExperimentStore], () =>
    ExperimentStore.getEligibleExperiment(Experiments.STAFF_GUILD_INSIGHTS_BYPASS),
  );
  return viewAnalyticsExperiment != null && viewAnalyticsExperiment.bucket === ExperimentBuckets.TREATMENT_1;
};

export default useCanViewAllCommunityGuilds;
