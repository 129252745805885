import {TeamMemberRoles} from '@discordapp/common/shared-constants/TeamMemberRoles';
import {useStateFromStores} from '@discordapp/flux';

import {teamMemberCan} from '@developers/lib/teams';
import ApplicationStore from '@developers/stores/ApplicationStore';
import UserStore from '@developers/stores/UserStore';

import {ApplicationFlags, UserFlags} from '@developers/Constants';

export function useShouldShowActiveDeveloperCta() {
  const user = useStateFromStores([UserStore], () => UserStore.user);
  const applications = useStateFromStores([ApplicationStore], () => ApplicationStore.applications);

  if (user == null) return false;
  const isUserFlagUnset = ((user?.flags ?? 0) & UserFlags.ACTIVE_DEVELOPER) === 0;
  const hasActiveApp =
    applications.filter(
      (app) =>
        (app.flags & ApplicationFlags.ACTIVE) !== 0 && teamMemberCan(app.owner.id, user.id, TeamMemberRoles.DEVELOPER),
    ).length > 0;

  return isUserFlagUnset && hasActiveApp;
}
