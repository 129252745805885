interface MessageResponseError {
  body:
    | {
        message: string;
        code?: number;
        retry_after?: number;
      }
    | undefined
    | null;
  status: number;
}

interface FieldResponseError {
  body:
    | {
        [field: string]: string[];
      }
    | undefined
    | null;
  status: number;
}

export type MessageOrResponse = string | MessageResponseError | FieldResponseError;

export type ErrorCode = number;

function parseConstructorInput(
  messageOrResponse: MessageOrResponse,
  code?: number | null,
): {
  message?: string;
  retryAfter?: number;
  code?: number | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  fields?: {};
  status?: number;
} {
  if (typeof messageOrResponse === 'string') {
    return {
      message: messageOrResponse,
      code,
    };
  }
  if (messageOrResponse.body != null) {
    if (
      messageOrResponse.body.message != null &&
      !Array.isArray(messageOrResponse.body.message) &&
      (messageOrResponse.body.code == null || !Array.isArray(messageOrResponse.body.code))
    ) {
      return {
        message: messageOrResponse.body.message,
        code: messageOrResponse.body.code as number,
        retryAfter: messageOrResponse.body.retry_after as number,
        status: messageOrResponse.status,
      };
    } else {
      const fields = messageOrResponse.body;
      const defaultMessageList: string[] | null = fields != null ? Object.values(fields)[0] : null;

      return {
        message: defaultMessageList != null ? defaultMessageList[0] : undefined,
        fields: fields,
        status: messageOrResponse.status,
      };
    }
  }

  return {};
}

// TODO: To be honest, the ErrorCodes and Fields types are not that useful.
// Everywhere in our codebase these are just `any` or `object`. We should probably remove them.
/**
 * @deprecated Prefer V8APIError, which also supports the format of this APIError. v7 never existed in the wild.
 *
 */
export class APIError<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ErrorCodes extends Record<string, number> = Record<string, number>,
  Fields extends Record<string, string> = Record<string, string>,
> {
  message: string;
  code: number;
  retryAfter?: number;
  fields: Record<string, string[]>;
  error: Error;
  status?: number;

  constructor(
    messageOrResponse: MessageOrResponse,
    code?: ErrorCode | null,
    // Ideally all consumers will provide their own i18n string for generic error messages.
    defaultErrorMessage: string = 'An unexpected error occurred.',
  ) {
    const {message, code: parsedCode, retryAfter, fields, status} = parseConstructorInput(messageOrResponse, code);
    this.message = message || defaultErrorMessage;
    this.retryAfter = retryAfter;
    this.code = parsedCode || -1;
    this.fields = fields || {};
    this.status = status;
    this.error = new Error(message);
  }

  getFieldMessage(field: Fields[keyof Fields]): string | null | undefined {
    if (this.fields[field] != null) {
      return this.fields[field][0];
    }
    return null;
  }
}
