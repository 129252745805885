import IntlMessageFormat from 'intl-messageformat';
import SimpleMarkdown, {Rules, ASTNode} from 'simple-markdown';

import {rules} from './markdownRules';

import {FORMAT_RE, MARKDOWN_RE, UNSAFE_RE, UNSAFE_RE_ALL} from './Constants';

type FormatContext = Record<string, any>;

export class FormattedMessage<T extends FormatContext> {
  readonly message: string;
  readonly hasMarkdown: boolean;

  readonly intlMessage: IntlMessageFormat;

  constructor(message: string, locale: string, hasMarkdown: boolean) {
    this.message = hasMarkdown ? message : message.replace(UNSAFE_RE_ALL, '');
    this.hasMarkdown = hasMarkdown;

    this.intlMessage = new IntlMessageFormat(this.message, locale);
  }

  format(context?: T): string {
    if (!this.hasMarkdown) {
      return this.intlMessage.format(context);
    }

    const [safeContext, unsafeContext] = this.getContext(context);
    return parse!(this.intlMessage.format(safeContext), safeContext, unsafeContext);
  }

  astFormat(context?: T): ASTNode {
    const [safeContext, unsafeContext] = this.getContext(context);
    return parseForNonReact!(this.intlMessage.format(safeContext), safeContext, unsafeContext);
  }

  plainFormat(context?: T): string {
    return this.intlMessage.format(context);
  }

  private getContext(context?: T): [FormatContext, FormatContext] {
    const safeContext: FormatContext = context as any;
    const unsafe = UNSAFE_RE.test(this.message);
    const unsafeContext: Record<number, any> = {};
    if (unsafe) {
      let i = 0;
      for (const [key, value] of Object.entries(safeContext)) {
        if (this.message.includes(`!!{${key}}!!`)) {
          unsafeContext[++i] = value;
          safeContext[key] = i;
        }
      }
    }
    return [safeContext, unsafeContext];
  }
}

function parserFor(rules: Rules<any>, updateRules: (Rules: Rules<any>) => Rules<any>): Parse {
  const parser = SimpleMarkdown.parserFor(updateRules(rules));
  // @ts-expect-error Interface does not expose reactFor, but it is what we need.
  const output: SimpleMarkdown.Output<any> = SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react'));
  return (str: string, context?: FormatContext, unsafeContext?: FormatContext) => {
    const inline = !str.includes('\n\n');
    if (!inline) {
      str += '\n\n';
    }
    return output(parser(str, {inline, context, unsafeContext}));
  };
}

function parserForNonReact(rules: Rules<any>): ParseNonReact {
  const parser = SimpleMarkdown.parserFor(rules);
  return (str: string, context?: FormatContext, unsafeContext?: FormatContext) =>
    parser(str + '\n\n', {inline: false, context, unsafeContext});
}

type Parse = (str: string, context?: FormatContext, unsafeContext?: FormatContext) => string;
type ParseNonReact = (str: string, context?: FormatContext, unsafeContext?: FormatContext) => ASTNode;

let parse: Parse;
let parseForNonReact: ParseNonReact;

export function setUpdateRules(updateRules: (rules: Rules<any>) => Rules<any>) {
  parse = parserFor(rules, updateRules);
  parseForNonReact = parserForNonReact(rules);
}

export function getMessage(str: string | null, locale: string): string | FormattedMessage<any> {
  // could be null if the key was removed, or going back to old versions of the codebase + new downloaded json
  if (str == null) {
    return '';
  }

  if (parse == null) {
    setUpdateRules(require('./updateRules.web').default);
  }

  // Strip any leading and trailing newlines.
  str = str.replace(/^\n+|\n+$/g, '');

  // Check if plain or formatted string.
  const hasFormat = FORMAT_RE.test(str);
  const hasMarkdown = MARKDOWN_RE.test(str);

  return hasFormat || hasMarkdown ? new FormattedMessage(str, locale, hasMarkdown) : str;
}
