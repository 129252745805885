export function getIconSize(iconSize: IconSize): {width: number; height: number} | null {
  if (iconSize === 'custom') return null;

  const size = ICON_SIZE[iconSize];
  return {width: size, height: size};
}
export const ICON_SIZE = {
  xxs: 12,
  xs: 16,
  sm: 18,
  md: 24,
  lg: 32,
  custom: undefined,
};

export type IconSize = keyof typeof ICON_SIZE;
