/**
 * Get the proper login path for the app, and add a safe redirect.
 */
export function getLoginPath(returnPath?: string | null, includeHost: boolean = true, params: string = ''): string {
  // This is for local development on marketing
  const host = window.GLOBAL_ENV.WEBAPP_ENDPOINT != null ? window.GLOBAL_ENV.WEBAPP_ENDPOINT : '';
  const redirectPath = returnPath != null ? `?redirect_to=${encodeURIComponent(returnPath)}` : '';
  if (params.length !== 0) {
    params = redirectPath.length === 0 ? `?${params}` : `&${params}`;
  }
  return `${includeHost ? host : ''}/login${redirectPath}${params}`;
}

function encodePathArguments(
  unencodedArguments: Array<string | null | undefined>,
  safeCharacters?: string[],
): Array<string | null | undefined> {
  return unencodedArguments.map((unencodedArgument) => {
    if (unencodedArgument == null) {
      return unencodedArgument;
    }
    if (safeCharacters == null) {
      return encodeURIComponent(unencodedArgument);
    }
    return String(unencodedArgument)
      .split('')
      .map((character) =>
        safeCharacters != null && safeCharacters.includes(character) ? character : encodeURIComponent(character),
      )
      .join('');
  });
}

type Path = string | ((...args: any[]) => string);

// Wraps all paths with a path arguement url encoder (prevents path injection)
export function wrapPaths<T extends Record<string, Path>>(paths: T, safeCharacters?: string[]): T {
  const newPaths: Record<string, any> = {};
  for (const key of Object.keys(paths)) {
    const path = paths[key];
    if (typeof path !== 'function') {
      newPaths[key] = path;
      continue;
    }
    newPaths[key] = (...args: any[]) => path(...encodePathArguments(args, safeCharacters));
  }
  return newPaths as T;
}
