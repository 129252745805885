/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum OAuth2Scopes {
  IDENTIFY = 'identify',
  EMAIL = 'email',
  CONNECTIONS = 'connections',
  GUILDS = 'guilds',
  GUILDS_JOIN = 'guilds.join',
  GUILDS_MEMBERS_READ = 'guilds.members.read',
  GUILDS_CHANNELS_READ = 'guilds.channels.read',
  GDM_JOIN = 'gdm.join',
  BOT = 'bot',
  RPC = 'rpc',
  RPC_NOTIFICATIONS_READ = 'rpc.notifications.read',
  RPC_VOICE_READ = 'rpc.voice.read',
  RPC_VOICE_WRITE = 'rpc.voice.write',
  RPC_VIDEO_READ = 'rpc.video.read',
  RPC_VIDEO_WRITE = 'rpc.video.write',
  RPC_SCREENSHARE_READ = 'rpc.screenshare.read',
  RPC_SCREENSHARE_WRITE = 'rpc.screenshare.write',
  RPC_ACTIVITIES_WRITE = 'rpc.activities.write',
  WEBHOOK_INCOMING = 'webhook.incoming',
  MESSAGES_READ = 'messages.read',
  APPLICATIONS_BUILDS_UPLOAD = 'applications.builds.upload',
  APPLICATIONS_BUILDS_READ = 'applications.builds.read',
  APPLICATIONS_COMMANDS = 'applications.commands',
  APPLICATIONS_COMMANDS_PERMISSIONS_UPDATE = 'applications.commands.permissions.update',
  APPLICATIONS_COMMANDS_UPDATE = 'applications.commands.update',
  APPLICATIONS_STORE_UPDATE = 'applications.store.update',
  APPLICATIONS_ENTITLEMENTS = 'applications.entitlements',
  ACTIVITIES_READ = 'activities.read',
  ACTIVITIES_WRITE = 'activities.write',
  RELATIONSHIPS_READ = 'relationships.read',
  RELATIONSHIPS_WRITE = 'relationships.write',
  VOICE = 'voice',
  DM_CHANNELS_READ = 'dm_channels.read',
  ROLE_CONNECTIONS_WRITE = 'role_connections.write',
  PRESENCES_READ = 'presences.read',
  PRESENCES_WRITE = 'presences.write',
  OPENID = 'openid',
  DM_CHANNELS_MESSAGES_READ = 'dm_channels.messages.read',
  DM_CHANNELS_MESSAGES_WRITE = 'dm_channels.messages.write',
  GATEWAY_CONNECT = 'gateway.connect',
  ACCOUNT_GLOBAL_NAME_UPDATE = 'account.global_name.update',
  PAYMENT_SOURCES_COUNTRY_CODE = 'payment_sources.country_code',
  SDK_SOCIAL_LAYER = 'sdk.social_layer',
}

export const OAuth2ScopesSets = {
  PUBLIC: new Set(['applications.builds.read', 'applications.builds.upload', 'applications.commands', 'applications.commands.permissions.update', 'applications.entitlements', 'applications.store.update', 'bot', 'connections', 'email', 'gdm.join', 'guilds', 'guilds.join', 'guilds.members.read', 'identify', 'messages.read', 'openid', 'role_connections.write', 'rpc', 'rpc.activities.write', 'rpc.notifications.read', 'rpc.screenshare.read', 'rpc.screenshare.write', 'rpc.video.read', 'rpc.video.write', 'rpc.voice.read', 'rpc.voice.write', 'webhook.incoming']),
  PRIVATE: new Set(['account.global_name.update', 'activities.read', 'activities.write', 'dm_channels.messages.read', 'dm_channels.messages.write', 'dm_channels.read', 'gateway.connect', 'guilds.channels.read', 'payment_sources.country_code', 'presences.read', 'presences.write', 'relationships.read', 'relationships.write', 'sdk.social_layer', 'voice']),
  TEAM_USER: new Set(['applications.builds.read', 'applications.builds.upload', 'applications.commands.update', 'applications.entitlements', 'applications.store.update', 'identify']),
  EMBEDDED_APPS: new Set(['rpc.activities.write', 'rpc.voice.read', 'rpc.voice.write']),
  CLIENT_CREDENTIALS: new Set(['applications.commands.update']),
  CONFIDENTIAL_REQUEST_SCOPES: new Set(['role_connections.write']),
  BYPASS: new Set(['applications.commands', 'bot']),
  BASE_INTEGRATION: new Set(['applications.commands']),
  GUILD_INTEGRATION: new Set(['applications.commands', 'bot', 'webhook.incoming']),
  USER_INTEGRATION: new Set(['applications.commands']),
  GUILD_INTEGRATION_EXCLUSIVE: new Set(['bot', 'webhook.incoming']),
  ALL_INTEGRATION_SCOPES: new Set(['applications.commands', 'bot', 'webhook.incoming']),
  PRIVATE_CHANNEL_INTEGRATION: new Set(['applications.commands']),
  SLAYER_INTEGRATION: new Set(['activities.read', 'dm_channels.messages.read', 'dm_channels.messages.write', 'dm_channels.read', 'gateway.connect', 'identify', 'openid', 'presences.read', 'presences.write', 'relationships.read', 'relationships.write']),
  SLAYER_INTEGRATION_PROVISIONAL_ACCOUNT: new Set(['account.global_name.update', 'activities.read', 'dm_channels.messages.read', 'dm_channels.messages.write', 'dm_channels.read', 'gateway.connect', 'identify', 'openid', 'presences.read', 'presences.write', 'relationships.read', 'relationships.write']),
  UMBRELLA: new Set(['sdk.social_layer']),
  UMBRELLA_PERMISSIBLE_SCOPES: {
    ['sdk.social_layer']: new Set(['account.global_name.update', 'activities.read', 'activities.write', 'dm_channels.messages.read', 'dm_channels.messages.write', 'dm_channels.read', 'gateway.connect', 'guilds.channels.read', 'identify', 'openid', 'presences.read', 'presences.write', 'relationships.read', 'relationships.write']),
  },
  ALL: new Set(['account.global_name.update', 'activities.read', 'activities.write', 'applications.builds.read', 'applications.builds.upload', 'applications.commands', 'applications.commands.permissions.update', 'applications.entitlements', 'applications.store.update', 'bot', 'connections', 'dm_channels.messages.read', 'dm_channels.messages.write', 'dm_channels.read', 'email', 'gateway.connect', 'gdm.join', 'guilds', 'guilds.channels.read', 'guilds.join', 'guilds.members.read', 'identify', 'messages.read', 'openid', 'payment_sources.country_code', 'presences.read', 'presences.write', 'relationships.read', 'relationships.write', 'role_connections.write', 'rpc', 'rpc.activities.write', 'rpc.notifications.read', 'rpc.screenshare.read', 'rpc.screenshare.write', 'rpc.video.read', 'rpc.video.write', 'rpc.voice.read', 'rpc.voice.write', 'sdk.social_layer', 'voice', 'webhook.incoming']),
};
