import * as React from 'react';

import {isNullOrEmpty} from '@developers/utils/String';

interface Props<T> {
  children: React.ReactNode;
  className?: string;
  onChange?: (fieldName: string, fieldValue: unknown, event?: unknown) => void;
  onSubmit?: (formData: T, event: React.SyntheticEvent<HTMLFormElement>) => void;
}

export default class Form<T = unknown> extends React.Component<Props<T>> {
  formRef: HTMLFormElement | null | undefined = null;

  getFormData(): T {
    if (this.formRef == null) return {} as T;

    // Using a dirty dict, and then casting below to `T`. This is one of those rare
    // cases where making the code typesafe would not be worth it IMO (beckwith)
    const formData: Record<string, unknown> = {};
    for (const ele of this.formRef.elements) {
      const element = ele as HTMLInputElement;
      const {name, type, value} = element;

      if (isNullOrEmpty(name) || type === 'button' || type === 'submit' || type === 'reset') {
        continue;
      }
      if (type === 'checkbox') {
        formData[name] = element.checked;
      } else if (type === 'file') {
        formData[name] = element.files;
      } else if (type === 'text') {
        formData[name] = value === '' ? null : value;
      } else if (type !== 'radio') {
        formData[name] = value;
      } else if (type === 'radio' && !element.checked && !formData.hasOwnProperty(name)) {
        formData[name] = null;
      } else if (type === 'radio' && element.checked) {
        formData[name] = value;
      }
    }
    return formData as T;
  }

  handleFormChange = (event: React.SyntheticEvent<HTMLFormElement>): void => {
    const {onChange} = this.props;
    if (onChange != null) {
      const target = event.currentTarget;
      onChange(target.name, target.value, event);
    }
  };

  handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const {onSubmit} = this.props;
    if (onSubmit != null) {
      onSubmit(this.getFormData(), event);
    }
  };

  requestSubmit = (): void => {
    this.formRef?.requestSubmit();
  };

  resetForm = (): void => {
    this.formRef?.reset();
  };

  setFormRef = (ref?: HTMLFormElement | null): void => {
    this.formRef = ref;
  };

  render() {
    return (
      <form
        ref={this.setFormRef}
        className={this.props.className}
        onChange={this.handleFormChange}
        onSubmit={this.handleFormSubmit}>
        {this.props.children}
      </form>
    );
  }
}
