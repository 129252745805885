import * as React from 'react';
import {Text} from '@discordapp/design/components/Text/Text.web';
import {HTTPResponse, get} from '@discordapp/http-utils';

const buildOverridesEndpoint = new URL('/__development/build_overrides', location.origin);
const buildOverrideCookieName = 'buildOverride';
const buildOverrideProjectName = 'discord_developers';

function maybeHasBuildOverride(): boolean {
  return window.document.cookie.includes(`${buildOverrideCookieName}=`);
}

interface BuildOverride {
  id: string;
  type: 'id' | 'branch';
}

interface BuildOverrides {
  [project: string]: BuildOverride;
}

interface Props {
  override: BuildOverride;
}

export function BuildOverrideMenuItem({override}: Props) {
  return (
    <>
      <br />
      <Text variant="text-xs/normal">Build Override: {override.id}</Text>
    </>
  );
}

export function useFetchBuildOverride(): BuildOverride | void {
  const [buildOverride, setBuildOverride] = React.useState<BuildOverride>();
  React.useEffect(() => {
    if (__DEV__) return;
    if (!maybeHasBuildOverride()) return;
    get(buildOverridesEndpoint.href)
      .then((res: HTTPResponse<BuildOverrides>) => {
        if (buildOverrideProjectName in res.body) {
          setBuildOverride(res.body[buildOverrideProjectName]);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching build overrides:', err);
      });
  }, []);

  return buildOverride;
}
