/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde mana assets sync -t icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';
import {getIconSize} from '../getIconSize';

import type {SingleColorIconProps} from '../IconTypes';

/**
 * ![ChevronLargeRightIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgUlEQVR4nGNgGAWUAt+AiIN+/hGnPEJDRWlkQfgJv4CI/37+EZdpYom3d5Sgb0D4GYgl4de9QkMlhqYlHqGhon4B4RdpbolvQPilUUuIjxP/iMvQJHyKYUhZ4EHLyPYYNXx4BQsDAwOjn3/EWZoV1/X19Uy+/hHXQJbQrMJhGPEAANNof7Btb2w9AAAAAElFTkSuQmCC)
 */
export const ChevronLargeRightIcon = ({
  size = 'md',
  width,
  height,
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: SingleColorIconProps) => {
  const iconSize = getIconSize(size);
  const iconWidth = iconSize?.width ?? width;
  const iconHeight = iconSize?.height ?? height;
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={typeof color === 'string' ? color : color.css}
        d="M8.3 3.3a1 1 0 0 0 0 1.4l7.29 7.3-7.3 7.3a1 1 0 1 0 1.42 1.4l8-8a1 1 0 0 0 0-1.4l-8-8a1 1 0 0 0-1.42 0Z"
        className={colorClass}
      />
    </svg>
  );
};
