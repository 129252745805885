/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum DesignIds {
  CLASSIC_IA = 0,
  DESIGN_IA = 1,
  DESIGN_TABS_IA = 2,
  YOU_BAR_IA = 3,
}

