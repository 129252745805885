import '@discordapp/common/utils/shims';
import './utils/checkEnv';
import './lib/superagentPatch';
import * as Sentry from '@sentry/react';
import * as TokenManager from '@discordapp/common/lib/TokenManager';
import {UserDefenses} from '@discordapp/common/lib/UserDefenses';
import {initialize} from '@discordapp/flux';

import * as App from './components/App';

import {SENTRY_DSN, urlBasename} from './Constants';
import {i18n} from './i18n';

import './images/favicon.ico';
import './images/og/discord.png';
import './images/og/discord-chat.png';
import './images/og/discord-logo.png';
import './images/og/discord-3g.png';

import '@discordapp/tokens/web.css';
import './styles/global/Global.module.css';
// This has to be separate to avoid name mangling from `.module.css`.
import 'react-focus-rings/src/styles.css';

// These stores must be imported here so they'll be initialized when we invoke `Flux.initialize`
import './stores/APIStore';
import './stores/AnalyticsTrackingStore';
import './stores/ExperimentStore';
import './stores/UserStore';
import './stores/SkuStore';
import './stores/GuildStore';

declare global {
  interface NodeRequire {
    resolveWeak(id: string): string;
  }

  const __DEV__: boolean;
}

TokenManager.init();
// Must load a message to init i18n,
// otherwise UserDefenses is empty.
// There may be an underlying root cause because devportal
// doesn't support translations yet.
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
i18n.Messages.XSSDefenses.SELF_XSS_HEADER;
UserDefenses(i18n, TokenManager);

if (!__DEV__) {
  Sentry.init({
    tunnel: '/error-reporting-proxy/developers',
    dsn: SENTRY_DSN,
    environment: window.GLOBAL_ENV.RELEASE_CHANNEL,
    release: process.env.SENTRY_RELEASE,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        sentry: true,
        xhr: true,
      }),
    ],
  });
}

// The shared Discord Flux package uses `window.requestIdleCallback`, which is not
// available on iOS Safari as of 2023.  (beckwith)
// https://caniuse.com/?search=requestIdleCallback
// It's unlikely this is an issue with discord_app, since we really drive users towards
// the mobile apps.  I want to avoid a shim that could cause potential app performance
// problems, so I'm using the polyfillish documented at
// https://developer.chrome.com/blog/using-requestidlecallback
window.requestIdleCallback =
  window.requestIdleCallback ??
  function (cb) {
    return setTimeout(() => {
      const start = Date.now();
      cb({
        didTimeout: false,
        timeRemaining: () => {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

initialize();

App.render();

type HotModule = typeof module & {
  hot: {
    accept: (path: string, callback: () => void) => void;
  };
};

if (__DEV__) {
  // Redirect to app basename
  if (window.location.pathname === '/') {
    window.location.replace(urlBasename);
  }

  // Hot module replacement
  const hotModule = module as HotModule;
  if (hotModule.hot != null) {
    hotModule.hot.accept('./components/App', () => {
      require('./components/App.tsx').render();
    });
  }
}
