/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde mana assets sync -t icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';
import {getIconSize} from '../getIconSize';

import type {SingleColorIconProps} from '../IconTypes';

/**
 * ![ThemeLightIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVR4nM1VQU7DQAxcClzgQE98Av7QC+XSXdsRSu1IlXrLN3JAlfhUKWrVB1R8oHwBuIOcUpS22WxIVcCSD0lsz3jXGRvzn8yRzAF5djAAIPlQ36tImqanWZa1fgqQZVlLc4PFgeQFiBdRNLjcAUCeOZLp9nuN1RxAXlaCxHF8nAcqU5TnbndwXsnIGIM4bGvsqjte+LrfZPOV4JxchQAsJndrQmVdlydZe1Ys7ogRkJ+A+F3dkUwQE6ffOp3OicWEtBPTxIDkYX25JT5qVHSDOXmL5+6IbWOA1bGEAOSxBlOZfycV/lSH8lYD4DVUJ5/vQsK0KQB46lR1NgkBAPLYNDUdxSBAJD2zjwHJyHs8yPcNmQ/blvjWGHOkzzqKOi16J/m9II+LzHtR/7qOrOxIBUD/JhSvxWtLxbbYxXF8EQJQ5rXFLpdr5KVPrp1no9WW64MvnF9ZmaYKwLPR/tQ+ARZxQAFHjJNPAAAAAElFTkSuQmCC)
 */
export const ThemeLightIcon = ({
  size = 'md',
  width,
  height,
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: SingleColorIconProps) => {
  const iconSize = getIconSize(size);
  const iconWidth = iconSize?.width ?? width;
  const iconHeight = iconSize?.height ?? height;
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={typeof color === 'string' ? color : color.css}
        d="M12 1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1ZM19.78 4.22a1 1 0 0 1 0 1.42l-1.42 1.41a1 1 0 1 1-1.41-1.41l1.41-1.42a1 1 0 0 1 1.42 0ZM18.36 19.78a1 1 0 0 0 1.42-1.42l-1.42-1.41a1 1 0 0 0-1.41 1.41l1.41 1.42ZM23 12a1 1 0 0 1-1 1h-2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1ZM13 20a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2ZM7.05 16.95a1 1 0 0 1 0 1.41l-1.41 1.42a1 1 0 0 1-1.42-1.42l1.42-1.41a1 1 0 0 1 1.41 0ZM5.64 7.05a1 1 0 0 0 1.41-1.41L5.64 4.22a1 1 0 1 0-1.42 1.42l1.42 1.41ZM5 12a1 1 0 0 1-1 1H2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1ZM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        className={colorClass}
      />
    </svg>
  );
};
