import * as React from 'react';
import classNames from 'classnames';
import {getAcronym} from '@discordapp/common/utils/StringUtils';
import {Text} from '@discordapp/design/components/Text/Text.web';

import TrackedLink, {TrackedLinkProps} from './TrackedLink';

import {CardGridTypes} from '@developers/Constants';
import styles from './Card.module.css';

interface Props {
  cardClassName?: string;
  href: string;
  imageUri?: string | null;
  label: string;
  trackedLinkProps?: TrackedLinkProps;
  wrapperClassName?: string;
  type: CardGridTypes;
}

const Card = ({cardClassName, href, imageUri, label, trackedLinkProps, wrapperClassName, type}: Props) => {
  const renderTileImagePlaceholder = () => (
    <Text
      className={styles.cardImagePlaceholder}
      variant={type === CardGridTypes.SMALL_CARD ? 'text-lg/normal' : 'heading-xl/normal'}
      tag="span">
      {getAcronym(label)}
    </Text>
  );

  const renderTileImage = () => {
    const placeholderNode = imageUri != null ? null : renderTileImagePlaceholder();
    return (
      <div className={styles.cardImage} style={imageUri != null ? {backgroundImage: `url(${imageUri})`} : undefined}>
        {placeholderNode}
      </div>
    );
  };

  return (
    <div className={styles.cardWrapper}>
      <TrackedLink className={classNames(wrapperClassName, styles.cardLink)} to={href} {...trackedLinkProps}>
        <div className={classNames(cardClassName, styles.card)}>
          {renderTileImage()}
          <abbr className={classNames(styles.cardLabelAbbr)} title={label}>
            <Text variant="text-md/normal" className={styles.cardLabel}>
              {label}
            </Text>
          </abbr>
        </div>
      </TrackedLink>
    </div>
  );
};

export default Card;
