/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum UserIdentityVerificationErrorTypes {
  CONSENT_DECLINED = 1,
  UNVERIFIED = 2,
  DEVICE_UNSUPPORTED = 3,
  VERIFICATION_DOCUMENT_EXPIRED = 4,
  VERIFICATION_DOCUMENT_INVALID = 5,
  VERIFICATION_UNEXPECTED_DOCUMENT_COUNTRY = 6,
  VERIFICATION_UNEXPECTED_DOCUMENT_TYPE = 7,
  VERIFICATION_SCAN_NOT_READABLE = 8,
  VERIFICATION_SCAN_MISSING_BACK = 9,
  VERIFICATION_SCAN_ID_TYPE_NOT_SUPPORTED = 10,
  VERIFICATION_SCAN_CORRUPT = 11,
  VERIFICATION_SCAN_FAILED_COPY = 12,
  VERIFICATION_SCAN_MANIPULATED_DOCUMENT = 13,
  VERIFICATION_SCAN_FAILED_GRAYSCALE = 14,
  VERIFICATION_UNDER_SUPPORTED_AGE = 15,
}

export const UserIdentityVerificationErrorTypesSets = {
  ALL: new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]),
};
