import {useEffect, useState} from 'react';

import {useStateFromStores, useStateFromStoresArray} from './useStateFromStores';

import type {Store} from './Store';

export interface FetchStoreOptions<T, A extends unknown[]> {
  /**
   * Get data from the Flux store
   */
  get: (...args: A) => T;
  /**
   * Load data from the API. The provided AbortSignal should be used to abort the request.
   */
  load: (signal: AbortSignal, ...args: A) => Promise<void>;
  /**
   * Get loading state from the Flux store
   */
  getIsLoading: (...args: A) => boolean;

  /**
   * useStateFromStores hook to use for fetching data from Flux
   */
  useStateHook: T extends any[] ? typeof useStateFromStoresArray<T> : typeof useStateFromStores<T>;

  /**
   * Whether to abort the request on effect cleanup.
   *
   * **This is dangerous.** If the abort triggers a remount of the component, you could create an infinite render loop.
   * Only use this in components where the DOM tree is stable on loading state.
   */
  dangerousAbortOnCleanup?: boolean;
}

export interface FetchStoreData<T> {
  /**
   * The data available in Flux
   */
  data?: T;
  /**
   * The error that occurred while fetching data
   */
  error?: Error;
  /**
   * Whether data is being loaded
   */
  isLoading: boolean;
}

export type FetchStoreHook<T, A extends unknown[]> = (...args: A) => FetchStoreData<T>;

/**
 * Create a hook that uses data from Flux if available, otherwise loads it. The Flux
 * store is responsible for caching the data itself and the loading state. Errors
 * are tracked in local state.
 *
 * Types should be explicitly provided to this function.
 * - T represents the data stored in Flux
 * - A _must_ be a tuple: it represents the type and number of arguments provided to
 *   options and as hook dependencies. It is illegal to provide a variable number of
 *   dependencies to hooks, and leaving A inferred as unknown[] is very unsafe.
 *
 * @returns A React hook that accepts A-arguments and returns information about T-data
 */
export default function createFetchStore<T, A extends unknown[]>(
  store: Store<any>,
  {get, getIsLoading, load, useStateHook, dangerousAbortOnCleanup = false}: FetchStoreOptions<T, A>,
): FetchStoreHook<T, A> {
  return (...args: A) => {
    const data = useStateHook([store], () => get(...args), args);
    // eslint-disable-next-line @discordapp/discord/connect-stores-deps, @discordapp/discord/use-state-from-stores-deps
    const isLoading = useStateFromStores([store], () => getIsLoading(...args), args);

    // TODO: track errors in a common Flux store, along with loading state
    // An attempt was made, but the typing is extremely complicated
    const [error, setError] = useState();

    useEffect(() => {
      if (getIsLoading(...args) || get(...args) != null) return;

      const controller = new AbortController();
      load(controller.signal, ...args).catch((e) => {
        if (!controller.signal.aborted) setError(e);
      });

      return () => {
        if (dangerousAbortOnCleanup) controller.abort();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args);

    return {data, error, isLoading};
  };
}
