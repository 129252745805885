import reduce from 'lodash/reduce.js';
import murmurhash from 'murmurhash';
import {Logger} from '@discordapp/common/Logger';
import {Store} from '@discordapp/flux/Store';
import {Storage} from '@discordapp/storage';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import {fetchExperiments} from '@developers/actions/ExperimentActions';
import {GUILD_FILTERS} from '@developers/lib/GuildFilters';
import {track} from '@developers/utils/TrackingAnalyticsUtils';

import {ExperimentBuckets, ExperimentTypes, ExperimentContextTypes} from '@developers/Constants';
import type {ActionFor, Action} from '@developers/flow/Action';
import type {
  ExperimentBucket,
  ExperimentDescriptor,
  GuildExperiment,
  GuildExperimentDescriptor,
  UserExperimentChannelContext,
  UserExperimentContext,
  UserExperimentDescriptor,
} from '@developers/flow/Client';

const EXPERIMENT_OVERRIDES_KEY = 'experimentOverrides';
const TRIGGERED_EXPERIMENTS_KEY = 'scientist:triggered';
const EXPERIMENTS_VERSION = 1;
const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

const logger = new Logger('ExperimentStore');

interface Experiment {
  time: number;
  hash: number;
}

interface EventProperties {
  name: string;
  revision: number;
  bucket: ExperimentBucket;
  population?: number;
  guild_id?: string;
  context_type?: 'guild' | 'channel';
  context_guild_id?: string | null;
  context_channel_id?: string;
}

let eligibleExperiments: Record<string, UserExperimentDescriptor> = {};
let experimentOverrides: Record<string, UserExperimentDescriptor> = {};
let triggeredExperiments: Record<string, Experiment> = {};
const experimentNames: Record<string, string> = {};
let fetchedExperiments = false;
let fetchTimeout: NodeJS.Timeout;
let loadedGuildExperiments: Record<string, GuildExperiment> = {};
let cachedGuildExperimentDescriptors: Record<string, GuildExperimentDescriptor | null | undefined> = {};

function _loadOverrides(
  overrides?: Array<{
    b: number;
    k: string[];
  }> | null,
): {
  [guildId: string]: ExperimentBucket;
} {
  const indexedOverrides: Record<string, ExperimentBucket> = {};
  if (overrides == null) {
    return indexedOverrides;
  }
  for (const {b, k: keys} of overrides) {
    for (const k of keys) {
      indexedOverrides[k] = b;
    }
  }
  return indexedOverrides;
}

function _loadGuildFilter([filterNameHash, filterData]: [
  filterNameHash: number,
  // TODO(JONZ) The value is whatever the server sends back but should be JSON serializeable.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterData: Array<[hash_key: number, value: any]>,
]) {
  if (GUILD_FILTERS[filterNameHash] != null) {
    return GUILD_FILTERS[filterNameHash](filterData);
  }
  return null;
}

const hashName = (name: string) => String(murmurhash.v3(name));

function getEligibleUserExperiment(name: string) {
  const nameHash = hashName(name);
  const experiment = eligibleExperiments[nameHash] ?? experimentOverrides[nameHash] ?? null;

  if (experiment != null && experiment.name == null) {
    experiment.name = name;
  }

  return experiment;
}

const RING_SIZE = 10000;
function computeGuildExperimentDescriptor(guildId: string, experimentId: string) {
  const experimentIdHash = murmurhash.v3(experimentId);
  const experiment = loadedGuildExperiments[experimentIdHash.toString()];
  if (experiment == null) {
    return null;
  }

  const overrideBucket = experiment.overrides[guildId];
  if (overrideBucket != null) {
    if (overrideBucket === ExperimentBuckets.NOT_ELIGIBLE) {
      return null;
    }
    return {
      type: ExperimentTypes.GUILD,
      guildId,
      revision: experiment.revision,
      bucket: overrideBucket,
      override: true,
    };
  }

  const hashKey = `${experiment.hashKey ?? experimentId}:${guildId}`;
  const hash = murmurhash.v3(hashKey);
  const ringPosition = hash % RING_SIZE;

  // We want to find the first matching population we are eligible for
  // (first population which we pass all the filters for)
  // and then get the bucket from it.
  let bucket = null;
  for (const {buckets, filters} of experiment.populations) {
    var eligible = true;
    if (filters != null) {
      for (const filter of filters) {
        if (filter != null && !filter(guildId)) {
          eligible = false;
          break;
        }
      }
    }

    if (!eligible) continue;

    const foundBucket = buckets.find(({positions}) =>
      positions.some(({start, end}) => ringPosition >= start && ringPosition < end),
    );

    // The CONTROL range is not specified instead of the expected NOT_ELIGIBLE
    bucket = foundBucket != null ? foundBucket.bucket : ExperimentBuckets.CONTROL;
    if (bucket === ExperimentBuckets.NOT_ELIGIBLE) {
      return null;
    }
    break;
  }

  if (bucket == null) return null;

  return {
    type: ExperimentTypes.GUILD,
    guildId,
    revision: experiment.revision,
    bucket,
  };
}

function handleLoadedExperiments(options: ActionFor<'EXPERIMENTS_FETCH_SUCCESS'>) {
  const {experiments, guildExperiments} = options;
  clearTimeout(fetchTimeout);
  fetchedExperiments = true;
  eligibleExperiments = {...experimentOverrides};
  experiments.forEach((experiment) => {
    const [nameHash, revision, bucket, _serverOverride, population] = experiment;
    if (eligibleExperiments[nameHash.toString()] == null) {
      eligibleExperiments[nameHash.toString()] = {
        revision,
        population,
        bucket,
        type: ExperimentTypes.USER,
        context: null,
        name: experimentNames[nameHash] != null ? experimentNames[nameHash] : '',
      };
    }
  });

  loadedGuildExperiments = {};
  cachedGuildExperimentDescriptors = {};
  if (guildExperiments != null) {
    guildExperiments.forEach(([experimentIdHash, hashKey, revision, populations, overrides]) => {
      loadedGuildExperiments[experimentIdHash] = {
        hashKey,
        revision,
        populations: populations.map(([buckets, rawFilterData]) => {
          return {
            buckets: buckets.map(([bucket, positions]) => ({
              bucket,
              positions: positions.map(({s, e}) => ({
                start: s,
                end: e,
              })),
            })),
            filters: rawFilterData.map(_loadGuildFilter),
            rawFilterData,
          };
        }),
        overrides: _loadOverrides(overrides),
      };
    });
  }
}

function stringifyContext(context?: UserExperimentContext) {
  if (context == null) {
    return '';
  }
  switch (context.type) {
    case ExperimentContextTypes.GUILD:
      return `|guild:${context.guildId}`;
    case ExperimentContextTypes.CHANNEL:
      return `|channel:${(context as UserExperimentChannelContext).channelId}:${context.guildId ?? 0}`;
    default:
      return '';
  }
}

// Technically there will never be any guild experiments that run on marketing,
// however it should share the same triggered data from app, therefore this
// function is a copy-pasta of the app version
function handleExperimentTrigger({experimentDescriptor: e}: {experimentDescriptor: ExperimentDescriptor}) {
  // Never trigger analytics on developer experiments
  if (e.type === ExperimentTypes.DEVELOPER) {
    return false;
  }
  const name =
    (e as UserExperimentDescriptor).name == null || typeof (e as UserExperimentDescriptor).name !== 'string'
      ? ''
      : (e as UserExperimentDescriptor).name;
  let triggerKey = `${e.type}|${name}`;
  let stringified = `${e.bucket}|${e.revision}`;
  if (e.type === ExperimentTypes.GUILD) {
    stringified += `|${e.guildId}`;
    triggerKey += `|${e.guildId}`;
  } else if (e.type === ExperimentTypes.USER) {
    const context = stringifyContext((e as UserExperimentDescriptor).context!);
    stringified += context;
    triggerKey += context;
  }

  const experimentHash = murmurhash.v3(stringified);
  const now = Date.now();
  const existingTrigger = triggeredExperiments[triggerKey];
  if (existingTrigger != null && now - existingTrigger.time <= ONE_WEEK && existingTrigger.hash === experimentHash) {
    return false;
  }
  const eventName = e.type === ExperimentTypes.USER ? 'experiment_user_triggered' : 'experiment_guild_triggered';
  const nameHash = hashName(name);
  const eventProperties: EventProperties = {
    name: experimentNames[nameHash] != null ? experimentNames[nameHash] : name,
    revision: e.revision,
    bucket: e.bucket,
  };
  if (e.type === ExperimentTypes.USER) {
    eventProperties.population = e.population;
  }

  if (e.type === ExperimentTypes.GUILD) {
    eventProperties['guild_id'] = e.guildId;
  } else if (e.type === ExperimentTypes.USER && e.context != null) {
    const {context} = e;
    if (context != null) {
      eventProperties['context_type'] = context.type;
      switch (context.type) {
        case ExperimentContextTypes.GUILD:
          eventProperties['context_guild_id'] = context.guildId;
          break;
        case ExperimentContextTypes.CHANNEL:
          eventProperties['context_guild_id'] = context.guildId;
          eventProperties['context_channel_id'] = (context as UserExperimentChannelContext).channelId;
          break;
      }
    }
  }

  track(eventName, eventProperties);
  triggeredExperiments[triggerKey] = {time: now, hash: experimentHash};
  saveTriggeredExperiments();
  return true;
}

function handleExperimentRegister({name}: ActionFor<'EXPERIMENT_REGISTER'>): void {
  const nameHash = hashName(name);
  if (experimentNames[nameHash] != null) return;
  experimentNames[nameHash] = name;
  // Fix name if it exists - this has to be overly specific because Flow
  const experiment = eligibleExperiments[nameHash];
  if (experiment != null && experiment.type === ExperimentTypes.USER) {
    const e: UserExperimentDescriptor = {...experiment};
    e.name = name;
    eligibleExperiments[nameHash] = e;
  }
}

function scrubExperiments() {
  const now = Date.now();
  let needsResave = false;

  triggeredExperiments = reduce(
    triggeredExperiments,
    (acc, trigger, triggerKey: string) => {
      if (now - trigger.time <= ONE_WEEK) {
        acc[triggerKey] = trigger;
      } else {
        needsResave = true;
      }
      return acc;
    },
    {} as Record<string, Experiment>,
  );

  if (needsResave) {
    saveTriggeredExperiments();
  }
}

interface TriggeredExperiment {
  v: number;
  e: number;
}

function loadTriggeredExperiments() {
  const triggeredExperiments = Storage.get<TriggeredExperiment>(TRIGGERED_EXPERIMENTS_KEY);
  if (triggeredExperiments == null || triggeredExperiments.v !== EXPERIMENTS_VERSION) {
    return {};
  }
  return triggeredExperiments.e;
}

function saveTriggeredExperiments() {
  try {
    Storage.set(TRIGGERED_EXPERIMENTS_KEY, {
      v: EXPERIMENTS_VERSION,
      e: triggeredExperiments,
    });
  } catch (e) {
    logger.error('Error saving tracked exposure experiments, unsaved data will be lost', e);
    track('experiment_save_exposure_failed', {
      module: 'discord_developers',
      call: 'ExperimentStore.saveTriggeredExperiments',
    });
  }
}

class ExperimentStore extends Store<Action> {
  static displayName = 'ExperimentStore';
  initialize() {
    fetchExperiments({withGuildExperiments: true}).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
    experimentOverrides = Storage.get(EXPERIMENT_OVERRIDES_KEY) ?? {};
    triggeredExperiments = loadTriggeredExperiments();
    scrubExperiments();
    // If for some reason, the experiment call fails or takes a long time, we
    // should set fetchExperiments to true so experiment containers can at
    // least render something
    fetchTimeout = setTimeout(() => {
      fetchedExperiments = true;
      this.emitChange();
    }, 1000);
  }

  getGuildExperimentDescriptor(experimentId: string, guildId: string) {
    const cacheKey = `${guildId}:${experimentId}`;
    if (cacheKey in cachedGuildExperimentDescriptors) {
      return cachedGuildExperimentDescriptors[cacheKey];
    }
    const experimentDescriptor = computeGuildExperimentDescriptor(guildId, experimentId);
    cachedGuildExperimentDescriptors[cacheKey] = experimentDescriptor;
    return experimentDescriptor;
  }

  getEligibleExperiment(name: string): UserExperimentDescriptor | null | undefined {
    return getEligibleUserExperiment(name);
  }

  get eligibleExperiments() {
    return eligibleExperiments;
  }

  get fetchedExperiments(): boolean {
    return fetchedExperiments;
  }
}

export default new ExperimentStore(Dispatcher, {
  [ActionTypes.EXPERIMENTS_FETCH_SUCCESS]: handleLoadedExperiments,
  [ActionTypes.EXPERIMENT_TRIGGER]: handleExperimentTrigger,
  [ActionTypes.EXPERIMENT_REGISTER]: handleExperimentRegister,
});
