export class ChangeListeners {
  private listeners: Set<() => void> = new Set();

  /** Adds a listeners that will be invoked every time the store emits a change event. */
  add = (callback: () => void) => {
    this.listeners.add(callback);
  };

  /** Removes an existing listeners (if any). */
  remove = (callback: () => void) => {
    this.listeners.delete(callback);
  };

  /** Adds a listeners that will be called every time the store changes _until_ the callback returns false. */
  addConditional = (callback: () => boolean | undefined, preemptive: boolean = true) => {
    if (preemptive && callback() === false) {
      return;
    }

    const conditionalCallback = () => {
      if (callback() === false) {
        this.remove(conditionalCallback);
      }
    };
    this.add(conditionalCallback);
  };

  /** Returns true if the given callback has been added as a change listener. */
  has(callback: () => void) {
    return this.listeners.has(callback);
  }

  /** Returns true if there are any change listeners on this store. */
  hasAny() {
    return this.listeners.size > 0;
  }

  /** Invokes every change listener. Order of execution is not guaranteed and return values are ignored. */
  invokeAll() {
    this.listeners.forEach((callback) => callback());
  }
}
