import * as React from 'react';
import {useStateFromStores} from '@discordapp/flux';
import {Storage} from '@discordapp/storage';

import UserStore from '@developers/stores/UserStore';
import {prependBasename} from '@developers/utils/RouterUtils';
import {track} from '@developers/utils/TrackingAnalyticsUtils';
import SidebarCta, {
  SidebarCtaActionTypes,
  SidebarCtaActions,
  SidebarCtaAction,
  SidebarCtaContent,
  SidebarCtaHeading,
} from './SidebarCta';

import {AnalyticsEvents, Routes, UPDATED_TERMS_OF_SERVICE_VISIBILITY_KEY} from '@developers/Constants';
import {Messages} from '@developers/i18n';

const CTA_ANALYTICS_NAME = 'updated_terms_of_service_june_2024_cta';

export function shouldShowUpdatedTermsOfServiceCta() {
  return Storage.get(UPDATED_TERMS_OF_SERVICE_VISIBILITY_KEY) !== false;
}

export default function UpdatedTermsOfService() {
  const [shouldDisplayPrompt, setShouldDisplayPrompt] = React.useState(shouldShowUpdatedTermsOfServiceCta());

  const user = useStateFromStores([UserStore], () => UserStore.user);
  React.useEffect(() => {
    if (shouldDisplayPrompt && user != null) {
      track(AnalyticsEvents.CTA_VIEWED, {
        user_id: user.id,
        cta_name: CTA_ANALYTICS_NAME,
      });
    }
  }, [shouldDisplayPrompt, user]);

  if (!shouldDisplayPrompt) {
    return null;
  }

  const handleCtaClick = () => {
    if (user != null) {
      track(AnalyticsEvents.CTA_CLICKED, {
        user_id: user.id,
        cta_name: CTA_ANALYTICS_NAME,
      });
    }

    window.open(prependBasename(Routes.DOCS_POLICIES_AND_AGREEMENTS_DEVELOPER_TERMS_OF_SERVICE), '_blank');
    dismissPrompt();
  };

  const dismissPrompt = () => {
    Storage.set(UPDATED_TERMS_OF_SERVICE_VISIBILITY_KEY, false);
    setShouldDisplayPrompt(false);
  };

  return (
    <SidebarCta>
      <SidebarCtaHeading>{Messages.UpdatedTermsOfServiceJune2024.HEADING}</SidebarCtaHeading>
      <SidebarCtaContent>{Messages.UpdatedTermsOfServiceJune2024.BODY}</SidebarCtaContent>
      <SidebarCtaActions>
        <SidebarCtaAction onClick={handleCtaClick} type={SidebarCtaActionTypes.PRIMARY}>
          {Messages.UpdatedTermsOfServiceJune2024.CTA}
        </SidebarCtaAction>
        <SidebarCtaAction onClick={dismissPrompt}>{Messages.UpdatedTermsOfServiceJune2024.DISMISS}</SidebarCtaAction>
      </SidebarCtaActions>
    </SidebarCta>
  );
}
