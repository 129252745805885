import * as React from 'react';
import classNames from 'classnames';
import {FocusRing} from 'react-focus-rings';
import {hex2rgb} from '@discordapp/common/utils/ColorUtils';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';
import {CheckmarkSmallIcon} from '@discordapp/design/components/Icon/generated/CheckmarkSmallIcon.web';

import {NOOP, Colors} from '@developers/Constants';
import styles from './Checkbox.module.css';

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  value?: boolean;
  size?: number;
  type?: string;
  onChange?: (checked: boolean, name?: string) => void;
  color?: string;
  shape?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  name?: string;
}

const Types = {
  DEFAULT: 'default',
  INVERTED: 'inverted',
  GHOST: 'ghost',
};

const Shapes = {
  BOX: styles.box,
  ROUND: styles.round,
};

export default function Checkbox({
  size = 24,
  disabled = false,
  readOnly = false,
  value = false,
  type = Types.DEFAULT,
  color = Colors.WHITE,
  shape = Shapes.BOX,
  onChange = NOOP,
  name,
  children,
  className,
  ...props
}: Props) {
  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
    if (onChange != null) onChange(event.currentTarget.checked, name);
  };

  function getInputMode(): string {
    if (disabled) {
      return 'disabled';
    } else if (readOnly) {
      return 'readonly';
    }
    return 'default';
  }

  function getStyle(): React.CSSProperties {
    let style = props.style ?? {};
    if (value === false) {
      return style;
    }
    style = {...style};
    switch (type) {
      case Types.DEFAULT:
        style.borderColor = color;
        break;
      case Types.GHOST:
        style.borderColor = hex2rgb(color, 0.15) ?? undefined;
        style.backgroundColor = hex2rgb(color, 0.15) ?? undefined;
        break;
      case Types.INVERTED:
        style.backgroundColor = color;
        style.borderColor = color;
        break;
    }
    return style;
  }

  function getColor(): string {
    let adjustedColor = type === Types.INVERTED ? Colors.WHITE : color;
    if (type === Types.INVERTED && color === Colors.WHITE) {
      adjustedColor = Colors.BLACK;
    }
    return value ? adjustedColor : 'transparent';
  }

  return (
    <label
      className={classNames(getClass(styles, 'checkboxWrapper', disabled ? 'disabled' : ''), className)}
      style={{height: size}}>
      <FocusRing>
        <input
          className={getClass(styles, 'input', getInputMode())}
          type="checkbox"
          onChange={!disabled && !readOnly ? handleChange : NOOP}
          checked={value}
          style={{width: size, height: size}}
        />
      </FocusRing>
      <div
        className={classNames(styles.checkbox, shape, {
          [styles.checked]: value,
        })}
        style={{width: size, height: size, ...getStyle()}}>
        <CheckmarkSmallIcon size="sm" color={getColor()} />
      </div>
      {children != null ? (
        <div className={styles.label} style={{lineHeight: `${size}px`}}>
          {children}
        </div>
      ) : null}
    </label>
  );
}

Checkbox.Types = Types;
Checkbox.Shapes = Shapes;
