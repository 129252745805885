import * as React from 'react';
import classNames from 'classnames';

import {UnsavedChangesNoticePopupMountPoint} from './unsaved_changes/UnsavedChangesNoticePopup';

import styles from './PageContent.module.css';

interface PageContentProps {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
}

export default function PageContent({children, className, innerClassName}: PageContentProps) {
  return (
    <div className={classNames(styles.contentWrapper, className)}>
      <div className={classNames('page-content-scrolling-container', styles.scroller)}>
        <div className={classNames(styles.contentWrapperInner, innerClassName)}>
          {children}
          <UnsavedChangesNoticePopupMountPoint />
        </div>
      </div>
    </div>
  );
}
