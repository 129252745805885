import deepEqual from 'deep-equal';
import invariant from 'invariant';
import platform from 'platform';
import {getSystemLocale} from '@discordapp/i18n';
import {Storage} from '@discordapp/storage';
import {SessionStorage} from '@discordapp/storage/SessionStorage';

import {queueTrackingEventMaker} from './AnalyticsTrackingActionCreators';
import {encodeProperties} from './encodeProperties';

export {encodeProperties};

import {DesignIds} from '@discordapp/common/shared-constants/DesignIds';
import type {Dispatcher, ActionBase} from '@discordapp/flux';
export {analyticsTrackingStoreMaker, AnalyticsActionHandlers} from './AnalyticsTrackingStore';
export {
  Impression,
  ImpressionTypes,
  TypedEventProperties,
  StandardAnalyticsLocation,
  ImpressionGroups,
} from './StandardAnalyticsConstants';
export {ImpressionNames, NetworkActionNames, StandardAnalyticsSchemaNameMap, ImpressionSchema} from './AnalyticsSchema';

// Configuration for throttling on a simple percentage of an event type. Useful when you care about approximating the
// full volume of events, but the event's volume would be prohibitively large. Events that are throttled are simply
// discarded.
export interface AnalyticEventThrottlePercentConfig {
  // 1.0 means "no throttling", 0.5 means "50% of the events are discarded"
  throttlePercent: number;
}

// Configuration for throttling an event type in a time window. Only one event which matches the composite key defined
// by throttleKeys will be sent during the throttlePeriod window. Deduplication occurs after the base throttle logic,
// and deduplication is also keyed off of the composite key defined by throttleKeys.
export interface AnalyticEventThrottlePeriodConfig extends Partial<AnalyticEventThrottlePercentConfig> {
  throttlePeriod: number;
  throttleKeys: (properties: Record<string, any>) => string[];
  deduplicate?: boolean;
}

export type AnalyticEventConfigType = AnalyticEventThrottlePeriodConfig | AnalyticEventThrottlePercentConfig;

export interface TrackFunctionOptions {
  /** Flushes the event cache, sending all pending events to the server. */
  flush?: boolean;
  /** Overrides the fingerprint associated with this event. */
  fingerprint?: string;
}

export interface TrackFunction<TAnalyticsSchemaNameMapType> {
  <EventName extends keyof TAnalyticsSchemaNameMapType>(
    event: EventName,
    properties?: TAnalyticsSchemaNameMapType[EventName],
    options?: TrackFunctionOptions,
  ): Promise<void>;
}

interface ReferralPropertiesType {
  referrer?: string;
  referring_domain?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  search_engine?: string;
  mp_keyword?: string;
}

interface CurrentReferralPropertiesType {
  referrer_current?: string;
  referring_domain_current?: string;
  utm_source_current?: string;
  utm_medium_current?: string;
  utm_campaign_current?: string;
  utm_content_current?: string;
  utm_term_current?: string;
  search_engine_current?: string;
  mp_keyword_current?: string;
}

interface DevicePropertiesType {
  os?: string;
  browser?: string;
  browser_version?: string;
  release_channel?: string;
  client_version?: string;
  client_build_number?: number;
  client_event_source?: string | null;
  os_version?: string;
  os_sdk_version?: string;
  os_arch?: string;
  window_manager?: string;
  distro?: string;
  browser_user_agent?: string;
  system_locale?: string;
  device?: string;
  device_advertiser_id?: string;
  device_vendor_id?: string;
  native_build_number?: number;
  design_id?: DesignIds;
  app_arch?: string;
}

type SuperPropertiesType = ReferralPropertiesType & DevicePropertiesType & CurrentReferralPropertiesType;

const DEVICE_PROPERTIES_KEY = 'deviceProperties';
const REFERRAL_PROPERTIES_KEY = 'referralProperties';

let superProperties: SuperPropertiesType | null | undefined;

const throttledEvents: Record<string, number> = {};
const deduplicatedEvents: Record<string, any> = {};

// Repeat some NativeUtils stuff rather than importing NativeUtils because this
// file is shared with the marketing page.
const DiscordNative = window.DiscordNative;

if (DiscordNative != null) {
  const appVersion = DiscordNative.remoteApp.getVersion();
  const nativeProcessPlatform = DiscordNative.process.platform;
  const release = DiscordNative.os.release;
  const arch = DiscordNative.os.arch;
  const appArch = DiscordNative.os.appArch;
  const releaseChannel = DiscordNative.remoteApp.getReleaseChannel();
  const systemLocale = getSystemLocale();

  let osName;
  switch (nativeProcessPlatform) {
    case 'win32':
      osName = 'Windows';
      break;
    case 'darwin':
      osName = 'Mac OS X';
      break;
    case 'linux':
      osName = 'Linux';
      break;
    default:
      osName = nativeProcessPlatform;
      break;
  }

  superProperties = {
    os: osName,
    browser: 'Discord Client',
    release_channel: releaseChannel || 'unknown',
    client_version: appVersion,
    os_version: release,
    os_arch: arch,
    app_arch: appArch,
    system_locale: systemLocale,
  };

  if (platform.name?.toLocaleLowerCase() === 'electron') {
    superProperties['browser_user_agent'] = platform.ua || '';
    superProperties['browser_version'] = platform.version || '';
  }

  if (nativeProcessPlatform === 'linux') {
    const metadata = DiscordNative.crashReporter.getMetadata();
    superProperties['window_manager'] = metadata['wm'];
    superProperties['distro'] = metadata['distro'];
  } else if (nativeProcessPlatform === 'darwin') {
    // Darwin major version (bumps with macOS major versions)
    superProperties['os_sdk_version'] = release?.split('.')[0];
  } else if (nativeProcessPlatform === 'win32') {
    // Windows build (monotonically increases with major versions, then minor versions)
    superProperties['os_sdk_version'] = release?.split('.')[2];
  }
}

const CAMPAIGN_KEYWORDS = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');

function getQueryParam(url: string | undefined, param: string) {
  if (url == null) return '';

  param = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
  const results = regex.exec(url);
  // @ts-expect-error ignoring from old code
  if (results === null || (typeof results[1] !== 'string' && results[1].length)) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\+/g, ' ');
  }
}

export function getCampaignParams(url?: string): Record<string, string> {
  const properties: Record<string, string> = {};
  CAMPAIGN_KEYWORDS.forEach((key) => {
    const value = getQueryParam(url, key);
    if (value.length > 0) {
      properties[key] = value;
    }
  });
  return properties;
}

function getSearchEngine() {
  const referrer = document.referrer;
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  } else if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  } else if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  } else if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  } else {
    return null;
  }
}

function getSearchInfo() {
  const properties: Record<string, string> = {};
  const referrer = document.referrer;
  const search = getSearchEngine();
  const param = search !== 'yahoo' ? 'q' : 'p';
  if (search != null) {
    properties['search_engine'] = search;
    const keyword = getQueryParam(referrer, param);
    if (keyword.length > 0) {
      properties['mp_keyword'] = keyword;
    }
  }
  return properties;
}

function getBrowser(): string {
  const {userAgent, vendor = ''} = window.navigator;
  const {opera} = window;
  if (__MOBILE__) {
    if (require('react-native').Platform.OS === 'android') {
      return 'Discord Android';
    }
    return 'Discord iOS';
  } else if (opera) {
    return /Mini/.test(userAgent) ? 'Opera Mini' : 'Opera';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (/FBIOS/.test(userAgent)) {
    return 'Facebook Mobile';
  } else if (/CriOS/.test(userAgent)) {
    return 'Chrome iOS';
  } else if (/Apple/.test(vendor)) {
    // When an iPad user's Safari requests the desktop version of the page, it omits all the normal iPad identifying
    // information from the userAgent, so we check to see if it has maxTouchPionts > 2 to infer that this is in fact mobile Safari.
    return /Mobile/.test(userAgent) || (window.navigator.maxTouchPoints != null && window.navigator.maxTouchPoints > 2)
      ? 'Mobile Safari'
      : 'Safari';
  } else if (/Android/.test(userAgent)) {
    return /Chrome/.test(userAgent) ? 'Android Chrome' : 'Android Mobile';
  } else if (/Edge/.test(userAgent)) {
    return 'Edge';
  } else if (/Chrome/.test(userAgent)) {
    return 'Chrome';
  } else if (/Konqueror/.test(userAgent)) {
    return 'Konqueror';
  } else if (/Firefox/.test(userAgent)) {
    return 'Firefox';
  } else if (/MSIE|Trident\//.test(userAgent)) {
    return 'Internet Explorer';
  } else if (/Gecko/.test(userAgent)) {
    return 'Mozilla';
  } else {
    return '';
  }
}

export function getOS(): string {
  const {userAgent} = window.navigator;
  if (__MOBILE__) {
    if (require('react-native').Platform.OS === 'android') {
      return 'Android';
    }

    return 'iOS';
  } else if (/Windows/i.test(userAgent)) {
    return /Phone/.test(userAgent) ? 'Windows Mobile' : 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return 'iOS';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(userAgent)) {
    // iPad user agent is "Mac" by default on Safari so we need additional logic to detect it
    return window.navigator.maxTouchPoints != null && window.navigator.maxTouchPoints > 2 ? 'iOS' : 'Mac OS X';
  } else if (/Linux/i.test(userAgent)) {
    return 'Linux';
  } else {
    return '';
  }
}

export function getDevice(): string {
  const {userAgent} = window.navigator;
  if (__MOBILE__) {
    const rn = require('react-native');
    if (rn.Platform.OS === 'android') {
      // eslint-disable-next-line import/no-unresolved -- native apps must specify dep in package.json
      return require('@discordapp/rtn-codegen/js/NativeDeviceManager').default.getConstants().device;
    }
    return rn.NativeModules.DCDDeviceManager.device;
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (/Windows Phone/i.test(userAgent)) {
    return 'Windows Phone';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/iPhone/.test(userAgent)) {
    return 'iPhone';
  } else if (/iPad/.test(userAgent)) {
    return 'iPad';
  } else {
    return '';
  }
}

function getReferringDomain(): string {
  const split = document.referrer.split('/');
  return split.length >= 3 ? split[2] : '';
}

function getDeviceProperties(): DevicePropertiesType {
  const properties: Partial<DevicePropertiesType> = {};
  const OS = getOS();
  properties['os'] = OS;
  properties['browser'] = getBrowser();
  properties['device'] = getDevice();
  properties['system_locale'] = getSystemLocale();
  if (__MOBILE__) {
    try {
      const rn = require('react-native');
      const info =
        rn.Platform.OS === 'android'
          ? // turbo module
            // eslint-disable-next-line import/no-unresolved -- native apps must specify dep in package.json
            require('@discordapp/rtn-codegen/js/NativeClientInfoManager').default.getConstants()
          : // TODO: migrate iOS to turbo modules
            rn.NativeModules.InfoDictionaryManager.getConstants();
      const {Version, ReleaseChannel, DeviceVendorID} = info;
      properties['client_version'] = Version + (OS === 'Android' ? ' - rn' : '');
      properties['release_channel'] = ReleaseChannel;
      properties['device_vendor_id'] = DeviceVendorID;
      properties['design_id'] = DesignIds.DESIGN_TABS_IA;
    } catch (e) {}
  }
  return properties;
}

function getDeviceVersionProperties() {
  const properties = {
    browser_user_agent: window.navigator.userAgent || '',
    browser_version: platform.version || '',
    /* eslint-enable */
  };
  if (__MOBILE__) {
    const rn = require('react-native');
    const osVersion =
      rn.Platform.OS === 'android'
        ? // eslint-disable-next-line import/no-unresolved -- native apps must specify dep in package.json
          require('@discordapp/rtn-codegen/js/NativeDeviceManager').default.getConstants().systemVersion
        : rn.NativeModules.DCDDeviceManager.systemVersion;
    return {
      ...properties,
      os_version: osVersion || '',
      /* eslint-enable */
    };
  }
  return {
    ...properties,
    os_version: platform?.os?.version ?? '',
    /* eslint-enable */
  };
}

function getReferralProperties(): ReferralPropertiesType {
  let properties: Partial<ReferralPropertiesType> = {};
  if (__WEB__) {
    properties['referrer'] = document.referrer;
    properties['referring_domain'] = getReferringDomain();
    properties = {
      ...properties,
      ...getCampaignParams(window.location.href),
      ...getSearchInfo(),
    };
  }
  return properties;
}

/**
 * Append string to end of all keys in an object, returns newly keyed object
 */
function suffixObjectKeys(rawObject: Record<string, any>, suffix: string) {
  const suffixedObject: Record<string, any> = {};
  Object.keys(rawObject).map((key) => (suffixedObject[`${key}${suffix}`] = rawObject[key]));
  return suffixedObject;
}

function getCachedSuperProperties(): SuperPropertiesType {
  // store device in perm storage
  let deviceProperties: DevicePropertiesType | null | undefined = Storage.get(DEVICE_PROPERTIES_KEY);
  if (deviceProperties == null) {
    deviceProperties = getDeviceProperties();
    Storage.set(DEVICE_PROPERTIES_KEY, deviceProperties);
  }
  // store referral info in perm storage
  let referralProperties: ReferralPropertiesType | null | undefined = Storage.get(REFERRAL_PROPERTIES_KEY);
  if (referralProperties == null) {
    referralProperties = getReferralProperties();
    Storage.set(REFERRAL_PROPERTIES_KEY, referralProperties);
  }
  // store current_referral info in sessionStorage
  let currentReferralProperties: CurrentReferralPropertiesType | null | undefined =
    SessionStorage.get(REFERRAL_PROPERTIES_KEY);
  if (currentReferralProperties == null) {
    currentReferralProperties = suffixObjectKeys(getReferralProperties(), '_current');
    SessionStorage.set(REFERRAL_PROPERTIES_KEY, currentReferralProperties);
  }
  return {
    ...deviceProperties,
    ...getDeviceVersionProperties(),
    ...referralProperties,
    ...currentReferralProperties,
  };
}

function getClientEventSource() {
  // TODO: Add other sources at some point maybe?
  try {
    if (__OVERLAY__) {
      return 'OVERLAY';
    }
  } catch (e) {}
  return null;
}

function getContextualSuperProperties() {
  const properties: SuperPropertiesType = {};

  const releaseChannel = window.GLOBAL_ENV.RELEASE_CHANNEL;
  if (releaseChannel) {
    // On iOS, release channel comes from native land to detect TestFlight installs.
    // Otherwise, we should prefer JS release channel over native release channel.
    if (!__MOBILE__ || properties['release_channel'] == null || properties['release_channel'] === '') {
      properties['release_channel'] = releaseChannel.split('-')[0]; // match up to the `-` for `internal` channel
    }
  }

  const buildNumber = parseInt(process.env.BUILD_NUMBER ?? '', 10);
  if (!isNaN(buildNumber)) {
    // discord-web build ID
    properties['client_build_number'] = buildNumber;
  }

  const nativeBuildNumber = DiscordNative?.remoteApp.getBuildNumber?.();
  if (!isNaN(nativeBuildNumber)) {
    // discord-desktop build ID
    properties['native_build_number'] = nativeBuildNumber;
  }

  properties['client_event_source'] = getClientEventSource();

  return properties;
}

export function isThrottled<TAnalyticsSchemaNameMapType>(namedKey: keyof TAnalyticsSchemaNameMapType): boolean {
  return throttledEvents[namedKey as string] != null && throttledEvents[namedKey as string] > Date.now();
}

if (superProperties == null) {
  try {
    superProperties = getCachedSuperProperties();
  } catch (e) {
    superProperties = {};
  }
}

let superPropertiesBase64: string | null;

export function extendSuperProperties(additionalSuperProperties: SuperPropertiesType) {
  superProperties = {...superProperties, ...additionalSuperProperties};
  superPropertiesBase64 = encodeProperties(superProperties);
}

extendSuperProperties(getContextualSuperProperties());

export const trackMaker = <
  TDispatcher extends Readonly<ActionBase>,
  TAnalyticsSchemaNameMapType extends Record<string, any>,
>({
  analyticEventConfigs,
  dispatcher,
  TRACK_ACTION_NAME,
}: {
  analyticEventConfigs: Record<string, AnalyticEventConfigType | null>;
  dispatcher: Dispatcher<TDispatcher>;
  TRACK_ACTION_NAME: string;
}) => {
  /**
   * Send event to API server to track on multiple services.
   *
   * @param {String} event
   * @param {Object} [properties]
   */
  const queueTrackingEvent = queueTrackingEventMaker(dispatcher, TRACK_ACTION_NAME);
  function track<EventName extends keyof TAnalyticsSchemaNameMapType & string>(
    event: EventName,
    properties?: TAnalyticsSchemaNameMapType[EventName],
    options: TrackFunctionOptions = {},
  ): Promise<void> {
    // During marketing server render, we set the global variable `isServerRendering` to true,
    // which we check for here to prevent AnalyticsUtils from throwing errors.
    // @ts-expect-error
    if (global.isServerRendering != null && global.isServerRendering === true) {
      return Promise.resolve();
    }

    const eventProperties = properties ?? {};
    const config: AnalyticEventConfigType | null = analyticEventConfigs[event];

    if (config != null) {
      if ('throttlePeriod' in config) {
        const throttleKey = [event, ...config.throttleKeys(eventProperties)].join('_');
        if (isThrottled(throttleKey)) {
          return Promise.resolve();
        } else if (typeof config.throttlePercent === 'number') {
          if (Math.random() > config.throttlePercent) {
            return Promise.resolve();
          }
        }

        if (config.deduplicate) {
          const oldProperties = deduplicatedEvents[throttleKey];
          if (deepEqual(oldProperties, eventProperties)) {
            return Promise.resolve();
          }
          deduplicatedEvents[throttleKey] = eventProperties;
        }
        throttledEvents[throttleKey] = Date.now() + config.throttlePeriod;
      } else if ('throttlePercent' in config) {
        if (Math.random() > config.throttlePercent) {
          return Promise.resolve();
        }
      } else {
        invariant(false, `Unsupported analytics event config: ${config}`);
      }
    }

    return queueTrackingEvent(event, properties, options);
  }

  return track;
};

export function getSuperProperties() {
  return superProperties;
}

export function getSuperPropertiesBase64() {
  return superPropertiesBase64;
}
