import {HTTP, ResponseInterceptorRetry} from '@discordapp/http-utils';

export type MFAMethod =
  | {
      type: 'webauthn';
      challenge: string;
    }
  | {
      type: 'totp';
      backup_codes_allowed: boolean;
    }
  | {
      type: 'backup';
    }
  | {
      type: 'sms';
    }
  | {
      type: 'password';
    };

export interface MfaChallenge {
  ticket: string;
  methods: MFAMethod[];
}

export interface FinishMFACheckRequest {
  mfaType: MFAMethod['type'];
  ticket: string;
  data: string;
}

export interface FinishMFACheckResponse {
  token: string;
}

// We expected 8 characters, but we show backup codes with
//  hyphen so we are allowing some padding space.
export const BACKUP_CODE_MIN_LENGTH = 8;
export const BACKUP_CODE_MAX_LENGTH = 11;

export const TOTP_CODE_LENGTH = 6;
export const SMS_CODE_LENGTH = 6;

export async function finishMFACheck(
  {ticket, mfaType, data}: FinishMFACheckRequest,
  retries: number = 2,
): Promise<FinishMFACheckResponse> {
  try {
    const response = await HTTP.post({
      url: '/mfa/finish',
      body: {
        ticket,
        mfa_type: mfaType,
        data,
      },
      retries,
    });

    return response.body;
  } catch (e) {
    if (e.body?.message) {
      throw new Error(e.body.message);
    }
    throw e;
  }
}

const MFA_REQUIRED = 60003;
const MFA_INVALID_CODE = 60008;

export async function trySubmit(request: FinishMFACheckRequest, retry: ResponseInterceptorRetry) {
  const {token} = await finishMFACheck(request);

  return new Promise<void>((resolve, reject) => {
    retry(
      {
        'X-Discord-MFA-Authorization': token,
      },
      (res) => {
        if (res.body?.code === MFA_INVALID_CODE || res.body?.code === MFA_REQUIRED) {
          reject(new Error(res.body.message));
          return true;
        }
        resolve();
        return false;
      },
    );
  });
}
