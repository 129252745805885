/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde mana assets sync -t icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';
import {getIconSize} from '../getIconSize';

import type {SingleColorIconProps} from '../IconTypes';

/**
 * ![ThemeDarkIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABwUlEQVR4nMVWyy4EURC1ICReKyvPL/AJZsEkHaMeI7mqPBIJMbEcW4+IhT8gfgL/IViwsxA2LInnUksNMjOtH3eQuMnNTaerz6lTdVK3m5r+cznnuoFkEXFq5E+Bc/Pzbci6iSzPyBoCyZHPdxMko5lB45Mzg8hyZsDVLbuZ4BNuwBKyMxUcWG7qwTXEohayMv9SWzlxauxbUBAErcBy/g2cNSwUXO+vFQDpVhw4sr7lcrnmJOBSqdRSqyQ2CBE7keUhgSCsBalT7VwPsNzbmSoPWZeTwJE1LBZn++IT05UPl2k5lQBYDtMIMNLkSuakZSC5/bTxrT0nKkGSyzQCINn5irVyWVniY+UutpzI+pKqgOUun59r/7ECIH1KJ1Db67/ogV5lEQDpK4AOR3th6jxcJPseCkLrVdRRSRaOEOiSFwF/kESVZK4gmO0C1kdfErBykWw45zq8SYBk1VsFV90FJHtE02AzyMZ8IoG9BJbjxkk0urcTSYikH0mufwxOcm0jP7VUNpqR9LRRcGA5IZIhr36Y9ZBlzWrs0Qebwut2n3iB1y4b45VLn2UfSC/MPRVSm12kB8i6YD8GDQP/5XoHOOH2iZeHEuUAAAAASUVORK5CYII=)
 */
export const ThemeDarkIcon = ({
  size = 'md',
  width,
  height,
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: SingleColorIconProps) => {
  const iconSize = getIconSize(size);
  const iconWidth = iconSize?.width ?? width;
  const iconHeight = iconSize?.height ?? height;
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={typeof color === 'string' ? color : color.css}
        d="M20.52 18.96c.32-.4-.01-.96-.52-.96A11 11 0 0 1 9.77 2.94c.31-.78-.3-1.68-1.1-1.43a11 11 0 1 0 11.85 17.45Z"
        className={colorClass}
      />
      <path
        fill={typeof color === 'string' ? color : color.css}
        d="m17.73 9.27-.76-2.02a.5.5 0 0 0-.94 0l-.76 2.02-2.02.76a.5.5 0 0 0 0 .94l2.02.76.76 2.02a.5.5 0 0 0 .94 0l.76-2.02 2.02-.76a.5.5 0 0 0 0-.94l-2.02-.76ZM19.73 2.62l.45 1.2 1.2.45c.21.08.21.38 0 .46l-1.2.45-.45 1.2a.25.25 0 0 1-.46 0l-.45-1.2-1.2-.45a.25.25 0 0 1 0-.46l1.2-.45.45-1.2a.25.25 0 0 1 .46 0Z"
        className={colorClass}
      />
    </svg>
  );
};
