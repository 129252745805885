function calculatePopupPosition(width: number, height: number): {left: number; top: number} {
  const currentWindowLeft = window.screenLeft;
  const currentWindowTop = window.screenTop;

  const {outerHeight, outerWidth} = window;
  const left = outerWidth / 2 - width / 2 + currentWindowLeft;
  const top = outerHeight / 2 - height / 2 + currentWindowTop;

  return {left, top};
}

function serializeWindowFeatures(features: {[key: string]: any}): string {
  const serializedParts = [];
  for (const feature of Object.keys(features)) {
    let value = features[feature];
    if (typeof value === 'boolean') {
      value = value ? 'yes' : 'no';
    } else {
      value = value.toString();
    }
    serializedParts.push(`${feature}=${value}`);
  }
  return serializedParts.join(',');
}

export class PopupWindow {
  handleClose: () => unknown;
  window: any;

  constructor(
    {
      url = 'about:blank',
      title = 'Loading...',
      width = 500,
      height = 600,
      scrollbars = true,
      resizable = true,
      toolbar = false,
      location = true,
    }: {
      url?: string;
      title?: string;
      width?: number;
      height?: number;
      scrollbars?: boolean;
      resizable?: boolean;
      toolbar?: boolean;
      location?: boolean;
    } = {},
    onClose: () => unknown,
  ) {
    const {left, top} = calculatePopupPosition(width, height);
    this.handleClose = onClose;
    this.window = window.open(
      url,
      title,
      serializeWindowFeatures({
        width,
        height,
        left,
        top,
        scrollbars,
        resizable,
        toolbar,
        location,
      }),
    );
    // There is no API for monitoring when a popup is closed, so we quite literally
    // have to poll for state change :|
    const windowCloseTimer = setInterval(() => {
      if (this.window != null && !this.window.closed) {
        return;
      }
      clearInterval(windowCloseTimer);
      this.close();
    }, 250);
  }

  resize(width: number, height: number) {
    if (this.window == null) return;
    this.window.resizeTo(width, height);
    const {left, top} = calculatePopupPosition(width, height);
    this.window.moveTo(left, top);
  }

  redirectTo(url: string) {
    if (this.window == null) return;
    this.window.location = url;
  }

  close() {
    if (this.window == null) return;
    try {
      if (!this.window.closed) {
        this.window.close();
      }
    } catch (_) {}
    this.window = null;
    if (this.handleClose != null) {
      this.handleClose();
    }
  }
}
