import * as React from 'react';
import classNames from 'classnames';
import * as ReactDOM from 'react-dom';
import {useBlocker} from 'react-router-dom';

import usePrevious from '@developers/hooks/usePrevious';
import {ComponentDispatch} from '@developers/utils/ComponentDispatchUtils';
import UnsavedChangesNotice, {UnsavedChangesNoticeProps} from './UnsavedChangesNotice';

import {ComponentActions} from '@developers/Constants';
import styles from './UnsavedChangesNoticePopup.module.css';

const PLACEHOLDER_MOUNT_POINT_ID = crypto.randomUUID();

export function UnsavedChangesNoticePopupMountPoint() {
  return <div id={PLACEHOLDER_MOUNT_POINT_ID} className={styles.noticeBar} />;
}

interface UnsavedChangesNoticePopupProps extends UnsavedChangesNoticeProps {
  show: boolean;
}

export default function UnsavedChangesNoticePopup({show, ...noticeProps}: UnsavedChangesNoticePopupProps) {
  const [isSlidingIn, setIsSlidingIn] = React.useState(false);
  const [isSlidingOut, setIsSlidingOut] = React.useState(false);

  useBlocker(() => {
    if (show) {
      ComponentDispatch.dispatch(ComponentActions.EMPHASIZE_NOTICE);
      return true;
    }
    return false;
  });

  const prevShow = usePrevious(show);
  if (show && prevShow === false && !isSlidingIn && !isSlidingOut) {
    setIsSlidingIn(true);
    setTimeout(() => setIsSlidingIn(false), 300);
  } else if (!show && prevShow && !isSlidingOut && !isSlidingIn) {
    setIsSlidingOut(true);
    setTimeout(() => setIsSlidingOut(false), 300);
  }

  const mountPoint = window.document.getElementById(PLACEHOLDER_MOUNT_POINT_ID);
  if (mountPoint == null || (!show && !isSlidingIn && !isSlidingOut)) {
    return null;
  }

  return ReactDOM.createPortal(
    <UnsavedChangesNotice
      {...noticeProps}
      className={classNames({
        [styles.slideIn]: isSlidingIn,
        [styles.slideOut]: isSlidingOut,
      })}
    />,
    mountPoint,
  );
}
