/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde mana tokens generate`
*/
import {Color} from './ColorDetails';

export enum ThemeColor {
  ACTION_SHEET_GRADIENT_BG = 'action-sheet-gradient-bg',
  ACTIVITY_CARD_BACKGROUND = 'activity-card-background',
  ACTIVITY_CARD_ICON_OVERLAY = 'activity-card-icon-overlay',
  ALERT_BG = 'alert-bg',
  ANDROID_NAVIGATION_BAR_BACKGROUND = 'android-navigation-bar-background',
  ANDROID_NAVIGATION_SCRIM_BACKGROUND = 'android-navigation-scrim-background',
  ANDROID_RIPPLE = 'android-ripple',
  AUTOCOMPLETE_BG = 'autocomplete-bg',
  BACKGROUND_ACCENT = 'background-accent',
  BACKGROUND_FLOATING = 'background-floating',
  BACKGROUND_MENTIONED = 'background-mentioned',
  BACKGROUND_MENTIONED_HOVER = 'background-mentioned-hover',
  BACKGROUND_MESSAGE_AUTOMOD = 'background-message-automod',
  BACKGROUND_MESSAGE_AUTOMOD_HOVER = 'background-message-automod-hover',
  BACKGROUND_MESSAGE_HIGHLIGHT = 'background-message-highlight',
  BACKGROUND_MESSAGE_HIGHLIGHT_HOVER = 'background-message-highlight-hover',
  BACKGROUND_MESSAGE_HOVER = 'background-message-hover',
  BACKGROUND_MOBILE_PRIMARY = 'background-mobile-primary',
  BACKGROUND_MOBILE_SECONDARY = 'background-mobile-secondary',
  BACKGROUND_MODIFIER_ACCENT = 'background-modifier-accent',
  BACKGROUND_MODIFIER_ACCENT_2 = 'background-modifier-accent-2',
  BACKGROUND_MODIFIER_ACTIVE = 'background-modifier-active',
  BACKGROUND_MODIFIER_HOVER = 'background-modifier-hover',
  BACKGROUND_MODIFIER_SELECTED = 'background-modifier-selected',
  BACKGROUND_NESTED_FLOATING = 'background-nested-floating',
  BACKGROUND_PRIMARY = 'background-primary',
  BACKGROUND_SECONDARY = 'background-secondary',
  BACKGROUND_SECONDARY_ALT = 'background-secondary-alt',
  BACKGROUND_TERTIARY = 'background-tertiary',
  BADGE_BRAND_BG = 'badge-brand-bg',
  BADGE_BRAND_TEXT = 'badge-brand-text',
  BG_BACKDROP = 'bg-backdrop',
  BG_BACKDROP_NO_OPACITY = 'bg-backdrop-no-opacity',
  BG_BASE_PRIMARY = 'bg-base-primary',
  BG_BASE_SECONDARY = 'bg-base-secondary',
  BG_BASE_TERTIARY = 'bg-base-tertiary',
  BG_BRAND = 'bg-brand',
  BG_MOD_FAINT = 'bg-mod-faint',
  BG_MOD_STRONG = 'bg-mod-strong',
  BG_MOD_SUBTLE = 'bg-mod-subtle',
  BG_SURFACE_OVERLAY = 'bg-surface-overlay',
  BG_SURFACE_OVERLAY_TMP = 'bg-surface-overlay-tmp',
  BG_SURFACE_RAISED = 'bg-surface-raised',
  BLACK = 'black',
  BLUR_FALLBACK = 'blur-fallback',
  BLUR_FALLBACK_PRESSED = 'blur-fallback-pressed',
  BORDER_FAINT = 'border-faint',
  BORDER_STRONG = 'border-strong',
  BORDER_SUBTLE = 'border-subtle',
  BUG_REPORTER_MODAL_SUBMITTING_BACKGROUND = 'bug-reporter-modal-submitting-background',
  BUTTON_CREATOR_REVENUE_BACKGROUND = 'button-creator-revenue-background',
  BUTTON_DANGER_BACKGROUND = 'button-danger-background',
  BUTTON_DANGER_BACKGROUND_ACTIVE = 'button-danger-background-active',
  BUTTON_DANGER_BACKGROUND_DISABLED = 'button-danger-background-disabled',
  BUTTON_DANGER_BACKGROUND_HOVER = 'button-danger-background-hover',
  BUTTON_OUTLINE_BRAND_BACKGROUND = 'button-outline-brand-background',
  BUTTON_OUTLINE_BRAND_BACKGROUND_ACTIVE = 'button-outline-brand-background-active',
  BUTTON_OUTLINE_BRAND_BACKGROUND_HOVER = 'button-outline-brand-background-hover',
  BUTTON_OUTLINE_BRAND_BORDER = 'button-outline-brand-border',
  BUTTON_OUTLINE_BRAND_BORDER_ACTIVE = 'button-outline-brand-border-active',
  BUTTON_OUTLINE_BRAND_BORDER_HOVER = 'button-outline-brand-border-hover',
  BUTTON_OUTLINE_BRAND_TEXT = 'button-outline-brand-text',
  BUTTON_OUTLINE_BRAND_TEXT_ACTIVE = 'button-outline-brand-text-active',
  BUTTON_OUTLINE_BRAND_TEXT_HOVER = 'button-outline-brand-text-hover',
  BUTTON_OUTLINE_DANGER_BACKGROUND = 'button-outline-danger-background',
  BUTTON_OUTLINE_DANGER_BACKGROUND_ACTIVE = 'button-outline-danger-background-active',
  BUTTON_OUTLINE_DANGER_BACKGROUND_HOVER = 'button-outline-danger-background-hover',
  BUTTON_OUTLINE_DANGER_BORDER = 'button-outline-danger-border',
  BUTTON_OUTLINE_DANGER_BORDER_ACTIVE = 'button-outline-danger-border-active',
  BUTTON_OUTLINE_DANGER_BORDER_HOVER = 'button-outline-danger-border-hover',
  BUTTON_OUTLINE_DANGER_TEXT = 'button-outline-danger-text',
  BUTTON_OUTLINE_DANGER_TEXT_ACTIVE = 'button-outline-danger-text-active',
  BUTTON_OUTLINE_DANGER_TEXT_HOVER = 'button-outline-danger-text-hover',
  BUTTON_OUTLINE_POSITIVE_BACKGROUND = 'button-outline-positive-background',
  BUTTON_OUTLINE_POSITIVE_BACKGROUND_ACTIVE = 'button-outline-positive-background-active',
  BUTTON_OUTLINE_POSITIVE_BACKGROUND_HOVER = 'button-outline-positive-background-hover',
  BUTTON_OUTLINE_POSITIVE_BORDER = 'button-outline-positive-border',
  BUTTON_OUTLINE_POSITIVE_BORDER_ACTIVE = 'button-outline-positive-border-active',
  BUTTON_OUTLINE_POSITIVE_BORDER_HOVER = 'button-outline-positive-border-hover',
  BUTTON_OUTLINE_POSITIVE_TEXT = 'button-outline-positive-text',
  BUTTON_OUTLINE_POSITIVE_TEXT_ACTIVE = 'button-outline-positive-text-active',
  BUTTON_OUTLINE_POSITIVE_TEXT_HOVER = 'button-outline-positive-text-hover',
  BUTTON_OUTLINE_PRIMARY_BACKGROUND = 'button-outline-primary-background',
  BUTTON_OUTLINE_PRIMARY_BACKGROUND_ACTIVE = 'button-outline-primary-background-active',
  BUTTON_OUTLINE_PRIMARY_BACKGROUND_HOVER = 'button-outline-primary-background-hover',
  BUTTON_OUTLINE_PRIMARY_BORDER = 'button-outline-primary-border',
  BUTTON_OUTLINE_PRIMARY_BORDER_ACTIVE = 'button-outline-primary-border-active',
  BUTTON_OUTLINE_PRIMARY_BORDER_HOVER = 'button-outline-primary-border-hover',
  BUTTON_OUTLINE_PRIMARY_TEXT = 'button-outline-primary-text',
  BUTTON_OUTLINE_PRIMARY_TEXT_ACTIVE = 'button-outline-primary-text-active',
  BUTTON_OUTLINE_PRIMARY_TEXT_HOVER = 'button-outline-primary-text-hover',
  BUTTON_POSITIVE_BACKGROUND = 'button-positive-background',
  BUTTON_POSITIVE_BACKGROUND_ACTIVE = 'button-positive-background-active',
  BUTTON_POSITIVE_BACKGROUND_DISABLED = 'button-positive-background-disabled',
  BUTTON_POSITIVE_BACKGROUND_HOVER = 'button-positive-background-hover',
  BUTTON_SECONDARY_BACKGROUND = 'button-secondary-background',
  BUTTON_SECONDARY_BACKGROUND_ACTIVE = 'button-secondary-background-active',
  BUTTON_SECONDARY_BACKGROUND_DISABLED = 'button-secondary-background-disabled',
  BUTTON_SECONDARY_BACKGROUND_HOVER = 'button-secondary-background-hover',
  CARD_GRADIENT_BG = 'card-gradient-bg',
  CARD_GRADIENT_PRESSED_BG = 'card-gradient-pressed-bg',
  CARD_PRIMARY_BG = 'card-primary-bg',
  CARD_PRIMARY_PRESSED_BG = 'card-primary-pressed-bg',
  CARD_SECONDARY_BG = 'card-secondary-bg',
  CARD_SECONDARY_PRESSED_BG = 'card-secondary-pressed-bg',
  CHANNEL_ICON = 'channel-icon',
  CHANNEL_TEXT_AREA_PLACEHOLDER = 'channel-text-area-placeholder',
  CHANNELS_DEFAULT = 'channels-default',
  CHANNELTEXTAREA_BACKGROUND = 'channeltextarea-background',
  CHAT_BACKGROUND = 'chat-background',
  CHAT_BANNER_BG = 'chat-banner-bg',
  CHAT_BORDER = 'chat-border',
  CHAT_INPUT_CONTAINER_BACKGROUND = 'chat-input-container-background',
  CHAT_SWIPE_TO_REPLY_BACKGROUND = 'chat-swipe-to-reply-background',
  CHAT_SWIPE_TO_REPLY_GRADIENT_BACKGROUND = 'chat-swipe-to-reply-gradient-background',
  COACHMARK_BG = 'coachmark-bg',
  CONTENT_INVENTORY_MEDIA_SEEKBAR_CONTAINER = 'content-inventory-media-seekbar-container',
  CONTENT_INVENTORY_OVERLAY_TEXT_PRIMARY = 'content-inventory-overlay-text-primary',
  CONTENT_INVENTORY_OVERLAY_TEXT_SECONDARY = 'content-inventory-overlay-text-secondary',
  CONTENT_INVENTORY_OVERLAY_UI_MOD = 'content-inventory-overlay-ui-mod',
  CONTENT_INVENTORY_OVERLAY_UI_MOD_BG = 'content-inventory-overlay-ui-mod-bg',
  CONTEXT_MENU_BACKDROP_BACKGROUND = 'context-menu-backdrop-background',
  CONTROL_BRAND_FOREGROUND = 'control-brand-foreground',
  CONTROL_BRAND_FOREGROUND_NEW = 'control-brand-foreground-new',
  CREATOR_REVENUE_ICON_GRADIENT_END = 'creator-revenue-icon-gradient-end',
  CREATOR_REVENUE_ICON_GRADIENT_START = 'creator-revenue-icon-gradient-start',
  CREATOR_REVENUE_INFO_BOX_BACKGROUND = 'creator-revenue-info-box-background',
  CREATOR_REVENUE_INFO_BOX_BORDER = 'creator-revenue-info-box-border',
  CREATOR_REVENUE_LOCKED_CHANNEL_ICON = 'creator-revenue-locked-channel-icon',
  CREATOR_REVENUE_PROGRESS_BAR = 'creator-revenue-progress-bar',
  DEPRECATED_CARD_BG = 'deprecated-card-bg',
  DEPRECATED_CARD_EDITABLE_BG = 'deprecated-card-editable-bg',
  DEPRECATED_QUICKSWITCHER_INPUT_BACKGROUND = 'deprecated-quickswitcher-input-background',
  DEPRECATED_QUICKSWITCHER_INPUT_PLACEHOLDER = 'deprecated-quickswitcher-input-placeholder',
  DEPRECATED_STORE_BG = 'deprecated-store-bg',
  DEPRECATED_TEXT_INPUT_BG = 'deprecated-text-input-bg',
  DEPRECATED_TEXT_INPUT_BORDER = 'deprecated-text-input-border',
  DEPRECATED_TEXT_INPUT_BORDER_DISABLED = 'deprecated-text-input-border-disabled',
  DEPRECATED_TEXT_INPUT_BORDER_HOVER = 'deprecated-text-input-border-hover',
  DEPRECATED_TEXT_INPUT_PREFIX = 'deprecated-text-input-prefix',
  DISPLAY_BANNER_OVERFLOW_BACKGROUND = 'display-banner-overflow-background',
  DIVIDER_STRONG = 'divider-strong',
  DIVIDER_SUBTLE = 'divider-subtle',
  EMBED_BACKGROUND = 'embed-background',
  EMBED_BACKGROUND_ALTERNATE = 'embed-background-alternate',
  EMBED_TITLE = 'embed-title',
  EXPRESSION_PICKER_BG = 'expression-picker-bg',
  FOCUS_PRIMARY = 'focus-primary',
  FORUM_POST_EXTRA_MEDIA_COUNT_CONTAINER_BACKGROUND = 'forum-post-extra-media-count-container-background',
  FORUM_POST_TAG_BACKGROUND = 'forum-post-tag-background',
  GUILD_ICON_INACTIVE_BG = 'guild-icon-inactive-bg',
  GUILD_ICON_INACTIVE_NESTED_BG = 'guild-icon-inactive-nested-bg',
  GUILD_NOTIFICATIONS_BOTTOM_SHEET_PILL_BACKGROUND = 'guild-notifications-bottom-sheet-pill-background',
  HALO_POSITIVE = 'halo-positive',
  HEADER_MUTED = 'header-muted',
  HEADER_PRIMARY = 'header-primary',
  HEADER_SECONDARY = 'header-secondary',
  HOME_BACKGROUND = 'home-background',
  HOME_CARD_RESTING_BORDER = 'home-card-resting-border',
  ICON_MUTED = 'icon-muted',
  ICON_PRIMARY = 'icon-primary',
  ICON_SECONDARY = 'icon-secondary',
  ICON_TRANSPARENT = 'icon-transparent',
  INFO_BOX_BACKGROUND = 'info-box-background',
  INFO_DANGER_BACKGROUND = 'info-danger-background',
  INFO_DANGER_FOREGROUND = 'info-danger-foreground',
  INFO_DANGER_TEXT = 'info-danger-text',
  INFO_HELP_BACKGROUND = 'info-help-background',
  INFO_HELP_FOREGROUND = 'info-help-foreground',
  INFO_HELP_TEXT = 'info-help-text',
  INFO_POSITIVE_BACKGROUND = 'info-positive-background',
  INFO_POSITIVE_FOREGROUND = 'info-positive-foreground',
  INFO_POSITIVE_TEXT = 'info-positive-text',
  INFO_WARNING_BACKGROUND = 'info-warning-background',
  INFO_WARNING_FOREGROUND = 'info-warning-foreground',
  INFO_WARNING_TEXT = 'info-warning-text',
  INPUT_BACKGROUND = 'input-background',
  INPUT_FOCUSED_BORDER = 'input-focused-border',
  INPUT_PLACEHOLDER_TEXT = 'input-placeholder-text',
  INTERACTIVE_ACTIVE = 'interactive-active',
  INTERACTIVE_HOVER = 'interactive-hover',
  INTERACTIVE_MUTED = 'interactive-muted',
  INTERACTIVE_NORMAL = 'interactive-normal',
  LEGACY_ANDROID_BLUR_OVERLAY_DEFAULT = 'legacy-android-blur-overlay-default',
  LEGACY_ANDROID_BLUR_OVERLAY_ULTRA_THIN = 'legacy-android-blur-overlay-ultra-thin',
  LEGACY_BLUR_FALLBACK_DEFAULT = 'legacy-blur-fallback-default',
  LEGACY_BLUR_FALLBACK_ULTRA_THIN = 'legacy-blur-fallback-ultra-thin',
  LIVE_STAGE_TILE_BORDER = 'live-stage-tile-border',
  LOGO_PRIMARY = 'logo-primary',
  MENTION_BACKGROUND = 'mention-background',
  MENTION_FOREGROUND = 'mention-foreground',
  MENU_ITEM_DANGER_ACTIVE_BG = 'menu-item-danger-active-bg',
  MENU_ITEM_DANGER_HOVER_BG = 'menu-item-danger-hover-bg',
  MENU_ITEM_DEFAULT_ACTIVE_BG = 'menu-item-default-active-bg',
  MENU_ITEM_DEFAULT_HOVER_BG = 'menu-item-default-hover-bg',
  MODAL_BACKGROUND = 'modal-background',
  MODAL_FOOTER_BACKGROUND = 'modal-footer-background',
  NAVIGATOR_HEADER_TINT = 'navigator-header-tint',
  PANEL_BG = 'panel-bg',
  POLLS_NORMAL_FILL_HOVER = 'polls-normal-fill-hover',
  POLLS_NORMAL_IMAGE_BACKGROUND = 'polls-normal-image-background',
  POLLS_VICTOR_FILL = 'polls-victor-fill',
  POLLS_VOTED_FILL = 'polls-voted-fill',
  PREMIUM_NITRO_PINK_TEXT = 'premium-nitro-pink-text',
  PROFILE_GRADIENT_CARD_BACKGROUND = 'profile-gradient-card-background',
  PROFILE_GRADIENT_MESSAGE_INPUT_BORDER = 'profile-gradient-message-input-border',
  PROFILE_GRADIENT_NOTE_BACKGROUND = 'profile-gradient-note-background',
  PROFILE_GRADIENT_OVERLAY = 'profile-gradient-overlay',
  PROFILE_GRADIENT_OVERLAY_SYNCED_WITH_USER_THEME = 'profile-gradient-overlay-synced-with-user-theme',
  PROFILE_GRADIENT_PROFILE_BODY_BACKGROUND_HOVER = 'profile-gradient-profile-body-background-hover',
  PROFILE_GRADIENT_ROLE_PILL_BACKGROUND = 'profile-gradient-role-pill-background',
  PROFILE_GRADIENT_ROLE_PILL_BORDER = 'profile-gradient-role-pill-border',
  PROFILE_GRADIENT_SECTION_BOX = 'profile-gradient-section-box',
  REDESIGN_ACTIVITY_CARD_BACKGROUND = 'redesign-activity-card-background',
  REDESIGN_ACTIVITY_CARD_BACKGROUND_PRESSED = 'redesign-activity-card-background-pressed',
  REDESIGN_ACTIVITY_CARD_BADGE_ICON = 'redesign-activity-card-badge-icon',
  REDESIGN_ACTIVITY_CARD_BORDER = 'redesign-activity-card-border',
  REDESIGN_ACTIVITY_CARD_OVERFLOW_BACKGROUND = 'redesign-activity-card-overflow-background',
  REDESIGN_BUTTON_ACTIVE_BACKGROUND = 'redesign-button-active-background',
  REDESIGN_BUTTON_ACTIVE_PRESSED_BACKGROUND = 'redesign-button-active-pressed-background',
  REDESIGN_BUTTON_ACTIVE_TEXT = 'redesign-button-active-text',
  REDESIGN_BUTTON_DANGER_BACKGROUND = 'redesign-button-danger-background',
  REDESIGN_BUTTON_DANGER_PRESSED_BACKGROUND = 'redesign-button-danger-pressed-background',
  REDESIGN_BUTTON_DANGER_TEXT = 'redesign-button-danger-text',
  REDESIGN_BUTTON_DESTRUCTIVE_BACKGROUND = 'redesign-button-destructive-background',
  REDESIGN_BUTTON_DESTRUCTIVE_PRESSED_BACKGROUND = 'redesign-button-destructive-pressed-background',
  REDESIGN_BUTTON_DESTRUCTIVE_TEXT = 'redesign-button-destructive-text',
  REDESIGN_BUTTON_OVERLAY_ALPHA_BACKGROUND = 'redesign-button-overlay-alpha-background',
  REDESIGN_BUTTON_OVERLAY_ALPHA_PRESSED_BACKGROUND = 'redesign-button-overlay-alpha-pressed-background',
  REDESIGN_BUTTON_OVERLAY_ALPHA_TEXT = 'redesign-button-overlay-alpha-text',
  REDESIGN_BUTTON_OVERLAY_BACKGROUND = 'redesign-button-overlay-background',
  REDESIGN_BUTTON_OVERLAY_PRESSED_BACKGROUND = 'redesign-button-overlay-pressed-background',
  REDESIGN_BUTTON_OVERLAY_TEXT = 'redesign-button-overlay-text',
  REDESIGN_BUTTON_POSITIVE_BACKGROUND = 'redesign-button-positive-background',
  REDESIGN_BUTTON_POSITIVE_PRESSED_BACKGROUND = 'redesign-button-positive-pressed-background',
  REDESIGN_BUTTON_POSITIVE_TEXT = 'redesign-button-positive-text',
  REDESIGN_BUTTON_PRIMARY_ALT_BACKGROUND = 'redesign-button-primary-alt-background',
  REDESIGN_BUTTON_PRIMARY_ALT_BORDER = 'redesign-button-primary-alt-border',
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BACKGROUND = 'redesign-button-primary-alt-on-blurple-background',
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BORDER = 'redesign-button-primary-alt-on-blurple-border',
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BACKGROUND = 'redesign-button-primary-alt-on-blurple-pressed-background',
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BORDER = 'redesign-button-primary-alt-on-blurple-pressed-border',
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_TEXT = 'redesign-button-primary-alt-on-blurple-text',
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BACKGROUND = 'redesign-button-primary-alt-pressed-background',
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BORDER = 'redesign-button-primary-alt-pressed-border',
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_TEXT = 'redesign-button-primary-alt-pressed-text',
  REDESIGN_BUTTON_PRIMARY_ALT_TEXT = 'redesign-button-primary-alt-text',
  REDESIGN_BUTTON_PRIMARY_BACKGROUND = 'redesign-button-primary-background',
  REDESIGN_BUTTON_PRIMARY_ON_BLURPLE_PRESSED_TEXT = 'redesign-button-primary-on-blurple-pressed-text',
  REDESIGN_BUTTON_PRIMARY_OVERLAY_BACKGROUND = 'redesign-button-primary-overlay-background',
  REDESIGN_BUTTON_PRIMARY_OVERLAY_PRESSED_BACKGROUND = 'redesign-button-primary-overlay-pressed-background',
  REDESIGN_BUTTON_PRIMARY_OVERLAY_TEXT = 'redesign-button-primary-overlay-text',
  REDESIGN_BUTTON_PRIMARY_PRESSED_BACKGROUND = 'redesign-button-primary-pressed-background',
  REDESIGN_BUTTON_PRIMARY_TEXT = 'redesign-button-primary-text',
  REDESIGN_BUTTON_SECONDARY_BACKGROUND = 'redesign-button-secondary-background',
  REDESIGN_BUTTON_SECONDARY_BORDER = 'redesign-button-secondary-border',
  REDESIGN_BUTTON_SECONDARY_OVERLAY_BACKGROUND = 'redesign-button-secondary-overlay-background',
  REDESIGN_BUTTON_SECONDARY_OVERLAY_PRESSED_BACKGROUND = 'redesign-button-secondary-overlay-pressed-background',
  REDESIGN_BUTTON_SECONDARY_OVERLAY_TEXT = 'redesign-button-secondary-overlay-text',
  REDESIGN_BUTTON_SECONDARY_PRESSED_BACKGROUND = 'redesign-button-secondary-pressed-background',
  REDESIGN_BUTTON_SECONDARY_PRESSED_BORDER = 'redesign-button-secondary-pressed-border',
  REDESIGN_BUTTON_SECONDARY_TEXT = 'redesign-button-secondary-text',
  REDESIGN_BUTTON_SELECTED_BACKGROUND = 'redesign-button-selected-background',
  REDESIGN_BUTTON_SELECTED_PRESSED_BACKGROUND = 'redesign-button-selected-pressed-background',
  REDESIGN_BUTTON_SELECTED_TEXT = 'redesign-button-selected-text',
  REDESIGN_BUTTON_TERTIARY_BACKGROUND = 'redesign-button-tertiary-background',
  REDESIGN_BUTTON_TERTIARY_PRESSED_BACKGROUND = 'redesign-button-tertiary-pressed-background',
  REDESIGN_BUTTON_TERTIARY_PRESSED_TEXT = 'redesign-button-tertiary-pressed-text',
  REDESIGN_BUTTON_TERTIARY_TEXT = 'redesign-button-tertiary-text',
  REDESIGN_CHANNEL_CATEGORY_NAME_TEXT = 'redesign-channel-category-name-text',
  REDESIGN_CHANNEL_MESSAGE_PREVIEW_TEXT = 'redesign-channel-message-preview-text',
  REDESIGN_CHANNEL_NAME_MUTED_TEXT = 'redesign-channel-name-muted-text',
  REDESIGN_CHANNEL_NAME_TEXT = 'redesign-channel-name-text',
  REDESIGN_CHAT_INPUT_BACKGROUND = 'redesign-chat-input-background',
  REDESIGN_IMAGE_BUTTON_PRESSED_BACKGROUND = 'redesign-image-button-pressed-background',
  REDESIGN_INPUT_CONTROL_ACTIVE_BG = 'redesign-input-control-active-bg',
  REDESIGN_INPUT_CONTROL_SELECTED = 'redesign-input-control-selected',
  REDESIGN_ONLY_BACKGROUND_ACTIVE = 'redesign-only-background-active',
  REDESIGN_ONLY_BACKGROUND_DEFAULT = 'redesign-only-background-default',
  REDESIGN_ONLY_BACKGROUND_OVERLAY = 'redesign-only-background-overlay',
  REDESIGN_ONLY_BACKGROUND_RAISED = 'redesign-only-background-raised',
  REDESIGN_ONLY_BACKGROUND_SUNKEN = 'redesign-only-background-sunken',
  SCROLLBAR_AUTO_SCROLLBAR_COLOR_THUMB = 'scrollbar-auto-scrollbar-color-thumb',
  SCROLLBAR_AUTO_SCROLLBAR_COLOR_TRACK = 'scrollbar-auto-scrollbar-color-track',
  SCROLLBAR_AUTO_THUMB = 'scrollbar-auto-thumb',
  SCROLLBAR_AUTO_TRACK = 'scrollbar-auto-track',
  SCROLLBAR_THIN_THUMB = 'scrollbar-thin-thumb',
  SCROLLBAR_THIN_TRACK = 'scrollbar-thin-track',
  SPOILER_HIDDEN_BACKGROUND = 'spoiler-hidden-background',
  SPOILER_REVEALED_BACKGROUND = 'spoiler-revealed-background',
  STAGE_CARD_PILL_BG = 'stage-card-pill-bg',
  STATUS_DANGER = 'status-danger',
  STATUS_DANGER_BACKGROUND = 'status-danger-background',
  STATUS_DANGER_TEXT = 'status-danger-text',
  STATUS_DND = 'status-dnd',
  STATUS_IDLE = 'status-idle',
  STATUS_OFFLINE = 'status-offline',
  STATUS_ONLINE = 'status-online',
  STATUS_POSITIVE = 'status-positive',
  STATUS_POSITIVE_BACKGROUND = 'status-positive-background',
  STATUS_POSITIVE_TEXT = 'status-positive-text',
  STATUS_SPEAKING = 'status-speaking',
  STATUS_WARNING = 'status-warning',
  STATUS_WARNING_BACKGROUND = 'status-warning-background',
  STATUS_WARNING_TEXT = 'status-warning-text',
  TEXT_BRAND = 'text-brand',
  TEXT_DANGER = 'text-danger',
  TEXT_LINK = 'text-link',
  TEXT_LINK_LOW_SATURATION = 'text-link-low-saturation',
  TEXT_LOW_CONTRAST = 'text-low-contrast',
  TEXT_MESSAGE_PREVIEW_LOW_SAT = 'text-message-preview-low-sat',
  TEXT_MUTED = 'text-muted',
  TEXT_MUTED_ON_DEFAULT = 'text-muted-on-default',
  TEXT_NORMAL = 'text-normal',
  TEXT_POSITIVE = 'text-positive',
  TEXT_PRIMARY = 'text-primary',
  TEXT_SECONDARY = 'text-secondary',
  TEXT_WARNING = 'text-warning',
  TEXTBOX_MARKDOWN_SYNTAX = 'textbox-markdown-syntax',
  THEME_LOCKED_BLUR_FALLBACK = 'theme-locked-blur-fallback',
  THREAD_CHANNEL_SPINE = 'thread-channel-spine',
  TOAST_BG = 'toast-bg',
  TYPING_INDICATOR_BG = 'typing-indicator-bg',
  USER_PROFILE_HEADER_OVERFLOW_BACKGROUND = 'user-profile-header-overflow-background',
  VOICE_VIDEO_VIDEO_TILE_BACKGROUND = 'voice-video-video-tile-background',
  VOICE_VIDEO_VIDEO_TILE_BLUR_FALLBACK = 'voice-video-video-tile-blur-fallback',
  WHITE = 'white',
  YOU_BAR_BG = 'you-bar-bg',
}

const themeColorMap = {
  [ThemeColor.ACTION_SHEET_GRADIENT_BG]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.ACTIVITY_CARD_BACKGROUND]: [Color.PRIMARY_700, Color.WHITE, Color.PRIMARY_700, Color.PRIMARY_700],
  [ThemeColor.ACTIVITY_CARD_ICON_OVERLAY]: [Color.PRIMARY_600, Color.PRIMARY_600, Color.PRIMARY_600, Color.PRIMARY_600],
  [ThemeColor.ALERT_BG]: [Color.PRIMARY_600, Color.WHITE, Color.BLACK, Color.PLUM_20],
  [ThemeColor.ANDROID_NAVIGATION_BAR_BACKGROUND]: [Color.PRIMARY_830, Color.PRIMARY_100, Color.PLUM_24, Color.PLUM_24],
  [ThemeColor.ANDROID_NAVIGATION_SCRIM_BACKGROUND]: [
    Color.PRIMARY_830,
    Color.PRIMARY_100,
    Color.PLUM_24,
    Color.PLUM_24,
  ],
  [ThemeColor.ANDROID_RIPPLE]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.AUTOCOMPLETE_BG]: [Color.PRIMARY_630, Color.PRIMARY_100, Color.PRIMARY_630, Color.PRIMARY_630],
  [ThemeColor.BACKGROUND_ACCENT]: [Color.PRIMARY_530, Color.PRIMARY_430, Color.PLUM_17, Color.PLUM_15],
  [ThemeColor.BACKGROUND_FLOATING]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_23, Color.PLUM_18],
  [ThemeColor.BACKGROUND_MENTIONED]: [Color.YELLOW_300, Color.YELLOW_300, Color.YELLOW_260, Color.YELLOW_260],
  [ThemeColor.BACKGROUND_MENTIONED_HOVER]: [Color.YELLOW_300, Color.YELLOW_300, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.BACKGROUND_MESSAGE_AUTOMOD]: [Color.RED_400, Color.RED_400, Color.RED_345, Color.RED_345],
  [ThemeColor.BACKGROUND_MESSAGE_AUTOMOD_HOVER]: [Color.RED_400, Color.RED_400, Color.RED_400, Color.RED_400],
  [ThemeColor.BACKGROUND_MESSAGE_HIGHLIGHT]: [Color.BRAND_360, Color.BRAND_360, Color.BRAND_360, Color.BRAND_360],
  [ThemeColor.BACKGROUND_MESSAGE_HIGHLIGHT_HOVER]: [Color.BRAND_360, Color.BRAND_360, Color.BRAND_360, Color.BRAND_360],
  [ThemeColor.BACKGROUND_MESSAGE_HOVER]: [Color.PRIMARY_900, Color.PRIMARY_900, Color.PLUM_21, Color.PLUM_21],
  [ThemeColor.BACKGROUND_MOBILE_PRIMARY]: [Color.PRIMARY_600, Color.PRIMARY_100, Color.BLACK, Color.PLUM_20],
  [ThemeColor.BACKGROUND_MOBILE_SECONDARY]: [Color.PRIMARY_630, Color.WHITE, Color.BLACK, Color.PLUM_20],
  [ThemeColor.BACKGROUND_MODIFIER_ACCENT]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_23_ALPHA, Color.PLUM_11],
  [ThemeColor.BACKGROUND_MODIFIER_ACCENT_2]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BACKGROUND_MODIFIER_ACTIVE]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.BACKGROUND_MODIFIER_HOVER]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.BACKGROUND_MODIFIER_SELECTED]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.BACKGROUND_NESTED_FLOATING]: [Color.PRIMARY_630, Color.WHITE, Color.BLACK, Color.PLUM_22],
  [ThemeColor.BACKGROUND_PRIMARY]: [Color.PRIMARY_600, Color.WHITE, Color.BLACK, Color.PLUM_20],
  [ThemeColor.BACKGROUND_SECONDARY]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.PLUM_23, Color.PLUM_18],
  [ThemeColor.BACKGROUND_SECONDARY_ALT]: [Color.PRIMARY_660, Color.PRIMARY_160, Color.PLUM_17, Color.PLUM_15],
  [ThemeColor.BACKGROUND_TERTIARY]: [Color.PRIMARY_700, Color.PRIMARY_200, Color.PLUM_19, Color.PLUM_16],
  [ThemeColor.BADGE_BRAND_BG]: [Color.BRAND_260, Color.BRAND_260, Color.BRAND_260, Color.BRAND_260],
  [ThemeColor.BADGE_BRAND_TEXT]: [Color.BRAND_560, Color.BRAND_560, Color.BRAND_560, Color.BRAND_560],
  [ThemeColor.BG_BACKDROP]: [Color.BLACK, Color.BLACK, Color.PLUM_19, Color.BLACK],
  [ThemeColor.BG_BACKDROP_NO_OPACITY]: [Color.BLACK, Color.BLACK, Color.PLUM_19, Color.BLACK],
  [ThemeColor.BG_BASE_PRIMARY]: [Color.PRIMARY_600, Color.WHITE, Color.BLACK, Color.PLUM_20],
  [ThemeColor.BG_BASE_SECONDARY]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.BG_BASE_TERTIARY]: [Color.PRIMARY_660, Color.PRIMARY_160, Color.BLACK, Color.PLUM_23],
  [ThemeColor.BG_BRAND]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.BG_MOD_FAINT]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BG_MOD_STRONG]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BG_MOD_SUBTLE]: [Color.PRIMARY_500, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BG_SURFACE_OVERLAY]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_19, Color.PLUM_17],
  [ThemeColor.BG_SURFACE_OVERLAY_TMP]: [Color.PRIMARY_800, Color.PRIMARY_130, Color.PLUM_21, Color.PLUM_17],
  [ThemeColor.BG_SURFACE_RAISED]: [Color.PRIMARY_600, Color.WHITE, Color.PLUM_23, Color.PLUM_18],
  [ThemeColor.BLACK]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.BLUR_FALLBACK]: [Color.PRIMARY_700, Color.PRIMARY_530, Color.PLUM_19, Color.PLUM_17],
  [ThemeColor.BLUR_FALLBACK_PRESSED]: [Color.PRIMARY_730, Color.PRIMARY_560, Color.PLUM_20, Color.PLUM_18],
  [ThemeColor.BORDER_FAINT]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BORDER_STRONG]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BORDER_SUBTLE]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.BUG_REPORTER_MODAL_SUBMITTING_BACKGROUND]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_23, Color.PLUM_23],
  [ThemeColor.BUTTON_CREATOR_REVENUE_BACKGROUND]: [Color.TEAL_430, Color.TEAL_430, Color.TEAL_430, Color.TEAL_430],
  [ThemeColor.BUTTON_DANGER_BACKGROUND]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.BUTTON_DANGER_BACKGROUND_ACTIVE]: [Color.RED_530, Color.RED_530, Color.RED_530, Color.RED_530],
  [ThemeColor.BUTTON_DANGER_BACKGROUND_DISABLED]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.BUTTON_DANGER_BACKGROUND_HOVER]: [Color.RED_500, Color.RED_500, Color.RED_500, Color.RED_500],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BACKGROUND_ACTIVE]: [
    Color.BRAND_560,
    Color.BRAND_560,
    Color.BRAND_560,
    Color.BRAND_560,
  ],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BACKGROUND_HOVER]: [
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
  ],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BORDER]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BORDER_ACTIVE]: [Color.BRAND_560, Color.BRAND_560, Color.BRAND_560, Color.BRAND_560],
  [ThemeColor.BUTTON_OUTLINE_BRAND_BORDER_HOVER]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.BUTTON_OUTLINE_BRAND_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_BRAND_TEXT_ACTIVE]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_BRAND_TEXT_HOVER]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BACKGROUND_ACTIVE]: [Color.RED_460, Color.RED_460, Color.RED_460, Color.RED_460],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BACKGROUND_HOVER]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BORDER]: [Color.RED_400, Color.RED_430, Color.RED_400, Color.RED_400],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BORDER_ACTIVE]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.BUTTON_OUTLINE_DANGER_BORDER_HOVER]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.BUTTON_OUTLINE_DANGER_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_DANGER_TEXT_ACTIVE]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_DANGER_TEXT_HOVER]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BACKGROUND_ACTIVE]: [
    Color.GREEN_530,
    Color.GREEN_530,
    Color.GREEN_530,
    Color.GREEN_530,
  ],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BACKGROUND_HOVER]: [
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
  ],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BORDER]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BORDER_ACTIVE]: [
    Color.GREEN_530,
    Color.GREEN_530,
    Color.GREEN_530,
    Color.GREEN_530,
  ],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_BORDER_HOVER]: [
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
  ],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_TEXT_ACTIVE]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_POSITIVE_TEXT_HOVER]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BACKGROUND_ACTIVE]: [
    Color.PRIMARY_430,
    Color.PRIMARY_600,
    Color.PLUM_11,
    Color.PLUM_11,
  ],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BACKGROUND_HOVER]: [
    Color.PRIMARY_500,
    Color.PRIMARY_530,
    Color.PLUM_13,
    Color.PLUM_13,
  ],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BORDER]: [
    Color.PRIMARY_500,
    Color.PRIMARY_530,
    Color.PRIMARY_500,
    Color.PRIMARY_500,
  ],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BORDER_ACTIVE]: [
    Color.PRIMARY_430,
    Color.PRIMARY_600,
    Color.PLUM_11,
    Color.PLUM_11,
  ],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_BORDER_HOVER]: [
    Color.PRIMARY_500,
    Color.PRIMARY_530,
    Color.PLUM_13,
    Color.PLUM_13,
  ],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_TEXT_ACTIVE]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_OUTLINE_PRIMARY_TEXT_HOVER]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.BUTTON_POSITIVE_BACKGROUND]: [Color.GREEN_430, Color.GREEN_430, Color.GREEN_430, Color.GREEN_430],
  [ThemeColor.BUTTON_POSITIVE_BACKGROUND_ACTIVE]: [Color.GREEN_530, Color.GREEN_530, Color.GREEN_530, Color.GREEN_530],
  [ThemeColor.BUTTON_POSITIVE_BACKGROUND_DISABLED]: [
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
  ],
  [ThemeColor.BUTTON_POSITIVE_BACKGROUND_HOVER]: [Color.GREEN_500, Color.GREEN_530, Color.GREEN_530, Color.GREEN_500],
  [ThemeColor.BUTTON_SECONDARY_BACKGROUND]: [Color.PRIMARY_500, Color.PRIMARY_430, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.BUTTON_SECONDARY_BACKGROUND_ACTIVE]: [Color.PRIMARY_400, Color.PRIMARY_530, Color.PLUM_10, Color.PLUM_10],
  [ThemeColor.BUTTON_SECONDARY_BACKGROUND_DISABLED]: [
    Color.PRIMARY_500,
    Color.PRIMARY_430,
    Color.PLUM_13,
    Color.PLUM_13,
  ],
  [ThemeColor.BUTTON_SECONDARY_BACKGROUND_HOVER]: [Color.PRIMARY_430, Color.PRIMARY_500, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.CARD_GRADIENT_BG]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.CARD_GRADIENT_PRESSED_BG]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.CARD_PRIMARY_BG]: [Color.PRIMARY_600, Color.WHITE, Color.PLUM_23, Color.PLUM_18],
  [ThemeColor.CARD_PRIMARY_PRESSED_BG]: [Color.PRIMARY_645, Color.PRIMARY_160, Color.PLUM_25, Color.PLUM_19],
  [ThemeColor.CARD_SECONDARY_BG]: [Color.PRIMARY_630, Color.WHITE, Color.PLUM_25, Color.PLUM_19],
  [ThemeColor.CARD_SECONDARY_PRESSED_BG]: [Color.PRIMARY_645, Color.PRIMARY_160, Color.PLUM_26, Color.PLUM_21],
  [ThemeColor.CHANNEL_ICON]: [Color.PRIMARY_400, Color.PRIMARY_430, Color.PLUM_10, Color.PLUM_10],
  [ThemeColor.CHANNEL_TEXT_AREA_PLACEHOLDER]: [Color.PRIMARY_430, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.CHANNELS_DEFAULT]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_9, Color.PLUM_9],
  [ThemeColor.CHANNELTEXTAREA_BACKGROUND]: [Color.PRIMARY_560, Color.PRIMARY_160, Color.PLUM_23, Color.PLUM_15],
  [ThemeColor.CHAT_BACKGROUND]: [Color.PRIMARY_600, Color.WHITE, Color.BLACK, Color.PLUM_16],
  [ThemeColor.CHAT_BANNER_BG]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.CHAT_BORDER]: [Color.PRIMARY_700, Color.PRIMARY_200, Color.PLUM_20, Color.PLUM_20],
  [ThemeColor.CHAT_INPUT_CONTAINER_BACKGROUND]: [Color.PRIMARY_600, Color.WHITE, Color.PLUM_16, Color.PLUM_16],
  [ThemeColor.CHAT_SWIPE_TO_REPLY_BACKGROUND]: [Color.PRIMARY_660, Color.PRIMARY_160, Color.PLUM_22, Color.PLUM_22],
  [ThemeColor.CHAT_SWIPE_TO_REPLY_GRADIENT_BACKGROUND]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.COACHMARK_BG]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_19, Color.PLUM_17],
  [ThemeColor.CONTENT_INVENTORY_MEDIA_SEEKBAR_CONTAINER]: [Color.PLUM_6, Color.PLUM_6, Color.PLUM_6, Color.PLUM_6],
  [ThemeColor.CONTENT_INVENTORY_OVERLAY_TEXT_PRIMARY]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.CONTENT_INVENTORY_OVERLAY_TEXT_SECONDARY]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.CONTENT_INVENTORY_OVERLAY_UI_MOD]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.CONTENT_INVENTORY_OVERLAY_UI_MOD_BG]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.CONTEXT_MENU_BACKDROP_BACKGROUND]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.CONTROL_BRAND_FOREGROUND]: [Color.BRAND_360, Color.BRAND_500, Color.BRAND_360, Color.BRAND_360],
  [ThemeColor.CONTROL_BRAND_FOREGROUND_NEW]: [Color.BRAND_360, Color.BRAND_500, Color.BRAND_360, Color.BRAND_360],
  [ThemeColor.CREATOR_REVENUE_ICON_GRADIENT_END]: [Color.TEAL_430, Color.TEAL_400, Color.TEAL_430, Color.TEAL_430],
  [ThemeColor.CREATOR_REVENUE_ICON_GRADIENT_START]: [Color.TEAL_360, Color.TEAL_345, Color.TEAL_360, Color.TEAL_360],
  [ThemeColor.CREATOR_REVENUE_INFO_BOX_BACKGROUND]: [Color.TEAL_430, Color.TEAL_430, Color.TEAL_430, Color.TEAL_430],
  [ThemeColor.CREATOR_REVENUE_INFO_BOX_BORDER]: [Color.TEAL_400, Color.TEAL_400, Color.TEAL_400, Color.TEAL_400],
  [ThemeColor.CREATOR_REVENUE_LOCKED_CHANNEL_ICON]: [Color.TEAL_345, Color.TEAL_400, Color.TEAL_345, Color.TEAL_345],
  [ThemeColor.CREATOR_REVENUE_PROGRESS_BAR]: [Color.TEAL_400, Color.TEAL_345, Color.TEAL_400, Color.TEAL_400],
  [ThemeColor.DEPRECATED_CARD_BG]: [Color.PRIMARY_700, Color.PRIMARY_100, Color.PRIMARY_700, Color.PRIMARY_700],
  [ThemeColor.DEPRECATED_CARD_EDITABLE_BG]: [
    Color.PRIMARY_700,
    Color.PRIMARY_100,
    Color.PRIMARY_700,
    Color.PRIMARY_700,
  ],
  [ThemeColor.DEPRECATED_QUICKSWITCHER_INPUT_BACKGROUND]: [
    Color.PRIMARY_400,
    Color.WHITE,
    Color.PRIMARY_400,
    Color.PRIMARY_400,
  ],
  [ThemeColor.DEPRECATED_QUICKSWITCHER_INPUT_PLACEHOLDER]: [Color.WHITE, Color.PRIMARY_500, Color.WHITE, Color.WHITE],
  [ThemeColor.DEPRECATED_STORE_BG]: [Color.PRIMARY_600, Color.PRIMARY_100, Color.PRIMARY_600, Color.PRIMARY_600],
  [ThemeColor.DEPRECATED_TEXT_INPUT_BG]: [Color.PRIMARY_700, Color.PRIMARY_200, Color.PRIMARY_700, Color.PRIMARY_700],
  [ThemeColor.DEPRECATED_TEXT_INPUT_BORDER]: [Color.BLACK, Color.PRIMARY_500, Color.BLACK, Color.BLACK],
  [ThemeColor.DEPRECATED_TEXT_INPUT_BORDER_DISABLED]: [
    Color.PRIMARY_700,
    Color.PRIMARY_200,
    Color.PRIMARY_700,
    Color.PRIMARY_700,
  ],
  [ThemeColor.DEPRECATED_TEXT_INPUT_BORDER_HOVER]: [
    Color.PRIMARY_900,
    Color.PRIMARY_300,
    Color.PRIMARY_900,
    Color.PRIMARY_900,
  ],
  [ThemeColor.DEPRECATED_TEXT_INPUT_PREFIX]: [
    Color.PRIMARY_200,
    Color.PRIMARY_300,
    Color.PRIMARY_200,
    Color.PRIMARY_200,
  ],
  [ThemeColor.DISPLAY_BANNER_OVERFLOW_BACKGROUND]: [Color.PRIMARY_700, Color.WHITE, Color.PLUM_20, Color.PLUM_20],
  [ThemeColor.DIVIDER_STRONG]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.DIVIDER_SUBTLE]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.EMBED_BACKGROUND]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.PLUM_23, Color.PLUM_18],
  [ThemeColor.EMBED_BACKGROUND_ALTERNATE]: [Color.PRIMARY_600, Color.PRIMARY_200, Color.PRIMARY_600, Color.PRIMARY_600],
  [ThemeColor.EMBED_TITLE]: [Color.PRIMARY_130, Color.PRIMARY_860, Color.PLUM_2, Color.PLUM_3],
  [ThemeColor.EXPRESSION_PICKER_BG]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.FOCUS_PRIMARY]: [Color.BLUE_345, Color.BLUE_360, Color.BLUE_345, Color.BLUE_345],
  [ThemeColor.FORUM_POST_EXTRA_MEDIA_COUNT_CONTAINER_BACKGROUND]: [
    Color.PRIMARY_660,
    Color.PRIMARY_160,
    Color.PLUM_19,
    Color.PLUM_19,
  ],
  [ThemeColor.FORUM_POST_TAG_BACKGROUND]: [Color.PRIMARY_660, Color.PRIMARY_160, Color.PLUM_19, Color.PLUM_19],
  [ThemeColor.GUILD_ICON_INACTIVE_BG]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.GUILD_ICON_INACTIVE_NESTED_BG]: [Color.PRIMARY_600, Color.WHITE, Color.BLACK, Color.PLUM_20],
  [ThemeColor.GUILD_NOTIFICATIONS_BOTTOM_SHEET_PILL_BACKGROUND]: [
    Color.PRIMARY_700,
    Color.WHITE,
    Color.PRIMARY_700,
    Color.PRIMARY_700,
  ],
  [ThemeColor.HALO_POSITIVE]: [Color.GREEN_360, Color.GREEN_360, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.HEADER_MUTED]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_10, Color.PLUM_10],
  [ThemeColor.HEADER_PRIMARY]: [Color.PRIMARY_130, Color.PRIMARY_860, Color.PLUM_2, Color.PLUM_3],
  [ThemeColor.HEADER_SECONDARY]: [Color.PRIMARY_330, Color.PRIMARY_500, Color.PLUM_8, Color.PLUM_9],
  [ThemeColor.HOME_BACKGROUND]: [Color.PRIMARY_645, Color.PRIMARY_100, Color.PLUM_18, Color.PLUM_18],
  [ThemeColor.HOME_CARD_RESTING_BORDER]: [Color.TRANSPARENT, Color.PRIMARY_400, Color.TRANSPARENT, Color.TRANSPARENT],
  [ThemeColor.ICON_MUTED]: [Color.PRIMARY_400, Color.PRIMARY_400, Color.PLUM_12, Color.PLUM_11],
  [ThemeColor.ICON_PRIMARY]: [Color.PRIMARY_130, Color.PRIMARY_860, Color.PLUM_5, Color.PLUM_4],
  [ThemeColor.ICON_SECONDARY]: [Color.PRIMARY_330, Color.PRIMARY_500, Color.PLUM_9, Color.PLUM_9],
  [ThemeColor.ICON_TRANSPARENT]: [Color.TRANSPARENT, Color.TRANSPARENT, Color.TRANSPARENT, Color.TRANSPARENT],
  [ThemeColor.INFO_BOX_BACKGROUND]: [Color.BLUE_345, Color.BLUE_345, Color.BLUE_345, Color.BLUE_345],
  [ThemeColor.INFO_DANGER_BACKGROUND]: [Color.RED_400, Color.RED_430, Color.RED_400, Color.RED_400],
  [ThemeColor.INFO_DANGER_FOREGROUND]: [Color.RED_400, Color.RED_430, Color.RED_400, Color.RED_400],
  [ThemeColor.INFO_DANGER_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.INFO_HELP_BACKGROUND]: [Color.BLUE_345, Color.BLUE_430, Color.BLUE_345, Color.BLUE_345],
  [ThemeColor.INFO_HELP_FOREGROUND]: [Color.BLUE_345, Color.BLUE_430, Color.BLUE_345, Color.BLUE_345],
  [ThemeColor.INFO_HELP_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.INFO_POSITIVE_BACKGROUND]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.INFO_POSITIVE_FOREGROUND]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.INFO_POSITIVE_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.INFO_WARNING_BACKGROUND]: [Color.YELLOW_300, Color.YELLOW_400, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.INFO_WARNING_FOREGROUND]: [Color.YELLOW_300, Color.YELLOW_400, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.INFO_WARNING_TEXT]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.INPUT_BACKGROUND]: [Color.PRIMARY_700, Color.BLACK, Color.PLUM_23_ALPHA, Color.PLUM_24],
  [ThemeColor.INPUT_FOCUSED_BORDER]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.INPUT_PLACEHOLDER_TEXT]: [
    Color.INPUT_PLACEHOLDER_TEXT_DARK,
    Color.PRIMARY_460,
    Color.PLUM_9,
    Color.PLUM_10,
  ],
  [ThemeColor.INTERACTIVE_ACTIVE]: [Color.WHITE, Color.PRIMARY_860, Color.PLUM_3, Color.PLUM_1],
  [ThemeColor.INTERACTIVE_HOVER]: [Color.PRIMARY_230, Color.PRIMARY_600, Color.PLUM_4, Color.PLUM_4],
  [ThemeColor.INTERACTIVE_MUTED]: [Color.PRIMARY_500, Color.PRIMARY_300, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.INTERACTIVE_NORMAL]: [Color.PRIMARY_330, Color.PRIMARY_500, Color.PLUM_6, Color.PLUM_6],
  [ThemeColor.LEGACY_ANDROID_BLUR_OVERLAY_DEFAULT]: [
    Color.PRIMARY_660,
    Color.PRIMARY_130,
    Color.PRIMARY_660,
    Color.PRIMARY_660,
  ],
  [ThemeColor.LEGACY_ANDROID_BLUR_OVERLAY_ULTRA_THIN]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.LEGACY_BLUR_FALLBACK_DEFAULT]: [
    Color.PRIMARY_660,
    Color.PRIMARY_230,
    Color.PRIMARY_660,
    Color.PRIMARY_660,
  ],
  [ThemeColor.LEGACY_BLUR_FALLBACK_ULTRA_THIN]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.LIVE_STAGE_TILE_BORDER]: [Color.PRIMARY_500, Color.PRIMARY_430, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.LOGO_PRIMARY]: [Color.WHITE, Color.BRAND_500, Color.WHITE, Color.WHITE],
  [ThemeColor.MENTION_BACKGROUND]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.MENTION_FOREGROUND]: [Color.BRAND_260, Color.BRAND_560, Color.BRAND_230, Color.BRAND_230],
  [ThemeColor.MENU_ITEM_DANGER_ACTIVE_BG]: [Color.RED_530, Color.RED_530, Color.RED_530, Color.RED_530],
  [ThemeColor.MENU_ITEM_DANGER_HOVER_BG]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.MENU_ITEM_DEFAULT_ACTIVE_BG]: [Color.BRAND_560, Color.BRAND_560, Color.BRAND_560, Color.BRAND_560],
  [ThemeColor.MENU_ITEM_DEFAULT_HOVER_BG]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.MODAL_BACKGROUND]: [Color.PRIMARY_600, Color.WHITE, Color.PLUM_16, Color.PLUM_16],
  [ThemeColor.MODAL_FOOTER_BACKGROUND]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.PLUM_17, Color.PLUM_17],
  [ThemeColor.NAVIGATOR_HEADER_TINT]: [Color.WHITE, Color.PRIMARY_500, Color.WHITE, Color.WHITE],
  [ThemeColor.PANEL_BG]: [Color.PRIMARY_600, Color.WHITE, Color.PLUM_24, Color.PLUM_20],
  [ThemeColor.POLLS_NORMAL_FILL_HOVER]: [Color.PRIMARY_600, Color.PRIMARY_230, Color.PRIMARY_600, Color.PRIMARY_600],
  [ThemeColor.POLLS_NORMAL_IMAGE_BACKGROUND]: [Color.PRIMARY_660, Color.WHITE, Color.PRIMARY_660, Color.PRIMARY_660],
  [ThemeColor.POLLS_VICTOR_FILL]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.POLLS_VOTED_FILL]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.PREMIUM_NITRO_PINK_TEXT]: [
    Color.PREMIUM_NITRO_PINK_LIGHT,
    Color.PREMIUM_NITRO_PINK_DARK,
    Color.PREMIUM_NITRO_PINK_LIGHT,
    Color.PREMIUM_NITRO_PINK_LIGHT,
  ],
  [ThemeColor.PROFILE_GRADIENT_CARD_BACKGROUND]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.PROFILE_GRADIENT_MESSAGE_INPUT_BORDER]: [
    Color.PRIMARY_500,
    Color.PRIMARY_430,
    Color.PRIMARY_500,
    Color.PRIMARY_500,
  ],
  [ThemeColor.PROFILE_GRADIENT_NOTE_BACKGROUND]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.PROFILE_GRADIENT_OVERLAY]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.PROFILE_GRADIENT_OVERLAY_SYNCED_WITH_USER_THEME]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.PROFILE_GRADIENT_PROFILE_BODY_BACKGROUND_HOVER]: [Color.WHITE, Color.BLACK, Color.WHITE, Color.WHITE],
  [ThemeColor.PROFILE_GRADIENT_ROLE_PILL_BACKGROUND]: [
    Color.PRIMARY_660,
    Color.WHITE,
    Color.PRIMARY_660,
    Color.PRIMARY_660,
  ],
  [ThemeColor.PROFILE_GRADIENT_ROLE_PILL_BORDER]: [Color.WHITE, Color.PRIMARY_660, Color.WHITE, Color.WHITE],
  [ThemeColor.PROFILE_GRADIENT_SECTION_BOX]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.REDESIGN_ACTIVITY_CARD_BACKGROUND]: [
    Color.PRIMARY_560,
    Color.WHITE,
    Color.PRIMARY_560,
    Color.PRIMARY_560,
  ],
  [ThemeColor.REDESIGN_ACTIVITY_CARD_BACKGROUND_PRESSED]: [
    Color.PRIMARY_630,
    Color.PRIMARY_160,
    Color.PRIMARY_630,
    Color.PRIMARY_630,
  ],
  [ThemeColor.REDESIGN_ACTIVITY_CARD_BADGE_ICON]: [Color.PRIMARY_360, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_10],
  [ThemeColor.REDESIGN_ACTIVITY_CARD_BORDER]: [Color.WHITE, Color.PRIMARY_400, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.REDESIGN_ACTIVITY_CARD_OVERFLOW_BACKGROUND]: [
    Color.PRIMARY_630,
    Color.PRIMARY_230,
    Color.PLUM_17,
    Color.PLUM_17,
  ],
  [ThemeColor.REDESIGN_BUTTON_ACTIVE_BACKGROUND]: [Color.GREEN_430, Color.GREEN_430, Color.GREEN_430, Color.GREEN_430],
  [ThemeColor.REDESIGN_BUTTON_ACTIVE_PRESSED_BACKGROUND]: [
    Color.GREEN_460,
    Color.GREEN_460,
    Color.GREEN_460,
    Color.GREEN_460,
  ],
  [ThemeColor.REDESIGN_BUTTON_ACTIVE_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_DANGER_BACKGROUND]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.REDESIGN_BUTTON_DANGER_PRESSED_BACKGROUND]: [Color.RED_460, Color.RED_460, Color.RED_460, Color.RED_460],
  [ThemeColor.REDESIGN_BUTTON_DANGER_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_DESTRUCTIVE_BACKGROUND]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.REDESIGN_BUTTON_DESTRUCTIVE_PRESSED_BACKGROUND]: [
    Color.RED_460,
    Color.RED_460,
    Color.RED_460,
    Color.RED_460,
  ],
  [ThemeColor.REDESIGN_BUTTON_DESTRUCTIVE_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_ALPHA_BACKGROUND]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_ALPHA_PRESSED_BACKGROUND]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_ALPHA_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_PRESSED_BACKGROUND]: [
    Color.PRIMARY_230,
    Color.PRIMARY_230,
    Color.PRIMARY_230,
    Color.PRIMARY_230,
  ],
  [ThemeColor.REDESIGN_BUTTON_OVERLAY_TEXT]: [
    Color.PRIMARY_860,
    Color.PRIMARY_860,
    Color.PRIMARY_860,
    Color.PRIMARY_860,
  ],
  [ThemeColor.REDESIGN_BUTTON_POSITIVE_BACKGROUND]: [
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
    Color.GREEN_430,
  ],
  [ThemeColor.REDESIGN_BUTTON_POSITIVE_PRESSED_BACKGROUND]: [
    Color.GREEN_460,
    Color.GREEN_460,
    Color.GREEN_460,
    Color.GREEN_460,
  ],
  [ThemeColor.REDESIGN_BUTTON_POSITIVE_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_BACKGROUND]: [
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_BORDER]: [Color.BRAND_360, Color.BRAND_500, Color.BRAND_400, Color.BRAND_360],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BACKGROUND]: [
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BORDER]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BACKGROUND]: [
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BORDER]: [
    Color.BRAND_360,
    Color.BRAND_360,
    Color.BRAND_360,
    Color.BRAND_360,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BACKGROUND]: [
    Color.BRAND_700,
    Color.BRAND_500,
    Color.BRAND_700,
    Color.BRAND_700,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BORDER]: [
    Color.BRAND_400,
    Color.BRAND_330,
    Color.BRAND_400,
    Color.BRAND_400,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_TEXT]: [
    Color.BRAND_360,
    Color.BRAND_560,
    Color.BRAND_360,
    Color.BRAND_360,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ALT_TEXT]: [Color.BRAND_360, Color.BRAND_500, Color.BRAND_400, Color.BRAND_360],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_BACKGROUND]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_ON_BLURPLE_PRESSED_TEXT]: [
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
    Color.BRAND_530,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_OVERLAY_BACKGROUND]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_OVERLAY_PRESSED_BACKGROUND]: [
    Color.PRIMARY_230,
    Color.PRIMARY_230,
    Color.PLUM_4,
    Color.PLUM_4,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_OVERLAY_TEXT]: [
    Color.PRIMARY_860,
    Color.PRIMARY_860,
    Color.PLUM_25,
    Color.PLUM_25,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_PRESSED_BACKGROUND]: [
    Color.BRAND_560,
    Color.BRAND_560,
    Color.BRAND_560,
    Color.BRAND_560,
  ],
  [ThemeColor.REDESIGN_BUTTON_PRIMARY_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_BACKGROUND]: [Color.PRIMARY_460, Color.WHITE, Color.PLUM_17, Color.PLUM_15],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_BORDER]: [Color.WHITE, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_OVERLAY_BACKGROUND]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_OVERLAY_PRESSED_BACKGROUND]: [
    Color.BLACK,
    Color.BLACK,
    Color.BLACK,
    Color.BLACK,
  ],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_OVERLAY_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_PRESSED_BACKGROUND]: [
    Color.PRIMARY_500,
    Color.PRIMARY_130,
    Color.PLUM_15,
    Color.PLUM_13,
  ],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_PRESSED_BORDER]: [Color.TRANSPARENT, Color.BLACK, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.REDESIGN_BUTTON_SECONDARY_TEXT]: [Color.PRIMARY_230, Color.PRIMARY_600, Color.PLUM_4, Color.PLUM_6],
  [ThemeColor.REDESIGN_BUTTON_SELECTED_BACKGROUND]: [
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
  ],
  [ThemeColor.REDESIGN_BUTTON_SELECTED_PRESSED_BACKGROUND]: [
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
    Color.BRAND_500,
  ],
  [ThemeColor.REDESIGN_BUTTON_SELECTED_TEXT]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.REDESIGN_BUTTON_TERTIARY_BACKGROUND]: [
    Color.PRIMARY_660,
    Color.BLACK,
    Color.PLUM_23_ALPHA,
    Color.PLUM_11,
  ],
  [ThemeColor.REDESIGN_BUTTON_TERTIARY_PRESSED_BACKGROUND]: [
    Color.PRIMARY_560,
    Color.BLACK,
    Color.PLUM_11,
    Color.PLUM_11,
  ],
  [ThemeColor.REDESIGN_BUTTON_TERTIARY_PRESSED_TEXT]: [
    Color.PRIMARY_330,
    Color.PRIMARY_500,
    Color.PLUM_6,
    Color.PLUM_5,
  ],
  [ThemeColor.REDESIGN_BUTTON_TERTIARY_TEXT]: [Color.PRIMARY_230, Color.PRIMARY_600, Color.PLUM_4, Color.PLUM_6],
  [ThemeColor.REDESIGN_CHANNEL_CATEGORY_NAME_TEXT]: [Color.PRIMARY_330, Color.PRIMARY_500, Color.PLUM_8, Color.PLUM_9],
  [ThemeColor.REDESIGN_CHANNEL_MESSAGE_PREVIEW_TEXT]: [
    Color.PRIMARY_360,
    Color.PRIMARY_460,
    Color.PLUM_10,
    Color.PLUM_10,
  ],
  [ThemeColor.REDESIGN_CHANNEL_NAME_MUTED_TEXT]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_10, Color.PLUM_10],
  [ThemeColor.REDESIGN_CHANNEL_NAME_TEXT]: [Color.PRIMARY_130, Color.PRIMARY_860, Color.PLUM_2, Color.PLUM_3],
  [ThemeColor.REDESIGN_CHAT_INPUT_BACKGROUND]: [Color.PRIMARY_700, Color.BLACK, Color.PLUM_23_ALPHA, Color.PLUM_11],
  [ThemeColor.REDESIGN_IMAGE_BUTTON_PRESSED_BACKGROUND]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.REDESIGN_INPUT_CONTROL_ACTIVE_BG]: [Color.PRIMARY_645, Color.WHITE, Color.PLUM_17, Color.PLUM_16],
  [ThemeColor.REDESIGN_INPUT_CONTROL_SELECTED]: [Color.BRAND_500, Color.BRAND_500, Color.BRAND_500, Color.BRAND_500],
  [ThemeColor.REDESIGN_ONLY_BACKGROUND_ACTIVE]: [
    Color.PRIMARY_530,
    Color.PRIMARY_430,
    Color.PRIMARY_530,
    Color.PRIMARY_530,
  ],
  [ThemeColor.REDESIGN_ONLY_BACKGROUND_DEFAULT]: [Color.PRIMARY_600, Color.WHITE, Color.PRIMARY_600, Color.PRIMARY_600],
  [ThemeColor.REDESIGN_ONLY_BACKGROUND_OVERLAY]: [
    Color.PRIMARY_645,
    Color.PRIMARY_160,
    Color.PRIMARY_645,
    Color.PRIMARY_645,
  ],
  [ThemeColor.REDESIGN_ONLY_BACKGROUND_RAISED]: [
    Color.PRIMARY_630,
    Color.PRIMARY_130,
    Color.PRIMARY_630,
    Color.PRIMARY_630,
  ],
  [ThemeColor.REDESIGN_ONLY_BACKGROUND_SUNKEN]: [
    Color.PRIMARY_660,
    Color.PRIMARY_200,
    Color.PRIMARY_660,
    Color.PRIMARY_660,
  ],
  [ThemeColor.SCROLLBAR_AUTO_SCROLLBAR_COLOR_THUMB]: [
    Color.PRIMARY_730,
    Color.PRIMARY_230,
    Color.PLUM_20,
    Color.PLUM_21,
  ],
  [ThemeColor.SCROLLBAR_AUTO_SCROLLBAR_COLOR_TRACK]: [
    Color.PRIMARY_630,
    Color.PRIMARY_130,
    Color.PLUM_17,
    Color.PLUM_17,
  ],
  [ThemeColor.SCROLLBAR_AUTO_THUMB]: [Color.PRIMARY_730, Color.PRIMARY_300, Color.PLUM_16, Color.PLUM_21],
  [ThemeColor.SCROLLBAR_AUTO_TRACK]: [Color.PRIMARY_630, Color.PRIMARY_160, Color.PLUM_25, Color.PLUM_19],
  [ThemeColor.SCROLLBAR_THIN_THUMB]: [Color.PRIMARY_730, Color.PRIMARY_500, Color.PLUM_20, Color.PLUM_21],
  [ThemeColor.SCROLLBAR_THIN_TRACK]: [Color.BLACK, Color.BLACK, Color.BLACK, Color.BLACK],
  [ThemeColor.SPOILER_HIDDEN_BACKGROUND]: [Color.PRIMARY_700, Color.PRIMARY_200, Color.PLUM_14, Color.PLUM_14],
  [ThemeColor.SPOILER_REVEALED_BACKGROUND]: [Color.PRIMARY_660, Color.PRIMARY_160, Color.PLUM_11, Color.PLUM_11],
  [ThemeColor.STAGE_CARD_PILL_BG]: [Color.PRIMARY_630, Color.WHITE, Color.PLUM_25, Color.PLUM_19],
  [ThemeColor.STATUS_DANGER]: [Color.RED_400, Color.RED_430, Color.RED_400, Color.RED_400],
  [ThemeColor.STATUS_DANGER_BACKGROUND]: [Color.RED_430, Color.RED_430, Color.RED_430, Color.RED_430],
  [ThemeColor.STATUS_DANGER_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.STATUS_DND]: [Color.RED_400, Color.RED_430, Color.RED_400, Color.RED_400],
  [ThemeColor.STATUS_IDLE]: [Color.YELLOW_300, Color.YELLOW_400, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.STATUS_OFFLINE]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_9, Color.PLUM_9],
  [ThemeColor.STATUS_ONLINE]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.STATUS_POSITIVE]: [Color.GREEN_360, Color.GREEN_400, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.STATUS_POSITIVE_BACKGROUND]: [Color.GREEN_430, Color.GREEN_430, Color.GREEN_430, Color.GREEN_430],
  [ThemeColor.STATUS_POSITIVE_TEXT]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.STATUS_SPEAKING]: [Color.GREEN_360, Color.GREEN_360, Color.GREEN_360, Color.GREEN_360],
  [ThemeColor.STATUS_WARNING]: [Color.YELLOW_300, Color.YELLOW_400, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.STATUS_WARNING_BACKGROUND]: [Color.YELLOW_300, Color.YELLOW_400, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.STATUS_WARNING_TEXT]: [Color.BLACK, Color.WHITE, Color.BLACK, Color.BLACK],
  [ThemeColor.TEXT_BRAND]: [Color.BRAND_360, Color.BRAND_500, Color.BRAND_400, Color.BRAND_360],
  [ThemeColor.TEXT_DANGER]: [Color.RED_345, Color.RED_500, Color.RED_345, Color.RED_345],
  [ThemeColor.TEXT_LINK]: [Color.BLUE_345, Color.BLUE_430, Color.BLUE_345, Color.BLUE_345],
  [ThemeColor.TEXT_LINK_LOW_SATURATION]: [Color.BLUE_330, Color.BLUE_430, Color.BLUE_330, Color.BLUE_330],
  [ThemeColor.TEXT_LOW_CONTRAST]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_12, Color.PLUM_11],
  [ThemeColor.TEXT_MESSAGE_PREVIEW_LOW_SAT]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_9, Color.PLUM_11],
  [ThemeColor.TEXT_MUTED]: [Color.PRIMARY_360, Color.PRIMARY_460, Color.PLUM_10, Color.PLUM_10],
  [ThemeColor.TEXT_MUTED_ON_DEFAULT]: [Color.PRIMARY_330, Color.PRIMARY_430, Color.PRIMARY_330, Color.PRIMARY_330],
  [ThemeColor.TEXT_NORMAL]: [Color.PRIMARY_230, Color.PRIMARY_600, Color.PLUM_4, Color.PLUM_6],
  [ThemeColor.TEXT_POSITIVE]: [Color.GREEN_330, Color.GREEN_430, Color.GREEN_330, Color.GREEN_330],
  [ThemeColor.TEXT_PRIMARY]: [Color.PRIMARY_230, Color.PRIMARY_600, Color.PLUM_4, Color.PLUM_6],
  [ThemeColor.TEXT_SECONDARY]: [Color.PRIMARY_330, Color.PRIMARY_500, Color.PLUM_8, Color.PLUM_9],
  [ThemeColor.TEXT_WARNING]: [Color.YELLOW_300, Color.YELLOW_430, Color.YELLOW_300, Color.YELLOW_300],
  [ThemeColor.TEXTBOX_MARKDOWN_SYNTAX]: [Color.PRIMARY_360, Color.PRIMARY_530, Color.PLUM_9, Color.PLUM_9],
  [ThemeColor.THEME_LOCKED_BLUR_FALLBACK]: [Color.PRIMARY_700, Color.PRIMARY_700, Color.PRIMARY_700, Color.PRIMARY_700],
  [ThemeColor.THREAD_CHANNEL_SPINE]: [Color.PRIMARY_500, Color.PRIMARY_300, Color.PLUM_13, Color.PLUM_13],
  [ThemeColor.TOAST_BG]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_19, Color.PLUM_17],
  [ThemeColor.TYPING_INDICATOR_BG]: [Color.PRIMARY_630, Color.PRIMARY_130, Color.BLACK, Color.PLUM_20],
  [ThemeColor.USER_PROFILE_HEADER_OVERFLOW_BACKGROUND]: [Color.PRIMARY_700, Color.WHITE, Color.PLUM_20, Color.PLUM_20],
  [ThemeColor.VOICE_VIDEO_VIDEO_TILE_BACKGROUND]: [Color.PLUM_17, Color.PLUM_17, Color.PLUM_17, Color.PLUM_17],
  [ThemeColor.VOICE_VIDEO_VIDEO_TILE_BLUR_FALLBACK]: [
    Color.PRIMARY_700,
    Color.PRIMARY_700,
    Color.PRIMARY_700,
    Color.PRIMARY_700,
  ],
  [ThemeColor.WHITE]: [Color.WHITE, Color.WHITE, Color.WHITE, Color.WHITE],
  [ThemeColor.YOU_BAR_BG]: [Color.PRIMARY_800, Color.WHITE, Color.PLUM_19, Color.PLUM_17],
} as const;

export default Object.freeze(themeColorMap);
