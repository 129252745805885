import * as React from 'react';

import TrackedLink from './TrackedLink';

import {Routes} from '@developers/Constants';
import styles from './AppLogo.module.css';

interface Props {
  color?: string;
}

const AppLogo = ({color = 'var(--bg-brand)'}: Props) => {
  return (
    <TrackedLink className={styles.wrapper} to={Routes.APPLICATIONS} from="nav">
      <svg width="68" height="32" viewBox="0 0 68 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.0366 7.0733C23.2519 6.26791 21.3382 5.67453 19.3373 5.33468C19.3008 5.32812 19.2644 5.34451 19.2456 5.37729C18.9995 5.80784 18.7269 6.36953 18.536 6.81101C16.3838 6.49412 14.2428 6.49412 12.1347 6.81101C11.9438 6.35972 11.6613 5.80784 11.414 5.37729C11.3953 5.3456 11.3589 5.32921 11.3224 5.33468C9.3226 5.67344 7.40886 6.26682 5.62313 7.0733C5.60767 7.07985 5.59442 7.09079 5.58563 7.10499C1.95566 12.4389 0.961265 17.6416 1.44908 22.7799C1.45129 22.805 1.46564 22.8291 1.48551 22.8444C3.88045 24.5742 6.20037 25.6244 8.4772 26.3205C8.51364 26.3314 8.55225 26.3183 8.57544 26.2888C9.11402 25.5654 9.59412 24.8027 10.0058 24.0005C10.0301 23.9536 10.0069 23.8978 9.95722 23.8792C9.19569 23.5951 8.47057 23.2487 7.77306 22.8553C7.71789 22.8236 7.71347 22.746 7.76423 22.7088C7.91101 22.6007 8.05783 22.4881 8.19799 22.3745C8.22335 22.3537 8.25868 22.3493 8.2885 22.3624C12.8709 24.4202 17.8318 24.4202 22.3602 22.3624C22.39 22.3482 22.4253 22.3526 22.4518 22.3734C22.592 22.487 22.7387 22.6007 22.8866 22.7088C22.9374 22.746 22.9341 22.8236 22.8789 22.8553C22.1814 23.2563 21.4563 23.5951 20.6936 23.8781C20.644 23.8967 20.6219 23.9536 20.6462 24.0005C21.0667 24.8015 21.5468 25.5643 22.0754 26.2877C22.0975 26.3183 22.1372 26.3314 22.1737 26.3205C24.4615 25.6244 26.7814 24.5742 29.1764 22.8444C29.1974 22.8291 29.2106 22.8061 29.2128 22.781C29.7966 16.8406 28.2349 11.6805 25.073 7.10607C25.0652 7.09079 25.052 7.07985 25.0366 7.0733ZM10.6901 19.6512C9.31046 19.6512 8.1737 18.4055 8.1737 16.8756C8.1737 15.3457 9.28841 14.0999 10.6901 14.0999C12.1027 14.0999 13.2285 15.3566 13.2064 16.8756C13.2064 18.4055 12.0917 19.6512 10.6901 19.6512ZM19.9939 19.6512C18.6143 19.6512 17.4776 18.4055 17.4776 16.8756C17.4776 15.3457 18.5923 14.0999 19.9939 14.0999C21.4066 14.0999 22.5323 15.3566 22.5103 16.8756C22.5103 18.4055 21.4066 19.6512 19.9939 19.6512Z"
          fill={color}
        />
        <path
          d="M33.316 22.264C34.504 22.264 35.392 21.532 35.392 20.608C35.392 19.684 34.504 18.964 33.316 18.964C32.116 18.964 31.24 19.684 31.24 20.608C31.24 21.532 32.116 22.264 33.316 22.264ZM42.2772 16.696C41.8332 15.244 40.9092 14.572 39.3732 14.572C37.2252 14.572 35.8812 16.252 35.8812 18.4C35.8812 20.656 37.0932 22.264 39.1812 22.264C40.7532 22.264 41.8092 21.628 42.2772 20.14V22H46.0212V13.36H42.2772V16.696ZM41.0292 19.66C40.2612 19.66 39.7572 19.168 39.7572 18.412C39.7572 17.668 40.2612 17.188 41.0412 17.188C41.8212 17.188 42.2892 17.668 42.2892 18.4C42.2892 19.144 41.8092 19.66 41.0292 19.66ZM55.7548 18.292C55.7548 16.084 54.4348 14.572 51.4108 14.572C48.4828 14.572 46.7788 16.132 46.7788 18.34C46.7788 20.656 48.6268 22.264 51.8668 22.264C53.3668 22.264 54.7108 21.904 55.5148 21.436V19.168C54.8428 19.54 54.0268 19.792 52.9468 19.792C51.5788 19.792 50.7868 19.468 50.4148 18.94H55.7548V18.292ZM51.6028 16.816C52.3708 16.816 52.8028 17.152 52.8988 17.692H50.2828C50.3908 17.152 50.8468 16.816 51.6028 16.816ZM60.7351 18.52L59.3911 14.896H55.4671L58.7671 22H62.6791L65.9791 14.896H62.0671L60.7351 18.52Z"
          fill="currentColor"
        />
      </svg>
    </TrackedLink>
  );
};

export default AppLogo;
