import * as React from 'react';
import classNames from 'classnames';
import {GridSquareIcon} from '@discordapp/design/components/Icon/generated/GridSquareIcon.web';
import {Text} from '@discordapp/design/components/Text/Text.web';

import Clickable from '@developers/uikit/Clickable';
import Select from '@developers/uikit/Select';

import {CardGridSortOrder, CardGridTypes} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './CardGrid.module.css';

export const CardGridContext = React.createContext({
  cardType: CardGridTypes.SMALL_CARD,
  sortOrder: CardGridSortOrder.DATE_CREATED,
});

const CardGridOptionTile = ({
  icon,
  text,
  isActive,
  handleTileClick,
}: {
  icon: React.ReactNode;
  text: React.ReactNode;
  isActive: boolean;
  handleTileClick: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <Clickable
      onClick={handleTileClick}
      className={classNames(styles.cardOptionTile, {[styles.cardOptionTileSelected]: isActive})}>
      {icon}
      <Text variant="text-sm/medium">{text}</Text>
    </Clickable>
  );
};

export const CardGridContainer = ({children}: {children: React.ReactNode}) => {
  const [activeTile, setActiveTile] = React.useState<CardGridTypes>(CardGridTypes.SMALL_CARD);
  const [sortBy, setSortBy] = React.useState<CardGridSortOrder>(CardGridSortOrder.DATE_CREATED);

  const handleSortOrderChange = ({value}: {value: CardGridSortOrder}) => {
    setSortBy(value);
  };

  return (
    <CardGridContext.Provider value={{cardType: activeTile, sortOrder: sortBy}}>
      <div className={styles.optionBar}>
        <div className={styles.sortBySelectWrapper}>
          <Text variant="text-md/medium" className={styles.sortBySelectLabel}>
            {Messages.Common.SORT_BY}
          </Text>
          <Select
            className={styles.sortBySelect}
            value={sortBy}
            options={[
              {label: Messages.Common.DATE_CREATED, value: CardGridSortOrder.DATE_CREATED},
              {label: Messages.Common.A_THROUGH_Z, value: CardGridSortOrder.ALPHABETICAL},
            ]}
            onChange={handleSortOrderChange}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className={styles.cardOptionTiles}>
          <CardGridOptionTile
            isActive={activeTile === CardGridTypes.SMALL_CARD}
            icon={<GridSquareIcon />}
            text={Messages.Common.SMALL}
            handleTileClick={() => setActiveTile(CardGridTypes.SMALL_CARD)}
          />
          <CardGridOptionTile
            isActive={activeTile === CardGridTypes.LARGE_CARD}
            icon={<GridSquareIcon color="currentColor" />}
            text={Messages.Common.LARGE}
            handleTileClick={() => setActiveTile(CardGridTypes.LARGE_CARD)}
          />
        </div>
      </div>
      {children}
    </CardGridContext.Provider>
  );
};

const CardGrid = ({children, type = CardGridTypes.SMALL_CARD}: {children: React.ReactNode; type: CardGridTypes}) => {
  return (
    <div
      className={classNames({
        [styles.cardGridSmall]: type === CardGridTypes.SMALL_CARD,
        [styles.cardGridLarge]: type === CardGridTypes.LARGE_CARD,
      })}>
      {children}
    </div>
  );
};

export default CardGrid;
