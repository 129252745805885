import {HTTPResponse, get, post, del, patch, stringifyErrors, V8APIError} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import UserStore from '@developers/stores/UserStore';
import {getFileExtension} from '@developers/utils/AssetUtils';
import {APIErrorHandler} from '@developers/utils/ErrorUtils';
import ActionTypes from './ActionTypes';

import {Endpoints} from '@developers/Constants';
import type {
  ApplicationId,
  ApplicationEmbeddedActivityConfig,
  ApplicationProxyMapping,
  Application,
  AssetId,
  RequestAdditionalIntents,
  Asset,
  ApplicationDirectoryEntry,
  EmojiId,
  Emoji,
} from '@developers/flow/Server';

export const createApplication = (formData: Record<string, unknown>): Promise<Application> => {
  return post({
    url: Endpoints.APPLICATIONS,
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const application = res.body;
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_CREATE_SUCCESS, application});
      return application;
    })
    .catch(APIErrorHandler);
};

export const createBot = async (appId: ApplicationId): Promise<Application> => {
  const createBotResponse = await post({
    url: Endpoints.APPLICATION_BOT(appId),
    oldFormErrors: true,
  }).catch(APIErrorHandler);

  const application = (await fetchApplication(appId).catch(APIErrorHandler)) as Application;

  if (application.bot != null && createBotResponse?.body.token != null) {
    application.bot.token = createBotResponse.body.token;
  }

  return application;
};

export const addUserToApplicationWhitelist = (
  appId: ApplicationId,
  userData: {
    username: string;
    discriminator: string;
  },
): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_WHITELIST(appId),
    body: userData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_ADD_USER_SUCCESS,
        appId,
        whitelistEntry: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const createAsset = (
  appId: ApplicationId,
  assetData: {
    name: string;
    image: string;
    type: string;
  },
): Promise<Asset | void> => {
  return post({
    url: Endpoints.APPLICATION_ASSETS(appId),
    body: assetData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse<Asset>) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_ADD_SUCCESS,
        appId,
        asset: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const deleteApplication = (appId: ApplicationId): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_DELETE(appId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_DELETE_SUCCESS,
        appId,
      });
    })
    .catch(APIErrorHandler);
};

export const deleteAsset = (appId: ApplicationId, assetId: AssetId): Promise<void> => {
  return del({
    url: Endpoints.APPLICATION_ASSET(appId, assetId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_REMOVE_SUCCESS,
        appId,
        assetId,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplication = (appId: ApplicationId): Promise<Application> => {
  return get({
    url: Endpoints.APPLICATION(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const application: Application = res.body;
      // HACK(shira): ApplicationStore currently merges API results into the existing store models as if they were
      // partials. This means any key that is conditionally serialized will not replace the store's existing value for
      // that key. So for now, lets explicitly set omitted keys to undefined to force the merge.
      application.install_params = application.install_params;
      application.custom_install_url = application.custom_install_url;
      application.event_webhooks_url = application.event_webhooks_url;
      application.event_webhooks_types = application.event_webhooks_types;
      application.event_webhooks_status = application.event_webhooks_status;
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_FETCH_SUCCESS, application});

      return res.body;
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationAssets = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.APPLICATION_ASSETS(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_ASSETS_FETCH_SUCCESS,
        appId,
        assets: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationDiscoverabilityState = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.APPLICATION_DISCOVERABILITY_STATE(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS,
        appId,
        badCommands: res.body.bad_commands,
        discoverabilityState: res.body.discoverability_state,
        discoveryEligibilityFlags: res.body.discovery_eligibility_flags,
      });
    })
    .catch(APIErrorHandler);
};

export const fetchApplications = (options?: {withTeamApplications: boolean}): Promise<void> => {
  return get({
    url: Endpoints.APPLICATIONS,
    query: {
      with_team_applications: options != null && options.withTeamApplications,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({type: ActionTypes.APPLICATIONS_FETCH_SUCCESS, applications: res.body});
    })
    .catch(APIErrorHandler);
};

export const fetchApplicationDirectoryEntry = (
  appId: ApplicationId,
  withLocalizations?: boolean,
): Promise<HTTPResponse<ApplicationDirectoryEntry>> => {
  let url = Endpoints.APPLICATION_DIRECTORY_ENTRY(appId);

  if (withLocalizations) {
    url += '?with_localizations=true';
  }

  return get({
    url,
    oldFormErrors: true,
  }).catch(APIErrorHandler);
};

export const fetchApplicationWhitelist = (appId: ApplicationId): Promise<unknown> => {
  return get({
    url: Endpoints.APPLICATION_WHITELIST(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_FETCH_SUCCESS,
        appId,
        whitelist: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const generateSecret = (appId: ApplicationId): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_RESET(appId),
    oldFormErrors: true,
  })
    .then(({body}) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_RESET_SECRET_SUCCESS,
        appId,
        secret: body.secret,
      });
    })
    .catch(APIErrorHandler);
};

export const generateToken = (appId: ApplicationId): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_BOT_RESET(appId),
    oldFormErrors: true,
  })
    .then(({body}) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_RESET_BOT_TOKEN_SUCCESS,
        appId,
        token: body.token,
      });
    })
    .catch(APIErrorHandler);
};

export const removeUserFromApplicationWhitelist = (appId: ApplicationId, userId: string): Promise<void> => {
  return del({
    url: Endpoints.APPLICATION_WHITELIST_USER(appId, userId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_WHITELIST_REMOVE_USER_SUCCESS,
        appId,
        userId,
      });
    })
    .catch(APIErrorHandler);
};

type UpdateApplicationArgs = Partial<
  Pick<
    Application,
    | 'name'
    | 'redirect_uris'
    | 'rpc_origins'
    | 'terms_of_service_url'
    | 'privacy_policy_url'
    | 'guild_id'
    | 'discoverability_state'
    | 'integration_public'
    | 'integration_require_code_grant'
    | 'monetization_state'
    | 'description'
    | 'custom_install_url'
    | 'role_connections_verification_url'
    | 'install_params'
    | 'integration_types_config'
    | 'flags'
    | 'icon'
    | 'cover_image'
    | 'interactions_endpoint_url'
    | 'interactions_version'
    | 'interactions_event_types'
    | 'event_webhooks_url'
    | 'event_webhooks_status'
    | 'event_webhooks_types'
    | 'tags'
  >
> & {
  developer_ids?: string[];
  publisher_ids?: string[];
};
export const updateApplication = (appId: ApplicationId, body: UpdateApplicationArgs): Promise<Application> => {
  return patch({
    url: Endpoints.APPLICATION(appId),
    body,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const transferAppOwnership = (appId: ApplicationId, formData: Record<string, unknown>): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_OWNER_TRANSFER(appId),
    body: formData,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({type: ActionTypes.APPLICATION_OWNER_TRANSFER_SUCCESS, application: res.body});
    })
    .catch(APIErrorHandler);
};

export const updateBot = (
  appId: ApplicationId,
  formData: {
    username: string;
    avatar: string;
    banner: string;
  },
): Promise<Application | void> => {
  return patch({
    url: Endpoints.APPLICATION_BOT(appId),
    body: formData,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const fetchStoreAssets = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.STORE_ASSETS(appId),
    oldFormErrors: true,
  }).then((res: HTTPResponse) => {
    Dispatcher.dispatch({
      type: ActionTypes.STORE_ASSETS_FETCH_SUCCESS,
      appId,
      assets: res.body,
    });
  });
};

export const deleteStoreAsset = (appId: ApplicationId, assetId: string): Promise<void> => {
  return del({
    url: Endpoints.DELETE_STORE_ASSET(appId, assetId),
    oldFormErrors: true,
  }).then(() => {
    Dispatcher.dispatch({
      type: ActionTypes.STORE_ASSET_REMOVE_SUCCESS,
      appId,
      assetId,
    });
  });
};

export const uploadStoreAsset = (
  appId: ApplicationId,
  asset: {
    filename: string;
    file: File;
  },
): Promise<void> => {
  return post({
    url: Endpoints.STORE_ASSETS(appId),
    attachments: [
      {
        name: 'assets',
        file: asset.file,
        filename: `${asset.filename}${getFileExtension(asset.file) ?? ''}`,
      },
    ],
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.STORE_ASSET_UPLOAD_SUCCESS,
        appId,
        asset: res.body,
      });
    })
    .catch((res: HTTPResponse) => {
      throw new Error(stringifyErrors(res.body));
    });
};

export const fetchManifestLabels = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.MANIFEST_LABELS(appId),
    oldFormErrors: true,
  }).then((res: HTTPResponse) => {
    Dispatcher.dispatch({
      type: ActionTypes.MANIFEST_LABELS_FETCH_SUCCESS,
      appId,
      manifestLabels: res.body,
    });
  });
};

export const activateApplicationLicense = (appId: ApplicationId, guildId: string): Promise<void> => {
  return post({
    url: Endpoints.APPLICATION_LICENSE_ACTIVATE(appId),
    body: {
      guild_id: guildId,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_LICENSE_ACTIVATE_SUCCESS,
        application: res.body,
      });
    })
    .catch(APIErrorHandler);
};

export const submitStoreApplicationForApproval = (appId: ApplicationId): Promise<HTTPResponse> => {
  return post({
    url: Endpoints.APPLICATION_APPROVALS(appId),
    oldFormErrors: true,
  }).catch(APIErrorHandler);
};

export const submitApplicationAdditionalIntentsRequest = (
  appId: ApplicationId,
  body: RequestAdditionalIntents,
): Promise<Application> => {
  return post({
    url: Endpoints.APPLICATION_REQUEST_ADDITIONAL_INTENTS(appId),
    body,
    oldFormErrors: true,
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const getApplicationProxyConfig = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.APPLICATION_PROXY_CONFIG(appId),
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_PROXY_CONFIG_FETCH_SUCCESS,
        appId,
        config: response.body.url_map,
      });
    })
    .catch(APIErrorHandler);
};

export const updateApplicationProxyConfig = (appId: ApplicationId, updatedConfig: ApplicationProxyMapping[]) => {
  return post({
    url: Endpoints.APPLICATION_PROXY_CONFIG(appId),
    body: {
      /* eslint-disable-next-line */
      url_map: updatedConfig,
    },
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS,
        appId,
        config: response.body.url_map,
      });
    })
    .catch((response) => {
      throw new V8APIError(response);
    });
};

export const getApplicationEmbeddedActivityConfig = (appId: ApplicationId): Promise<void> => {
  return get({
    url: Endpoints.APPLICATION_EMBEDDED_ACTIVITY_CONFIG(appId),
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS,
        appId,
        config: response.body,
      });
    })
    .catch(APIErrorHandler);
};

export const updateApplicationEmbeddedActivityConfig = (
  appId: ApplicationId,
  updatedConfig: Partial<ApplicationEmbeddedActivityConfig>,
) => {
  return patch({
    url: Endpoints.APPLICATION_EMBEDDED_ACTIVITY_CONFIG(appId),
    body: updatedConfig,
  })
    .then((response) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS,
        appId,
        config: response.body,
      });
    })
    .catch(APIErrorHandler);
};

export const setApplicationEmbedded = (appId: ApplicationId, embedded: boolean) => {
  return post({
    url: Endpoints.APPLICATION_EMBEDDED_TOGGLE(appId),
    body: {
      embedded,
    },
  })
    .then(() => fetchApplication(appId))
    .catch(APIErrorHandler);
};

export const updateApplicationDirectoryEntry = (appId: ApplicationId, body: unknown): Promise<unknown> => {
  return patch({
    body,
    url: Endpoints.APPLICATION_DIRECTORY_ENTRY(appId),
  }).catch((r) => {
    throw new V8APIError(r);
  });
};

export const acknowledgeOnboarding = (applicationId: ApplicationId) => {
  Dispatcher.dispatch({
    type: ActionTypes.ACKNOWLEDGE_ONBOARDING,
    applicationId,
  });
};

export async function fetchStoreLayout(applicationId: ApplicationId): Promise<void> {
  try {
    const response = await get({
      url: Endpoints.STORE_LAYOUT(applicationId),
    });
    Dispatcher.dispatch({
      type: ActionTypes.APPLICATION_REFRESH_STORE_LAYOUT,
      applicationId,
      listingsByType: response.body,
    });
  } catch (e) {
    if (!(e instanceof Error) && 'status' in e && e.status === 404) {
      // We do not want to log 404s
      return;
    }
    APIErrorHandler(e);
  }
}

export async function postStoreLayout(applicationId: ApplicationId, allSkus: string[]): Promise<void> {
  try {
    const response = await post({
      url: Endpoints.STORE_LAYOUT(applicationId),
      body: {
        all_skus: allSkus,
      },
    });
    Dispatcher.dispatch({
      type: ActionTypes.APPLICATION_REFRESH_STORE_LAYOUT,
      applicationId,
      listingsByType: response.body,
    });
  } catch (e) {
    APIErrorHandler(e);
  }
}

export function fetchApplicationEmojis(appId: ApplicationId): Promise<void> {
  return get({
    url: Endpoints.APPLICATION_EMOJIS(appId),
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMOJIS_FETCH_SUCCESS,
        appId,
        emojis: res.body.items,
      });
    })
    .catch(APIErrorHandler);
}

export function uploadApplicationEmoji(appId: ApplicationId, name: string, url: string): Promise<void> {
  return post({
    url: Endpoints.APPLICATION_EMOJIS(appId),
    body: {
      name,
      image: url,
    },
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMOJI_UPLOAD_SUCCESS,
        appId,
        emoji: {
          user: UserStore.user,
          ...res.body,
        },
      });
    })
    .catch(APIErrorHandler);
}

export function patchApplicationEmoji(appId: ApplicationId, emojiId: EmojiId, body: {name?: string}): Promise<Emoji> {
  return patch({
    url: Endpoints.APPLICATION_EMOJI(appId, emojiId),
    body,
    oldFormErrors: true,
  })
    .then((res: HTTPResponse) => {
      const emoji = res.body;
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMOJI_UPDATE_SUCCESS,
        appId,
        emoji,
      });
      return emoji;
    })
    .catch(APIErrorHandler);
}

export function deleteApplicationEmoji(appId: ApplicationId, emojiId: EmojiId): Promise<void> {
  return del({
    url: Endpoints.APPLICATION_EMOJI(appId, emojiId),
    oldFormErrors: true,
  })
    .then(() => {
      Dispatcher.dispatch({
        type: ActionTypes.APPLICATION_EMOJI_REMOVE_SUCCESS,
        appId,
        emojiId,
      });
    })
    .catch(APIErrorHandler);
}
