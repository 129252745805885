/* eslint-disable no-console */
import {DevToolsListener} from './DevToolsListener';
import {isMobile, isTablet} from './PlatformUtils';

import type {FormattedMessage} from '@discordapp/i18n';

interface TokenManager {
  hideToken: () => void;
  showToken: () => void;
}

interface RemoteApp {
  getVersion: () => string;
}

interface DiscordNativeWindow {
  setDevtoolsCallbacks: (onOpened: () => void, onClosed: () => void) => void;
  webContents: {
    removeAllListeners: (event: string) => void;
    on: (event: string, cb: () => void) => void;
  };
}

interface UserDefensesMessages {
  SELF_XSS_HEADER: string;
  SELF_XSS_LINE_1: string;
  SELF_XSS_LINE_2: string;
  SELF_XSS_LINE_3: string;
  SELF_XSS_LINE_4: FormattedMessage<{
    url: any;
  }>;
}

interface UserDefensesi18n {
  Messages: UserDefensesMessages | {XSSDefenses: UserDefensesMessages};
}

function consoleWarning(i18n: {Messages: any}, count = 0) {
  const XSSMessages = i18n.Messages.XSSDefenses !== '' ? i18n.Messages.XSSDefenses : i18n.Messages;
  // i18n may not have loaded yet.
  if (XSSMessages.SELF_XSS_HEADER != null) {
    console.log(
      `%c${XSSMessages.SELF_XSS_HEADER}`,
      'color: #5865f2; -webkit-text-stroke: 2px black; font-size: 72px; font-weight: bold;',
    );
    console.log(`%c${XSSMessages.SELF_XSS_LINE_1}`, 'font-size: 16px;');
    console.log(`%c${XSSMessages.SELF_XSS_LINE_2}`, 'font-size: 18px; font-weight: bold; color: red;');
    if (count >= 4) {
      console.log(`%c${XSSMessages.SELF_XSS_LINE_3}`, 'font-size: 16px;');
      console.log(
        `%c${XSSMessages.SELF_XSS_LINE_4.format({
          url: `${location.protocol}${window.GLOBAL_ENV.MARKETING_ENDPOINT}/jobs`,
        })}`,
        'font-size: 16px;',
      );
    } else {
      setTimeout(() => consoleWarning(i18n, count + 1), 1000);
    }
  }
}
export function UserDefenses(
  i18n: UserDefensesi18n,
  tokenManager: TokenManager,
  DiscordNative?: {
    window: DiscordNativeWindow;
    remoteApp: RemoteApp;
  },
) {
  if (process.env.NODE_ENV !== 'production') return;
  if (DiscordNative != null && DiscordNative.remoteApp.getVersion() === '0.0.0') return;
  const listener = new DevToolsListener();

  if (DiscordNative != null) {
    if (DiscordNative.window.setDevtoolsCallbacks != null) {
      DiscordNative.window.setDevtoolsCallbacks(
        () => {
          tokenManager.hideToken();
          consoleWarning(i18n);
        },
        () => {
          tokenManager.showToken();
        },
      );
    } else {
      const webContents = DiscordNative.window.webContents;
      webContents.removeAllListeners('devtools-opened');
      webContents.on('devtools-opened', () => {
        tokenManager.hideToken();
        consoleWarning(i18n);
      });
      webContents.on('devtools-closed', tokenManager.showToken);
    }
  } else if (!isMobile && !isTablet) {
    listener.on('changed', ({open}: {open: boolean}) => {
      if (open) {
        tokenManager.hideToken();
        consoleWarning(i18n);
      } else {
        tokenManager.showToken();
      }
    });
  }
  window.addEventListener('beforeunload', (e) => {
    if (!e.isTrusted) return;
    tokenManager.showToken();
  });
}
