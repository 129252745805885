import superagent from 'superagent';
import Backoff from '@discordapp/backoff';
import * as DevtoolsExtension from '@discordapp/common/DevtoolsExtension';
import {Logger} from '@discordapp/common/Logger';

import {INVALID_FORM_BODY_ERROR_CODE, ResponseError} from './V8APIError';
import {convertSkemaError} from './convertSkemaError';

export {INVALID_FORM_BODY_ERROR_CODE, convertSkemaError};
export {stringifyErrors} from './stringifyErrors';
export {ErrorCode, MessageOrResponse, APIError as V6OrEarlierAPIError} from './V6OrEarlierAPIError';
export {ResponseError, APIError as V8APIError} from './V8APIError';

const logger = new Logger('HTTPUtils');

export type HTTPMethod = 'get' | 'post' | 'put' | 'patch' | 'del';

export interface HTTPHeaders {
  [key: string]: string;
}

// Superagent doesn't export these, so I've copied them here.
// The Record<string, any> was added by myself. It appears that
// superagent can convert arbitrary objects into response data even though
// the typing doesn't support it.
type MultipartValueSingle = Blob | Buffer | string | boolean | number | Record<string, any>;
type MultipartValue = MultipartValueSingle | MultipartValueSingle[];

const RETRYABLE_HTTP_CODES = new Set([
  502, 504, 507, 598, 599,
  // Cloudflare codes
  522, 523, 524,
]);

interface ReactNativeFile {
  name?: string;
  type?: string;
  uri: string;
}

export interface Attachment {
  name: string;
  file: File | ReactNativeFile | MultipartValueSingle;
  filename?: string;
}

export type ResponseInterceptorRetry = (
  additionalHeaders?: Record<string, string>,
  intercept?: ResponseInterceptor,
) => void;
export type ResponseInterceptor = (
  res: superagent.Response,
  retry: ResponseInterceptorRetry,
  cancel: (error: Error) => void,
) => boolean;

export interface HTTPRequest {
  url: string;
  query?:
    | {
        [key: string]: any;
      }
    | string;
  body?: any;
  headers?: HTTPHeaders;
  /**
   * The reason that will show up in the guild audit log.
   */
  reason?: string | undefined | null;
  backoff?: Backoff;
  retried?: number;
  retries?: number | null;
  timeout?:
    | number // equivalent to deadline (see below)
    | {
        /**
         * deadline for the entire request (including all uploads, redirects, server processing time) to complete.
         * If the response isn't completed within that time, the request will be aborted.
         */
        deadline?: number | undefined;
        /**
         * maximum time to wait for the first byte to arrive from the server
         */
        response?: number | undefined;
      };
  context?: Record<string, any>;
  attachments?: Attachment[];
  fields?: Array<{
    name: string;
    value: MultipartValue;
  }>;
  signal?: AbortSignal;
  /**
   * In APIv8, we migrated to a new structure for form errors. For backwards
   * compatibility, we added this field to every existing API call.
   *
   * Please omit/remove if:
   *
   * - Your API call is new; or
   * - Your API call does not emit form errors; or
   * - You are not yet handling errors; or
   * - You can wrap the error in an APIError.
   *
   * Notes:
   * - Your API probably does not emit form errors if you don't raise
   *   ValidationError, and it performs no skema validations. `abort()` is not
   *   a form error.
   * - APIError actually handles both v6 and v8 error responses, so you can
   *   comfortably migrate to that without needing to omit this field.
   */
  oldFormErrors?: true;
  onRequestCreated?: (req: superagent.Request) => void;
  onRequestProgress?: (e: ProgressEvent) => void;
  interceptResponse?: ResponseInterceptor;

  binary?: true;

  failImmediatelyWhenRateLimited?: true; // Instead of queueing previously rate-limited requests, fail immediately if retryAfterTimestamp hasn't been reached.
}

export interface HTTPResponse<T = any> {
  ok: boolean;
  status: number;
  headers: HTTPHeaders;
  body: T;
  text: string;
}

interface HTTPError {
  err: Error & {code?: string};
}

export type HTTPResponseCallbackResult =
  | ({
      ok: boolean;
      hasErr: false;
    } & HTTPResponse)
  | ({
      ok: false;
      hasErr: true;
    } & HTTPError);

export type HTTPResponseCallback = (res: HTTPResponseCallbackResult) => void;

function sendRequest(
  method: HTTPMethod,
  opts: HTTPRequest,
  resolve: (v: HTTPResponse) => unknown,
  reject: (e: any) => unknown,
  onComplete?: HTTPResponseCallback,
) {
  if (process.env.NODE_ENV === 'development') {
    reportDevtoolsEvent(`Sending ${method.toUpperCase()} to ${opts.url}`, opts);
  }

  const r = superagent[method](opts.url);
  if (opts.onRequestCreated != null) {
    opts.onRequestCreated(r);
  }
  if (opts.query != null) {
    let query = opts.query;
    // Super agent used to strip null-ish values from query strings,
    // the new one includes them as empty values which our API chokes on
    if (typeof query === 'object') {
      const queryCopy = {...query};
      Object.keys(queryCopy).map((key) => {
        if (queryCopy[key] == null) {
          delete queryCopy[key];
        }
      });
      query = queryCopy;
    }
    r.query(query);
  }
  if (opts.body) {
    r.send(opts.body);
  }
  if (opts.headers != null) {
    r.set(opts.headers);
  }
  if (opts.reason != null) {
    r.set('X-Audit-Log-Reason', encodeURIComponent(opts.reason));
  }
  opts.attachments?.forEach((attachment) => {
    // @ts-expect-error Superagent doesn't support React Native file types.
    r.attach(attachment.name, attachment.file, attachment.filename);
  });

  opts.fields?.forEach((field) => {
    // @ts-expect-error Superagent doesn't support Record<string, any>
    // as a field, but it handles it fine, as we use it during the message
    // send path!
    r.field(field.name, field.value);
  });

  if (opts.context != null) {
    const contextProperties = encodeProperties(opts.context);
    if (contextProperties != null) {
      r.set('X-Context-Properties', contextProperties);
    }
  }

  if (opts.retried != null && opts.retried !== 0) {
    r.set('X-Failed-Requests', `${opts.retried}`);
  }

  if (opts.timeout != null && opts.timeout !== 0) {
    r.timeout(opts.timeout);
  }

  if (opts.binary) {
    r.responseType('blob');
  }

  if (opts.onRequestProgress != null) {
    r.on('progress', (e: ProgressEvent) => {
      opts.onRequestProgress?.(e);
    });
  }

  const retry = () => {
    if (process.env.NODE_ENV === 'development') {
      reportDevtoolsEvent(`Retrying ${method.toUpperCase()} to ${opts.url}`, opts);
      logger.info(`Retrying ${method.toUpperCase()} to ${opts.url} (retries left: ${opts.retries})`);
    }

    opts.backoff = opts.backoff != null ? opts.backoff : new Backoff();
    opts.retried = (opts.retried != null ? opts.retried : 0) + 1;
    opts.backoff.fail(() => awaitOnline(opts.url).then(() => sendRequest(method, opts, resolve, reject, onComplete)));
  };

  requestPatch?.prepareRequest?.(r);

  // Consider all status codes that are non-null as not errors, that way we'll be able to handle retries
  // specifically in the `then`.
  r.ok((res: superagent.Response) => res.status != null);
  r.then(
    (res: superagent.Response) => {
      if (opts.retries != null && opts.retries-- > 0 && RETRYABLE_HTTP_CODES.has(res.status)) {
        return retry();
      }

      const newRes = {
        ok: res.ok,
        headers: res.headers,
        body: res.body,
        text: res.text,
        status: res.status,
      };

      if (process.env.NODE_ENV === 'development') {
        reportDevtoolsEvent(`Completed ${method.toUpperCase()} to ${opts.url}`, newRes);
      }

      cleanupRequestEntry(opts, newRes);

      // If we retry the request, we don't want code cleaning up from the previous
      // request to be able to resolve/reject the new request, so track whether we
      // have "transferred control" to a new request.
      let controlTransferred = false;
      const interceptRetry = (additionalHeaders?: Record<string, string>, interceptResponse?: ResponseInterceptor) => {
        const newOpts = {...opts, headers: {...opts.headers, ...additionalHeaders}, interceptResponse};
        controlTransferred = true;
        sendRequest(method, newOpts, resolve, reject, onComplete);
      };
      const interceptCancel = (err: Error) => {
        if (controlTransferred) return;
        reject(err);
        onComplete?.({
          ok: false,
          hasErr: true,
          err,
        });
      };
      if (opts?.interceptResponse?.(res, interceptRetry, interceptCancel) === true) {
        return;
      }
      if (requestPatch?.interceptResponse?.(res, interceptRetry, interceptCancel) === true) {
        return;
      }

      if (res.ok) {
        resolve(newRes);
      } else {
        // Convert API v8 form errors to v6 form errors.
        // Note that we don't check if we're using API v8 here. This not needed because
        // `code` does not exist on API v6 form errors.
        // Text is unchanged, in case somebody wants the original values.
        if (opts.oldFormErrors && newRes?.body?.code === INVALID_FORM_BODY_ERROR_CODE) {
          const {errors} = newRes.body;
          if (errors != null) {
            newRes.body = convertSkemaError(errors);
          }
        }

        // FIXME: we should reject with an Error here, and code should expect
        // that http errors can be any Error, instead of a response object.
        reject(newRes);
      }
      if (onComplete != null) {
        onComplete({
          hasErr: false,
          ...newRes,
        });
      }
    },
    (err: any) => {
      // This handles all non http errors, e.g. connection errors and the likes.
      if (opts.retries != null && opts.retries-- > 0 && err.code !== 'ABORTED') {
        retry();
      } else {
        if (process.env.NODE_ENV === 'development') {
          reportDevtoolsEvent(`Non-retryable error on ${method.toUpperCase()} to ${opts.url}`, err.response);
          logger.info(`Non-retryable error on ${method.toUpperCase()} to ${opts.url}`, err.code);
        }

        cleanupRequestEntry(opts);

        // reject the promise with the error here, and not the response, as it's not an HTTP
        // error, since those are handled above.
        reject(err);
        if (onComplete != null) {
          onComplete({ok: false, hasErr: true, err});
        }
      }
    },
  );

  if (opts.signal?.aborted) {
    r.abort();
  } else {
    opts.signal?.addEventListener('abort', () => r.abort(), {once: true});
  }
}

// Sends events to the chrome devtools extension
function reportDevtoolsEvent(description: string, data: any) {
  DevtoolsExtension.reportEvent({type: 'HTTP', description, data});
}

interface ActiveRateLimit {
  queue: Array<() => void>;
  retryAfterTimestamp: number;
  // workaround for the fact that discord_web this is a number, but in discord_admin it's a Timeout :(
  timeoutId: any /* number */;
  latestErrorMessage: string;
}

// Although application code may handle request queues and rate limits,
// this provides a last line of defense to ensure that clients cannot
// spam 429s and cause a user to get IP banned.
// The map is keyed by the request URL's path, so it does not include any
// query paremeters and may be too loose with regard to rate limit major
// parameters in some cases, but we don't generally need to worry about that
// in the case of misbehaved client code.
const rateLimitQueue: Map<string, ActiveRateLimit> = new Map();

function rateLimitExpirationHandler(url: string) {
  const activeRateLimit = rateLimitQueue.get(url);
  if (activeRateLimit == null) {
    // should not happen
    logger.verbose('rateLimitExpirationHandler: rate limit for', url, 'expired, but record was already removed');
    return;
  }
  const next = activeRateLimit.queue.shift();
  if (next == null) {
    logger.verbose('rateLimitExpirationHandler: removing key for', url);
    rateLimitQueue.delete(url);
    return;
  } else {
    logger.verbose('rateLimitExpirationHandler: moving to next record for ', url);
    next();
  }
}

function cleanupRequestEntry(opts: HTTPRequest, r?: HTTPResponse) {
  const activeRateLimit = rateLimitQueue.get(opts.url);
  if (r != null && r.status === 429) {
    const retryAfterSec = r.body?.retry_after || 5;
    const retryAfterTimestamp = Date.now() + retryAfterSec * 1000;
    if (activeRateLimit != null) {
      if (activeRateLimit.retryAfterTimestamp < retryAfterTimestamp) {
        logger.verbose('cleanupRequestEntry: extending rate limit for ', opts.url);
        clearTimeout(activeRateLimit.timeoutId);
      } else {
        logger.verbose('cleanupRequestEntry: already has rate limit for ', opts.url);
        return;
      }
    }
    logger.verbose(`cleanupRequestEntry: rate limit for ${opts.url} retry after ${retryAfterSec} seconds`);
    const timeoutId = setTimeout(() => rateLimitExpirationHandler(opts.url), retryAfterSec * 1000);
    rateLimitQueue.set(opts.url, {
      queue: activeRateLimit?.queue ?? [],
      retryAfterTimestamp: retryAfterTimestamp,
      latestErrorMessage: String(r.body?.message),
      timeoutId,
    });
  } else if (activeRateLimit != null && activeRateLimit.retryAfterTimestamp < Date.now()) {
    // move to the next request in the queue
    logger.verbose('cleanupRequestEntry: rate limit for ', opts.url, 'expired');
    rateLimitExpirationHandler(opts.url);
  }
}

const _rejectWithRateLimitedResponse = (reject: (reason?: any) => void, activeRateLimit: ActiveRateLimit) => {
  const retryAfterMs = activeRateLimit.retryAfterTimestamp - Date.now();
  const retryAfterSeconds = Math.round(retryAfterMs / 1000);
  const rateLimitedResponse: ResponseError = {
    status: 429,
    body: {message: activeRateLimit.latestErrorMessage, retry_after: retryAfterSeconds},
  };
  return reject(rateLimitedResponse);
};

function makeRequest(
  method: HTTPMethod,
  opts: string | HTTPRequest,
  onComplete?: HTTPResponseCallback,
): Promise<HTTPResponse> {
  return new Promise((resolve, reject) => {
    if (typeof opts === 'string') {
      opts = {url: opts};
    }
    const activeRateLimit = rateLimitQueue.get(opts.url);
    if (activeRateLimit != null && opts.failImmediatelyWhenRateLimited) {
      // Rather than enqueueing the request to be made when rateLimitExpirationHandler is called,
      // which results in a potentially long delay before resolving or rejecting the request,
      // reject immediately.
      return _rejectWithRateLimitedResponse(reject, activeRateLimit);
    } else if (activeRateLimit != null) {
      logger.verbose('makeRequest: queueing request for ', opts.url);
      activeRateLimit.queue.push(sendRequest.bind(null, method, opts, resolve, reject, onComplete));
    } else {
      sendRequest(method, opts, resolve, reject, onComplete);
    }
  });
}

export type RequestMethod = (
  opts: string | HTTPRequest,
  onRequestComplete?: HTTPResponseCallback,
) => Promise<HTTPResponse>;

export let get: RequestMethod = makeRequest.bind(null, 'get');
export let post: RequestMethod = makeRequest.bind(null, 'post');
export let put: RequestMethod = makeRequest.bind(null, 'put');
export let patch: RequestMethod = makeRequest.bind(null, 'patch');
export let del: RequestMethod = makeRequest.bind(null, 'del');

export const HTTP = {
  get,
  post,
  put,
  patch,
  del,
};

// @ts-expect-error
if (global.isServerRendering) {
  // Shim a promise response to not trip up flow
  const noop: RequestMethod = (_opts, _callback) =>
    Promise.resolve({ok: true, status: 200, headers: {}, body: null, text: ''});
  get = noop;
  post = noop;
  put = noop;
  patch = noop;
  del = noop;
}

export function getAPIBaseURL(version: boolean = true) {
  return (
    (process.env.API_PROTOCOL || location.protocol) +
    window.GLOBAL_ENV.API_ENDPOINT +
    (version ? `/v${window.GLOBAL_ENV.API_VERSION}` : '')
  );
}

interface RequestPatch {
  prepareRequest?: (req: superagent.SuperAgentRequest) => unknown;
  interceptResponse?: ResponseInterceptor;
}
let requestPatch: RequestPatch | null = null;

export function setRequestPatch(p: RequestPatch) {
  requestPatch = p;
}

let awaitOnline: (url: string) => Promise<void> = () => Promise.resolve();
export function setAwaitOnline(value: (url: string) => Promise<void>) {
  awaitOnline = value;
}

export function encodeProperties(properties: Record<string, any>): string | null {
  try {
    return Buffer.from(JSON.stringify(properties)).toString('base64');
  } catch (e) {
    return null;
  }
}
