import {calculateSize} from './ImageLoaderUtils';

import {DEFAULT_AVATAR_SIZE, Endpoints, Images} from '@developers/Constants';
import type {SizeMultiple} from './ApplicationImageUtils';

const LEGACY_DEFAULT_AVATAR_COUNT = 5;
const DEFAULT_AVATAR_COUNT = 6;
const DEFAULT_AVATARS = [0, 1, 2, 3, 4, 5].map((val, i) => Images[`ASSET_DEFAULT_AVATAR_${i}`]);

export function getUserAvatarURL(
  user: {id: string; avatar?: string | null | undefined; discriminator: string},
  size?: SizeMultiple | null,
) {
  const {id, avatar, discriminator} = user;

  const sizeQueryString = size != null ? `?size=${size}` : '';

  if (avatar != null) {
    if (window.GLOBAL_ENV.CDN_HOST != null) {
      return `//${window.GLOBAL_ENV.CDN_HOST}/avatars/${id}/${avatar}${sizeQueryString}`;
    } else {
      return `${window.GLOBAL_ENV.API_ENDPOINT}/users/${id}/avatars/${avatar}${sizeQueryString}`;
    }
  }

  if (discriminator === '0') {
    // equiv to `(id >> 22) % n` but using division because of js numbers.
    // 2^22 = 4194304
    const index = Math.floor(Number(id) / 4194304) % DEFAULT_AVATAR_COUNT;
    return DEFAULT_AVATARS[index];
  }

  const index = parseInt(discriminator) % LEGACY_DEFAULT_AVATAR_COUNT;
  return DEFAULT_AVATARS[index];
}

export function getUserBannerURL(id: string, banner: string, size?: SizeMultiple | null) {
  const sizeQueryString = size != null ? `?size=${size}` : '';

  if (window.GLOBAL_ENV.CDN_HOST != null) {
    return `//${window.GLOBAL_ENV.CDN_HOST}/banners/${id}/${banner}${sizeQueryString}`;
  } else {
    return `${window.GLOBAL_ENV.API_ENDPOINT}/users/${id}/banners/${banner}${sizeQueryString}`;
  }
}

export const hasAnimatedGuildIcon = (
  guild: Readonly<{
    icon: string | null | undefined;
  }>,
): boolean => {
  if (guild == null || guild.icon == null) {
    return false;
  }
  // Using substr because it's faster than a regex on chrome
  return guild.icon.substr(0, 2) === 'a_';
};

export const getGuildIconURL = ({
  id,
  icon,
  size = DEFAULT_AVATAR_SIZE,
  format,
}: {
  id: string;
  icon: string | null | undefined;
  size?: number;
  format?: 'jpg' | 'png' | 'gif' | null;
}): string | null | undefined => {
  return getAvatarURL({
    endpoint: Endpoints.GUILD_ICON,
    path: 'icons',
    id: id,
    avatar: icon,
    size: size,
    format: format != null ? format : 'png',
  });
};

interface GetAvatarUrlOptions {
  endpoint: (id: string, avatar: string, format?: 'jpg' | 'png' | 'gif') => string;
  path: string;
  id: string;
  avatar?: string | null;
  size?: number | null;
  format?: 'jpg' | 'png' | 'gif';
  keepAspectRatio?: boolean | null;
}

const getAvatarURL = ({endpoint, path, id, avatar, size, format = 'png', keepAspectRatio}: GetAvatarUrlOptions) => {
  if (avatar == null) return;
  let url: string;
  const CDN_HOST = window.GLOBAL_ENV.CDN_HOST;
  if (CDN_HOST != null) {
    url = `${location.protocol}//${CDN_HOST}/${path}/${id}/${avatar}.${format}`;
  } else {
    url = location.protocol + window.GLOBAL_ENV.API_ENDPOINT + endpoint(id, avatar, format);
  }

  const qs = new URLSearchParams();

  if (size != null) {
    qs.set('size', calculateSize(size).toString());
  }
  if (keepAspectRatio != null) {
    qs.set('keep_aspect_ratio', keepAspectRatio.toString());
  }
  return url + `?${qs.toString()}`;
};
