import * as React from 'react';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy.js';

import Card from '@developers/components/common/Card';
import CardGrid, {CardGridContext} from '@developers/components/common/CardGrid';
import PageSectionHeading from '@developers/components/common/PageSectionHeading';
import {getApplicationIcon, Sizes as ImageSizes} from '@developers/utils/ApplicationImageUtils';

import {CardGridSortOrder, CardGridTypes, Routes} from '@developers/Constants';
import type {ApplicationWithDetails} from '@developers/flow/Client';
import styles from './ApplicationGrid.module.css';

interface Props {
  applications: ApplicationWithDetails[] | null | undefined;
  heading: React.ReactNode;
  isFlush: boolean;
}

const ApplicationGrid = ({applications, heading, isFlush = false}: Props) => {
  const {cardType, sortOrder} = React.useContext(CardGridContext);

  const renderApplications = () => {
    if (applications == null) return null;
    const applicationNodes = sortBy(applications, (application) =>
      sortOrder === CardGridSortOrder.ALPHABETICAL ? application.name : Number(application.id),
    ).map((application) => {
      return (
        <Card
          key={application.id}
          type={cardType}
          href={Routes.EDIT_APPLICATION(application.id)}
          imageUri={getApplicationIcon(
            application.id,
            application.icon,
            cardType === CardGridTypes.SMALL_CARD ? ImageSizes.MEDIUM_LARGE : ImageSizes.LARGE,
          )}
          label={application.name}
          trackedLinkProps={{from: 'link', to: Routes.EDIT_APPLICATION(application.id)}}
        />
      );
    });

    return <CardGrid type={cardType}>{applicationNodes}</CardGrid>;
  };

  return (
    <div className={classNames(styles.wrapper, {[styles.isFlush]: isFlush})}>
      <PageSectionHeading bottomMargin={PageSectionHeading.BottomMarginSizes.LARGE}>{heading}</PageSectionHeading>
      {renderApplications()}
    </div>
  );
};

export default ApplicationGrid;
