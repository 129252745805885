import * as React from 'react';
import classNames from 'classnames';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import Flex from './Flex';

import styles from './Grid.module.css';

const responsiveColumnPropNames: Array<keyof ColumnProperties> = [
  'columnSpreadExtraSmall',
  'columnSpreadSmall',
  'columnSpreadSmallMedium',
  'columnSpreadMedium',
  'columnSpreadLarge',
  'columnSpreadExtraLarge',
];

interface ColumnProperties {
  columnSpreadExtraSmall?: number;
  columnSpreadSmall?: number;
  columnSpreadSmallMedium?: number;
  columnSpreadMedium?: number;
  columnSpreadLarge?: number;
  columnSpreadExtraLarge?: number;
}

// Constructs a string of class names for each responsive column prop provided.
const getColumnClasses = (columnProps: ColumnProperties): string => {
  return responsiveColumnPropNames
    .reduce<string[]>((acc, propName) => {
      const propValue = columnProps[propName];
      if (propValue != null) {
        acc.push(getClass(styles, propName, propValue.toString()));
      }
      return acc;
    }, [])
    .join(' ');
};

export interface GridProps {
  children: React.ReactNode;
  className?: string;
}

const Grid = ({children, className}: GridProps) => {
  return <div className={classNames(className, styles.grid)}>{children}</div>;
};

export interface GridSectionProps {
  children: React.ReactNode;
  className?: string;
  wrap?: (typeof Flex.Wrap)[keyof typeof Flex.Wrap];
  grow?: number;
}

export const GridSection = ({children, className, wrap, grow}: GridSectionProps) => {
  return (
    <Flex className={className} wrap={wrap} grow={grow}>
      {children}
    </Flex>
  );
};

GridSection.Wrap = Flex.Wrap;

interface GridChildProps {
  children: React.ReactNode;
  className?: string;
  columnSpread?: number;
  columnSpreadExtraSmall?: number;
  columnSpreadSmall?: number;
  columnSpreadSmallMedium?: number;
  columnSpreadMedium?: number;
  columnSpreadLarge?: number;
  columnSpreadExtraLarge?: number;
  grow?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  shrink?: number;
  style?: React.CSSProperties;
}

export const GridChild = ({
  children,
  className,
  columnSpread,
  columnSpreadExtraSmall,
  columnSpreadSmall,
  columnSpreadSmallMedium,
  columnSpreadMedium,
  columnSpreadLarge,
  columnSpreadExtraLarge,
  grow = 0,
  shrink = 0,
  onClick,
  style,
}: GridChildProps) => {
  const columnSpreadString = columnSpread == null ? null : columnSpread.toString();
  return (
    <Flex.Child
      className={classNames(
        className,
        styles.child,
        columnSpreadString != null && columnSpreadString !== ''
          ? getClass(styles, 'columnSpread', columnSpreadString)
          : null,
        getColumnClasses({
          columnSpreadExtraSmall,
          columnSpreadSmall,
          columnSpreadMedium,
          columnSpreadSmallMedium,
          columnSpreadLarge,
          columnSpreadExtraLarge,
        }),
      )}
      grow={grow}
      onClick={onClick}
      shrink={shrink}
      wrap
      style={style}>
      {children}
    </Flex.Child>
  );
};

export default Grid;
