import * as React from 'react';
import {Text} from '@discordapp/design/components/Text/Text.web';

import Anchor from '@developers/uikit/Anchor';
import Flex from '@developers/uikit/Flex';
import {getLocalizedLink} from '@developers/utils/LinkUtils';
import FourOhFour from './404';

import {LocalizedLinks, Links} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './index.module.css';

const ErrorLink = ({href, children}: {href: string; children: React.ReactNode}) => (
  <Anchor href={href} className={styles.link}>
    <Text variant="text-md/normal" className={styles.linkText}>
      {children}
    </Text>
  </Anchor>
);

export default function Error() {
  return (
    <Flex
      className={styles.pageWrapper}
      direction={Flex.Direction.VERTICAL}
      align={Flex.Align.CENTER}
      justify={Flex.Justify.CENTER}>
      <FourOhFour />
      <div className={styles.textSection}>
        <Text variant="text-lg/normal" className={styles.header}>
          {Messages.ErrorPage.ERROR_HEADER}
        </Text>
        <Text variant="text-md/normal">{Messages.ErrorPage.ERROR_DESCRIPTION}</Text>
      </div>
      <Flex direction={Flex.Direction.VERTICAL} align={Flex.Align.START} grow={0}>
        <ErrorLink href={Links.API_DOCS}>{Messages.ErrorPage.DOCUMENTATION_LINK}</ErrorLink>
        <ErrorLink href={Links.STATUS}>{Messages.ErrorPage.STATUS_PAGE_LINK}</ErrorLink>
        <ErrorLink href={getLocalizedLink(LocalizedLinks.TWITTER)}>{Messages.SocialMedia.TWITTER_HANDLE}</ErrorLink>
        <ErrorLink href={Links.HELP_AND_SUPPORT}>{Messages.ErrorPage.SUPPORT_LINK}</ErrorLink>
      </Flex>
    </Flex>
  );
}
