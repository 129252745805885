import {Store} from '@discordapp/flux/Store';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {Action, ActionFor} from '@developers/flow/Action';

let mfaModalProps: ActionFor<'SHOW_MFA_MODAL'> | null = null;

class MFAModalStore extends Store<Action> {
  static displayName = 'MFAModalStore';

  get mfaModalProps() {
    return mfaModalProps;
  }
}

export default new MFAModalStore(Dispatcher, {
  [ActionTypes.SHOW_MFA_MODAL](props) {
    mfaModalProps = props;
  },
  [ActionTypes.HIDE_MFA_MODAL]() {
    mfaModalProps = null;
  },
});
