/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde mana tokens generate`
*/
type Modules = typeof Modules;
const Modules = {
  chat: {
    /*
     * Width of the resize handle in pixels
     */
    RESIZE_HANDLE_WIDTH: 8,
  },
} as const;
export const _private = {Modules};
