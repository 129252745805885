import formatDate from 'date-fns/format';
import maxBy from 'lodash/maxBy.js';
import uniqueId from 'lodash/uniqueId.js';
import {HTTPResponse, get, post} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import {APIErrorHandler} from '@developers/utils/ErrorUtils';
import ActionTypes from './ActionTypes';

import {
  Endpoints,
  DATE_FORMAT,
  AnalyticsQueryTypes,
  AggregationIntervals,
  GuildAnalyticsPages,
} from '@developers/Constants';
import type {
  ActivationFunnel,
  ActivePlayersByCountry,
  ApplicationId,
  ExternalConversion,
  ImpressionsByType,
  ImpressionsConversionFunnel,
  InstallationsByDate,
  NewPlayersByDate,
  PlayerRetentionByDate,
  ReactivatedPlayersByDate,
  RevenueByCountry,
  RevenueByDate,
  RevenueBySku,
  StoreListingViewsBySource,
  UnitsByCountry,
  UnitsByDate,
  UtmLinkConversion,
  GrowthActivationJoins,
  GrowthActivationOverview,
  GrowthActivationJoinsByInviteLink,
  GrowthActivationJoinsByReferrer,
  GrowthActivationJoinsBySource,
  GrowthActivationLeavers,
  GrowthActivationActivation,
  GrowthActivationMembership,
  EngagementBase,
  EngagementOverview,
  EngagementMuters,
  EngagementPruneableMembers,
  EngagementTextChannels,
  EngagementVoiceChannels,
  AudienceNewMembersByDiscordTenure,
  AudienceParticipatorsByGuildTenure,
  AudienceParticipatorsByPlatform,
  AudienceParticipatorsByRegCountry,
  ChannelFollowingOverview,
  ChannelFollowingByChannelOverview,
  ChannelFollowingReach,
  ChannelFollowingFollowingGuildSize,
  ChannelFollowingGuildSizeByChannel,
  WelcomeScreenFunnel,
  WelcomeScreenUsers,
  PartnerProgramHealth,
  InsightsRoleSubscriptionMembers,
  InsightsRoleSubscriptionEvents,
  InsightsRoleSubscriptionCancellations,
  InsightsRoleSubscriptionPayouts,
  InsightsRoleSubscriptionEstimatedEarnings,
  InsightsRoleSubscriptionPayoutsByPlatform,
  InsightsAudienceActivityByDayOfWeek,
  InsightsAudienceActivityByTimeOfDay,
  InsightsAudienceGenderDistribution,
  InsightsAudienceAgeDistribution,
  InsightsAudienceGuildLeaveReason,
  InsightsAudienceLanguageDistribution,
  InsightsAudienceOtherGuildMembership,
  InsightsAudienceOtherGuildCategories,
  InsightsMessageReachAnnouncementMessageImpressions,
  InsightsMessageReachGuildsFollowingAnnouncementChannel,
  GuildAnalyticsBase,
  ApplicationAnalyticsBase,
} from '@developers/flow/Server';
import type {CSATSurveyResponses} from '@discordapp/common/shared-constants/CSATSurveyResponses';

const handleAnalyticsEndpointResponse = (res: HTTPResponse) => {
  Dispatcher.dispatch({
    type: ActionTypes.ANALYTICS_REQUEST_SUCCESS,
    lastModified: res.headers['last-modified'],
  });

  return res.body;
};

export const fetchAcquirers = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  queryType: (typeof AnalyticsQueryTypes)[keyof typeof AnalyticsQueryTypes],
  interval: number,
) => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_ACQUIRERS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval: interval,
      query_type: queryType,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchImpressionsFunnel = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<ImpressionsConversionFunnel[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_FUNNEL(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchExternalConversions = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<ExternalConversion[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_EXTERNAL(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchUtmConversions = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<UtmLinkConversion[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_UTM(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

const fetchRevenue = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  queryType: (typeof AnalyticsQueryTypes)[keyof typeof AnalyticsQueryTypes],
  interval: number,
) => {
  return get({
    url: Endpoints.ANALYTICS_REVENUE(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
      query_type: queryType,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchRevenueByDate = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<RevenueByDate[]> => {
  return fetchRevenue(applicationId, startDate, endDate, AnalyticsQueryTypes.BY_INTERVAL, interval);
};

export const fetchRevenueByCountry = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<RevenueByCountry[]> => {
  return fetchRevenue(applicationId, startDate, endDate, AnalyticsQueryTypes.BY_COUNTRY, AggregationIntervals.DAILY);
};

export const fetchRevenueBySku = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<RevenueBySku[]> => {
  return fetchRevenue(applicationId, startDate, endDate, AnalyticsQueryTypes.BY_SKU, interval);
};

const fetchUnits = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  queryType: (typeof AnalyticsQueryTypes)[keyof typeof AnalyticsQueryTypes],
  interval: number,
) => {
  return get({
    url: Endpoints.ANALYTICS_UNITS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval: interval,
      query_type: queryType,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchUnitsByDate = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<UnitsByDate[]> => {
  return fetchUnits(applicationId, startDate, endDate, AnalyticsQueryTypes.BY_INTERVAL, interval);
};

export const fetchUnitsByCountry = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<UnitsByCountry[]> => {
  return fetchUnits(applicationId, startDate, endDate, AnalyticsQueryTypes.BY_COUNTRY, AggregationIntervals.DAILY);
};

export const fetchImpressionsByType = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<ImpressionsByType[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_IMPRESSIONS_BY_TYPE(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchStoreListingViews = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<StoreListingViewsBySource[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACQUISITION_STORE_LISTING_VIEWS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchActivePlayersByCountry = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<ActivePlayersByCountry[]> => {
  return get({
    url: Endpoints.ANALYTICS_ENGAGEMENT_ACTIVE_PLAYERS_BY_COUNTRY(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchInstallations = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  queryType: (typeof AnalyticsQueryTypes)[keyof typeof AnalyticsQueryTypes],
  interval: number,
): Promise<InstallationsByDate[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACTIVATION_INSTALLATIONS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
      query_type: queryType,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchActivationsFunnel = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
): Promise<ActivationFunnel> => {
  return get({
    url: Endpoints.ANALYTICS_ACTIVATION_FUNNEL(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchNewPlayers = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<NewPlayersByDate[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACTIVATION_NEW_PLAYERS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchPlayerRetentionByDate = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<PlayerRetentionByDate[]> => {
  return get({
    url: Endpoints.ANALYTICS_ENGAGEMENT_PLAYER_RETENTION_BY_DATE(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const fetchReactivatedPlayers = (
  applicationId: ApplicationId,
  startDate: Date,
  endDate: Date,
  interval: number,
): Promise<ReactivatedPlayersByDate[]> => {
  return get({
    url: Endpoints.ANALYTICS_ACTIVATION_REACTIVATED_PLAYERS(applicationId),
    query: {
      start: formatDate(startDate, DATE_FORMAT),
      end: formatDate(endDate, DATE_FORMAT),
      interval,
    },
    oldFormErrors: true,
  })
    .then(handleAnalyticsEndpointResponse)
    .catch(APIErrorHandler);
};

export const trackDevPortalCSATSurveyResponse = (params: {
  user_id: string;
  csat_response: Values<typeof CSATSurveyResponses>;
}) => {
  return post({
    url: Endpoints.DEV_PORTAL_CSAT_SURVEY_RESPONSE,
    body: params,
  }).catch(APIErrorHandler);
};

function handleGuildAnalyticsEndpointResponse<T extends Array<GuildAnalyticsBase | ApplicationAnalyticsBase>>(
  guildId: string,
  page: string,
  res: HTTPResponse<T>,
) {
  var lastModified: string | null | undefined = null;
  var results: T = res.body;
  if (results != null) {
    results.forEach((item) => {
      // TODO(shen.zhang): This is a stop gap solution for the migration period. For derived tables,
      // `interval_start_timestamp` is used as the timestamp for the data point. However, this field is renamed
      // to `day_pt` for the T2 tables. This code block creates a `interval_start_timestamp` field for all `day_pt`
      // returned. Once the migration is complete, we should rename all the timestamp to `day_pt` and this block
      // can be removed.
      if (item.day_pt != null && item.interval_start_timestamp == null) {
        item.interval_start_timestamp = item.day_pt;
      }
      if (item.interval_start_timestamp != null) {
        lastModified = maxBy([item.interval_start_timestamp, lastModified], (ts) => ts);
      }
    });
  }
  return results;
}

// Server Insights - function generator for fetch actions
function createFetchGuildAnalyticsAction<T extends Array<GuildAnalyticsBase | ApplicationAnalyticsBase>>(
  endpoint: (guildId: string) => string,
  page: string,
): {
  (guildId: string, startDate: Date, endDate: Date, interval: number, useT2?: string | null | undefined): Promise<T>;
  _uid: string;
} {
  const action = (
    guildId: string,
    startDate: Date,
    endDate: Date,
    interval: number,
    useT2?: string | null | undefined,
  ) => {
    return get({
      url: endpoint(guildId),
      query: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        interval,
        use_t2: useT2 ?? null,
      },
      oldFormErrors: true,
    })
      .then((res) => handleGuildAnalyticsEndpointResponse<T>(guildId, page, res))
      .catch(APIErrorHandler);
  };
  // used by useCachedGuildAnalyticsRouteData to uniquely identify the action
  action._uid = uniqueId();
  return action;
}

function createFetchGuildAnalyticsByChannelAction<T>(
  endpoint: (guildId: string) => string,
  page: string,
): (guildId: string, startDate: Date, endDate: Date, interval: number, channelId: string) => Promise<T> {
  return (guildId: string, startDate: Date, endDate: Date, interval: number, channelId: string) => {
    return get({
      url: endpoint(guildId),
      query: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        interval,
        channel_id: channelId,
      },
      oldFormErrors: true,
    })
      .then((res) => handleGuildAnalyticsEndpointResponse(guildId, page, res))
      .catch(APIErrorHandler);
  };
}

// Server Insights - Growth and Activation
export const fetchGrowthActivationJoins = createFetchGuildAnalyticsAction<GrowthActivationJoins[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationOverview = createFetchGuildAnalyticsAction<GrowthActivationOverview[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_OVERVIEW,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationJoinsByInviteLink = createFetchGuildAnalyticsAction<
  GrowthActivationJoinsByInviteLink[]
>(Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_INVITE_LINK, GuildAnalyticsPages.GROWTH_ACTIVATION);

export const fetchGrowthActivationJoinsByReferrer = createFetchGuildAnalyticsAction<GrowthActivationJoinsByReferrer[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_REFERRER,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationJoinsBySource = createFetchGuildAnalyticsAction<GrowthActivationJoinsBySource[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_JOINS_BY_SOURCE,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationLeavers = createFetchGuildAnalyticsAction<GrowthActivationLeavers[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_LEAVERS,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationActivation = createFetchGuildAnalyticsAction<GrowthActivationActivation[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_ACTIVATION,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationRetention = createFetchGuildAnalyticsAction<GrowthActivationActivation[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_RETENTION,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

export const fetchGrowthActivationMembership = createFetchGuildAnalyticsAction<GrowthActivationMembership[]>(
  Endpoints.GUILD_ANALYTICS_GROWTH_ACTIVATION_MEMBERSHIP,
  GuildAnalyticsPages.GROWTH_ACTIVATION,
);

// Server Insights - Engagement
export const fetchEngagementBase = createFetchGuildAnalyticsAction<EngagementBase[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_BASE,
  GuildAnalyticsPages.ENGAGEMENT,
);

export const fetchEngagementOverview = createFetchGuildAnalyticsAction<EngagementOverview[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_OVERVIEW,
  GuildAnalyticsPages.ENGAGEMENT,
);

export const fetchEngagementMuters = createFetchGuildAnalyticsAction<EngagementMuters[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_MUTERS,
  GuildAnalyticsPages.ENGAGEMENT,
);

export const fetchEngagementPruneableMembers = createFetchGuildAnalyticsAction<EngagementPruneableMembers[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_PRUNEABLE_MEMBERS,
  GuildAnalyticsPages.ENGAGEMENT,
);

export const fetchEngagementTextChannels = createFetchGuildAnalyticsAction<EngagementTextChannels[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_TEXT_CHANNELS,
  GuildAnalyticsPages.ENGAGEMENT,
);

export const fetchEngagementVoiceChannels = createFetchGuildAnalyticsAction<EngagementVoiceChannels[]>(
  Endpoints.GUILD_ANALYTICS_ENGAGEMENT_VOICE_CHANNELS,
  GuildAnalyticsPages.ENGAGEMENT,
);

// Server Insights - Audience
export const fetchAudienceNewMembersByDiscordTenure = createFetchGuildAnalyticsAction<
  AudienceNewMembersByDiscordTenure[]
>(Endpoints.GUILD_ANALYTICS_AUDIENCE_NEW_MEMBERS_BY_DISCORD_TENURE, GuildAnalyticsPages.AUDIENCE);

export const fetchAudienceParticipatorsByGuildTenure = createFetchGuildAnalyticsAction<
  AudienceParticipatorsByGuildTenure[]
>(Endpoints.GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_GUILD_TENURE, GuildAnalyticsPages.AUDIENCE);

export const fetchAudienceParticipatorsByPlatform = createFetchGuildAnalyticsAction<AudienceParticipatorsByPlatform[]>(
  Endpoints.GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_PLATFORM,
  GuildAnalyticsPages.AUDIENCE,
);

export const fetchAudienceParticipatorsByRegCountry = createFetchGuildAnalyticsAction<
  AudienceParticipatorsByRegCountry[]
>(Endpoints.GUILD_ANALYTICS_AUDIENCE_PARTICIPATORS_BY_REG_COUNTRY, GuildAnalyticsPages.AUDIENCE);

// Server Insights - Announcement Channels
export const fetchChannelFollowingOverview = createFetchGuildAnalyticsAction<ChannelFollowingOverview[]>(
  Endpoints.GUILD_ANALYTICS_CHANNEL_FOLLOWING_OVERVIEW,
  GuildAnalyticsPages.CHANNEL_FOLLOWING,
);

export const fetchChannelFollowingByChannelOverview = createFetchGuildAnalyticsAction<
  ChannelFollowingByChannelOverview[]
>(Endpoints.GUILD_ANALYTICS_CHANNEL_FOLLOWING_BY_CHANNEL_OVERVIEW, GuildAnalyticsPages.CHANNEL_FOLLOWING);

export const fetchChannelFollowingReach = createFetchGuildAnalyticsAction<ChannelFollowingReach[]>(
  Endpoints.GUILD_ANALYTICS_CHANNEL_FOLLOWING_REACH,
  GuildAnalyticsPages.CHANNEL_FOLLOWING,
);

export const fetchChannelFollowingFollowingGuildSize = createFetchGuildAnalyticsAction<
  ChannelFollowingFollowingGuildSize[]
>(Endpoints.GUILD_ANALYTICS_CHANNEL_FOLLOWING_FOLLOWING_GUILD_SIZE, GuildAnalyticsPages.CHANNEL_FOLLOWING);

export const fetchChannelFollowingGuildSizeByChannel = createFetchGuildAnalyticsByChannelAction<
  ChannelFollowingGuildSizeByChannel[]
>(Endpoints.GUILD_ANALYTICS_CHANNEL_FOLLOWING_GUILD_SIZE_BY_CHANNEL, GuildAnalyticsPages.CHANNEL_FOLLOWING);

// Server Insights - Welcome Screen
export const fetchWelcomeScreenFunnel = createFetchGuildAnalyticsAction<WelcomeScreenFunnel[]>(
  Endpoints.GUILD_ANALYTICS_WELCOME_SCREEN_FUNNEL,
  GuildAnalyticsPages.WELCOME_SCREEN,
);

export const fetchWelcomeScreenUsers = createFetchGuildAnalyticsAction<WelcomeScreenUsers[]>(
  Endpoints.GUILD_ANALYTICS_WELCOME_SCREEN_USERS,
  GuildAnalyticsPages.WELCOME_SCREEN,
);

// Server Insights - Partner Program
export const fetchPartnerProgramHealth = createFetchGuildAnalyticsAction<PartnerProgramHealth[]>(
  Endpoints.GUILD_ANALYTICS_PARTNER_PROGRAM_HEALTH,
  GuildAnalyticsPages.PARTNER,
);

// Server Insights - Role Subscriptions
export const fetchRoleSubscriptionEvents = createFetchGuildAnalyticsAction<InsightsRoleSubscriptionEvents[]>(
  Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_EVENTS,
  GuildAnalyticsPages.ROLE_SUBSCRIPTIONS,
);

export const fetchRoleSubscriptionCancellation = createFetchGuildAnalyticsAction<
  InsightsRoleSubscriptionCancellations[]
>(Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_CANCELLATIONS, GuildAnalyticsPages.ROLE_SUBSCRIPTIONS);

export const fetchRoleSubscriptionPayouts = createFetchGuildAnalyticsAction<InsightsRoleSubscriptionPayouts[]>(
  Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_PAYOUTS,
  GuildAnalyticsPages.ROLE_SUBSCRIPTIONS,
);

export const fetchRoleSubscriptionMembers = createFetchGuildAnalyticsAction<InsightsRoleSubscriptionMembers[]>(
  Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_MEMBERS,
  GuildAnalyticsPages.ROLE_SUBSCRIPTIONS,
);

export const fetchRoleSubscriptionEstimatedEarnings = createFetchGuildAnalyticsAction<
  InsightsRoleSubscriptionEstimatedEarnings[]
>(Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_ESTIMATED_EARNINGS, GuildAnalyticsPages.ROLE_SUBSCRIPTIONS);

export const fetchRoleSubscriptionPayoutsByPlatform = createFetchGuildAnalyticsAction<
  InsightsRoleSubscriptionPayoutsByPlatform[]
>(Endpoints.GUILD_ANALYTICS_ROLE_SUBSCRIPTION_PAYOUTS_BY_PLATFORM, GuildAnalyticsPages.ROLE_SUBSCRIPTIONS);

export const fetchActivityByDayOfWeek = createFetchGuildAnalyticsAction<InsightsAudienceActivityByDayOfWeek[]>(
  Endpoints.GUILD_ANALYTICS_ACTIVITY_BY_DAY_OF_WEEK,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchActivityByTimeOfDay = createFetchGuildAnalyticsAction<InsightsAudienceActivityByTimeOfDay[]>(
  Endpoints.GUILD_ANALYTICS_ACTIVITY_BY_TIME_OF_DAY,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchGenderDistribution = createFetchGuildAnalyticsAction<InsightsAudienceGenderDistribution[]>(
  Endpoints.GUILD_ANALYTICS_GENDER_DISTRIBUTION,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchAgeDistribution = createFetchGuildAnalyticsAction<InsightsAudienceAgeDistribution[]>(
  Endpoints.GUILD_ANALYTICS_AGE_DISTRIBUTION,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchLanguageDistribution = createFetchGuildAnalyticsAction<InsightsAudienceLanguageDistribution[]>(
  Endpoints.GUILD_ANALYTICS_LANGUAGE_DISTRIBUTION,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchOtherGuildMembership = createFetchGuildAnalyticsAction<InsightsAudienceOtherGuildMembership[]>(
  Endpoints.GUILD_ANALYTICS_OTHER_GUILD_MEMBERSHIP,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchOtherGuildCategories = createFetchGuildAnalyticsAction<InsightsAudienceOtherGuildCategories[]>(
  Endpoints.GUILD_ANALYTICS_OTHER_GUILD_CATEGORIES,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchGuildLeaveReason = createFetchGuildAnalyticsAction<InsightsAudienceGuildLeaveReason[]>(
  Endpoints.GUILD_ANALYTICS_GUILD_LEAVE_REASON,
  GuildAnalyticsPages.PROJECT_TETRIS,
);

export const fetchAnnouncementMessageImpressions = createFetchGuildAnalyticsAction<
  InsightsMessageReachAnnouncementMessageImpressions[]
>(Endpoints.GUILD_ANALYTICS_ANNOUNCEMENT_MESSAGE_IMPRESSIONS, GuildAnalyticsPages.PROJECT_TETRIS);

export const fetchGuildsFollowingAnnouncementChannel = createFetchGuildAnalyticsAction<
  InsightsMessageReachGuildsFollowingAnnouncementChannel[]
>(Endpoints.GUILD_ANALYTICS_GUILDS_FOLLOWING, GuildAnalyticsPages.PROJECT_TETRIS);
