/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum ChannelTypes {
  DM = 1,
  GROUP_DM = 3,
  GUILD_TEXT = 0,
  GUILD_VOICE = 2,
  GUILD_CATEGORY = 4,
  GUILD_ANNOUNCEMENT = 5,
  GUILD_STORE = 6,
  ANNOUNCEMENT_THREAD = 10,
  PUBLIC_THREAD = 11,
  PRIVATE_THREAD = 12,
  GUILD_STAGE_VOICE = 13,
  GUILD_DIRECTORY = 14,
  GUILD_FORUM = 15,
  GUILD_MEDIA = 16,
  LOBBY = 17,
  UNKNOWN = 10000,
}

export const ChannelTypesSets = {
  CALLABLE: new Set([1, 3]),
  TEXTUAL: new Set([0, 1, 2, 3, 5, 10, 11, 12, 13]),
  GUILD_THREADS_ONLY: new Set([15, 16]),
  STICKERS: new Set([0, 1, 2, 3, 5, 10, 11, 12, 13, 15, 16]),
  READABLE: new Set([0, 1, 2, 3, 5, 10, 11, 12, 13, 14, 15, 16]),
  GUILD: new Set([0, 2, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16]),
  THREADS: new Set([10, 11, 12]),
  PUBLIC_THREADS: new Set([10, 11]),
  GUILD_THREADED: new Set([0, 5, 15, 16]),
  GUILD_STORED: new Set([0, 2, 4, 5, 6, 13, 14, 15, 16]),
  GUILD_TEXTUAL: new Set([0, 2, 5, 10, 11, 12, 13]),
  GUILD_VOCAL: new Set([2, 13]),
  VOCAL_THREAD: new Set([11, 12]),
  VOCAL: new Set([1, 2, 3, 11, 12, 13]),
  VOICE_EFFECTS: new Set([1, 2, 3, 11, 12]),
  GUILD_TEXT_ONLY: new Set([0, 5, 10, 11, 12]),
  LIMITED_CHANNEL_NAME: new Set([0, 5, 10, 11, 12, 15, 16]),
  SEARCHABLE: new Set([0, 1, 2, 3, 5, 10, 11, 12, 13, 15, 16]),
  GUILD_USER_CONTENT: new Set([0, 2, 5, 10, 11, 12, 13, 15, 16]),
  GUILD_TOPICAL: new Set([0, 5, 13, 14, 15, 16]),
  GUILD_WEBHOOKS: new Set([0, 2, 5, 15, 16]),
  GUILD_SYSTEM_CHANNEL: new Set([0, 5]),
  GUILD_PARENTABLE: new Set([0, 2, 5, 10, 11, 12, 13, 14, 15, 16]),
  GUILD_AUTO_MODERATED: new Set([0, 2, 5, 10, 11, 12, 13, 15, 16]),
  GUILD_BASIC: new Set([0, 2, 4]),
  CREATEABLE_GUILD_CHANNELS: new Set([0, 2, 4, 5, 6, 13, 14, 15, 16]),
  MULTI_USER_DMS: new Set([3]),
  ALL_DMS: new Set([1, 3]),
  INVITABLE: new Set([0, 2, 3, 5, 6, 13, 14, 15, 16]),
  GUILD_FEED_FEATURABLE_MESSAGES: new Set([0, 5, 11]),
  ROLE_SUBSCRIPTIONS: new Set([0, 2, 5, 13, 15, 16]),
  ICON_EMOJIS: new Set([0, 2, 5, 13, 15]),
  SUMMARIZEABLE: new Set([0]),
  CONTENT_ENTRY_EMBEDS: new Set([0, 1, 3, 5]),
  POLLS: new Set([0, 1, 2, 3, 5, 10, 11, 12, 13]),
  ACTIVITY_LAUNCHABLE: new Set([0, 1, 2, 3]),
  ALL: new Set([0, 1, 2, 3, 4, 5, 6, 10, 11, 12, 13, 14, 15, 16]),
};
