/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 *
 * Constants in this file are generated from the Python discord_common.shared_constants module.
 * Run `clyde gen ts-constants` to regenerate.
 */
export enum UserIdentityVerificationStatusTypes {
  REQUIRES_ACTION = 1,
  PROCESSING = 2,
  CANCELED = 3,
  SUCCEEDED = 4,
  MANUALLY_SUCCEEDED = 5,
  DELETED = 6,
  SUCCEEDED_GRACE_PERIOD = 7,
}

export const UserIdentityVerificationStatusTypesSets = {
  ALL: new Set([1, 2, 3, 4, 5, 6, 7]),
  ALL_SUCCESS: new Set([4, 5, 7]),
  NON_GRACE_PERIOD_SUCCESS: new Set([4, 5]),
  REVERIFIABLE: new Set([3, 7]),
};
