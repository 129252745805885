import * as DevtoolsExtension from '@discordapp/common/DevtoolsExtension';
import {Logger} from '@discordapp/common/Logger';

import * as LastFewActions from './LastFewActions';

import type {Store, AnyStore, SyncWith} from './Store';

const logger = new Logger('Flux');
const SLOW_EMIT_THRESHOLD = 100;
let batchEmitChanges = (callback: () => void) => callback();

class Emitter {
  private changedStores: Set<AnyStore> = new Set();
  private reactChangedStores: Set<AnyStore> = new Set();

  private changeSentinel = 0;
  isBatchEmitting = false;
  isDispatching = false;

  private isPaused: boolean = false;
  // In discord_web this is a number, but in discord_admin it's a Timeout :(
  private pauseTimer: any /* number | null */ = null;

  destroy() {
    this.changedStores.clear();
    this.reactChangedStores.clear();
    batchEmitChanges = (callback) => callback();
  }

  injectBatchEmitChanges(callback: (callback: () => void) => any) {
    batchEmitChanges = callback;
  }

  /**
   * Pause emitting changes to store listeners
   * @param delay If provided, will only pause emitting changes for `delay` ms.
   */
  pause(delay: number | null = null) {
    this.isPaused = true;
    if (this.pauseTimer !== null) {
      clearTimeout(this.pauseTimer);
    }
    if (delay !== null) {
      this.pauseTimer = setTimeout(() => {
        this.pauseTimer = null;
        this.resume();
      }, delay);
    }
  }

  /**
   * Resume emitting changes to store listeners after the next event loop tick.
   */
  resume(scheduleEmitChanges: boolean = true) {
    clearTimeout(this.pauseTimer);
    this.pauseTimer = null;

    if (this.isPaused) {
      this.isPaused = false;
      if (scheduleEmitChanges && this.changedStores.size > 0) {
        setImmediate(() => this.emit());
      }
    }
  }

  batched<T>(callback: () => T) {
    if (this.isPaused) {
      return callback();
    }

    try {
      this.isPaused = true;
      return callback();
    } finally {
      this.resume(false /* scheduleEmitChanges */);
      this.emit();
    }
  }

  /**
   * Emit changes on all stores at once.
   */
  emit() {
    if (!this.isBatchEmitting && !this.isPaused) {
      batchEmitChanges(() => {
        try {
          this.isBatchEmitting = true;
          this.changeSentinel++;
          // It's possible that stores listen on change events from other stores, and that can lead to cascading
          // re-renders. So when a store emits a change event while we are emitting, we'll add that store to
          // the set of changed stores tracked in instance variables. Once we've fully flushed the _initial_ set
          // of changed stores, we'll go back and flush changes for the newly changed stores too, all within the same
          // `batchEmitChanges()` react callback
          let emitCount = 0;

          const syncWiths = new Set<SyncWith>();
          const allChangedStores = new Set<AnyStore>();
          while (this.changedStores.size > 0) {
            if (++emitCount > 100) {
              logger.error('LastFewActions', LastFewActions.serialize());
              throw Error('change emit loop detected, aborting');
            }
            this.emitNonReactOnce(syncWiths, allChangedStores);
          }
          while (this.reactChangedStores.size > 0) {
            if (++emitCount > 100) {
              logger.error('LastFewActions', LastFewActions.serialize());
              throw Error('react change emit loop detected, aborting');
            }
            this.emitReactOnce();
          }
        } finally {
          this.isBatchEmitting = false;
        }
      });
    }
  }

  getChangeSentinel() {
    return this.changeSentinel;
  }

  getIsPaused() {
    return this.isPaused;
  }

  markChanged(store: Store<any>) {
    if (store._changeCallbacks.hasAny() || store._syncWiths.length > 0) {
      this.changedStores.add(store);
    }
    if (store._reactChangeCallbacks.hasAny()) {
      this.reactChangedStores.add(store);
    }

    if (!this.isBatchEmitting && !this.isDispatching && !this.isPaused) {
      this.emit();
    }
  }

  private emitNonReactOnce(syncWiths: Set<SyncWith>, allChangedStores: Set<AnyStore>) {
    const start = Date.now();

    // Each time changes are emitted, the set of changed stores will be reset to a new set
    // This way if a store emits a change while we are batch emitting, we can track that separately
    // and be sure to emit changes for that store later
    const stores = this.changedStores;
    this.changedStores = new Set();
    stores.forEach((store) => {
      if (process.env.NODE_ENV === 'development') {
        DevtoolsExtension.notifyStoreChange(store.getName());
      }

      allChangedStores.add(store);
      store._changeCallbacks.invokeAll();

      // See above, but this ensures that if a store calls emitChange() while we are batch emitting for some reason
      // we won't double-emit
      this.changedStores.delete(store);
    });

    stores.forEach((store) => {
      store._syncWiths.forEach(({func, store: storeToMarkChanged}) => {
        if (syncWiths.has(func)) return;
        syncWiths.add(func);

        const returnValue = func();
        if (returnValue !== false) {
          if (!allChangedStores.has(storeToMarkChanged)) {
            allChangedStores.add(storeToMarkChanged);
            this.markChanged(storeToMarkChanged);
          }
        }
      });
    });

    const end = Date.now();
    if (end - start > SLOW_EMIT_THRESHOLD) {
      logger.verbose(`Slow batch emitChanges took ${end - start}ms recentActions:`, LastFewActions.serialize());
    }
  }

  private emitReactOnce() {
    const start = Date.now();

    // Each time changes are emitted, the set of changed stores will be reset to a new set
    // This way if a store emits a change while we are batch emitting, we can track that separately
    // and be sure to emit changes for that store later
    const stores = this.reactChangedStores;
    this.reactChangedStores = new Set();
    stores.forEach((store) => {
      if (process.env.NODE_ENV === 'development') {
        DevtoolsExtension.notifyStoreChange(store.getName());
      }

      store._reactChangeCallbacks.invokeAll();

      // See above, but this ensures that if a store calls emitChange() while we are batch emitting for some reason
      // we won't double-emit
      this.reactChangedStores.delete(store);
    });

    const end = Date.now();
    if (end - start > SLOW_EMIT_THRESHOLD) {
      logger.verbose(`Slow batch emitReactChanges took ${end - start}ms recentActions:`, LastFewActions.serialize());
    }
  }
}

export default new Emitter();
