import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {FocusRingScope} from 'react-focus-rings';
import {HelmetProvider} from 'react-helmet-async';
import {
  Navigate,
  Route,
  Routes as Switch,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
} from 'react-router-dom';

import {enforceUserAuthentication} from '@developers/actions/UserActionCreators';
import {makeLazyWithLoadingIndicator} from '@developers/utils/LazyLoadUtils';
import WinterSnowflakes from './WinterSnowflakes';
import Applications from './applications';
import ErrorBoundary from './common/ErrorBoundary';
import PageWrapper from './common/PageWrapper';
import {CommonConfettiCannonProvider} from './common/context/CommonConfettiCannonProvider';
import ErrorPage from './common/errors';

import {Routes, urlBasename} from '@developers/Constants';

const ApplicationRoutesLoader = {
  createPromise: () => import('./ApplicationRoutes.tsx' /* webpackChunkName: 'applications' */),
  webpackId: require.resolveWeak('./ApplicationRoutes'),
};
const ApplicationRoutes = makeLazyWithLoadingIndicator(ApplicationRoutesLoader);

const TeamRoutesLoader = {
  createPromise: () => import('./TeamRoutes.tsx' /* webpackChunkName: 'teams' */),
  webpackId: require.resolveWeak('./TeamRoutes'),
};
const TeamRoutes = makeLazyWithLoadingIndicator(TeamRoutesLoader);

const DocumentationWrapper = {
  createPromise: () => import('./DocumentationRoutes.tsx' /* webpackChunkName: 'documentation' */),
  webpackId: require.resolveWeak('./DocumentationRoutes'),
};
const GuildWrapperLoader = {
  createPromise: () => import('./GuildRoutes.tsx' /* webpackChunkName: 'guilds' */),
  webpackId: require.resolveWeak('./GuildRoutes'),
};
const GuildWrapper = makeLazyWithLoadingIndicator(GuildWrapperLoader);

const HandoffExchange = {
  createPromise: () => import('./common/HandoffExchange.tsx' /* webpackChunkName: 'handoff-resolution' */),
  webpackId: require.resolveWeak('./common/HandoffExchange'),
};
const PopupWindowTransition = {
  createPromise: () => import('./common/PopupWindowTransition.tsx' /* webpackChunkName: 'popup-window-transition' */),
  webpackId: require.resolveWeak('./common/PopupWindowTransition'),
};

const TeamsLoader = {
  createPromise: () => import('./teams/index.tsx' /* webpackChunkName: 'teams' */),
  webpackId: require.resolveWeak('./teams'),
};
const Teams = makeLazyWithLoadingIndicator(TeamsLoader);
const ActiveDeveloperProgramLoader = {
  createPromise: () => import('./active_developer/index.tsx' /* webpackChunkName: 'active-developer' */),
  webpackId: require.resolveWeak('./active_developer'),
};
const ActiveDeveloperProgram = makeLazyWithLoadingIndicator(ActiveDeveloperProgramLoader);

const EmbedsProgramLoader = {
  createPromise: () => import('./embeds/index.tsx' /* webpackChunkName: 'embeds' */),
  webpackId: require.resolveWeak('./embeds'),
};
const EmbedsDebugger = makeLazyWithLoadingIndicator(EmbedsProgramLoader);

const LocalDevAuth = __DEV__
  ? makeLazyWithLoadingIndicator({
      createPromise: () => import('./development_auth/LocalDevAuth.tsx' /* webpackChunkName: 'local-dev-auth' */),
      webpackId: require.resolveWeak('./development_auth/LocalDevAuth'),
    })
  : null;

const ProtectedRoute = ({children}: {children: JSX.Element}) => {
  const location = useLocation();
  enforceUserAuthentication(location.pathname + location.search);
  return children;
};

function renderPageWrapper() {
  return (
    <PageWrapper>
      <WinterSnowflakes />
      <Switch>
        <Route
          path={Routes.APPLICATIONS}
          element={
            <ProtectedRoute>
              <Applications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/applications/:id/*"
          element={
            <ProtectedRoute>
              <ApplicationRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.TEAMS}
          element={
            <ProtectedRoute>
              <Teams />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.TEAM_INVITE_ACCEPT}
          element={
            <ProtectedRoute>
              <Teams />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teams/:teamId/*"
          element={
            <ProtectedRoute>
              <TeamRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/servers/*"
          element={
            <ProtectedRoute>
              <GuildWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.ACTIVE_DEVELOPER_PROGRAM}
          element={
            <ProtectedRoute>
              <ActiveDeveloperProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.EMBED_DEBUGGER}
          element={
            <ProtectedRoute>
              <EmbedsDebugger />
            </ProtectedRoute>
          }
        />
        <Route path={Routes.DEVELOPERS_DOCS} element={<Navigate replace to={Routes.DOCS_INTRO} />} />
        <Route path="/docs/*" element={makeLazyWithLoadingIndicator(DocumentationWrapper)()} />
        {__DEV__ && LocalDevAuth != null ? (
          <Route path={Routes.LOCAL_DEVELOPMENT_AUTH} element={<LocalDevAuth />} />
        ) : null}
        <Route
          path={Routes.INDEX}
          element={<Navigate replace to={{pathname: Routes.APPLICATIONS, search: global.location.search}} />}
        />
        <Route path={Routes.ERROR_404} element={<ErrorPage />} />
        <Route element={<ErrorPage />} />
      </Switch>
    </PageWrapper>
  );
}

export const render = () => {
  const mountPoint = document.getElementById('app-mount');
  if (mountPoint == null) {
    throw new Error('App mount point not found.');
  }
  const root = createRoot(mountPoint);
  const containerRef = {current: mountPoint};

  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path={Routes.HANDOFF_EXCHANGE} element={makeLazyWithLoadingIndicator(HandoffExchange)()} />,
      <Route
        path={Routes.POPUP_WINDOW_TRANSITION_LOADING}
        element={makeLazyWithLoadingIndicator(PopupWindowTransition)()}
      />,
      <Route
        path={Routes.POPUP_WINDOW_TRANSITION_CALLBACK}
        element={makeLazyWithLoadingIndicator(PopupWindowTransition)()}
      />,
      <Route path="*" element={renderPageWrapper()} />,
    ]),
    {
      basename: urlBasename,
    },
  );

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <HelmetProvider>
          <FocusRingScope containerRef={containerRef}>
            <CommonConfettiCannonProvider>
              <RouterProvider router={router} />
            </CommonConfettiCannonProvider>
          </FocusRingScope>
        </HelmetProvider>
      </ErrorBoundary>
    </React.StrictMode>,
  );
};
