export function logFluxAction(action: any, durationMs: number): void {
  reportEvent({
    type: 'Flux-Dispatch',
    description: action.type,
    data: action,
    durationMs,
  });
}

export function reportEvent(data: any): void {
  getDevtools()?.reportEvent?.(data);
}

export function notifyStoreCreated(storeName: string): void {
  getDevtools()?.notifyStoreCreated?.({storeName});
}

export function notifyStoreChange(storeName: string): void {
  getDevtools()?.notifyStoreChange?.({storeName});
}

function getDevtools() {
  return typeof window !== 'undefined' ? window.__DISCORD_DEVTOOLS : null;
}
