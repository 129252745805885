import {EventEmitter} from 'events';

const THRESHOLD = 160;
const INTERVAL = 500;

enum ORIENTATIONS {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface DevToolsType {
  open: boolean;
  orientation: ORIENTATIONS | null;
}

let devTools: DevToolsType = {
  open: false,
  orientation: null,
};

function widthDifference() {
  try {
    return window.outerWidth - window.innerWidth;
  } catch (e) {
    return 0;
  }
}

function heightDifference() {
  try {
    return window.outerHeight - window.innerHeight;
  } catch (e) {
    return 0;
  }
}

export class DevToolsListener extends EventEmitter {
  constructor() {
    super();

    setInterval(() => this.check(), INTERVAL);
  }

  get orientations() {
    return Object.values(ORIENTATIONS);
  }

  get state() {
    return devTools;
  }

  check() {
    const widthThreshold = widthDifference() > THRESHOLD;
    const heightThreshold = heightDifference() > THRESHOLD;
    const orientation = widthThreshold ? ORIENTATIONS.VERTICAL : ORIENTATIONS.HORIZONTAL;

    if (!(heightThreshold && widthThreshold) && (widthThreshold || heightThreshold)) {
      const open = devTools.open;

      devTools = {
        open: true,
        orientation,
      };

      if (!open || devTools.orientation !== orientation) {
        this.emit('changed', devTools);
      }
    } else if (devTools.open) {
      devTools.open = false;

      this.emit('changed', devTools);
    }
  }
}
