import * as React from 'react';
import classNames from 'classnames';
import Select, {
  GroupBase,
  components as selectComponents,
  SelectComponentsConfig,
  SelectInstance,
  type Props as ReactSelectProps,
} from 'react-select';

import {KeyboardKeys} from '@developers/Constants';
import {i18n} from '@developers/i18n';
import styles from './Select.module.css';

export enum MenuPlacements {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface ThemeType {
  colors: Record<string, string>;
}

export type MenuPlacement = MenuPlacements;
type ProvidedStyles = React.CSSProperties;

export const DEFAULT_SELECT_STYLES = {
  container: (provided: ProvidedStyles, {isDisabled}: {isDisabled: boolean}) => ({
    ...provided,
    cursor: isDisabled ? 'not-allowed' : null,
    pointerEvents: null,
  }),
  control: (
    provided: ProvidedStyles,
    {
      isDisabled,
      isFocused,
      menuIsOpen,
      theme: {colors},
    }: {isDisabled: boolean; isFocused: boolean; menuIsOpen: boolean; theme: ThemeType},
  ) => ({
    ...provided,
    borderColor: isDisabled ? colors.neutral10 : isFocused ? colors.primary : colors.neutral20,
    boxShadow: null,
    borderRadius: menuIsOpen ? '4px 4px 0 0' : '4px',
    minHeight: 40,
    transition: 'border 0.15s ease',
    cursor: isDisabled ? 'not-allowed' : null,
    pointerEvents: isDisabled ? 'none' : null,

    ':hover': {
      borderColor: isDisabled ? colors.neutral10 : colors.primary,
    },
  }),
  singleValue: (provided: ProvidedStyles, {isDisabled, theme: {colors}}: {isDisabled: boolean; theme: ThemeType}) => ({
    ...provided,
    color: colors.text,
    opacity: isDisabled ? 0.5 : 1,
  }),
  input: (provided: ProvidedStyles, {theme: {colors}}: {theme: ThemeType}) => ({
    ...provided,
    color: colors.text,
  }),
  menu: (provided: ProvidedStyles, {theme: {colors}}: {theme: ThemeType}) => ({
    ...provided,
    backgroundColor: colors.menuBackground,
    border: `1px solid ${colors.menuBorder}`,
    borderRadius: '0 0 4px 4px',
    boxShadow: `0 1px 5px 0 ${colors.neutral20}`,
    color: colors.text,
    marginTop: -1,
    marginBottom: -1,
    zIndex: 125, // Show over the unsaved changes bar
  }),
  clearIndicator: (
    provided: ProvidedStyles,
    {isDisabled, theme: {colors}}: {isDisabled: boolean; theme: ThemeType},
  ) => ({
    ...provided,
    color: colors.text,
    cursor: !isDisabled ? 'pointer' : null,
    opacity: 0.3,
    padding: '8px 0',
    transform: 'scale(0.8)',

    ':hover': {
      color: colors.danger,
      opacity: 1,
    },
  }),
  indicatorsContainer: (provided: ProvidedStyles) => ({
    ...provided,
    alignItems: 'flex-start',
  }),
  dropdownIndicator: (
    provided: ProvidedStyles,
    {isDisabled, theme: {colors}}: {isFocused: boolean; isDisabled: boolean; theme: ThemeType},
  ) => ({
    ...provided,
    color: colors.interactive,
    cursor: !isDisabled ? 'pointer' : null,
    opacity: !isDisabled ? 1 : 0.3,
    padding: '8px 8px 8px 0',

    ':hover': {
      color: colors.interactiveHover,
      opacity: !isDisabled ? 1 : 0.3,
    },
  }),
  menuList: (provided: ProvidedStyles, {theme: {colors}}: {theme: ThemeType}) => ({
    ...provided,
    padding: 0,

    '&::-webkit-scrollbar': {
      width: 8,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.scrollBarThumb,
      borderColor: 'transparent',
      backgroundClip: 'padding-box',
      borderRadius: 4,
    },

    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  }),
  option: (
    provided: ProvidedStyles,
    {isSelected, isFocused, theme: {colors}}: {isSelected: boolean; isFocused: boolean; theme: ThemeType},
  ) =>
    ({
      ...provided,
      backgroundColor: isSelected ? colors.selectedOptionBackground : isFocused ? colors.primary25 : 'transparent',
      color: colors.text,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      minHeight: 40,
    }) as React.CSSProperties,
  placeholder: (provided: ProvidedStyles, {theme: {colors}}: {theme: ThemeType}) => ({
    ...provided,
    color: colors.placeholder,
  }),
};

export const THEME_COLORS = {
  neutral0: 'var(--input-background)',
  neutral5: 'var(--background-secondary)',
  neutral10: 'var(--background-secondary-alt)',
  neutral20: 'var(--background-tertiary)',
  neutral30: 'var(--background-accent)',
  primary: 'var(--background-tertiary)',
  primary25: 'var(--background-secondary-alt)',
  primary50: 'var(--background-secondary)',
  selectedOptionBackground: 'var(--background-primary)',
  text: 'var(--text)',
  placeholder: 'var(--text-muted)',
  menuBackground: 'var(--background-secondary)',
  menuBorder: 'var(--background-secondary-alt)',
  scrollBarThumb: 'var(--background-secondary)',
  multiOptionBackground: 'var(--background-secondary)',
  interactive: 'var(--text)',
  interactiveHover: 'var(--text-muted)',
};

type FilterOption<Option, IsMulti extends boolean> = ReactSelectProps<Option, IsMulti>['filterOption'];

interface SingleSelectType<T, Option extends SingleOption<T>> {
  isMulti?: false;
  value?: T | null;
  onChange: (value: Option) => void;
  filterOption?: FilterOption<Option, false>;
}

interface MultiSelectType<T, Option extends SingleOption<T>> {
  isMulti: true;
  value?: T[] | null;
  onChange: (value: Option[]) => void;
  filterOption?: FilterOption<Option, true>;
}

interface SingleOption<T> {
  value: T;
}
interface OptionGroup<T> {
  label: React.ReactNode;
  options: Array<SingleOption<T>>;
}
type OptionOrOptionGroup<T> = SingleOption<T> | OptionGroup<T>;

type SelectProps<T, Option extends OptionOrOptionGroup<T>> = {
  options: Option[];
  error?: string | null;
  className?: string;
  menuShouldScrollIntoView?: boolean;
  placeholder?: React.ReactNode;
  autofocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  name?: string;
  'aria-label'?: string;
  menuPlacement?: MenuPlacement;
  maxMenuHeight?: number;
  onFocus?: (e: React.SyntheticEvent, name?: string) => void;
  onBlur?: (e: React.SyntheticEvent, name?: string) => void;
  valueRenderer?: (option: Option) => React.ReactNode;
  optionRenderer?: (option: Option) => React.ReactNode;
  multiValueRenderer?: (option: Option) => React.ReactElement;
  styleOverrides?: typeof DEFAULT_SELECT_STYLES;
  closeMenuOnSelect?: boolean;
} & (
  | MultiSelectType<T, Option extends OptionGroup<T> ? Option['options'][number] : Option>
  | SingleSelectType<T, Option extends OptionGroup<T> ? Option['options'][number] : Option>
);

interface SelectState {
  isFocused: boolean;
  isOpen: boolean;
}

export default class SelectTempWrapper<T, Option extends OptionOrOptionGroup<T>> extends React.Component<
  SelectProps<T, Option>,
  SelectState
> {
  static MenuPlacements = MenuPlacements;
  _selectRef = React.createRef<SelectInstance>();
  _containerRef = React.createRef<HTMLDivElement>();

  state: SelectState = {isFocused: false, isOpen: false};

  focus() {
    this._selectRef.current?.focus();
  }

  handleFocus = (event: React.FocusEvent) => {
    this.setState({isFocused: true});
    this.props.onFocus?.(event);
  };

  handleBlur = (event: React.FocusEvent) => {
    this.setState({isFocused: false});
    this.props.onBlur?.(event);
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    // Prevent Escape from propagating if the menu is open.
    if (event.which === KeyboardKeys.ESCAPE && this.state.isOpen) {
      event.stopPropagation();
    }
  };

  handleMenuOpen = () => {
    this.setState({isOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {
      className,
      error,
      valueRenderer,
      optionRenderer,
      multiValueRenderer,
      options,
      value,
      autofocus,
      disabled,
      clearable,
      searchable,
      styleOverrides,
      isMulti,
      placeholder = i18n.Messages.Common.SELECT,
      ...props
    } = this.props;

    // @ts-expect-error
    const selectProps: Partial<React.ElementConfig<typeof Select>> = {
      ...props,
    };
    if (autofocus != null) {
      selectProps.autoFocus = autofocus;
    }
    if (disabled != null) {
      selectProps.isDisabled = disabled;
    }
    if (clearable != null) {
      selectProps.isClearable = clearable;
    }
    if (searchable != null) {
      selectProps.isSearchable = searchable;
    }

    const components: SelectComponentsConfig<Option, boolean, GroupBase<Option>> = {
      IndicatorSeparator: () => null,
    };
    if (optionRenderer != null) {
      components.Option = (props) => {
        return <selectComponents.Option {...props}>{optionRenderer(props.data)}</selectComponents.Option>;
      };
    }
    if (valueRenderer != null) {
      components.SingleValue = (props) => {
        return <selectComponents.SingleValue {...props}>{valueRenderer(props.data)}</selectComponents.SingleValue>;
      };
    }
    if (multiValueRenderer != null) {
      components.MultiValue = (props) => {
        return multiValueRenderer(props.data);
      };
    }

    const selectStyles = styleOverrides != null ? styleOverrides : DEFAULT_SELECT_STYLES;
    const selectValue = computeSelectedValue(options, value);

    return (
      <div className={classNames(styles.select, className, {[styles.error]: error != null})}>
        <Select
          {...selectProps}
          ref={this._selectRef}
          isMulti={isMulti}
          components={components}
          options={options}
          styles={selectStyles}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          value={selectValue}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          noOptionsMessage={() => i18n.Messages.Common.NO_RESULTS_FOUND}
          menuPlacement="auto"
          theme={(theme) => ({
            ...theme,

            colors: {
              ...theme.colors,
              ...THEME_COLORS,
            },
          })}
        />
        {error != null ? <div className={styles.errorMessage}>{error}</div> : null}
      </div>
    );
  }
}

function computeSelectedValue<T>(options: Array<OptionOrOptionGroup<T>>, value: T) {
  // Multi Select
  if (Array.isArray(value)) {
    const optionsMap: Record<string, OptionOrOptionGroup<T>> = {};
    options.forEach((option) => {
      // Handle Option Groups
      if ('options' in option) {
        for (const childOption of option.options) {
          optionsMap[String(childOption.value)] = option;
        }
      } else {
        optionsMap[String(option.value)] = option;
      }
    });
    return value.map((item) => optionsMap[String(item)]);
  }
  // Single Select
  if (value != null) {
    for (const option of options) {
      // Handle Option Groups
      if ('options' in option) {
        for (const childOption of option.options) {
          if (childOption.value === value) return childOption;
        }
      } else if (option.value === value) {
        return option;
      }
    }
  }

  return null;
}
