import * as React from 'react';
import * as WebAuthn from '@github/webauthn-json';
import {
  MfaChallenge,
  trySubmit,
  BACKUP_CODE_MAX_LENGTH,
  BACKUP_CODE_MIN_LENGTH,
  MFAMethod,
  TOTP_CODE_LENGTH,
  SMS_CODE_LENGTH,
} from '@discordapp/common/MFA';
import {ChevronLargeRightIcon} from '@discordapp/design/components/Icon/generated/ChevronLargeRightIcon.web';
import {Text} from '@discordapp/design/components/Text/Text.web';
import {useStateFromStores} from '@discordapp/flux';
import {post} from '@discordapp/http-utils';

import Dispatcher from '@developers/Dispatcher';
import MFAModalStore from '@developers/stores/MFAModalStore';
import Button from '@developers/uikit/Button';
import Clickable from '@developers/uikit/Clickable';
import Grid, {GridChild, GridSection} from '@developers/uikit/Grid';
import TextInput, {Props as TextProps} from '@developers/uikit/TextInput';
import Modal, {ModalHeader, ModalCancel, ModalContent, ModalFooter, ModalAlert, ModalAlertTypes} from './Modal';

import {Endpoints} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import type {ResponseInterceptorRetry} from '@discordapp/http-utils';
import modalStyles from './Modal.module.css';

export default function MFACodeModal() {
  const props = useStateFromStores([MFAModalStore], () => MFAModalStore.mfaModalProps);

  if (props == null) return null;
  const {cancel, retry, request} = props;

  return <NewModal onCancel={cancel} retry={retry} mfaRequest={request} />;
}

const SELECT_NAMES: {[K in MFAMethod['type']]: string} = {
  get webauthn() {
    return Messages.Mfa.MFA_V2_WEBAUTHN_NAME;
  },
  get totp() {
    return Messages.Mfa.MFA_V2_TOTP_NAME;
  },
  get sms() {
    return Messages.Mfa.MFA_V2_SMS_NAME;
  },
  get password() {
    return Messages.Mfa.MFA_V2_PASSWORD_NAME;
  },
  get backup() {
    return Messages.Mfa.MFA_V2_BACKUP_NAME;
  },
};

function NewModal({
  onCancel,
  retry,
  mfaRequest,
}: {
  onCancel: (e: Error) => void;
  retry: ResponseInterceptorRetry;
  mfaRequest: MfaChallenge;
}) {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [mfaData, setMfaData] = React.useState<string>('');
  const [mfaMethod, setMfaMethod] = React.useState<MFAMethod['type'] | null>(mfaRequest.methods[0].type);
  const [phone, setPhone] = React.useState(null);

  function handleCancel() {
    onCancel(new Error('cancelled'));
    Dispatcher.dispatch({type: 'HIDE_MFA_MODAL'});
  }

  React.useEffect(() => {
    setError(null);
    setPhone(null);
    setSubmitting(false);

    if (mfaMethod === 'sms') {
      post({
        url: Endpoints.LOGIN_SMS_SEND,
        body: {ticket: mfaRequest.ticket},
        oldFormErrors: true,
      })
        .then((r) => {
          setPhone(r.body.phone);
        })
        .catch((e) => {
          setError(e.message || e.body?.message);
        });
    }
  }, [mfaMethod, mfaRequest.ticket]);

  if (mfaMethod == null) {
    return (
      <Modal onRequestClose={handleCancel}>
        <ModalContent>
          <ModalHeader className={modalStyles.mfaModalHeader}>{Messages.Mfa.MFA_PICKER_HEADER}</ModalHeader>
          {mfaRequest.methods.map((method) => (
            <Clickable
              key={method.type}
              className={modalStyles.listItemContainer}
              onClick={() => setMfaMethod(method.type)}>
              <Text variant="text-md/semibold" className={modalStyles.listItemText}>
                {SELECT_NAMES[method.type]}
              </Text>
              <ChevronLargeRightIcon
                size="custom"
                width={20}
                height={20}
                className={modalStyles.listItemArrow}
                color="currentColor"
              />
            </Clickable>
          ))}
        </ModalContent>
        <ModalFooter>
          <ModalCancel onClick={handleCancel}>{Messages.Actions.CANCEL}</ModalCancel>
        </ModalFooter>
      </Modal>
    );
  }

  const finish = async (data: string) => {
    setError(null);
    try {
      await trySubmit(
        {
          ticket: mfaRequest.ticket,
          mfaType: mfaMethod,
          data,
        },
        retry,
      );
      Dispatcher.dispatch({type: 'HIDE_MFA_MODAL'});
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFormSubmit = async () => {
    setSubmitting(true);
    await finish(mfaData);
    setSubmitting(false);
  };

  const handleWebAuthn = async () => {
    setSubmitting(true);
    setError(null);
    const {challenge} = mfaRequest.methods.find(
      (m): m is Extract<MFAMethod, {type: 'webauthn'}> => m.type === 'webauthn',
    )!;

    try {
      const response = await WebAuthn.get(JSON.parse(challenge)).then((r) => JSON.stringify(r));
      await finish(response);
    } catch (error) {
      setError(Messages.Mfa.MFA_V2_WEBAUTHN_GENERIC_ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  let textProps: Partial<TextProps> | null = null;

  if (mfaMethod === 'backup') {
    textProps = {
      placeholder: Messages.Information.MFA_BACKUP_PLACEHOLDER,
      maxLength: BACKUP_CODE_MAX_LENGTH,
      minLength: BACKUP_CODE_MIN_LENGTH,
      spellCheck: false,
    };
  } else if (mfaMethod === 'totp') {
    textProps = {
      placeholder: Messages.Information.MFA_CODE_PLACEHOLDER,
      minLength: TOTP_CODE_LENGTH,
      maxLength: TOTP_CODE_LENGTH,
      autoComplete: 'one-time-code',
      spellCheck: false,
    };
  } else if (mfaMethod === 'sms') {
    textProps = {
      placeholder: Messages.Information.MFA_CODE_PLACEHOLDER,
      maxLength: SMS_CODE_LENGTH,
      minLength: SMS_CODE_LENGTH,
      autoComplete: 'one-time-code',
      spellCheck: false,
    };
  } else if (mfaMethod === 'password') {
    textProps = {
      placeholder: Messages.Information.MFA_PASSWORD_PLACEHOLDER,
      type: 'password',
      autoComplete: 'password',
      spellCheck: false,
    };
  }

  const phoneSubtitle =
    phone == null
      ? error != null
        ? null
        : Messages.Mfa.TWO_FA_ENTER_SMS_TOKEN_SENDING
      : Messages.Mfa.TWO_FA_ENTER_SMS_TOKEN_SENT.format({phoneNumber: phone});

  return (
    <Modal onRequestClose={handleCancel}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}>
        <ModalContent>
          <ModalHeader className={modalStyles.mfaModalHeader}>{Messages.Mfa.MFA_PICKER_HEADER}</ModalHeader>
          {error != null ? <ModalAlert type={ModalAlertTypes.ERROR}>{error}</ModalAlert> : null}
          <Grid>
            <GridSection>
              <GridChild columnSpread={12} style={mfaMethod === 'webauthn' ? {textAlign: 'center'} : undefined}>
                {textProps != null && <TextInput name="mfaCode" onChange={setMfaData} value={mfaData} {...textProps} />}
                {mfaMethod === 'sms' && phoneSubtitle}
                {mfaMethod === 'webauthn' && (
                  <Button submitting={submitting} onClick={handleWebAuthn}>
                    {Messages.Mfa.MFA_V2_WEBAUTHN_CTA}
                  </Button>
                )}
              </GridChild>
            </GridSection>
          </Grid>
        </ModalContent>
        <ModalFooter>
          <ModalCancel
            onClick={() => {
              setMfaMethod(null);
              setMfaData('');
            }}>
            {Messages.Mfa.MFA_VERIFY_WITH_SOMETHING_ELSE}
          </ModalCancel>
          {mfaMethod !== 'webauthn' && (
            <Button
              className={mfaData === '' ? modalStyles.dangerButtonDisabled : modalStyles.dangerButton}
              disabled={mfaData === ''}
              submitting={submitting}
              onClick={handleFormSubmit}
              type="submit">
              {Messages.Common.SUBMIT}
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
}
