import * as React from 'react';
import classNames from 'classnames';
import {FocusRing} from 'react-focus-rings';

import Clickable from '@developers/uikit/Clickable';

import {Messages} from '@developers/i18n';
import type {NoticeTypes} from '@developers/Constants';
import styles from './Notice.module.css';

export const NoticeColors = Object.freeze({
  NOTIFICATION: styles.colorNotification,
  CUSTOM: styles.colorCustom,
  DEFAULT: styles.colorDefault,
});

interface NoticeButtonProps extends React.PropsWithoutRef<JSX.IntrinsicElements['button']> {
  minor?: boolean;
}

interface PrimaryCTANoticeButtonProps extends NoticeButtonProps {
  noticeType: Values<typeof NoticeTypes>;
}

function NoticeButton({children, className, minor = false, ...props}: NoticeButtonProps) {
  return (
    <FocusRing>
      <button className={classNames(styles.button, className, {[styles.buttonMinor]: minor})} {...props}>
        {children}
      </button>
    </FocusRing>
  );
}

export function PrimaryCTANoticeButton({children, ...props}: PrimaryCTANoticeButtonProps) {
  return (
    <NoticeButton
      {...props}
      onClick={(e) => {
        props.onClick != null && props.onClick(e);
      }}>
      {children}
    </NoticeButton>
  );
}

interface NoticeCloseButtonProps {
  onClick: () => void;
}

export function NoticeCloseButton({onClick}: NoticeCloseButtonProps) {
  return (
    <Clickable
      className={styles.closeButton}
      onClick={() => {
        onClick();
      }}
      aria-label={Messages.DeveloperExperienceSurvey.DISMISS}
    />
  );
}

interface NoticeProps {
  color?: Values<typeof NoticeColors>;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export default function Notice({color = NoticeColors.DEFAULT, className, children}: NoticeProps) {
  return <div className={classNames(styles.notice, className, color)}>{children}</div>;
}
