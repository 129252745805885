/*
  THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
  If you need to add or edit existing variables, check out
  discord_common/js/packages/tokens/definitions, then run
  `clyde mana tokens generate`
*/

const Colors = Object.freeze({
  APPLICATION_SUBSCRIPTION_END: '#0177a4',
  APPLICATION_SUBSCRIPTION_START: '#3442d9',
  BATTLENET: '#009ae5',
  BG_GRADIENT_AURORA_1: '#062053',
  BG_GRADIENT_AURORA_2: '#191fbb',
  BG_GRADIENT_AURORA_3: '#13929a',
  BG_GRADIENT_AURORA_4: '#218573',
  BG_GRADIENT_AURORA_5: '#051a81',
  BG_GRADIENT_BLURPLE_TWILIGHT_1: '#2C3FE7',
  BG_GRADIENT_BLURPLE_TWILIGHT_2: '#261D83',
  BG_GRADIENT_CHROMA_GLOW_1: '#0eb5bf',
  BG_GRADIENT_CHROMA_GLOW_2: '#4c0ce0',
  BG_GRADIENT_CHROMA_GLOW_3: '#a308a7',
  BG_GRADIENT_CHROMA_GLOW_4: '#9a53ff',
  BG_GRADIENT_CHROMA_GLOW_5: '#218be0',
  BG_GRADIENT_CITRUS_SHERBERT_1: '#f3b336',
  BG_GRADIENT_CITRUS_SHERBERT_2: '#ee8558',
  BG_GRADIENT_COTTON_CANDY_1: '#f4abb8',
  BG_GRADIENT_COTTON_CANDY_2: '#b1c2fc',
  BG_GRADIENT_CRIMSON_MOON_1: '#950909',
  BG_GRADIENT_CRIMSON_MOON_2: '#000000',
  BG_GRADIENT_DESERT_KHAKI_1: '#e7dbd0',
  BG_GRADIENT_DESERT_KHAKI_2: '#dfd0b2',
  BG_GRADIENT_DESERT_KHAKI_3: '#e0d6a3',
  BG_GRADIENT_DUSK_1: '#665069',
  BG_GRADIENT_DUSK_2: '#91a3d1',
  BG_GRADIENT_EASTER_EGG_1: '#7289da',
  BG_GRADIENT_EASTER_EGG_2: '#4e5d94',
  BG_GRADIENT_FOREST_1: '#142215',
  BG_GRADIENT_FOREST_2: '#2d4d39',
  BG_GRADIENT_FOREST_3: '#454c32',
  BG_GRADIENT_FOREST_4: '#5a7c58',
  BG_GRADIENT_FOREST_5: '#a98e4b',
  BG_GRADIENT_HANAMI_1: '#efaab3',
  BG_GRADIENT_HANAMI_2: '#efd696',
  BG_GRADIENT_HANAMI_3: '#a6daa2',
  BG_GRADIENT_LOFI_VIBES_1: '#a4c0f7',
  BG_GRADIENT_LOFI_VIBES_2: '#a9e4e8',
  BG_GRADIENT_LOFI_VIBES_3: '#b0e2b8',
  BG_GRADIENT_LOFI_VIBES_4: '#cfdfa2',
  BG_GRADIENT_MARS_1: '#895240',
  BG_GRADIENT_MARS_2: '#8f4343',
  BG_GRADIENT_MIDNIGHT_BLURPLE_1: '#5348ca',
  BG_GRADIENT_MIDNIGHT_BLURPLE_2: '#140730',
  BG_GRADIENT_MINT_APPLE_1: '#56b69f',
  BG_GRADIENT_MINT_APPLE_2: '#63bc61',
  BG_GRADIENT_MINT_APPLE_3: '#9eca67',
  BG_GRADIENT_NEON_NIGHTS_1: '#01a89e',
  BG_GRADIENT_NEON_NIGHTS_2: '#7d60ba',
  BG_GRADIENT_NEON_NIGHTS_3: '#b43898',
  BG_GRADIENT_RETRO_RAINCLOUD_1: '#3a7ca1',
  BG_GRADIENT_RETRO_RAINCLOUD_2: '#7f7eb9',
  BG_GRADIENT_RETRO_STORM_1: '#3a7ca1',
  BG_GRADIENT_RETRO_STORM_2: '#58579a',
  BG_GRADIENT_SEPIA_1: '#857664',
  BG_GRADIENT_SEPIA_2: '#5b4421',
  BG_GRADIENT_STRAWBERRY_LEMONADE_1: '#af1a6c',
  BG_GRADIENT_STRAWBERRY_LEMONADE_2: '#c26b20',
  BG_GRADIENT_STRAWBERRY_LEMONADE_3: '#e7a525',
  BG_GRADIENT_SUNRISE_1: '#9f4175',
  BG_GRADIENT_SUNRISE_2: '#c49064',
  BG_GRADIENT_SUNRISE_3: '#a6953d',
  BG_GRADIENT_SUNSET_1: '#48288c',
  BG_GRADIENT_SUNSET_2: '#db7f4b',
  BG_GRADIENT_UNDER_THE_SEA_1: '#647962',
  BG_GRADIENT_UNDER_THE_SEA_2: '#588575',
  BG_GRADIENT_UNDER_THE_SEA_3: '#6a8482',
  BLACK: '#000000',
  BLACK_100: '#f2f2f2',
  BLACK_130: '#e8e8e8',
  BLACK_160: '#dadada',
  BLACK_200: '#cccccc',
  BLACK_230: '#bdbdbd',
  BLACK_260: '#acacac',
  BLACK_300: '#999999',
  BLACK_330: '#7a7a7a',
  BLACK_345: '#666666',
  BLACK_360: '#5c5c5c',
  BLACK_400: '#333333',
  BLACK_430: '#252525',
  BLACK_460: '#141414',
  BLACK_500: '#000000',
  BLACK_530: '#000000',
  BLACK_560: '#000000',
  BLACK_600: '#000000',
  BLACK_630: '#000000',
  BLACK_660: '#000000',
  BLACK_700: '#000000',
  BLACK_730: '#000000',
  BLACK_760: '#000000',
  BLACK_800: '#000000',
  BLACK_830: '#000000',
  BLACK_860: '#000000',
  BLACK_900: '#000000',
  BLUE_100: '#f6fafe',
  BLUE_130: '#f0f7fe',
  BLUE_160: '#e2f0fd',
  BLUE_200: '#cde8fd',
  BLUE_230: '#b2ddfc',
  BLUE_260: '#94d2fc',
  BLUE_300: '#66c4fd',
  BLUE_330: '#2eb6ff',
  BLUE_345: '#00aafc',
  BLUE_360: '#0097f2',
  BLUE_400: '#0082eb',
  BLUE_430: '#006be7',
  BLUE_460: '#005cd1',
  BLUE_500: '#0051b6',
  BLUE: '#0051b6',
  BLUE_530: '#00489b',
  BLUE_560: '#004088',
  BLUE_600: '#003976',
  BLUE_630: '#00336a',
  BLUE_660: '#002d5f',
  BLUE_700: '#002855',
  BLUE_730: '#002348',
  BLUE_760: '#001e3f',
  BLUE_800: '#001a36',
  BLUE_830: '#001630',
  BLUE_860: '#00132b',
  BLUE_900: '#001024',
  BRAND_100: '#f7f7fe',
  BRAND_130: '#f0f1fe',
  BRAND_160: '#e7e9fd',
  BRAND_200: '#dee0fc',
  BRAND_230: '#d4d7fc',
  BRAND_260: '#c9cdfb',
  BRAND_300: '#bcc1fa',
  BRAND_330: '#a8aff8',
  BRAND_345: '#9ba3f7',
  BRAND_360: '#949cf7',
  BRAND_400: '#7984f5',
  BRAND_430: '#707bf4',
  BRAND_460: '#6571f3',
  BRAND_500: '#5865f2',
  BRAND: '#5865f2',
  BRAND_530: '#505cdc',
  BRAND_560: '#4752c4',
  BRAND_600: '#3c45a5',
  BRAND_630: '#343b8f',
  BRAND_660: '#2d347d',
  BRAND_700: '#232861',
  BRAND_730: '#21265b',
  BRAND_760: '#1e2353',
  BRAND_800: '#1a1e49',
  BRAND_830: '#141738',
  BRAND_860: '#0d0f24',
  BRAND_900: '#04050c',
  BUNGIE: '#00a3e3',
  CRUNCHYROLL: '#f78b24',
  DISCORD_8TH_BDAY_BLUE: '#5665f9',
  DISCORD_8TH_BDAY_DARK_BLUE: '#3241e0',
  DISCORD_8TH_BDAY_GREEN: '#a4ef00',
  DISCORD_8TH_BDAY_LIGHT_BLUE: '#5e8fff',
  DISCORD_8TH_BDAY_PINK: '#ff52d2',
  DISCORD_8TH_BDAY_PURPLE: '#5665F9',
  DISCORD_8TH_BDAY_YELLOW: '#ffff00',
  EBAY: '#0064d2',
  EPIC_GAMES: '#3c3935',
  FACEBOOK: '#355089',
  GITHUB: '#191717',
  GOLD: '#ffc819',
  GREEN_100: '#ecfef1',
  GREEN_130: '#defee7',
  GREEN_160: '#bdfcd3',
  GREEN_200: '#88fbb5',
  GREEN_230: '#58f39c',
  GREEN_260: '#48e58b',
  GREEN_300: '#3bd67f',
  GREEN_330: '#2dc771',
  GREEN_345: '#26b968',
  GREEN_360: '#23a55a',
  GREEN_400: '#24934e',
  GREEN_430: '#248045',
  GREEN_460: '#1f703c',
  GREEN_500: '#1a6334',
  GREEN: '#1a6334',
  GREEN_530: '#15562b',
  GREEN_560: '#124c24',
  GREEN_600: '#0e431f',
  GREEN_630: '#0c3c1c',
  GREEN_660: '#0a3618',
  GREEN_700: '#072f15',
  GREEN_730: '#052910',
  GREEN_760: '#03240e',
  GREEN_800: '#031f0c',
  GREEN_830: '#031b0a',
  GREEN_860: '#041708',
  GREEN_900: '#051307',
  GUILD_BOOSTING_BLUE: '#3e70dd',
  GUILD_BOOSTING_BLUE_FOR_GRADIENTS: '#3442d9',
  GUILD_BOOSTING_PINK: '#ff73fa',
  GUILD_BOOSTING_PURPLE: '#b377f3',
  GUILD_BOOSTING_PURPLE_FOR_GRADIENTS: '#9b44f7',
  HYPESQUAD_HOUSE_1: '#9c84ef',
  HYPESQUAD_HOUSE_2: '#f47b67',
  HYPESQUAD_HOUSE_3: '#45ddc0',
  INPUT_PLACEHOLDER_TEXT_DARK: '#87898c',
  LOL: '#021f25',
  ORANGE_100: '#fff8f5',
  ORANGE_130: '#fff4ed',
  ORANGE_160: '#feeadf',
  ORANGE_200: '#fddecd',
  ORANGE_230: '#fccfb2',
  ORANGE_260: '#fcbd95',
  ORANGE_300: '#fbab70',
  ORANGE_330: '#fa9746',
  ORANGE_345: '#f1882a',
  ORANGE_360: '#db7628',
  ORANGE_400: '#c46926',
  ORANGE_430: '#ac591f',
  ORANGE_460: '#9b4c19',
  ORANGE_500: '#8c4013',
  ORANGE: '#8c4013',
  ORANGE_530: '#7b3710',
  ORANGE_560: '#6d300e',
  ORANGE_600: '#5f2b0b',
  ORANGE_630: '#56260a',
  ORANGE_660: '#4c2209',
  ORANGE_700: '#431e09',
  ORANGE_730: '#3b1a07',
  ORANGE_760: '#331606',
  ORANGE_800: '#2d1305',
  ORANGE_830: '#261005',
  ORANGE_860: '#200e05',
  ORANGE_900: '#190d04',
  PARTNER: '#4087ed',
  PAYPAL: '#13216b',
  PLAYSTATION: '#032f87',
  PLUM_23_ALPHA: '#BCBCF0',
  PLUM_0: '#f9f9fa',
  PLUM_1: '#f3f3f4',
  PLUM_10: '#818491',
  PLUM_11: '#6c6f7c',
  PLUM_12: '#5b5e6a',
  PLUM_13: '#4d505b',
  PLUM_14: '#41434d',
  PLUM_15: '#383a43',
  PLUM_16: '#31323b',
  PLUM_17: '#2c2d35',
  PLUM_18: '#26272f',
  PLUM_19: '#212229',
  PLUM_2: '#ecedef',
  PLUM_20: '#1c1d23',
  PLUM_21: '#18191f',
  PLUM_22: '#16161c',
  PLUM_23: '#131318',
  PLUM_24: '#0f1014',
  PLUM_25: '#0c0c10',
  PLUM_26: '#08080b',
  PLUM_3: '#e4e5e8',
  PLUM_4: '#dddee1',
  PLUM_5: '#d3d5d9',
  PLUM_6: '#c7c8ce',
  PLUM_7: '#b8bac1',
  PLUM_8: '#a8aab4',
  PLUM_9: '#9597a3',
  PREMIUM_NITRO_PINK_DARK: '#b64ab7',
  PREMIUM_NITRO_PINK_LIGHT: '#c269c3',
  PREMIUM_PERK_BLUE: '#80a6ff',
  PREMIUM_PERK_BLUE_ALT: '#9cb8ff',
  PREMIUM_PERK_DARK_BLUE: '#4173da',
  PREMIUM_PERK_GOLD: '#faa61a',
  PREMIUM_PERK_GREEN: '#86dcc5',
  PREMIUM_PERK_LIGHT_BLUE: '#aec7ff',
  PREMIUM_PERK_ORANGE: '#fc964b',
  PREMIUM_PERK_PINK: '#ff80f4',
  PREMIUM_PERK_PURPLE: '#d09aff',
  PREMIUM_PERK_YELLOW: '#fed648',
  PREMIUM_TIER_0_BLUE: '#007cc2',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS: '#007cc2',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS_2: '#1776cf',
  PREMIUM_TIER_0_HEADER_GRADIENT_1: '#3736bb',
  PREMIUM_TIER_0_HEADER_GRADIENT_2: '#4670e8',
  PREMIUM_TIER_0_HEADER_GRADIENT_3: '#8377eb',
  PREMIUM_TIER_0_HEADER_GRADIENT_4: '#e782f1',
  PREMIUM_TIER_0_HEADER_GRADIENT_5: '#df90af',
  PREMIUM_TIER_0_PURPLE: '#5865f2',
  PREMIUM_TIER_0_PURPLE_FOR_GRADIENTS: '#5865f2',
  PREMIUM_TIER_1_BLUE: '#738ef5',
  PREMIUM_TIER_1_BLUE_FOR_GRADIENTS: '#5865f2',
  PREMIUM_TIER_1_DARK_BLUE_FOR_GRADIENTS: '#3442d9',
  PREMIUM_TIER_1_PURPLE: '#b3aeff',
  PREMIUM_TIER_2_PINK: '#e292aa',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS: '#ab5d8a',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS_2: '#b73ec1',
  PREMIUM_TIER_2_PURPLE: '#b473f5',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS: '#8547c6',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS_2: '#b845c1',
  PRIMARY_100: '#f9f9f9',
  PRIMARY_130: '#f2f3f5',
  PRIMARY_160: '#ebedef',
  PRIMARY_200: '#e3e5e8',
  PRIMARY_230: '#dbdee1',
  PRIMARY_260: '#d2d5d9',
  PRIMARY_300: '#c4c9ce',
  PRIMARY_330: '#b5bac1',
  PRIMARY_345: '#a5abb3',
  PRIMARY_360: '#949ba4',
  PRIMARY_400: '#80848e',
  PRIMARY_430: '#6d6f78',
  PRIMARY_460: '#5c5e66',
  PRIMARY_500: '#4e5058',
  PRIMARY: '#4e5058',
  PRIMARY_530: '#41434a',
  PRIMARY_560: '#383a40',
  PRIMARY_600: '#313338',
  PRIMARY_630: '#2b2d31',
  PRIMARY_645: '#282a2e',
  PRIMARY_660: '#232428',
  PRIMARY_700: '#1e1f22',
  PRIMARY_730: '#1a1b1e',
  PRIMARY_760: '#161719',
  PRIMARY_800: '#111214',
  PRIMARY_830: '#0c0c0d',
  PRIMARY_860: '#060607',
  PRIMARY_900: '#020202',
  RED_100: '#fef8f9',
  RED_130: '#fef4f4',
  RED_160: '#feeaea',
  RED_200: '#fdddde',
  RED_230: '#fbcdce',
  RED_260: '#fbbabb',
  RED_300: '#fba6a8',
  RED_330: '#fa9193',
  RED_345: '#fa777b',
  RED_360: '#f85b5f',
  RED_400: '#f23f43',
  RED_430: '#da373c',
  RED_460: '#bb3033',
  RED_500: '#a12829',
  RED: '#a12829',
  RED_530: '#8f2022',
  RED_560: '#7f1c1e',
  RED_600: '#70181a',
  RED_630: '#651517',
  RED_660: '#5a1314',
  RED_700: '#501012',
  RED_730: '#460e0f',
  RED_760: '#3f0b0c',
  RED_800: '#360a0b',
  RED_830: '#2e090a',
  RED_860: '#280808',
  RED_900: '#220606',
  REDDIT: '#ff4500',
  RIOT_GAMES: '#ea0029',
  ROLE_BLUE: '#206694',
  ROLE_BROWN: '#a84300',
  ROLE_BURGUNDY: '#ad1457',
  ROLE_DARK_BLUE: '#546e7a',
  ROLE_DARK_GREY: '#607d8b',
  ROLE_DARK_PURPLE: '#71368a',
  ROLE_DARK_TEAL: '#11806a',
  ROLE_DEFAULT: '#99aab5',
  ROLE_GREEN: '#1f8b4c',
  ROLE_GREY: '#979c9f',
  ROLE_LIGHT_BLUE: '#99aab5',
  ROLE_LIGHT_GREEN: '#2ecc71',
  ROLE_LIGHT_GREY: '#95a5a6',
  ROLE_MAGENTA: '#e91e63',
  ROLE_ORANGE: '#e67e22',
  ROLE_PURPLE: '#9b59b6',
  ROLE_SALMON: '#e74c3c',
  ROLE_SKY_BLUE: '#3498db',
  ROLE_TAN: '#c27c0e',
  ROLE_TEAL: '#1abc9c',
  ROLE_TERRACOTTA: '#992d22',
  ROLE_YELLOW: '#f1c40f',
  SAMSUNG: '#1429a0',
  SKYPE: '#009dd7',
  SPOTIFY: '#1db954',
  STEAM: '#182332',
  TEAL_100: '#f4fbfd',
  TEAL_130: '#e9f9fd',
  TEAL_160: '#d3f4fb',
  TEAL_200: '#b1eff9',
  TEAL_230: '#7ee7f7',
  TEAL_260: '#5edbef',
  TEAL_300: '#47cbe2',
  TEAL_330: '#35bcd5',
  TEAL_345: '#2eb0c9',
  TEAL_360: '#289fb6',
  TEAL_400: '#248da1',
  TEAL_430: '#207a8d',
  TEAL_460: '#1b6b7c',
  TEAL_500: '#175e6d',
  TEAL: '#175e6d',
  TEAL_530: '#13525f',
  TEAL_560: '#0f4954',
  TEAL_600: '#0c4049',
  TEAL_630: '#0a3942',
  TEAL_660: '#08333a',
  TEAL_700: '#062d34',
  TEAL_730: '#05272d',
  TEAL_760: '#042227',
  TEAL_800: '#031d21',
  TEAL_830: '#02191d',
  TEAL_860: '#021619',
  TEAL_900: '#011215',
  TRANSPARENT: 'transparent',
  TWITCH: '#593695',
  TWITTER: '#1da1f2',
  WHITE: '#ffffff',
  WHITE_100: '#ffffff',
  WHITE_130: '#ffffff',
  WHITE_160: '#ffffff',
  WHITE_200: '#ffffff',
  WHITE_230: '#ffffff',
  WHITE_260: '#ffffff',
  WHITE_300: '#ffffff',
  WHITE_330: '#ffffff',
  WHITE_345: '#ffffff',
  WHITE_360: '#ffffff',
  WHITE_400: '#ffffff',
  WHITE_430: '#ffffff',
  WHITE_460: '#ffffff',
  WHITE_500: '#ffffff',
  WHITE_530: '#e8e8e8',
  WHITE_560: '#cfcfcf',
  WHITE_600: '#adadad',
  WHITE_630: '#969696',
  WHITE_660: '#838383',
  WHITE_700: '#666666',
  WHITE_730: '#5f5f5f',
  WHITE_760: '#585858',
  WHITE_800: '#4d4d4d',
  WHITE_830: '#3b3b3b',
  WHITE_860: '#262626',
  WHITE_900: '#0d0d0d',
  XBOX: '#107c10',
  YELLOW_100: '#fff8ef',
  YELLOW_130: '#fff4e8',
  YELLOW_160: '#ffebd3',
  YELLOW_200: '#fee0b6',
  YELLOW_230: '#fdd18c',
  YELLOW_260: '#fcc145',
  YELLOW_300: '#f0b232',
  YELLOW_330: '#e1a42a',
  YELLOW_345: '#d49824',
  YELLOW_360: '#bf861c',
  YELLOW_400: '#af7615',
  YELLOW_430: '#9a650d',
  YELLOW_460: '#8a5709',
  YELLOW_500: '#7c4b04',
  YELLOW: '#7c4b04',
  YELLOW_530: '#6d4104',
  YELLOW_560: '#613803',
  YELLOW_600: '#543203',
  YELLOW_630: '#4c2d03',
  YELLOW_660: '#432803',
  YELLOW_700: '#3b2303',
  YELLOW_730: '#351e02',
  YELLOW_760: '#2e1a02',
  YELLOW_800: '#271602',
  YELLOW_830: '#221302',
  YELLOW_860: '#1c1002',
  YELLOW_900: '#160e02',
  YOUTUBE: '#cb2120',
});

export default Colors as Record<keyof typeof Colors, string>;
