import {Store} from '@discordapp/flux/Store';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';

import type {ActionFor, Action} from '@developers/flow/Action';
import type {TooltipProps} from '@developers/flow/Client';

let tooltips: Record<string, TooltipProps> = {};

function handleShowTooltip(action: ActionFor<'TOOLTIP_SHOW'>) {
  tooltips = {...tooltips, [action.id]: action.tooltip};
}

function handleHideTooltip(action: ActionFor<'TOOLTIP_HIDE'>): void {
  if (tooltips[action.id] == null) return;
  tooltips = {...tooltips};
  delete tooltips[action.id];
}

class TooltipStore extends Store<Action> {
  static displayName = 'TooltipStore';
  getTooltips() {
    return tooltips;
  }

  isOpen(id: string | number) {
    return tooltips[id] != null;
  }
}

export default new TooltipStore(Dispatcher, {
  [ActionTypes.TOOLTIP_SHOW]: handleShowTooltip,
  [ActionTypes.TOOLTIP_HIDE]: handleHideTooltip,
});
