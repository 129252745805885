import * as React from 'react';
import classNames from 'classnames';
import map from 'lodash/map.js';
import {useStateFromStores} from '@discordapp/flux';

import {show, hide} from '@developers/actions/TooltipActionCreators';
import usePrevious from '@developers/hooks/usePrevious';
import TooltipStore from '@developers/stores/TooltipStore';
import {Colors} from './Tooltip';

import type {TooltipProps} from '@developers/flow/Client';
import styles from './Tooltips.module.css';

interface State {
  offsetX: number | null | undefined;
  offsetY: number | null | undefined;
}

function Tooltip(props: TooltipProps) {
  const {id, x, y, position, text, isInteractive, color, targetHeight, targetWidth} = props;
  const [offsets, setOffsets] = React.useState<State>({offsetX: null, offsetY: null});
  const ref = React.useRef<HTMLDivElement>(null);

  const updateOffsets = React.useCallback(() => {
    const domNode = ref.current;
    if (domNode == null || !(domNode instanceof HTMLElement)) {
      return;
    }

    setOffsets((currentOffset) => {
      const newState: State = {
        offsetX: currentOffset.offsetX,
        offsetY: -(domNode.offsetHeight / 2),
      };
      switch (position) {
        case 'left':
          newState.offsetX = -domNode.offsetWidth;
          newState.offsetY = (newState.offsetY ?? 0) + targetHeight / 2;
          break;
        case 'right':
          newState.offsetX = targetWidth;
          newState.offsetY = (newState.offsetY ?? 0) + targetHeight / 2;
          break;
        case 'bottom':
          newState.offsetX = (targetWidth - domNode.offsetWidth) / 2;
          newState.offsetY = targetHeight;
          break;
        case 'top':
        default:
          newState.offsetX = (targetWidth - domNode.offsetWidth) / 2;
          newState.offsetY = -domNode.offsetHeight;
      }

      return newState;
    });
  }, [position, targetHeight, targetWidth]);

  const prevText = usePrevious(text);
  const prevPosition = usePrevious(position);
  const prevX = usePrevious(x);
  const prevY = usePrevious(y);
  const prevTargetHeight = usePrevious(targetHeight);
  const prevTargetWidth = usePrevious(targetWidth);

  React.useEffect(() => {
    if (
      prevText !== text ||
      prevPosition !== position ||
      prevX !== x ||
      prevY !== y ||
      prevTargetWidth !== targetWidth ||
      prevTargetHeight !== targetHeight
    ) {
      updateOffsets();
    }
  }, [
    position,
    prevPosition,
    prevTargetHeight,
    prevTargetWidth,
    prevText,
    prevX,
    prevY,
    targetHeight,
    targetWidth,
    text,
    updateOffsets,
    x,
    y,
  ]);

  if (text == null) {
    return null;
  }

  const {offsetX, offsetY} = offsets;

  const style: React.CSSProperties = {
    left: offsetX == null ? undefined : x + offsetX,
    top: offsetY == null ? undefined : y + offsetY,
  };

  return (
    <div
      className={classNames(
        styles.tooltip,
        styles[position],
        {[styles.tooltipInteractive]: isInteractive},
        {[styles.grey]: color === Colors.GREY},
      )}
      onMouseEnter={() => {
        if (isInteractive) {
          show(id, props);
        }
      }}
      onMouseLeave={() => {
        if (isInteractive) {
          hide(id);
        }
      }}
      ref={ref}
      style={style}>
      {text}
    </div>
  );
}

export default function Tooltips() {
  const tooltips = useStateFromStores([TooltipStore], () => TooltipStore.getTooltips());
  return (
    <div className={styles.tooltips}>
      {map(tooltips, (props, i) => (
        <Tooltip key={i} {...props} />
      ))}
    </div>
  );
}
