import * as React from 'react';
import {CircleCheckIcon} from '@discordapp/design/components/Icon/generated/CircleCheckIcon.web';
import {Text} from '@discordapp/design/components/Text/Text.web';
import {useStateFromStores} from '@discordapp/flux';

import TeamStore from '@developers/stores/TeamStore';
import UserStore from '@developers/stores/UserStore';
import Alert, {AlertTypes} from '@developers/uikit/Alert';
import Button, {ButtonHeight} from '@developers/uikit/Button';
import Spinner, {SpinnerTypes} from '@developers/uikit/Spinner';
import Tooltip from '@developers/uikit/Tooltip';
import useGetOrCreateVerificationState, {IdentityVerificationUiState} from './useGetOrCreateVerificationState';

import {Links} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './OwnerVerificationStatusAlert.module.css';

export function OwnerVerificationStatus({
  state,
  ownerId,
  ownerIsTeam,
  submitting,
  onCreateIdentityVerification,
}: {
  state: IdentityVerificationUiState;
  ownerId: string;
  ownerIsTeam: boolean;
  submitting: boolean;
  onCreateIdentityVerification: () => void;
}) {
  const currentUserIsOwner = useStateFromStores([TeamStore, UserStore], () => {
    if (!ownerIsTeam) {
      return true;
    }
    const team = TeamStore.getTeam(ownerId);
    const userId = UserStore.user?.id;
    return team != null && userId != null && team.owner_user_id === userId;
  });
  switch (state) {
    case IdentityVerificationUiState.LOADING:
      return null;
    case IdentityVerificationUiState.PROCESSING:
      return (
        <div className={styles.row}>
          <Spinner className={styles.statusIcon} type={SpinnerTypes.PULSING_ELLIPSIS} />
          <span>{Messages.Verification.IDENTITY_PROCESSING}</span>
        </div>
      );
    case IdentityVerificationUiState.SUCCESS:
    case IdentityVerificationUiState.SUCCESS_GRACE_PERIOD:
      return (
        <div className={styles.row}>
          <CircleCheckIcon className={styles.statusIcon} />
          <span>{Messages.Verification.IDENTITY_VERIFIED}</span>
        </div>
      );
    default:
      const button = (
        <Button disabled={!currentUserIsOwner} submitting={submitting} onClick={onCreateIdentityVerification}>
          {Messages.Verification.IDENTITY_VERIFY}
        </Button>
      );
      if (!currentUserIsOwner) {
        return (
          <Tooltip
            position={Tooltip.Positions.TOP}
            className={styles.tooltip}
            text={Messages.Verification.IDENTITY_VERIFY_OWNER_ONLY_TOOLTIP}>
            {button}
          </Tooltip>
        );
      }
      return button;
  }
}

export default function OwnerVerificationStatusAlert({
  ownerId,
  ownerIsTeam,
  onError,
}: {
  ownerId: string;
  ownerIsTeam: boolean;
  onError: (error: React.ReactNode) => void;
}) {
  const {handleCreateIdentityVerification, state, submitting, lastErrorMessage} = useGetOrCreateVerificationState(
    ownerId,
    ownerIsTeam,
    onError,
  );
  const currentUserIsOwner = useStateFromStores([TeamStore, UserStore], () => {
    if (!ownerIsTeam) {
      return true;
    }
    const team = TeamStore.getTeam(ownerId);
    const userId = UserStore.user?.id;
    return team != null && userId != null && team.owner_user_id === userId;
  });
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (
      [
        IdentityVerificationUiState.PROCESSING,
        IdentityVerificationUiState.SUCCESS_GRACE_PERIOD,
        IdentityVerificationUiState.REQUIRES_ACTION,
      ].includes(state)
    ) {
      setIsVisible(true);
    } else if (state === IdentityVerificationUiState.NEEDS_VERIFICATION) {
      setIsVisible(false);
    }
  }, [state]);

  if (!isVisible || state === IdentityVerificationUiState.LOADING) {
    return null;
  }

  if (state === IdentityVerificationUiState.SUCCESS_GRACE_PERIOD) {
    let button = (
      <Button
        height={ButtonHeight.SHORT}
        submitting={submitting}
        className={styles.ctaButton}
        disabled={!currentUserIsOwner}
        onClick={handleCreateIdentityVerification}>
        {Messages.Verification.IDENTITY_VERIFY}
      </Button>
    );
    if (!currentUserIsOwner) {
      button = (
        <Tooltip
          position={Tooltip.Positions.TOP}
          className={styles.tooltip}
          text={Messages.Verification.IDENTITY_VERIFY_OWNER_ONLY_TOOLTIP}>
          {button}
        </Tooltip>
      );
    }
    return (
      <Alert type={AlertTypes.WARNING} heading={Messages.Verification.IDENTITY_GRACE_PERIOD_WARNING_HEADING}>
        <Text variant="text-md/normal">
          {Messages.Verification.IDENTITY_GRACE_PERIOD_WARNING.format({
            learnMoreUrl: Links.APPLICATION_VERIFICATION_SUPPORT,
          })}
        </Text>
        {button}
      </Alert>
    );
  } else if (state === IdentityVerificationUiState.REQUIRES_ACTION) {
    let button = (
      <Button
        height={ButtonHeight.SHORT}
        submitting={submitting}
        className={styles.ctaButton}
        disabled={!currentUserIsOwner}
        onClick={handleCreateIdentityVerification}>
        {Messages.Verification.IDENTITY_VERIFY}
      </Button>
    );
    if (!currentUserIsOwner) {
      button = (
        <Tooltip
          position={Tooltip.Positions.TOP}
          className={styles.tooltip}
          text={Messages.Verification.IDENTITY_VERIFY_OWNER_ONLY_TOOLTIP}>
          {button}
        </Tooltip>
      );
    }
    return (
      <Alert type={AlertTypes.WARNING} heading={Messages.Verification.IDENTITY_REQUIRES_ACTION_HEADING}>
        <Text variant="text-md/normal">{lastErrorMessage ?? Messages.Verification.IDENTITY_REQUIRES_ACTION}</Text>
        {button}
      </Alert>
    );
  } else if (state === IdentityVerificationUiState.SUCCESS) {
    return (
      <Alert type={AlertTypes.SUCCESS} heading={Messages.Verification.IDENTITY_VERIFIED_HEADING}>
        <OwnerVerificationStatus
          state={state}
          submitting={submitting}
          ownerId={ownerId}
          ownerIsTeam={ownerIsTeam}
          onCreateIdentityVerification={handleCreateIdentityVerification}
        />
      </Alert>
    );
  } else {
    return (
      <Alert type={AlertTypes.INFO} heading={Messages.Verification.IDENTITY_PROCESSING_HEADING}>
        <OwnerVerificationStatus
          state={state}
          submitting={submitting}
          ownerId={ownerId}
          ownerIsTeam={ownerIsTeam}
          onCreateIdentityVerification={handleCreateIdentityVerification}
        />
      </Alert>
    );
  }
}
