type Resolver = (value: unknown) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action<T> = (...args: any[]) => Promise<T>;

/* This function limits the number of times a promise-returning-function (referred to as an action) may be invoked.

   It queues any invocations of the action while the action is active, and resolves or rejects all queued
   invocations with the resolved or rejected value from the original action. The returned function accepts a cache key
   as its first argument, and all subsequent argumetns will be passed to the original action. The cache key may be changed
   to re-invoke the original action.

   For example, imagine you have multiple components that rely on data from a single endpoint, but you don't want to hit
   the endpoint multiple times. Maybe you want to handle the response from that endpoint differently in different
   components, or don't want to decouple the data fetching from the components' logic. You could wrap your action
   in callMeMaybe and invoke that wrapped action everywhere that requires the data from the original action.
*/
export function callMeMaybe<T>(action: Action<T>) {
  // Tracks the keys of actions that are currently active
  const activePromiseKeys = new Set<string>();

  // Queues the resolve and reject functions, to be invoked when the original action resolves or rejects
  const queuedResolvers = new Map<string, Resolver[]>();
  const queuedRejectors = new Map<string, Resolver[]>();

  // Stores the values with which the original action resolved or rejected
  const resolveValues = new Map<string, T>();

  // The `any` here is justified, in that `Promise.reject` can take any type.
  const rejectValues = new Map<string, never>();

  // Handles both resolutions and rejections:
  //  - sets the state of the action as inactive
  //  - invokes any queued resolve/reject functions from potential subsequent invocations of the original action
  //  - stores the resolved/rejected values
  // TODO(beckwith) The types here are far better than they used to be, but the `any` for handleFn
  // should ultimately be resolved.
  const getPromiseHandler = (
    cacheKey: string,
    queuedHandlersMap: Map<string, Resolver[]>,
    valueStore: Map<string, T>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFn: any,
  ) => {
    return (value: T) => {
      const queuedHandlers = queuedHandlersMap.get(cacheKey);
      // Wraps the action's return value in Promise.resolve or Promise.reject
      const handledValue = handleFn(value);

      // Flush the queued handlers, invoking them with the resolved or rejected value
      if (queuedHandlers != null) {
        while (queuedHandlers.length > 0) {
          const handler = queuedHandlers.shift();
          if (handler != null) {
            handler(handledValue);
          }
        }
        queuedHandlersMap.delete(cacheKey);
      }

      // Store the action's return value for future use
      valueStore.set(cacheKey, value);
      activePromiseKeys.delete(cacheKey);

      // Returns the resolved or rejected value to the consumer
      return handledValue;
    };
  };

  // Queues subsequent promise handlers
  const queuePromiseHandler = (cacheKey: string, queuedHandlersMap: Map<string, Resolver[]>, action: Resolver) => {
    const nextActions = queuedHandlersMap.get(cacheKey) ?? [];
    queuedHandlersMap.set(cacheKey, nextActions.concat(action));
  };

  const wrappedAction = (cacheKey: string, ...actionArgs: unknown[]) => {
    // Resolves with the action's stored value, if it exists
    if (resolveValues.has(cacheKey)) {
      return Promise.resolve(resolveValues.get(cacheKey));
    }

    // Rejects with the action's stored value, if it exists
    if (rejectValues.has(cacheKey)) {
      return Promise.reject(rejectValues.get(cacheKey));
    }

    // Queues the resolve and reject handlers, if the original action is active
    if (activePromiseKeys.has(cacheKey)) {
      return new Promise((resolve, reject) => {
        queuePromiseHandler(cacheKey, queuedResolvers, resolve);
        queuePromiseHandler(cacheKey, queuedRejectors, reject);
      });
    }

    activePromiseKeys.add(cacheKey);

    return action(...actionArgs).then(
      getPromiseHandler(cacheKey, queuedResolvers, resolveValues, Promise.resolve.bind(Promise)),
      getPromiseHandler(cacheKey, queuedRejectors, rejectValues, Promise.reject.bind(Promise)),
    );
  };

  const setResolveValue = (cacheKey: string, resolveValue: T): void => {
    resolveValues.set(cacheKey, resolveValue);
  };

  wrappedAction.setResolveValue = setResolveValue;

  return wrappedAction;
}
