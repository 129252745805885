import cookie from 'cookie';
import {I18N} from '@discordapp/i18n';

import {track} from '@developers/utils/TrackingAnalyticsUtils';

import {AnalyticsEvents} from '@developers/Constants';
import type ParsedMessages from '@developers/typings/messages.d.ts';

export const i18n = new I18N<typeof ParsedMessages>({
  initialLocale: cookie.parse(document.cookie).locale,
  getMessages: (locale) => {
    return require(`./messages/${locale}`);
  },
  getLanguages: () => require('./languages.json'),
});

i18n.addListener('locale', (locale: string, oldLocale: string) => {
  document.cookie = `locale=${locale}`;

  if (locale !== oldLocale && oldLocale !== '') {
    track(AnalyticsEvents.CHANGE_MARKETING_LOCALE, {from: oldLocale, to: locale});
  }
});

export const Messages = i18n.Messages;
