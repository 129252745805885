import * as React from 'react';
import classNames from 'classnames';

import {isSafeRedirect} from '@developers/utils/RedirectUtils';
import {track} from '@developers/utils/TrackingAnalyticsUtils';
import Clickable from './Clickable';

import {AnalyticsEvents} from '@developers/Constants';
import type {FocusRingProps} from 'react-focus-rings';
import styles from './Anchor.module.css';

export interface AnchorProps {
  href?: string | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  rel?: string;
  target?: string;
  children: React.ReactNode;
  focusProps?: FocusRingProps;
}

const Anchor = ({href, onClick, className, children, rel, target, ...props}: AnchorProps) => {
  const attributes: {
    className?: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    rel: string | undefined;
    target: string | undefined;
  } = {
    className: classNames(styles.anchor, className),
    href: href ?? undefined,
    onClick,
    rel,
    target,
  };

  if (process.env.NODE_ENV === 'development' && children == null) {
    throw new Error('Anchor component must have non-null children');
  }

  const isExternalLink = href != null && !isSafeRedirect(href) && href.indexOf('#') !== 0;
  if (isExternalLink) {
    attributes.rel = 'noreferrer noopener';
    attributes.target = '_blank';
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isExternalLink) {
      track(AnalyticsEvents.OUTBOUND_LINK_CLICKED, {
        href,
      });
    }

    onClick?.(e);
  };

  return (
    <Clickable tag="a" {...props} {...attributes} onClick={handleClick}>
      {children}
    </Clickable>
  );
};

export default Anchor;
