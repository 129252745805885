import ThemeTypes, {ThemeValues, ThemeExtends} from './config/colors/generated/ThemeTypes';
import * as BigFlagUtils from './utils/BigFlagUtils';
import {wrapPaths} from './utils/PathUtils';

export {default as Colors} from './config/colors/generated/Colors';
export {Color} from './config/colors/generated/ColorDetails';
export {ThemeColor} from './config/colors/generated/ThemeColorMapNew';
export {default as WebColors} from './config/colors/generated/web/Colors';
export {ThemeTypes, ThemeValues, ThemeExtends};

export const APP_URL_PREFIX = location.protocol + window.GLOBAL_ENV.WEBAPP_ENDPOINT;
export const VANITY_URL_PREFIX = location.protocol + '//discord.gg';

export const PRIMARY_DOMAIN = 'discord.com';

// TODO: replace-with-discord.com
/* eslint-disable @discordapp/discord/mark-discordapp-com */
export const SUPPORT_DOMAIN = 'support.discord.com';
export const SUPPORT_DEV_DOMAIN = 'support-dev.discord.com';
export const EMAIL_DOMAIN = 'discord.com';
export const BLOG_DOMAIN = 'blog.discord.com';
export const FEEDBACK_DOMAIN = 'feedback.discord.com';
/* eslint-enable @discordapp/discord/mark-discordapp-com */

export const UNSAFE_Colors = Object.freeze({
  HINT_PURPLE: '#c9d2f0',
  DARK_PURPLE: '#697ec4',
  MODAL_GREY: '#EFEFF4',
  ORANGE: '#f57731',
  GUILDS_GREY: '#1e2124',
  ACCOUNT_GREY: '#282b30',
  CHAT_GREY: '#36393e',
  UNREAD_GREY: '#8a8e94',
  HIGHLIGHT_GREY: '#25282c',
  AVATAR_GREY: '#747F8C',

  WHITE2: '#f3f3f3',
  WHITE3: '#f0f0f0',
  WHITE7: '#dbdde1',
  WHITE8: '#cdcdcd',

  GREY1: '#99aab5',
  GREY2: '#87909c',
  GREY3: '#737f8d',
  GREY4: '#949494',
  GREY5: '#535559',
  GREY6: '#4f545c',
  GREY7: '#1c242b',
  GREY9: '#202226',
});

export enum Fonts {
  PRIMARY_NORMAL = 'ggsans-Normal, NotoSans-Normal',
  PRIMARY_MEDIUM = 'ggsans-Medium, NotoSans-Medium',
  PRIMARY_SEMIBOLD = 'ggsans-Semibold, NotoSans-Semibold',
  PRIMARY_BOLD = 'ggsans-Bold, NotoSans-Bold',
  DISPLAY_NORMAL = 'ggsans-Normal, NotoSans-Normal',
  DISPLAY_MEDIUM = 'ggsans-Medium, NotoSans-Medium',
  DISPLAY_SEMIBOLD = 'ggsans-Semibold, NotoSans-Semibold',
  DISPLAY_BOLD = 'ggsans-Bold, NotoSans-Bold',
  DISPLAY_EXTRABOLD = 'ggsans-ExtraBold, NotoSans-ExtraBold',
  CODE_NORMAL = 'ggmono-Normal, SourceCodePro-Normal',
  CODE_BOLD = 'ggmono-Bold, SourceCodePro-Bold',
}

export enum StatusTypes {
  ONLINE = 'online',
  OFFLINE = 'offline',
  IDLE = 'idle',
  DND = 'dnd',
  INVISIBLE = 'invisible',
  STREAMING = 'streaming',
  UNKNOWN = 'unknown',
}

export enum HangStatusTypes {
  CHILLING = 'chilling',
  GAMING = 'gaming',
  FOCUSING = 'focusing',
  BRB = 'brb',
  EATING = 'eating',
  IN_TRANSIT = 'in-transit',
  WATCHING = 'watching',
  CUSTOM = 'custom',
}

export enum ClientTypes {
  DESKTOP = 'desktop',
  WEB = 'web',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown',
}

export const RECAPTCHA_SITE_KEY = '6Lef5iQTAAAAAKeIvIY-DeexoO3gj7ryl9rLMEnn';

export enum RevenueCampaigns {
  CHECKOUT_RECOVERY = 1,
  CASH_APP_PAY_AWARENESS = 2,
}

export enum PaymentGateways {
  STRIPE = 1,
  BRAINTREE = 2,
  APPLE = 3,
  GOOGLE = 4,
  ADYEN = 5,
  APPLE_PARTNER = 6,
}

export const PaymentGatewayToFriendlyName = Object.freeze({
  [PaymentGateways.STRIPE]: 'Stripe',
  [PaymentGateways.BRAINTREE]: 'Braintree',
  [PaymentGateways.APPLE]: 'Apple',
  [PaymentGateways.GOOGLE]: 'Google',
  [PaymentGateways.ADYEN]: 'Adyen',
  [PaymentGateways.APPLE_PARTNER]: 'Apple Partner',
});

export const PaymentSettings = Object.freeze({
  BRAINTREE: {
    KEY: window.GLOBAL_ENV.BRAINTREE_KEY,
    PAYMENT_GATEWAY: 'braintree',
  },
  STRIPE: {
    KEY: window.GLOBAL_ENV.STRIPE_KEY,
    PAYMENT_GATEWAY: 'stripe',
  },
  ADYEN: {
    KEY: window.GLOBAL_ENV.ADYEN_KEY,
    PAYMENT_GATEWAY: 'adyen',
  },
});

export enum PaymentSourceTypes {
  UNKNOWN = 0,
  CARD = 1,
  PAYPAL = 2,
  GIROPAY = 3,
  SOFORT = 4,
  PRZELEWY24 = 5,
  SEPA_DEBIT = 6,
  PAYSAFE_CARD = 7,
  GCASH = 8,
  GRABPAY_MY = 9,
  MOMO_WALLET = 10,
  VENMO = 11,
  GOPAY_WALLET = 12,
  KAKAOPAY = 13,
  BANCONTACT = 14,
  EPS = 15,
  IDEAL = 16,
  CASH_APP = 17,
  APPLE = 18,

  // TODO(churros): clean up PaymentSourceTypes so it matches PaymentSourceTypes
  // in discord_api, otherwise not matching values is confusing
  PAYMENT_REQUEST = 99,
}

export const ADYEN_PAYMENT_SOURCES: ReadonlyMap<PaymentSourceTypes, string> = new Map([
  [PaymentSourceTypes.PAYSAFE_CARD, 'paysafecard'],
  [PaymentSourceTypes.GCASH, 'gcash'],
  [PaymentSourceTypes.GRABPAY_MY, 'grabpay_MY'],
  [PaymentSourceTypes.MOMO_WALLET, 'momo_wallet'],
  [PaymentSourceTypes.KAKAOPAY, 'kakaopay'],
  [PaymentSourceTypes.GOPAY_WALLET, 'gopay_wallet'],
  [PaymentSourceTypes.CASH_APP, 'cashapp'],
]);

export const STRIPE_PAYMENT_SOURCES: ReadonlyMap<PaymentSourceTypes, string> = new Map([
  [PaymentSourceTypes.BANCONTACT, 'bancontact'],
  [PaymentSourceTypes.GIROPAY, 'giropay'],
  [PaymentSourceTypes.IDEAL, 'ideal'],
]);

// When users redeem trials with a payment method, we currently place a 99 cents hold on the payment method
// and this can only be done if the payment method supports manual capture.
// The payment methods below do not support manual capture.
export const IRREDEEMABLE_PAYMENT_SOURCES: ReadonlySet<PaymentSourceTypes> = new Set([
  PaymentSourceTypes.PAYSAFE_CARD,
  PaymentSourceTypes.GCASH,
  PaymentSourceTypes.GRABPAY_MY,
  PaymentSourceTypes.GOPAY_WALLET,
  PaymentSourceTypes.MOMO_WALLET,
  PaymentSourceTypes.KAKAOPAY,
  PaymentSourceTypes.BANCONTACT,
  PaymentSourceTypes.EPS,
  PaymentSourceTypes.IDEAL,
  PaymentSourceTypes.SOFORT,
  PaymentSourceTypes.GIROPAY,
  PaymentSourceTypes.PRZELEWY24,
  PaymentSourceTypes.CASH_APP,
]);

export const PREPAID_PAYMENT_SOURCES: ReadonlySet<PaymentSourceTypes> = new Set([
  PaymentSourceTypes.PAYSAFE_CARD,
  PaymentSourceTypes.GRABPAY_MY,
  PaymentSourceTypes.GIROPAY,
  PaymentSourceTypes.PRZELEWY24,
  // TODO(shen.zhang@): Remove Sofort and Bancontact after adding SEPA support.
  PaymentSourceTypes.SOFORT,
  PaymentSourceTypes.BANCONTACT,
  PaymentSourceTypes.EPS,
  PaymentSourceTypes.IDEAL,
]);

export const VAULTABLE_PAYMENT_SOURCES: ReadonlySet<PaymentSourceTypes> = new Set([
  PaymentSourceTypes.CARD,
  PaymentSourceTypes.PAYPAL,
  PaymentSourceTypes.SEPA_DEBIT,
  PaymentSourceTypes.GCASH,
  PaymentSourceTypes.MOMO_WALLET,
  PaymentSourceTypes.VENMO,
  PaymentSourceTypes.KAKAOPAY,
  PaymentSourceTypes.GOPAY_WALLET,
  PaymentSourceTypes.CASH_APP,
]);

export const REDIRECTED_PAYMENT_SOURCES: ReadonlySet<PaymentSourceTypes> = new Set([
  // Stripe
  PaymentSourceTypes.GIROPAY,
  PaymentSourceTypes.SOFORT,
  PaymentSourceTypes.PRZELEWY24,
  PaymentSourceTypes.BANCONTACT,
  PaymentSourceTypes.EPS,
  PaymentSourceTypes.IDEAL,

  // Adyen
  PaymentSourceTypes.PAYSAFE_CARD,
  PaymentSourceTypes.GRABPAY_MY,
  PaymentSourceTypes.GOPAY_WALLET,
]);

export const DELAYED_PAYMENTS = new Set([PaymentSourceTypes.SEPA_DEBIT]);
// We proactively grant perks/entitlements for payments with payment sources in PROACTIVE_FULFILLMENT. Payments
// could still be pending when the fulfillment happens. If the payment fails eventually, perks and entitlements
// will be revoked.
export const PROACTIVE_FULFILLMENT = new Set([PaymentSourceTypes.SOFORT]);

export enum PaymentSourceFlags {
  NEW = 1 << 0,
}

export enum PaymentStatusTypes {
  PENDING = 0,
  COMPLETED = 1,
  FAILED = 2,
  REVERSED = 3,
  REFUNDED = 4,
  CANCELED = 5,
}

export enum SubscriptionTypes {
  PREMIUM = 1,
  GUILD = 2,
  APPLICATION = 3,
}

export enum BraintreeErrors {
  PAYPAL_POPUP_CLOSED = 'PAYPAL_POPUP_CLOSED',
  VENMO_APP_CANCELED = 'VENMO_APP_CANCELED', // User canceled Venmo flow.
  VENMO_CANCELED = 'VENMO_CANCELED', // User canceled Venmo, or Venmo app is not available.
}

export const ExternalSKUStrategyTypes = Object.freeze({
  CONSTANT: 1,
  APPLE_STICKER: 2,
});

export enum RevenueSurfaces {
  // Where a user discovers a product they can purchase (e.g. marketing pages, upsells, etc)
  DISCOVERY = 0,
  // Where a user intends to make a purchase via the checkout flow
  CHECKOUT = 1,
}

export enum PurchaseTypes {
  SUBSCRIPTION = 0,
  ONE_TIME = 1,
}

export const PurchaseTypeToAnalyticsPaymentType = Object.freeze({
  [PurchaseTypes.SUBSCRIPTION]: 'subscription',
  [PurchaseTypes.ONE_TIME]: 'sku',
});

// "Basic Permissions".
//
// We store "basic" channels that are loaded at startup. These are smaller, compact representations of channels that are
// loaded at startup, and contain precomputed permissions.
//
// To aid performance, we limit the permissions stored to to the first 32 bits, so that we can skip the entire string ->
// bigint deserialization step and save ourselves significant cpu time.
//
// Thus, only the first 32 bits are preserved. Bits above the first 32 will not work.
export enum BasicPermissions {
  VIEW_CHANNEL = 1 << 10,
  READ_MESSAGE_HISTORY = 1 << 16,
  CONNECT = 1 << 20,
}

export const Permissions = Object.freeze({
  // General
  CREATE_INSTANT_INVITE: BigFlagUtils.getFlag(0),
  KICK_MEMBERS: BigFlagUtils.getFlag(1),
  BAN_MEMBERS: BigFlagUtils.getFlag(2),
  ADMINISTRATOR: BigFlagUtils.getFlag(3),
  MANAGE_CHANNELS: BigFlagUtils.getFlag(4),
  MANAGE_GUILD: BigFlagUtils.getFlag(5),
  CHANGE_NICKNAME: BigFlagUtils.getFlag(26),
  MANAGE_NICKNAMES: BigFlagUtils.getFlag(27),
  MANAGE_ROLES: BigFlagUtils.getFlag(28),
  MANAGE_WEBHOOKS: BigFlagUtils.getFlag(29),
  MANAGE_GUILD_EXPRESSIONS: BigFlagUtils.getFlag(30),
  CREATE_GUILD_EXPRESSIONS: BigFlagUtils.getFlag(43),
  VIEW_AUDIT_LOG: BigFlagUtils.getFlag(7),
  // Used to be called READ_MESSAGES
  VIEW_CHANNEL: BigFlagUtils.getFlag(10),
  VIEW_GUILD_ANALYTICS: BigFlagUtils.getFlag(19),
  VIEW_CREATOR_MONETIZATION_ANALYTICS: BigFlagUtils.getFlag(41),
  MODERATE_MEMBERS: BigFlagUtils.getFlag(40),
  USE_EMBEDDED_ACTIVITIES: BigFlagUtils.getFlag(39),
  USE_EXTERNAL_APPS: BigFlagUtils.getFlag(50),

  // Text
  SEND_MESSAGES: BigFlagUtils.getFlag(11),
  SEND_TTS_MESSAGES: BigFlagUtils.getFlag(12),
  MANAGE_MESSAGES: BigFlagUtils.getFlag(13),
  EMBED_LINKS: BigFlagUtils.getFlag(14),
  ATTACH_FILES: BigFlagUtils.getFlag(15),
  READ_MESSAGE_HISTORY: BigFlagUtils.getFlag(16),
  MENTION_EVERYONE: BigFlagUtils.getFlag(17),
  USE_EXTERNAL_EMOJIS: BigFlagUtils.getFlag(18),
  ADD_REACTIONS: BigFlagUtils.getFlag(6),
  USE_APPLICATION_COMMANDS: BigFlagUtils.getFlag(31),
  MANAGE_THREADS: BigFlagUtils.getFlag(34),
  CREATE_PUBLIC_THREADS: BigFlagUtils.getFlag(35),
  CREATE_PRIVATE_THREADS: BigFlagUtils.getFlag(36),
  USE_EXTERNAL_STICKERS: BigFlagUtils.getFlag(37),
  SEND_MESSAGES_IN_THREADS: BigFlagUtils.getFlag(38),
  SEND_VOICE_MESSAGES: BigFlagUtils.getFlag(46),
  USE_CLYDE_AI: BigFlagUtils.getFlag(47),
  SEND_POLLS: BigFlagUtils.getFlag(49),

  // Voice
  CONNECT: BigFlagUtils.getFlag(20),
  SPEAK: BigFlagUtils.getFlag(21),
  MUTE_MEMBERS: BigFlagUtils.getFlag(22),
  DEAFEN_MEMBERS: BigFlagUtils.getFlag(23),
  MOVE_MEMBERS: BigFlagUtils.getFlag(24),
  USE_VAD: BigFlagUtils.getFlag(25),
  PRIORITY_SPEAKER: BigFlagUtils.getFlag(8),
  STREAM: BigFlagUtils.getFlag(9),
  USE_SOUNDBOARD: BigFlagUtils.getFlag(42),
  USE_EXTERNAL_SOUNDS: BigFlagUtils.getFlag(45),
  SET_VOICE_CHANNEL_STATUS: BigFlagUtils.getFlag(48),

  // Stage
  REQUEST_TO_SPEAK: BigFlagUtils.getFlag(32),

  // Guild Scheduled Events
  MANAGE_EVENTS: BigFlagUtils.getFlag(33),
  CREATE_EVENTS: BigFlagUtils.getFlag(44),
});

export const NOOP = (..._: any): any => {};
export const NOOP_NULL = () => null;
export const NOOP_PROMISE = () => Promise.resolve();

// For Guild MFA
export const ElevatedPermissionsList = [
  Permissions.KICK_MEMBERS,
  Permissions.BAN_MEMBERS,
  Permissions.ADMINISTRATOR,
  Permissions.MANAGE_CHANNELS,
  Permissions.MANAGE_GUILD,
  Permissions.MANAGE_ROLES,
  Permissions.MANAGE_MESSAGES,
  Permissions.MANAGE_THREADS,
  Permissions.MODERATE_MEMBERS,
];
export const ElevatedPermissions = BigFlagUtils.combine(...ElevatedPermissionsList);

/* START RPC Constants */
export const RPC_VERSION = 1;
export const RPC_STARTING_PORT = 6463; // why is it 6463? 0x6463 = dc
export const RPC_PORT_RANGE = 10;
export const RPC_APPLICATION_LOGGING_CATEGORY = 'RpcApplicationLogger';

export enum RPCCommands {
  DISPATCH = 'DISPATCH',

  SET_CONFIG = 'SET_CONFIG',

  AUTHORIZE = 'AUTHORIZE',
  AUTHENTICATE = 'AUTHENTICATE',

  GET_GUILD = 'GET_GUILD',
  GET_GUILDS = 'GET_GUILDS',
  GET_CHANNEL = 'GET_CHANNEL',
  GET_CHANNELS = 'GET_CHANNELS',
  GET_CHANNEL_PERMISSIONS = 'GET_CHANNEL_PERMISSIONS',
  CREATE_CHANNEL_INVITE = 'CREATE_CHANNEL_INVITE',

  GET_RELATIONSHIPS = 'GET_RELATIONSHIPS',

  GET_USER = 'GET_USER',

  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',

  SET_USER_VOICE_SETTINGS = 'SET_USER_VOICE_SETTINGS',
  SET_USER_VOICE_SETTINGS_2 = 'SET_USER_VOICE_SETTINGS_2',
  PUSH_TO_TALK = 'PUSH_TO_TALK',
  SELECT_VOICE_CHANNEL = 'SELECT_VOICE_CHANNEL',
  GET_SELECTED_VOICE_CHANNEL = 'GET_SELECTED_VOICE_CHANNEL',
  SELECT_TEXT_CHANNEL = 'SELECT_TEXT_CHANNEL',
  GET_VOICE_SETTINGS = 'GET_VOICE_SETTINGS',
  SET_VOICE_SETTINGS_2 = 'SET_VOICE_SETTINGS_2',
  SET_VOICE_SETTINGS = 'SET_VOICE_SETTINGS',

  SET_ACTIVITY = 'SET_ACTIVITY',
  SEND_ACTIVITY_JOIN_INVITE = 'SEND_ACTIVITY_JOIN_INVITE',
  CLOSE_ACTIVITY_JOIN_REQUEST = 'CLOSE_ACTIVITY_JOIN_REQUEST',
  ACTIVITY_INVITE_USER = 'ACTIVITY_INVITE_USER',
  ACCEPT_ACTIVITY_INVITE = 'ACCEPT_ACTIVITY_INVITE',
  OPEN_INVITE_DIALOG = 'OPEN_INVITE_DIALOG',
  OPEN_SHARE_MOMENT_DIALOG = 'OPEN_SHARE_MOMENT_DIALOG',
  INITIATE_IMAGE_UPLOAD = 'INITIATE_IMAGE_UPLOAD',

  INVITE_BROWSER = 'INVITE_BROWSER',
  DEEP_LINK = 'DEEP_LINK',
  CONNECTIONS_CALLBACK = 'CONNECTIONS_CALLBACK',
  BILLING_POPUP_BRIDGE_CALLBACK = 'BILLING_POPUP_BRIDGE_CALLBACK',
  BRAINTREE_POPUP_BRIDGE_CALLBACK = 'BRAINTREE_POPUP_BRIDGE_CALLBACK',
  GIFT_CODE_BROWSER = 'GIFT_CODE_BROWSER',
  GUILD_TEMPLATE_BROWSER = 'GUILD_TEMPLATE_BROWSER',

  OVERLAY = 'OVERLAY',
  BROWSER_HANDOFF = 'BROWSER_HANDOFF',

  SET_CERTIFIED_DEVICES = 'SET_CERTIFIED_DEVICES',

  GET_IMAGE = 'GET_IMAGE',

  SET_OVERLAY_LOCKED = 'SET_OVERLAY_LOCKED',
  OPEN_OVERLAY_ACTIVITY_INVITE = 'OPEN_OVERLAY_ACTIVITY_INVITE',
  OPEN_OVERLAY_GUILD_INVITE = 'OPEN_OVERLAY_GUILD_INVITE',
  OPEN_OVERLAY_VOICE_SETTINGS = 'OPEN_OVERLAY_VOICE_SETTINGS',

  VALIDATE_APPLICATION = 'VALIDATE_APPLICATION',
  GET_ENTITLEMENT_TICKET = 'GET_ENTITLEMENT_TICKET',
  GET_APPLICATION_TICKET = 'GET_APPLICATION_TICKET',
  START_PURCHASE = 'START_PURCHASE',
  START_PREMIUM_PURCHASE = 'START_PREMIUM_PURCHASE',
  GET_SKUS = 'GET_SKUS',
  GET_ENTITLEMENTS = 'GET_ENTITLEMENTS',

  GET_SKUS_EMBEDDED = 'GET_SKUS_EMBEDDED',
  GET_ENTITLEMENTS_EMBEDDED = 'GET_ENTITLEMENTS_EMBEDDED',

  GET_NETWORKING_CONFIG = 'GET_NETWORKING_CONFIG',
  NETWORKING_SYSTEM_METRICS = 'NETWORKING_SYSTEM_METRICS',
  NETWORKING_PEER_METRICS = 'NETWORKING_PEER_METRICS',
  NETWORKING_CREATE_TOKEN = 'NETWORKING_CREATE_TOKEN', // deprecated

  SET_USER_ACHIEVEMENT = 'SET_USER_ACHIEVEMENT',
  GET_USER_ACHIEVEMENTS = 'GET_USER_ACHIEVEMENTS',

  USER_SETTINGS_GET_LOCALE = 'USER_SETTINGS_GET_LOCALE',

  SEND_GENERIC_EVENT = 'SEND_GENERIC_EVENT', // temp, poker night only
  SEND_ANALYTICS_EVENT = 'SEND_ANALYTICS_EVENT',
  OPEN_EXTERNAL_LINK = 'OPEN_EXTERNAL_LINK',
  CAPTURE_LOG = 'CAPTURE_LOG',

  ENCOURAGE_HW_ACCELERATION = 'ENCOURAGE_HW_ACCELERATION',
  SET_ORIENTATION_LOCK_STATE = 'SET_ORIENTATION_LOCK_STATE',
  GET_PLATFORM_BEHAVIORS = 'GET_PLATFORM_BEHAVIORS',

  GET_SOUNDBOARD_SOUNDS = 'GET_SOUNDBOARD_SOUNDS',
  PLAY_SOUNDBOARD_SOUND = 'PLAY_SOUNDBOARD_SOUND',

  TOGGLE_VIDEO = 'TOGGLE_VIDEO',
  TOGGLE_SCREENSHARE = 'TOGGLE_SCREENSHARE',

  GET_ACTIVITY_INSTANCE_CONNECTED_PARTICIPANTS = 'GET_ACTIVITY_INSTANCE_CONNECTED_PARTICIPANTS',
  GET_PROVIDER_ACCESS_TOKEN = 'GET_PROVIDER_ACCESS_TOKEN',
  MAYBE_GET_PROVIDER_ACCESS_TOKEN = 'MAYBE_GET_PROVIDER_ACCESS_TOKEN',

  NAVIGATE_TO_CONNECTIONS = 'NAVIGATE_TO_CONNECTIONS',
}

export enum RPCEvents {
  CURRENT_USER_UPDATE = 'CURRENT_USER_UPDATE',
  CURRENT_GUILD_MEMBER_UPDATE = 'CURRENT_GUILD_MEMBER_UPDATE',

  GUILD_STATUS = 'GUILD_STATUS',
  GUILD_CREATE = 'GUILD_CREATE',

  CHANNEL_CREATE = 'CHANNEL_CREATE',

  RELATIONSHIP_UPDATE = 'RELATIONSHIP_UPDATE',

  VOICE_CHANNEL_SELECT = 'VOICE_CHANNEL_SELECT',
  VOICE_STATE_CREATE = 'VOICE_STATE_CREATE',
  VOICE_STATE_DELETE = 'VOICE_STATE_DELETE',
  VOICE_STATE_UPDATE = 'VOICE_STATE_UPDATE',
  VOICE_SETTINGS_UPDATE = 'VOICE_SETTINGS_UPDATE',
  VOICE_SETTINGS_UPDATE_2 = 'VOICE_SETTINGS_UPDATE_2',

  VOICE_CONNECTION_STATUS = 'VOICE_CONNECTION_STATUS',
  SPEAKING_START = 'SPEAKING_START',
  SPEAKING_STOP = 'SPEAKING_STOP',

  GAME_JOIN = 'GAME_JOIN', // deprecated
  GAME_SPECTATE = 'GAME_SPECTATE', // deprecated
  ACTIVITY_JOIN = 'ACTIVITY_JOIN',
  ACTIVITY_JOIN_REQUEST = 'ACTIVITY_JOIN_REQUEST',
  ACTIVITY_SPECTATE = 'ACTIVITY_SPECTATE',
  ACTIVITY_INVITE = 'ACTIVITY_INVITE',
  ACTIVITY_PIP_MODE_UPDATE = 'ACTIVITY_PIP_MODE_UPDATE',
  ACTIVITY_LAYOUT_MODE_UPDATE = 'ACTIVITY_LAYOUT_MODE_UPDATE',
  THERMAL_STATE_UPDATE = 'THERMAL_STATE_UPDATE',
  ORIENTATION_UPDATE = 'ORIENTATION_UPDATE',
  ACTIVITY_INSTANCE_PARTICIPANTS_UPDATE = 'ACTIVITY_INSTANCE_PARTICIPANTS_UPDATE',

  NOTIFICATION_CREATE = 'NOTIFICATION_CREATE',

  MESSAGE_CREATE = 'MESSAGE_CREATE',
  MESSAGE_UPDATE = 'MESSAGE_UPDATE',
  MESSAGE_DELETE = 'MESSAGE_DELETE',

  OVERLAY = 'OVERLAY',
  OVERLAY_UPDATE = 'OVERLAY_UPDATE',

  ENTITLEMENT_CREATE = 'ENTITLEMENT_CREATE',
  ENTITLEMENT_DELETE = 'ENTITLEMENT_DELETE',

  USER_ACHIEVEMENT_UPDATE = 'USER_ACHIEVEMENT_UPDATE',

  VOICE_CHANNEL_EFFECT_SEND = 'VOICE_CHANNEL_EFFECT_SEND',
  VOICE_CHANNEL_EFFECT_RECENT_EMOJI = 'VOICE_CHANNEL_EFFECT_RECENT_EMOJI',
  VOICE_CHANNEL_EFFECT_TOGGLE_ANIMATION_TYPE = 'VOICE_CHANNEL_EFFECT_TOGGLE_ANIMATION_TYPE',

  SCREENSHARE_STATE_UPDATE = 'SCREENSHARE_STATE_UPDATE',
  VIDEO_STATE_UPDATE = 'VIDEO_STATE_UPDATE',

  READY = 'READY',
  ERROR = 'ERROR',
}

/**
 * WARNING RPCCloseCodes and RPCErrors overlap in values
 * Be careful not to create new codes without referencing both
 */
export enum RPCErrors {
  UNKNOWN_ERROR = 1000,
  SERVICE_UNAVAILABLE = 1001,
  TRANSACTION_ABORTED = 1002,

  INVALID_PAYLOAD = 4000,
  INVALID_COMMAND = 4002,
  INVALID_GUILD = 4003,
  INVALID_EVENT = 4004,
  INVALID_CHANNEL = 4005,
  INVALID_PERMISSIONS = 4006,
  INVALID_CLIENTID = 4007,
  INVALID_ORIGIN = 4008,
  INVALID_TOKEN = 4009,
  INVALID_USER = 4010,
  INVALID_INVITE = 4011,
  INVALID_ACTIVITY_JOIN_REQUEST = 4012,
  INVALID_ENTITLEMENT = 4015,
  INVALID_GIFT_CODE = 4016,
  INVALID_GUILD_TEMPLATE = 4017,
  INVALID_SOUND = 4018,
  INVALID_PROVIDER = 4019,
  INVALID_CONNECTION_CALLBACK_STATE = 4020,
  BAD_REQUEST_FOR_PROVIDER = 4021,

  OAUTH2_ERROR = 5000,
  SELECT_CHANNEL_TIMED_OUT = 5001,
  GET_GUILD_TIMED_OUT = 5002,
  SELECT_VOICE_FORCE_REQUIRED = 5003,
  INVALID_ACTIVITY_SECRET = 5005,
  NO_ELIGIBLE_ACTIVITY = 5006,
  PURCHASE_CANCELED = 5008,
  PURCHASE_ERROR = 5009,
  UNAUTHORIZED_FOR_ACHIEVEMENT = 5010,
  RATE_LIMITED = 5011,
  UNAUTHORIZED_FOR_APPLICATION = 5012,
  NO_CONNECTION_FOUND = 5013,
}

/**
 * WARNING RPCCloseCodes and RPCErrors overlap in values
 * Be careful not to create new codes without referencing both
 */
export enum RPCCloseCodes {
  CLOSE_NORMAL = 1000,
  CLOSE_UNSUPPORTED = 1003,
  CLOSE_ABNORMAL = 1006,
  INVALID_CLIENTID = 4000,
  INVALID_ORIGIN = 4001,
  RATELIMITED = 4002,
  TOKEN_REVOKED = 4003,
  INVALID_VERSION = 4004,
  INVALID_ENCODING = 4005,
}
/* END RPC Constants */

export const ROUGH_COUNT_MILLION_PLAYERS = 250;
export const TOKEN_KEY = 'token';
export const TOKENS_KEY = 'tokens';

// When linking users to our status page, we should use discordstatus.com since
// it is independent from our infrastructure. However, for automated status
// checks, we should use status.discord.com, since it caches using Cloudflare,
// so we don't overwhelm the statuspage servers.
export const STATUS_DOMAIN = `status.discord.com`;
export const STATUS_PAGE_ENDPOINT = `https://${STATUS_DOMAIN}`;

export const Links = {
  PRESSKIT: 'https://app.box.com/s/5puqm5ijahrrdao7yldi7fr3zah5i1am',
  REBRAND_PRESSKIT:
    'https://www.dropbox.com/scl/fo/2vittfakjukwa3zl4gnxh/AOS0AZlx-zA4TjDyv-M-dgk?rlkey=kn0v1lcqfq1lurj3lk3x1ujhf&dl=0',
  STATUS: `https://discordstatus.com`,
  HELP_AND_SUPPORT: `//${SUPPORT_DOMAIN}/hc/en-us`,
  FEEDBACK: `//${FEEDBACK_DOMAIN}`,
  EMAIL_SUPPORT: `mailto:support@${EMAIL_DOMAIN}`,
  EMAIL_LAW_ENFORCEMENT: `mailto:lawenforcement@${EMAIL_DOMAIN}`,
  EMAIL_HYPESQUAD: `mailto:hypesquad@${EMAIL_DOMAIN}`,
  EMAIL_JOBS: `mailto:jobs@${EMAIL_DOMAIN}`,
  DEV_PERKS_FORM: 'https://goo.gl/forms/oZfKBStV3sR8GHdU2',
  VERIFICATION_REQUIREMENTS: 'https://dis.gd/vfqual',
  BASE_URL: `https://${PRIMARY_DOMAIN}`,
  PRESS_INQUIRIES: `mailto:press@${EMAIL_DOMAIN}`,
  CONTACT_US: 'https://dis.gd/contact',
  DEV_LANDING: '/build',
  DEV_PORTAL: '/developers',
  DEV_PORTAL_APPLICATIONS: '/developers/applications',
  DEV_PORTAL_DOCUMENTATION: '/developers/docs',
  DEV_PORTAL_SELF_SERVE_MODAL: '/developers/?store_signup=true',
  PARTNER_CODE_OF_CONDUCT: `https://${SUPPORT_DOMAIN}/hc/en-us/articles/360024871991`,
  SUBMIT_TNS_REPORT: 'https://dis.gd/request',
  MERCH_STORE: 'https://discordmerch.com',
  MOD_ACADEMY_EXAM: 'https://dis.gd/exam',
  OFFICIAL_3RD_PARTY_MERCH: 'https://discordmerch.com/evergreenfooter',
  COMMUNITY: '/community',
  TWITTER_SUPPORT: 'https://twitter.com/discord_support',
  QUESTS: '/quests',
};

export const LocalizedLinks = Object.freeze({
  TWITTER: Object.freeze({
    default: 'https://twitter.com/discord',
    ja: 'https://twitter.com/discord_jp',
  }),
});

const UnsafeAppRoutes = Object.freeze({
  WELCOME: `${APP_URL_PREFIX}/welcome/`,
  ME: `${APP_URL_PREFIX}/channels/@me`,
  SETTINGS: (section: string, subsection?: string) =>
    `${APP_URL_PREFIX}/settings/${section}${subsection ? `/${subsection}` : ''}`,
  CONNECTION: (platformType: string) => `${APP_URL_PREFIX}/connections/${platformType}`,
  CHANGELOGS: `${APP_URL_PREFIX}/settings/changelogs`,
  LOGIN: `${APP_URL_PREFIX}/login`,
  LOGIN_WITH_REDIRECT: (redirectTo: string) => `${APP_URL_PREFIX}/login?redirect_to=${redirectTo}`,
  REGISTER: `${APP_URL_PREFIX}/register`,
  REGISTER_WITH_REDIRECT: (redirectTo: string) => `${APP_URL_PREFIX}/register?redirect_to=${redirectTo}`,
  CREATE_GUILD: `${APP_URL_PREFIX}/guilds/create`,
  VANITY_INVITE: (inviteCode: string) => `${VANITY_URL_PREFIX}/${inviteCode}`,
  HYPESQUAD_ONLINE: `${APP_URL_PREFIX}/settings/hypesquad-online`,
  BROWSE_NITRO_TITLES: `${APP_URL_PREFIX}/store/browse?type=nitro`,
  SKU_DETAILS: (skuId: string, storeListingId?: string) =>
    `${APP_URL_PREFIX}/store/skus/${skuId}${storeListingId != null ? `?store_listing_id=${storeListingId}` : ''}`,
  MESSAGE: (guildId: string, channelId: string, messageId: string) => `/channels/${guildId}/${channelId}/${messageId}`,
  CHANNEL: (guildId: string, channelId: string): string => {
    return `/channels/${guildId}/${channelId}`;
  },
  REPORT: `${APP_URL_PREFIX}/report`,
});

enum UnsafeWebRoutes {
  INDEX = '/',
  INDEX_WORD = '/index',
  INDEX_BUCKET = '/index/:bucketId',
  CHANGELOGS = '/changelogs/:date',
  COMMUNITY = '/community',
  COMPANY = '/company',
  COMPANY_INFORMATION = '/company-information',
  CREATORS = '/creators',
  DOWNLOADS = '/downloads', // do not use this, use DOWNLOAD
  DOWNLOAD = '/download',
  GAMING = '/gaming',
  PRIVACY = '/privacy',
  TOS = '/tos', // do not use this, use TERMS
  TERMS = '/terms',
  STORE_TERMS = '/store-terms',
  ACKNOWLEDGEMENTS = '/acknowledgements',
  LICENSES = '/licenses',
  PARTNERS = '/partners',
  HYPESQUAD = '/hypesquad',
  HYPESQUAD_RIOT = '/hypesquad-riot',
  BRANDING = '/branding',
  ERROR_404 = '/404',
  JOBS = '/jobs',
  JOB = '/jobs/:jobId',
  STREAMKIT = '/streamkit',
  SECURITY = '/security',
  NITRO = '/nitro',
  DISCOVER = '/servers',
  GUIDELINES = '/guidelines',
  RICH_PRESENCE = '/rich-presence',
  VERIFICATION = '/verification',
  OPEN_SOURCE = '/open-source',
  WARFRAME = '/warframe',
  REMOTE_AUTH = '/ra/:remoteAuthFingerprint',
  SAFETY_LANDING = '/safetycenter',
  SAFETY_ARTICLE_ROOT = '/safety',
  SAFETY_ARTICLE = '/safety/:article',
  NEWSROOM = '/newsroom',
  INSPIRATION = '/inspiration',
  MOD_ACADEMY_LANDING = '/moderation',
  MOD_ACADEMY_EXAM = '/moderation/exam',
  MOD_ACADEMY_ARTICLE = '/moderation/:article',
  BLOG = '/blog',
  APP_DIRECTORY = '/application-directory',

  REFRESH_INDEX = '/new',
  REFRESH_INDEX_WORD = '/new/index',
  REFRESH_COMPANY = '/new/company',
  REFRESH_DOWNLOAD = '/new/download',
  REFRESH_PRIVACY = '/new/privacy',
  REFRESH_TERMS = '/new/terms',
  REFRESH_ACKNOWLEDGEMENTS = '/new/acknowledgements',
  REFRESH_LICENSES = '/new/licenses',
  REFRESH_BRANDING = '/new/branding',
  REFRESH_JOBS = '/new/jobs',
  REFRESH_JOB = '/new/jobs/:jobId',
  REFRESH_STREAMKIT = '/new/streamkit',
  REFRESH_NITRO = '/new/nitro',
  REFRESH_GUIDELINES = '/new/guidelines',
  REFRESH_SAFETY_LANDING = '/new/safety',
  REFRESH_SAFETY_ARTICLE = '/new/safety/:article',
  COLLEGE = '/college',
  LEAGUE_CLUBS = '/league-communities',
  DISCORD_RECRUITING_SCAMS = '/discord-recruitment-scams',

  // DEVELOPERS
  DEV_NEWSLETTER_SIGNUP = '/dev-newsletter',
  LEGACY_DEV_NEWSLETTER = '/newsletter',

  // Archived Legal Documents
  LEGAL_ARCHIVE = '/archive/',
  TERMS_MAY_2020 = '/archive/terms/may-2020',
  PRIVACY_JUNE_2020 = '/archive/privacy/june-2020',
  GUIDELINES_MAY_2020 = '/archive/guidelines/may-2020',

  FALL_RELEASE_2023 = '/fallrelease',
  MOBILE_REDESIGN_2023 = '/mobile',

  // DEPRECATED - redirects elsewhere
  REFRESH_XBOX_OFFER = '/new/discord-xbox-offer-2019',
  REFRESH_WHY_DISCORD = '/why-discord-is-different',
  WHY_DISCORD = '/why-discord',
  XBOX_OFFER = '/discord-xbox-offer-2020',
  COLLEGE_BRUINS = '/bruins',
  COLLEGE_ANTEATERS = '/anteaters',
  COLLEGE_GAUCHOS = '/gauchos',
  COLLEGE_BEARS = '/bears',
  COLLEGE_SLUGS = '/slugs',
  BACK_TO_SCHOOL_GIVEAWAY_INSTAGRAM = '/terms/back-to-school-2020/instagram',
  BACK_TO_SCHOOL_GIVEAWAY_TWITTER = '/terms/back-to-school-2020/twitter',
  SNOWSGIVING_GIVEAWAY_INSTAGRAM = '/terms/snowsgiving-2020/instagram',
  SNOWSGIVING_GIVEAWAY_TWITTER = '/terms/snowsgiving-2020/twitter',
}

// Routes which take arguments are automatically urlencoded for safety.
export const AppRoutes: typeof UnsafeAppRoutes = wrapPaths(UnsafeAppRoutes);
export const WebRoutes: typeof UnsafeWebRoutes = wrapPaths(UnsafeWebRoutes);

export const SOCIAL_LINKS = Object.freeze({
  FACEBOOK_URL: 'https://www.facebook.com/discord/',
  INSTAGRAM_URL: 'https://www.instagram.com/discord/',
  YOUTUBE_URL: 'https://www.youtube.com/discord/',
  TIKTOK_URL: 'https://www.tiktok.com/@discord',
});

export enum WebAnalyticsEvents {
  EXPERIMENT_USER_TRIGGERED = 'experiment_user_triggered',
  EXPERIMENT_GUILD_TRIGGERED = 'experiment_guild_triggered',
  EXPERIMENT_SAVE_EXPOSURE_FAILED = 'experiment_save_exposure_failed',
  MKTG_PAGE_VIEWED = 'mktg_page_viewed',
  CLICK_LANDING_CTA = 'click_landing_cta',
  DOWNLOAD_APP = 'download_app',
  MKTG_HYPESQUAD_FORM_SUBMITTED = 'mktg_hypesquad_form_submitted',
  MKTG_HYPESQUAD_FORM_OPENED = 'mktg_hypesquad_form_opened',
  CHANGE_MARKETING_LOCALE = 'change_marketing_locale',
  GAME_CLICKED_LANDING = 'game_clicked_landing',
  MAIN_NAVIGATION_MENU = 'main_navigation_menu',
  MKTG_APPLICATION_STEP = 'mktg_application_step',
  MKTG_WARFRAME_CTA_CLICKED = 'mktg_warframe_cta_clicked',
  MKTG_PAGE_CTA_CLICKED = 'mktg_page_cta_clicked',
  MKTG_VIDEO_PLAYED = 'mktg_video_played',
  DEEP_LINK_CLICKED = 'deep_link_clicked',
  SEO_PAGE_VIEWED = 'seo_server_landing_page_viewed',
  SEO_PAGE_CTA_CLICKED = 'seo_server_landing_page_cta_clicked',
  SEO_AGGREGATOR_PAGE_VIEWED = 'seo_aggregator_page_viewed',
  SEO_AGGREGATOR_CTA_CLICKED = 'seo_aggregator_page_cta_clicked',
  LOCATION_STACK_METADATA = 'location_stack_metadata',
  CREATOR_STORE_PAGE_VIEWED = 'creator_store_page_viewed',
  CREATOR_STORE_PAGE_CTA_CLICKED = 'creator_store_page_cta_clicked',
}

export enum WebAnalyticsPageLoads {
  MKTG_ACKNOWLEDGEMENTS_MODAL_OPEN = 'mktg_ack_modal_open',
}

export enum CurrencyCodes {
  AED = 'aed',
  AFN = 'afn',
  ALL = 'all',
  AMD = 'amd',
  ANG = 'ang',
  AOA = 'aoa',
  ARS = 'ars',
  AUD = 'aud',
  AWG = 'awg',
  AZN = 'azn',
  BAM = 'bam',
  BBD = 'bbd',
  BDT = 'bdt',
  BGN = 'bgn',
  BHD = 'bhd',
  BIF = 'bif',
  BMD = 'bmd',
  BND = 'bnd',
  BOB = 'bob',
  BOV = 'bov',
  BRL = 'brl',
  BSD = 'bsd',
  BTN = 'btn',
  BWP = 'bwp',
  BYN = 'byn',
  BYR = 'byr',
  BZD = 'bzd',
  CAD = 'cad',
  CDF = 'cdf',
  CHE = 'che',
  CHF = 'chf',
  CHW = 'chw',
  CLF = 'clf',
  CLP = 'clp',
  CNY = 'cny',
  COP = 'cop',
  COU = 'cou',
  CRC = 'crc',
  CUC = 'cuc',
  CUP = 'cup',
  CVE = 'cve',
  CZK = 'czk',
  DJF = 'djf',
  DKK = 'dkk',
  DOP = 'dop',
  DZD = 'dzd',
  EGP = 'egp',
  ERN = 'ern',
  ETB = 'etb',
  EUR = 'eur',
  FJD = 'fjd',
  FKP = 'fkp',
  GBP = 'gbp',
  GEL = 'gel',
  GHS = 'ghs',
  GIP = 'gip',
  GMD = 'gmd',
  GNF = 'gnf',
  GTQ = 'gtq',
  GYD = 'gyd',
  HKD = 'hkd',
  HNL = 'hnl',
  HRK = 'hrk',
  HTG = 'htg',
  HUF = 'huf',
  IDR = 'idr',
  ILS = 'ils',
  INR = 'inr',
  IQD = 'iqd',
  IRR = 'irr',
  ISK = 'isk',
  JMD = 'jmd',
  JOD = 'jod',
  JPY = 'jpy',
  KES = 'kes',
  KGS = 'kgs',
  KHR = 'khr',
  KMF = 'kmf',
  KPW = 'kpw',
  KRW = 'krw',
  KWD = 'kwd',
  KYD = 'kyd',
  KZT = 'kzt',
  LAK = 'lak',
  LBP = 'lbp',
  LKR = 'lkr',
  LRD = 'lrd',
  LSL = 'lsl',
  LTL = 'ltl',
  LVL = 'lvl',
  LYD = 'lyd',
  MAD = 'mad',
  MDL = 'mdl',
  MGA = 'mga',
  MKD = 'mkd',
  MMK = 'mmk',
  MNT = 'mnt',
  MOP = 'mop',
  MRO = 'mro',
  MUR = 'mur',
  MVR = 'mvr',
  MWK = 'mwk',
  MXN = 'mxn',
  MXV = 'mxv',
  MYR = 'myr',
  MZN = 'mzn',
  NAD = 'nad',
  NGN = 'ngn',
  NIO = 'nio',
  NOK = 'nok',
  NPR = 'npr',
  NZD = 'nzd',
  OMR = 'omr',
  PAB = 'pab',
  PEN = 'pen',
  PGK = 'pgk',
  PHP = 'php',
  PKR = 'pkr',
  PLN = 'pln',
  PYG = 'pyg',
  QAR = 'qar',
  RON = 'ron',
  RSD = 'rsd',
  RUB = 'rub',
  RWF = 'rwf',
  SAR = 'sar',
  SBD = 'sbd',
  SCR = 'scr',
  SDG = 'sdg',
  SEK = 'sek',
  SGD = 'sgd',
  SHP = 'shp',
  SLL = 'sll',
  SOS = 'sos',
  SRD = 'srd',
  SSP = 'ssp',
  STD = 'std',
  SVC = 'svc',
  SYP = 'syp',
  SZL = 'szl',
  THB = 'thb',
  TJS = 'tjs',
  TMT = 'tmt',
  TND = 'tnd',
  TOP = 'top',
  TRY = 'try',
  TTD = 'ttd',
  TWD = 'twd',
  TZS = 'tzs',
  UAH = 'uah',
  UGX = 'ugx',
  USD = 'usd',
  USN = 'usn',
  USS = 'uss',
  UYI = 'uyi',
  UYU = 'uyu',
  UZS = 'uzs',
  VEF = 'vef',
  VND = 'vnd',
  VUV = 'vuv',
  WST = 'wst',
  XAF = 'xaf',
  XAG = 'xag',
  XAU = 'xau',
  XBA = 'xba',
  XBB = 'xbb',
  XBC = 'xbc',
  XBD = 'xbd',
  XCD = 'xcd',
  XDR = 'xdr',
  XFU = 'xfu',
  XOF = 'xof',
  XPD = 'xpd',
  XPF = 'xpf',
  XPT = 'xpt',
  XSU = 'xsu',
  XTS = 'xts',
  XUA = 'xua',
  YER = 'yer',
  ZAR = 'zar',
  ZMW = 'zmw',
  ZWL = 'zwl',
}

export const FallbackCurrencyCountry = 'US';
export enum MobilePaymentDefault {
  COUNTRY = 'US',
  CURRENCY = 'usd',
}

export enum SKUFeatureTypes {
  SINGLE_PLAYER = 1,
  ONLINE_MULTIPLAYER = 2,
  LOCAL_MULTIPLAYER = 3,
  PVP = 4,
  LOCAL_COOP = 5,
  CROSS_PLATFORM = 6,
  RICH_PRESENCE = 7,
  DISCORD_GAME_INVITES = 8,
  SPECTATOR_MODE = 9,
  CONTROLLER_SUPPORT = 10,
  CLOUD_SAVES = 11,
  ONLINE_COOP = 12,
  SECURE_NETWORKING = 13,
}

// Mirrors SKUGenres in discord_api/discord/constants.py
export enum SKUGenres {
  ACTION = 1,
  ACTION_RPG = 2,
  BRAWLER = 3,
  HACK_AND_SLASH = 4,
  PLATFORMER = 5,
  STEALTH = 6,
  SURVIVAL = 7,

  ADVENTURE = 8,
  ACTION_ADVENTURE = 9,
  METROIDVANIA = 10,
  OPEN_WORLD = 11,
  PSYCHOLOGICAL_HORROR = 12,
  SANDBOX = 13,
  SURVIVAL_HORROR = 14,
  VISUAL_NOVEL = 15,

  DRIVING_RACING = 16,
  VEHICULAR_COMBAT = 17,

  MASSIVELY_MULTIPLAYER = 18,
  MMORPG = 19,

  ROLE_PLAYING = 20,
  DUNGEON_CRAWLER = 21,
  ROGUELIKE = 22,

  SHOOTER = 23,
  LIGHT_GUN = 24,
  SHOOT_EM_UP = 25,
  FPS = 26,
  DUAL_JOYSTICK_SHOOTER = 27,

  SIMULATION = 28,
  FLIGHT_SIMULATOR = 29,
  TRAIN_SIMULATOR = 30,
  LIFE_SIMULATOR = 31,
  FISHING = 32,

  SPORTS = 33,
  BASEBALL = 34,
  BASKETBALL = 35,
  BILLIARDS = 36,
  BOWLING = 37,
  BOXING = 38,
  FOOTBALL = 39,
  GOLF = 40,
  HOCKEY = 41,
  SKATEBOARDING_SKATING = 42,
  SNOWBOARDING_SKIING = 43,
  SOCCER = 44,
  TRACK_FIELD = 45,
  SURFING_WAKEBOARDING = 46,
  WRESTLING = 47,

  STRATEGY = 48,
  FOUR_X = 49,
  ARTILLERY = 50,
  RTS = 51,
  TOWER_DEFENSE = 52,
  TURN_BASED_STRATEGY = 53,
  WARGAME = 54,
  MOBA = 55,

  FIGHTING = 56,
  PUZZLE = 57,
  CARD_GAME = 58,
  EDUCATION = 59,
  FITNESS = 60,
  GAMBLING = 61,
  MUSIC_RHYTHM = 62,
  PARTY_MINI_GAME = 63,
  PINBALL = 64,
  TRIVIA_BOARD_GAME = 65,
  TACTICAL = 66,
  INDIE = 67,
  ARCADE = 68,
  POINT_AND_CLICK = 69,
}

// Mirrors PriceTierTypes in discord_api/discord/constants.py
export enum PriceTierTypes {
  GUILD_ROLE_SUBSCRIPTIONS = 1,
  GUILD_PRODUCTS = 2,
}

export enum UserMediaErrors {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  PERMISSION_DISMISSED = 'PERMISSION_DISMISSED',
  NO_DEVICES_FOUND = 'NO_DEVICES_FOUND',
}

export enum NavigateEventSource {
  SWIPE = 'SWIPE',
  BROWSER = 'BROWSER',
  KEYBIND = 'KEYBIND',
}

export enum RoleFlags {
  IN_PROMPT = 1 << 0,
}

export const DiscoveryBannedSearchWords: readonly string[] = [
  'pepe',
  'nude',
  'nsfw',
  '18+',
  'hentai',
  'sex',
  'porn',
  'shit',
  'rape',
  'fuck',
  'penis',
  'pussy',
  'incest',
  'cum',
  'jizz',
  'cuck',
  'kkk',
  'terrorism',
];
