/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * @generated
 *
 * Run `./dev-cli api-docs` to regenerate.
 */

/* eslint-disable @discordapp/discord/mark-discordapp-com */

export default [
  {
    key: 'CHANGE_LOG',
    name: 'Change Log',
    sidebarLabel: 'Change Log',
    filename: 'Change_Log.mdx',
    category: null,
    subPath: 'change-log',
  },
  {key: 'INTRO', name: 'Intro', sidebarLabel: 'Intro', filename: 'Intro.mdx', category: null, subPath: 'intro'},
  {
    key: 'REFERENCE',
    name: 'Reference',
    sidebarLabel: 'API Reference',
    filename: 'Reference.mdx',
    category: null,
    subPath: 'reference',
  },
  {
    key: 'ACTIVITIES_BUILDING_AN_ACTIVITY',
    name: 'Building An Activity',
    sidebarLabel: 'Building an Activity',
    filename: 'Building_An_Activity.mdx',
    category: 'activities',
    subPath: 'activities/building-an-activity',
  },
  {
    key: 'ACTIVITIES_DESIGN_PATTERNS',
    name: 'Design Patterns',
    sidebarLabel: 'Design Patterns',
    filename: 'Design_Patterns.mdx',
    category: 'activities',
    subPath: 'activities/design-patterns',
  },
  {
    key: 'ACTIVITIES_DEVELOPMENT_GUIDES',
    name: 'Development Guides',
    sidebarLabel: 'Development Guides',
    filename: 'Development_Guides.mdx',
    category: 'activities',
    subPath: 'activities/development-guides',
  },
  {
    key: 'ACTIVITIES_HOW_ACTIVITIES_WORK',
    name: 'How Activities Work',
    sidebarLabel: 'How Activities Work',
    filename: 'How_Activities_Work.md',
    category: 'activities',
    subPath: 'activities/how-activities-work',
  },
  {
    key: 'ACTIVITIES_OVERVIEW',
    name: 'Overview',
    sidebarLabel: 'Overview',
    filename: 'Overview.mdx',
    category: 'activities',
    subPath: 'activities/overview',
  },
  {
    key: 'BEST_PRACTICES_CRAFTING_YOUR_APP_DIRECTORY_PRODUCT_PAGE',
    name: 'Crafting your App Directory Product Page',
    sidebarLabel: 'Crafting your App Directory Product Page',
    filename: 'Crafting_your_App_Directory_Product_Page.md',
    category: 'best_practices',
    subPath: 'best-practices/crafting-your-app-directory-product-page',
  },
  {
    key: 'CHANGE_LOG_2017-07-19-BREAKING-CHANGE-VERSION-6',
    name: '2017-07-19-breaking-change-version-6',
    sidebarLabel: 'Breaking Change: Version 6',
    filename: '2017-07-19-breaking-change-version-6.md',
    category: 'change_log',
    subPath: 'change-log/2017-07-19-breaking-change-version-6',
  },
  {
    key: 'CHANGE_LOG_2017-07-24-NEW-FEATURE-AUDIT-LOGS',
    name: '2017-07-24-new-feature-audit-logs',
    sidebarLabel: 'New Feature: Audit Logs',
    filename: '2017-07-24-new-feature-audit-logs.md',
    category: 'change_log',
    subPath: 'change-log/2017-07-24-new-feature-audit-logs',
  },
  {
    key: 'CHANGE_LOG_2017-08-03-BREAKING-CHANGE-DEFAULT-CHANNELS',
    name: '2017-08-03-breaking-change-default-channels',
    sidebarLabel: 'Breaking Change: Default Channels',
    filename: '2017-08-03-breaking-change-default-channels.md',
    category: 'change_log',
    subPath: 'change-log/2017-08-03-breaking-change-default-channels',
  },
  {
    key: 'CHANGE_LOG_2017-08-16-BREAKING-CHANGE-PRESENCE-ACTIVITY-OBJECTS',
    name: '2017-08-16-breaking-change-presence-activity-objects',
    sidebarLabel: 'Breaking Change: Presence Activity Objects',
    filename: '2017-08-16-breaking-change-presence-activity-objects.md',
    category: 'change_log',
    subPath: 'change-log/2017-08-16-breaking-change-presence-activity-objects',
  },
  {
    key: 'CHANGE_LOG_2017-09-10-NEW-FEATURE-EMOJI-ENDPOINTS',
    name: '2017-09-10-new-feature-emoji-endpoints',
    sidebarLabel: 'New Feature: Emoji Endpoints',
    filename: '2017-09-10-new-feature-emoji-endpoints.md',
    category: 'change_log',
    subPath: 'change-log/2017-09-10-new-feature-emoji-endpoints',
  },
  {
    key: 'CHANGE_LOG_2017-09-20-NEW-FEATURE-CHANNEL-CATEGORIES',
    name: '2017-09-20-new-feature-channel-categories',
    sidebarLabel: 'New Feature: Channel Categories',
    filename: '2017-09-20-new-feature-channel-categories.md',
    category: 'change_log',
    subPath: 'change-log/2017-09-20-new-feature-channel-categories',
  },
  {
    key: 'CHANGE_LOG_2017-10-16-BREAKING-CHANGE-API-GATEWAY-BELOW-V6-DISCONTINUED',
    name: '2017-10-16-breaking-change-api-gateway-below-v6-discontinued',
    sidebarLabel: 'Breaking Change: API & Gateway Below v6 Discontinued',
    filename: '2017-10-16-breaking-change-api-gateway-below-v6-discontinued.md',
    category: 'change_log',
    subPath: 'change-log/2017-10-16-breaking-change-api-gateway-below-v6-discontinued',
  },
  {
    key: 'CHANGE_LOG_2017-11-09-NEW-FEATURE-RICH-PRESENCE',
    name: '2017-11-09-new-feature-rich-presence',
    sidebarLabel: 'New Feature: Rich Presence',
    filename: '2017-11-09-new-feature-rich-presence.md',
    category: 'change_log',
    subPath: 'change-log/2017-11-09-new-feature-rich-presence',
  },
  {
    key: 'CHANGE_LOG_2018-01-03-SEMI-BREAKING-CHANGE-VERY-LARGE-BOT-SHARDING',
    name: '2018-01-03-semi-breaking-change-very-large-bot-sharding',
    sidebarLabel: 'Semi-Breaking Change: Very Large Bot Sharding',
    filename: '2018-01-03-semi-breaking-change-very-large-bot-sharding.md',
    category: 'change_log',
    subPath: 'change-log/2018-01-03-semi-breaking-change-very-large-bot-sharding',
  },
  {
    key: 'CHANGE_LOG_2018-01-23-DEPRECATION-ACCEPT-INVITE-ENDPOINT',
    name: '2018-01-23-deprecation-accept-invite-endpoint',
    sidebarLabel: 'Deprecation: Accept Invite Endpoint',
    filename: '2018-01-23-deprecation-accept-invite-endpoint.md',
    category: 'change_log',
    subPath: 'change-log/2018-01-23-deprecation-accept-invite-endpoint',
  },
  {
    key: 'CHANGE_LOG_2018-01-30-ENHANCEMENT-GET-GUILD-EMOJI-ENDPOINT',
    name: '2018-01-30-enhancement-get-guild-emoji-endpoint',
    sidebarLabel: 'Enhancement: Get Guild Emoji Endpoint',
    filename: '2018-01-30-enhancement-get-guild-emoji-endpoint.md',
    category: 'change_log',
    subPath: 'change-log/2018-01-30-enhancement-get-guild-emoji-endpoint',
  },
  {
    key: 'CHANGE_LOG_2018-02-05-ENHANCEMENT-NEW-MESSAGE-PROPERTIES',
    name: '2018-02-05-enhancement-new-message-properties',
    sidebarLabel: 'Enhancement: New Message Properties',
    filename: '2018-02-05-enhancement-new-message-properties.md',
    category: 'change_log',
    subPath: 'change-log/2018-02-05-enhancement-new-message-properties',
  },
  {
    key: 'CHANGE_LOG_2018-06-11-DEPRECATION-RPC-ONLINE-MEMBER-COUNT-AND-MEMBERS-LIST',
    name: '2018-06-11-deprecation-rpc-online-member-count-and-members-list',
    sidebarLabel: 'Deprecation: RPC online member count and members list',
    filename: '2018-06-11-deprecation-rpc-online-member-count-and-members-list.md',
    category: 'change_log',
    subPath: 'change-log/2018-06-11-deprecation-rpc-online-member-count-and-members-list',
  },
  {
    key: 'CHANGE_LOG_2018-06-19-DOCUMENTATION-FIX-LIST-OF-OPEN-DMS-IN-CERTAIN-PAYLOADS',
    name: '2018-06-19-documentation-fix-list-of-open-dms-in-certain-payloads',
    sidebarLabel: 'Documentation Fix: List of Open DMS in Certain Payloads',
    filename: '2018-06-19-documentation-fix-list-of-open-dms-in-certain-payloads.md',
    category: 'change_log',
    subPath: 'change-log/2018-06-19-documentation-fix-list-of-open-dms-in-certain-payloads',
  },
  {
    key: 'CHANGE_LOG_2018-11-30-ENHANCEMENT-USER-OBJECT',
    name: '2018-11-30-enhancement-user-object',
    sidebarLabel: 'Enhancement: User Object',
    filename: '2018-11-30-enhancement-user-object.md',
    category: 'change_log',
    subPath: 'change-log/2018-11-30-enhancement-user-object',
  },
  {
    key: 'CHANGE_LOG_2018-12-11-DOCUMENTATION-DISPATCH-STORE-LISTINGS',
    name: '2018-12-11-documentation-dispatch-store-listings',
    sidebarLabel: 'Documentation: Dispatch Store Listings',
    filename: '2018-12-11-documentation-dispatch-store-listings.md',
    category: 'change_log',
    subPath: 'change-log/2018-12-11-documentation-dispatch-store-listings',
  },
  {
    key: 'CHANGE_LOG_2019-01-14-ASK-TO-JOIN-RICH-PRESENCE-SDK',
    name: '2019-01-14-ask-to-join-rich-presence-sdk',
    sidebarLabel: 'Ask to Join & Rich Presence SDK',
    filename: '2019-01-14-ask-to-join-rich-presence-sdk.md',
    category: 'change_log',
    subPath: 'change-log/2019-01-14-ask-to-join-rich-presence-sdk',
  },
  {
    key: 'CHANGE_LOG_2019-04-18-NEW-INVITE-OBJECT-FIELDS',
    name: '2019-04-18-new-invite-object-fields',
    sidebarLabel: 'New Invite Object Fields',
    filename: '2019-04-18-new-invite-object-fields.md',
    category: 'change_log',
    subPath: 'change-log/2019-04-18-new-invite-object-fields',
  },
  {
    key: 'CHANGE_LOG_2019-04-29-DEPRECATION-OF-DISCORD-RPC-RICH-PRESENCE-SDK',
    name: '2019-04-29-deprecation-of-discord-rpc-rich-presence-sdk',
    sidebarLabel: 'Deprecation of Discord-RPC Rich Presence SDK',
    filename: '2019-04-29-deprecation-of-discord-rpc-rich-presence-sdk.md',
    category: 'change_log',
    subPath: 'change-log/2019-04-29-deprecation-of-discord-rpc-rich-presence-sdk',
  },
  {
    key: 'CHANGE_LOG_2019-05-29-ADDED-INFO-AROUND-NITRO-BOOSTING-EXPERIMENT',
    name: '2019-05-29-added-info-around-nitro-boosting-experiment',
    sidebarLabel: 'Added Info Around Nitro Boosting Experiment',
    filename: '2019-05-29-added-info-around-nitro-boosting-experiment.md',
    category: 'change_log',
    subPath: 'change-log/2019-05-29-added-info-around-nitro-boosting-experiment',
  },
  {
    key: 'CHANGE_LOG_2019-06-19-ADDITIONAL-TEAM-INFORMATION',
    name: '2019-06-19-additional-team-information',
    sidebarLabel: 'Additional Team Information',
    filename: '2019-06-19-additional-team-information.md',
    category: 'change_log',
    subPath: 'change-log/2019-06-19-additional-team-information',
  },
  {
    key: 'CHANGE_LOG_2019-07-18-BOT-TOKENS-FOR-ACHIEVEMENTS',
    name: '2019-07-18-bot-tokens-for-achievements',
    sidebarLabel: 'Bot Tokens for Achievements',
    filename: '2019-07-18-bot-tokens-for-achievements.md',
    category: 'change_log',
    subPath: 'change-log/2019-07-18-bot-tokens-for-achievements',
  },
  {
    key: 'CHANGE_LOG_2019-08-12-MORE-PRECISE-RATE-LIMITS',
    name: '2019-08-12-more-precise-rate-limits',
    sidebarLabel: 'More Precise Rate Limits',
    filename: '2019-08-12-more-precise-rate-limits.md',
    category: 'change_log',
    subPath: 'change-log/2019-08-12-more-precise-rate-limits',
  },
  {
    key: 'CHANGE_LOG_2019-08-22-CHANGES-TO-SPECIAL-CHANNELS',
    name: '2019-08-22-changes-to-special-channels',
    sidebarLabel: 'Changes to Special Channels',
    filename: '2019-08-22-changes-to-special-channels.md',
    category: 'change_log',
    subPath: 'change-log/2019-08-22-changes-to-special-channels',
  },
  {
    key: 'CHANGE_LOG_2019-11-14-GAMESDK-VERSION-2',
    name: '2019-11-14-gamesdk-version-2',
    sidebarLabel: 'GameSDK Version 2.5.5',
    filename: '2019-11-14-gamesdk-version-2.5.5.md',
    category: 'change_log',
    subPath: 'change-log/2019-11-14-gamesdk-version-2',
  },
  {
    key: 'CHANGE_LOG_2019-11-27-GAMESDK-VERSION-2',
    name: '2019-11-27-gamesdk-version-2',
    sidebarLabel: 'GameSDK Version 2.5.6',
    filename: '2019-11-27-gamesdk-version-2.5.6.md',
    category: 'change_log',
    subPath: 'change-log/2019-11-27-gamesdk-version-2',
  },
  {
    key: 'CHANGE_LOG_2019-12-06-IP-DISCOVERY-UPDATES',
    name: '2019-12-06-ip-discovery-updates',
    sidebarLabel: 'IP Discovery Updates',
    filename: '2019-12-06-ip-discovery-updates.md',
    category: 'change_log',
    subPath: 'change-log/2019-12-06-ip-discovery-updates',
  },
  {
    key: 'CHANGE_LOG_2020-02-14-GATEWAY-INTENTS',
    name: '2020-02-14-gateway-intents',
    sidebarLabel: 'Gateway Intents',
    filename: '2020-02-14-gateway-intents.md',
    category: 'change_log',
    subPath: 'change-log/2020-02-14-gateway-intents',
  },
  {
    key: 'CHANGE_LOG_2020-02-26-RICH-PRESENCE-SPECTATE-APPROVAL',
    name: '2020-02-26-rich-presence-spectate-approval',
    sidebarLabel: 'Rich Presence Spectate Approval',
    filename: '2020-02-26-rich-presence-spectate-approval.md',
    category: 'change_log',
    subPath: 'change-log/2020-02-26-rich-presence-spectate-approval',
  },
  {
    key: 'CHANGE_LOG_2020-03-02-NEW-INVITE-EVENTS-AND-REACTIONS-ENDPOINT',
    name: '2020-03-02-new-invite-events-and-reactions-endpoint',
    sidebarLabel: 'New Invite Events and Reactions Endpoint',
    filename: '2020-03-02-new-invite-events-and-reactions-endpoint.md',
    category: 'change_log',
    subPath: 'change-log/2020-03-02-new-invite-events-and-reactions-endpoint',
  },
  {
    key: 'CHANGE_LOG_2020-03-03-NEW-ALLOWED-MENTIONS-OBJECT',
    name: '2020-03-03-new-allowed-mentions-object',
    sidebarLabel: 'New Allowed Mentions Object',
    filename: '2020-03-03-new-allowed-mentions-object.md',
    category: 'change_log',
    subPath: 'change-log/2020-03-03-new-allowed-mentions-object',
  },
  {
    key: 'CHANGE_LOG_2020-04-24-NEW-PROPERTIES-ON-GUILD-MEMBERS-CHUNK-EVENT',
    name: '2020-04-24-new-properties-on-guild-members-chunk-event',
    sidebarLabel: 'New Properties on Guild Members Chunk Event',
    filename: '2020-04-24-new-properties-on-guild-members-chunk-event.md',
    category: 'change_log',
    subPath: 'change-log/2020-04-24-new-properties-on-guild-members-chunk-event',
  },
  {
    key: 'CHANGE_LOG_2020-05-11-LEGACY-MENTION-BEHAVIOR-DEPRECATION',
    name: '2020-05-11-legacy-mention-behavior-deprecation',
    sidebarLabel: 'Legacy Mention Behavior Deprecation',
    filename: '2020-05-11-legacy-mention-behavior-deprecation.md',
    category: 'change_log',
    subPath: 'change-log/2020-05-11-legacy-mention-behavior-deprecation',
  },
  {
    key: 'CHANGE_LOG_2020-07-28-NEW-PERMISSION-FIELDS',
    name: '2020-07-28-new-permission-fields',
    sidebarLabel: 'New Permission Fields',
    filename: '2020-07-28-new-permission-fields.md',
    category: 'change_log',
    subPath: 'change-log/2020-07-28-new-permission-fields',
  },
  {
    key: 'CHANGE_LOG_2020-09-24-API-AND-GATEWAY-V8',
    name: '2020-09-24-api-and-gateway-v8',
    sidebarLabel: 'API and Gateway V8',
    filename: '2020-09-24-api-and-gateway-v8.md',
    category: 'change_log',
    subPath: 'change-log/2020-09-24-api-and-gateway-v8',
  },
  {
    key: 'CHANGE_LOG_2020-10-27-GATEWAY-V6-INTENT-RESTRICTIONS',
    name: '2020-10-27-gateway-v6-intent-restrictions',
    sidebarLabel: 'Gateway v6 Intent Restrictions',
    filename: '2020-10-27-gateway-v6-intent-restrictions.md',
    category: 'change_log',
    subPath: 'change-log/2020-10-27-gateway-v6-intent-restrictions',
  },
  {
    key: 'CHANGE_LOG_2020-11-13-STICKERS',
    name: '2020-11-13-stickers',
    sidebarLabel: 'Stickers',
    filename: '2020-11-13-stickers.md',
    category: 'change_log',
    subPath: 'change-log/2020-11-13-stickers',
  },
  {
    key: 'CHANGE_LOG_2020-11-16-INLINE-REPLIES',
    name: '2020-11-16-inline-replies',
    sidebarLabel: 'Inline Replies',
    filename: '2020-11-16-inline-replies.md',
    category: 'change_log',
    subPath: 'change-log/2020-11-16-inline-replies',
  },
  {
    key: 'CHANGE_LOG_2020-12-15-SLASH-COMMANDS-AND-INTERACTIONS',
    name: '2020-12-15-slash-commands-and-interactions',
    sidebarLabel: 'Slash Commands and Interactions',
    filename: '2020-12-15-slash-commands-and-interactions.md',
    category: 'change_log',
    subPath: 'change-log/2020-12-15-slash-commands-and-interactions',
  },
  {
    key: 'CHANGE_LOG_2021-01-22-CHANGE-TO-PERMISSION-CHECKING-WHEN-CREATING-CHANNELS',
    name: '2021-01-22-change-to-permission-checking-when-creating-channels',
    sidebarLabel: 'Change to Permission Checking when Creating Channels',
    filename: '2021-01-22-change-to-permission-checking-when-creating-channels.md',
    category: 'change_log',
    subPath: 'change-log/2021-01-22-change-to-permission-checking-when-creating-channels',
  },
  {
    key: 'CHANGE_LOG_2021-02-09-SLASH-COMMANDS-IN-DMS',
    name: '2021-02-09-slash-commands-in-dms',
    sidebarLabel: 'Slash Commands in DMs',
    filename: '2021-02-09-slash-commands-in-dms.md',
    category: 'change_log',
    subPath: 'change-log/2021-02-09-slash-commands-in-dms',
  },
  {
    key: 'CHANGE_LOG_2021-03-05-CHANGES-TO-SLASH-COMMAND-RESPONSE-TYPES-AND-FLAGS',
    name: '2021-03-05-changes-to-slash-command-response-types-and-flags',
    sidebarLabel: 'Changes to Slash Command Response Types and Flags',
    filename: '2021-03-05-changes-to-slash-command-response-types-and-flags.md',
    category: 'change_log',
    subPath: 'change-log/2021-03-05-changes-to-slash-command-response-types-and-flags',
  },
  {
    key: 'CHANGE_LOG_2021-03-15-LARGE-BOT-SHARDING-LOWERED-TO-150-000-GUILDS',
    name: '2021-03-15-large-bot-sharding-lowered-to-150-000-guilds',
    sidebarLabel: 'Large Bot Sharding Lowered to 150,000 Guilds',
    filename: '2021-03-15-large-bot-sharding-lowered-to-150-000-guilds.md',
    category: 'change_log',
    subPath: 'change-log/2021-03-15-large-bot-sharding-lowered-to-150-000-guilds',
  },
  {
    key: 'CHANGE_LOG_2021-04-05-APPLICATION-COMMAND-PERMISSIONS',
    name: '2021-04-05-application-command-permissions',
    sidebarLabel: 'Application Command Permissions',
    filename: '2021-04-05-application-command-permissions.md',
    category: 'change_log',
    subPath: 'change-log/2021-04-05-application-command-permissions',
  },
  {
    key: 'CHANGE_LOG_2021-04-28-API-V9',
    name: '2021-04-28-api-v9',
    sidebarLabel: 'API v9',
    filename: '2021-04-28-api-v9.md',
    category: 'change_log',
    subPath: 'change-log/2021-04-28-api-v9',
  },
  {
    key: 'CHANGE_LOG_2021-05-26-BUTTONS-AND-MESSAGE-COMPONENTS',
    name: '2021-05-26-buttons-and-message-components',
    sidebarLabel: 'Buttons and Message Components',
    filename: '2021-05-26-buttons-and-message-components.md',
    category: 'change_log',
    subPath: 'change-log/2021-05-26-buttons-and-message-components',
  },
  {
    key: 'CHANGE_LOG_2021-06-10-SUPPORT-FOR-MULTIPLE-EMBEDS-IN-MESSAGE-ROUTES',
    name: '2021-06-10-support-for-multiple-embeds-in-message-routes',
    sidebarLabel: 'Support for Multiple Embeds in Message Routes',
    filename: '2021-06-10-support-for-multiple-embeds-in-message-routes.md',
    category: 'change_log',
    subPath: 'change-log/2021-06-10-support-for-multiple-embeds-in-message-routes',
  },
  {
    key: 'CHANGE_LOG_2021-06-30-SELECT-MENU-COMPONENTS',
    name: '2021-06-30-select-menu-components',
    sidebarLabel: 'Select Menu Components',
    filename: '2021-06-30-select-menu-components.md',
    category: 'change_log',
    subPath: 'change-log/2021-06-30-select-menu-components',
  },
  {
    key: 'CHANGE_LOG_2021-08-10-USER-AND-MESSAGE-COMMANDS',
    name: '2021-08-10-user-and-message-commands',
    sidebarLabel: 'User and Message Commands',
    filename: '2021-08-10-user-and-message-commands.md',
    category: 'change_log',
    subPath: 'change-log/2021-08-10-user-and-message-commands',
  },
  {
    key: 'CHANGE_LOG_2021-09-16-UPDATED-THREAD-PERMISSIONS',
    name: '2021-09-16-updated-thread-permissions',
    sidebarLabel: 'Updated Thread Permissions',
    filename: '2021-09-16-updated-thread-permissions.md',
    category: 'change_log',
    subPath: 'change-log/2021-09-16-updated-thread-permissions',
  },
  {
    key: 'CHANGE_LOG_2021-10-27-APPLICATION-COMMAND-AUTOCOMPLETE-INTERACTIONS',
    name: '2021-10-27-application-command-autocomplete-interactions',
    sidebarLabel: 'Application Command Autocomplete Interactions',
    filename: '2021-10-27-application-command-autocomplete-interactions.md',
    category: 'change_log',
    subPath: 'change-log/2021-10-27-application-command-autocomplete-interactions',
  },
  {
    key: 'CHANGE_LOG_2021-11-23-GUILD-SCHEDULED-EVENTS',
    name: '2021-11-23-guild-scheduled-events',
    sidebarLabel: 'Guild Scheduled Events',
    filename: '2021-11-23-guild-scheduled-events.md',
    category: 'change_log',
    subPath: 'change-log/2021-11-23-guild-scheduled-events',
  },
  {
    key: 'CHANGE_LOG_2021-12-20-GUILD-MEMBER-TIMEOUTS',
    name: '2021-12-20-guild-member-timeouts',
    sidebarLabel: 'Guild Member Timeouts',
    filename: '2021-12-20-guild-member-timeouts.md',
    category: 'change_log',
    subPath: 'change-log/2021-12-20-guild-member-timeouts',
  },
  {
    key: 'CHANGE_LOG_2022-02-08-INTERACTION-MODALS-AND-APPLICATION-COMMAND-ATTACHMENT-OPTION-TYPE',
    name: '2022-02-08-interaction-modals-and-application-command-attachment-option-type',
    sidebarLabel: 'Interaction Modals and Application Command Attachment Option Type',
    filename: '2022-02-08-interaction-modals-and-application-command-attachment-option-type.md',
    category: 'change_log',
    subPath: 'change-log/2022-02-08-interaction-modals-and-application-command-attachment-option-type',
  },
  {
    key: 'CHANGE_LOG_2022-02-14-API-V10',
    name: '2022-02-14-api-v10',
    sidebarLabel: 'API v10',
    filename: '2022-02-14-api-v10.md',
    category: 'change_log',
    subPath: 'change-log/2022-02-14-api-v10',
  },
  {
    key: 'CHANGE_LOG_2022-03-31-GUILD-BANS-PAGINATION',
    name: '2022-03-31-guild-bans-pagination',
    sidebarLabel: 'Guild Bans Pagination',
    filename: '2022-03-31-guild-bans-pagination.md',
    category: 'change_log',
    subPath: 'change-log/2022-03-31-guild-bans-pagination',
  },
  {
    key: 'CHANGE_LOG_2022-04-06-FORUM-CHANNELS',
    name: '2022-04-06-forum-channels',
    sidebarLabel: 'Forum Channels',
    filename: '2022-04-06-forum-channels.md',
    category: 'change_log',
    subPath: 'change-log/2022-04-06-forum-channels',
  },
  {
    key: 'CHANGE_LOG_2022-04-27-UPDATED-COMMAND-PERMISSIONS',
    name: '2022-04-27-updated-command-permissions',
    sidebarLabel: 'Updated Command Permissions',
    filename: '2022-04-27-updated-command-permissions.md',
    category: 'change_log',
    subPath: 'change-log/2022-04-27-updated-command-permissions',
  },
  {
    key: 'CHANGE_LOG_2022-06-16-AUTO-MODERATION',
    name: '2022-06-16-auto-moderation',
    sidebarLabel: 'Auto Moderation',
    filename: '2022-06-16-auto-moderation.md',
    category: 'change_log',
    subPath: 'change-log/2022-06-16-auto-moderation',
  },
  {
    key: 'CHANGE_LOG_2022-06-17-UPDATED-CONNECTION-PROPERTY-FIELD-NAMES',
    name: '2022-06-17-updated-connection-property-field-names',
    sidebarLabel: 'Updated Connection Property Field Names',
    filename: '2022-06-17-updated-connection-property-field-names.md',
    category: 'change_log',
    subPath: 'change-log/2022-06-17-updated-connection-property-field-names',
  },
  {
    key: 'CHANGE_LOG_2022-06-21-MESSAGE-CONTENT-IN-AUTO-MODERATION-EVENTS',
    name: '2022-06-21-message-content-in-auto-moderation-events',
    sidebarLabel: 'Message Content in Auto Moderation events',
    filename: '2022-06-21-message-content-in-auto-moderation-events.md',
    category: 'change_log',
    subPath: 'change-log/2022-06-21-message-content-in-auto-moderation-events',
  },
  {
    key: 'CHANGE_LOG_2022-06-29-CALCULATED-PERMISSIONS-IN-INTERACTION-PAYLOADS',
    name: '2022-06-29-calculated-permissions-in-interaction-payloads',
    sidebarLabel: 'Calculated Permissions in Interaction Payloads',
    filename: '2022-06-29-calculated-permissions-in-interaction-payloads.md',
    category: 'change_log',
    subPath: 'change-log/2022-06-29-calculated-permissions-in-interaction-payloads',
  },
  {
    key: 'CHANGE_LOG_2022-06-29-CHANGES-TO-BOT-PERMISSIONS-FOR-INTERACTIONS-AND-WEBHOOKS',
    name: '2022-06-29-changes-to-bot-permissions-for-interactions-and-webhooks',
    sidebarLabel: 'Changes to Bot Permissions for Interactions and Webhooks',
    filename: '2022-06-29-changes-to-bot-permissions-for-interactions-and-webhooks.md',
    category: 'change_log',
    subPath: 'change-log/2022-06-29-changes-to-bot-permissions-for-interactions-and-webhooks',
  },
  {
    key: 'CHANGE_LOG_2022-07-01-ADD-SUBCOMMAND-GROUPS-AND-SUBCOMMANDS-TO-MESSAGE-INTERACTION-OBJECTS',
    name: '2022-07-01-add-subcommand-groups-and-subcommands-to-message-interaction-objects',
    sidebarLabel: 'Add Subcommand Groups and Subcommands to Message Interaction Objects',
    filename: '2022-07-01-add-subcommand-groups-and-subcommands-to-message-interaction-objects.md',
    category: 'change_log',
    subPath: 'change-log/2022-07-01-add-subcommand-groups-and-subcommands-to-message-interaction-objects',
  },
  {
    key: 'CHANGE_LOG_2022-07-01-MIN-AND-MAX-LENGTH-FOR-COMMAND-OPTIONS',
    name: '2022-07-01-min-and-max-length-for-command-options',
    sidebarLabel: 'Min and Max Length for Command Options',
    filename: '2022-07-01-min-and-max-length-for-command-options.md',
    category: 'change_log',
    subPath: 'change-log/2022-07-01-min-and-max-length-for-command-options',
  },
  {
    key: 'CHANGE_LOG_2022-07-13-UPCOMING-PERMISSIONS-CHANGE-TO-WEBHOOK-ROUTES',
    name: '2022-07-13-upcoming-permissions-change-to-webhook-routes',
    sidebarLabel: 'Upcoming Permissions Change to Webhook Routes',
    filename: '2022-07-13-upcoming-permissions-change-to-webhook-routes.md',
    category: 'change_log',
    subPath: 'change-log/2022-07-13-upcoming-permissions-change-to-webhook-routes',
  },
  {
    key: 'CHANGE_LOG_2022-08-09-SESSION-SPECIFIC-GATEWAY-RESUME-URLS',
    name: '2022-08-09-session-specific-gateway-resume-urls',
    sidebarLabel: 'Session-specific Gateway Resume URLs',
    filename: '2022-08-09-session-specific-gateway-resume-urls.md',
    category: 'change_log',
    subPath: 'change-log/2022-08-09-session-specific-gateway-resume-urls',
  },
  {
    key: 'CHANGE_LOG_2022-08-22-SLASH-COMMAND-MENTIONS',
    name: '2022-08-22-slash-command-mentions',
    sidebarLabel: 'Slash Command Mentions',
    filename: '2022-08-22-slash-command-mentions.md',
    category: 'change_log',
    subPath: 'change-log/2022-08-22-slash-command-mentions',
  },
  {
    key: 'CHANGE_LOG_2022-09-01-MESSAGE-CONTENT-IS-A-PRIVILEGED-INTENT',
    name: '2022-09-01-message-content-is-a-privileged-intent',
    sidebarLabel: 'Message Content is a Privileged Intent',
    filename: '2022-09-01-message-content-is-a-privileged-intent.md',
    category: 'change_log',
    subPath: 'change-log/2022-09-01-message-content-is-a-privileged-intent',
  },
  {
    key: 'CHANGE_LOG_2022-09-14-FORUM-CHANNELS-RELEASE',
    name: '2022-09-14-forum-channels-release',
    sidebarLabel: 'Forum Channels Release',
    filename: '2022-09-14-forum-channels-release.md',
    category: 'change_log',
    subPath: 'change-log/2022-09-14-forum-channels-release',
  },
  {
    key: 'CHANGE_LOG_2022-09-21-AUTO-MODERATION-SPAM-AND-MENTION-SPAM-TRIGGER-TYPES',
    name: '2022-09-21-auto-moderation-spam-and-mention-spam-trigger-types',
    sidebarLabel: 'Auto Moderation Spam and Mention Spam Trigger Types',
    filename: '2022-09-21-auto-moderation-spam-and-mention-spam-trigger-types.md',
    category: 'change_log',
    subPath: 'change-log/2022-09-21-auto-moderation-spam-and-mention-spam-trigger-types',
  },
  {
    key: 'CHANGE_LOG_2022-09-22-DEFAULT-SORT-ORDER-FOR-FORUM-CHANNELS',
    name: '2022-09-22-default-sort-order-for-forum-channels',
    sidebarLabel: 'Default Sort Order for Forum Channels',
    filename: '2022-09-22-default-sort-order-for-forum-channels.md',
    category: 'change_log',
    subPath: 'change-log/2022-09-22-default-sort-order-for-forum-channels',
  },
  {
    key: 'CHANGE_LOG_2022-10-13-NEW-SELECT-MENU-COMPONENTS',
    name: '2022-10-13-new-select-menu-components',
    sidebarLabel: 'New Select Menu Components',
    filename: '2022-10-13-new-select-menu-components.md',
    category: 'change_log',
    subPath: 'change-log/2022-10-13-new-select-menu-components',
  },
  {
    key: 'CHANGE_LOG_2022-10-20-DELETE-EPHEMERAL-MESSAGES',
    name: '2022-10-20-delete-ephemeral-messages',
    sidebarLabel: 'Delete Ephemeral Messages',
    filename: '2022-10-20-delete-ephemeral-messages.md',
    category: 'change_log',
    subPath: 'change-log/2022-10-20-delete-ephemeral-messages',
  },
  {
    key: 'CHANGE_LOG_2022-11-04-ADD-AUTO-MODERATION-REGEX-SUPPORT',
    name: '2022-11-04-add-auto-moderation-regex-support',
    sidebarLabel: 'Add Auto Moderation Regex Support',
    filename: '2022-11-04-add-auto-moderation-regex-support.md',
    category: 'change_log',
    subPath: 'change-log/2022-11-04-add-auto-moderation-regex-support',
  },
  {
    key: 'CHANGE_LOG_2022-11-09-GAMESDK-FEATURE-DEPRECATION',
    name: '2022-11-09-gamesdk-feature-deprecation',
    sidebarLabel: 'GameSDK Feature Deprecation',
    filename: '2022-11-09-gamesdk-feature-deprecation.md',
    category: 'change_log',
    subPath: 'change-log/2022-11-09-gamesdk-feature-deprecation',
  },
  {
    key: 'CHANGE_LOG_2022-11-17-UPCOMING-APPLICATION-COMMAND-PERMISSION-CHANGES',
    name: '2022-11-17-upcoming-application-command-permission-changes',
    sidebarLabel: 'Upcoming Application Command Permission Changes',
    filename: '2022-11-17-upcoming-application-command-permission-changes.md',
    category: 'change_log',
    subPath: 'change-log/2022-11-17-upcoming-application-command-permission-changes',
  },
  {
    key: 'CHANGE_LOG_2022-11-22-ADD-AUTO-MODERATION-ALLOW-LIST-FOR-KEYWORD-RULES-AND-INCREASE-MAX-KEYWORD-RULES-PER-GUILD-LIMIT',
    name: '2022-11-22-add-auto-moderation-allow-list-for-keyword-rules-and-increase-max-keyword-rules-per-guild-limit',
    sidebarLabel: 'Add Auto Moderation Allow List for Keyword Rules and Increase Max Keyword Rules Per Guild Limit',
    filename:
      '2022-11-22-add-auto-moderation-allow-list-for-keyword-rules-and-increase-max-keyword-rules-per-guild-limit.md',
    category: 'change_log',
    subPath:
      'change-log/2022-11-22-add-auto-moderation-allow-list-for-keyword-rules-and-increase-max-keyword-rules-per-guild-limit',
  },
  {
    key: 'CHANGE_LOG_2022-12-12-ADD-APPLICATION-CONNECTIONS-METADATA-AND-LINKED-ROLES',
    name: '2022-12-12-add-application-connections-metadata-and-linked-roles',
    sidebarLabel: 'Add Application Connections Metadata and Linked Roles',
    filename: '2022-12-12-add-application-connections-metadata-and-linked-roles.md',
    category: 'change_log',
    subPath: 'change-log/2022-12-12-add-application-connections-metadata-and-linked-roles',
  },
  {
    key: 'CHANGE_LOG_2022-12-13-ADD-DEFAULT-LAYOUT-SETTING-FOR-FORUM-CHANNELS',
    name: '2022-12-13-add-default-layout-setting-for-forum-channels',
    sidebarLabel: 'Add Default Layout setting for Forum channels',
    filename: '2022-12-13-add-default-layout-setting-for-forum-channels.md',
    category: 'change_log',
    subPath: 'change-log/2022-12-13-add-default-layout-setting-for-forum-channels',
  },
  {
    key: 'CHANGE_LOG_2023-01-09-THREAD-MEMBER-DETAILS-AND-PAGINATION',
    name: '2023-01-09-thread-member-details-and-pagination',
    sidebarLabel: 'Thread Member Details and Pagination',
    filename: '2023-01-09-thread-member-details-and-pagination.md',
    category: 'change_log',
    subPath: 'change-log/2023-01-09-thread-member-details-and-pagination',
  },
  {
    key: 'CHANGE_LOG_2023-01-18-GUILD-AUDIT-LOG-EVENTS',
    name: '2023-01-18-guild-audit-log-events',
    sidebarLabel: 'Guild Audit Log Events',
    filename: '2023-01-18-guild-audit-log-events.md',
    category: 'change_log',
    subPath: 'change-log/2023-01-18-guild-audit-log-events',
  },
  {
    key: 'CHANGE_LOG_2023-02-08-INCREASE-AUTO-MODERATION-KEYWORD-LIMITS',
    name: '2023-02-08-increase-auto-moderation-keyword-limits',
    sidebarLabel: 'Increase Auto Moderation Keyword Limits',
    filename: '2023-02-08-increase-auto-moderation-keyword-limits.md',
    category: 'change_log',
    subPath: 'change-log/2023-02-08-increase-auto-moderation-keyword-limits',
  },
  {
    key: 'CHANGE_LOG_2023-02-10-UPDATE-TO-LOCKED-THREADS',
    name: '2023-02-10-update-to-locked-threads',
    sidebarLabel: 'Update to Locked Threads',
    filename: '2023-02-10-update-to-locked-threads.md',
    category: 'change_log',
    subPath: 'change-log/2023-02-10-update-to-locked-threads',
  },
  {
    key: 'CHANGE_LOG_2023-02-24-ADD-AUTO-MODERATION-CUSTOM-MESSAGE-ACTION-METADATA-FIELD',
    name: '2023-02-24-add-auto-moderation-custom-message-action-metadata-field',
    sidebarLabel: 'Add Auto Moderation custom_message Action Metadata Field',
    filename: '2023-02-24-add-auto-moderation-custom-message-action-metadata-field.md',
    category: 'change_log',
    subPath: 'change-log/2023-02-24-add-auto-moderation-custom-message-action-metadata-field',
  },
  {
    key: 'CHANGE_LOG_2023-04-06-INTERACTION-CHANNEL-DATA',
    name: '2023-04-06-interaction-channel-data',
    sidebarLabel: 'Interaction Channel Data',
    filename: '2023-04-06-interaction-channel-data.md',
    category: 'change_log',
    subPath: 'change-log/2023-04-06-interaction-channel-data',
  },
  {
    key: 'CHANGE_LOG_2023-04-14-BOT-USERS-ADDED-TO-ALL-NEW-APPS',
    name: '2023-04-14-bot-users-added-to-all-new-apps',
    sidebarLabel: 'Bot users added to all new apps',
    filename: '2023-04-14-bot-users-added-to-all-new-apps.md',
    category: 'change_log',
    subPath: 'change-log/2023-04-14-bot-users-added-to-all-new-apps',
  },
  {
    key: 'CHANGE_LOG_2023-05-03-UNIQUE-USERNAMES-ON-DISCORD',
    name: '2023-05-03-unique-usernames-on-discord',
    sidebarLabel: 'Unique usernames on Discord',
    filename: '2023-05-03-unique-usernames-on-discord.md',
    category: 'change_log',
    subPath: 'change-log/2023-05-03-unique-usernames-on-discord',
  },
  {
    key: 'CHANGE_LOG_2023-05-05-ADD-JOIN-RAID-AND-MENTION-RAID-FIELDS',
    name: '2023-05-05-add-join-raid-and-mention-raid-fields',
    sidebarLabel: 'Add Join Raid and Mention Raid fields',
    filename: '2023-05-05-add-join-raid-and-mention-raid-fields.md',
    category: 'change_log',
    subPath: 'change-log/2023-05-05-add-join-raid-and-mention-raid-fields',
  },
  {
    key: 'CHANGE_LOG_2023-08-01-NEW-GUILD-MEDIA-CHANNEL-TYPE',
    name: '2023-08-01-new-guild-media-channel-type',
    sidebarLabel: 'New GUILD_MEDIA channel type',
    filename: '2023-08-01-new-guild-media-channel-type.md',
    category: 'change_log',
    subPath: 'change-log/2023-08-01-new-guild-media-channel-type',
  },
  {
    key: 'CHANGE_LOG_2023-08-02-PUBLIC-PREVIEW-OF-OPENAPI-3',
    name: '2023-08-02-public-preview-of-openapi-3',
    sidebarLabel: 'Public Preview of OpenAPI 3.1 Specification',
    filename: '2023-08-02-public-preview-of-openapi-3.1-specification.md',
    category: 'change_log',
    subPath: 'change-log/2023-08-02-public-preview-of-openapi-3',
  },
  {
    key: 'CHANGE_LOG_2023-08-08-ACTIVITY-STATE-FOR-BOT-USERS',
    name: '2023-08-08-activity-state-for-bot-users',
    sidebarLabel: 'Activity State for Bot Users',
    filename: '2023-08-08-activity-state-for-bot-users.md',
    category: 'change_log',
    subPath: 'change-log/2023-08-08-activity-state-for-bot-users',
  },
  {
    key: 'CHANGE_LOG_2023-08-10-EMBED-DEBUGGER',
    name: '2023-08-10-embed-debugger',
    sidebarLabel: 'Embed Debugger',
    filename: '2023-08-10-embed-debugger.md',
    category: 'change_log',
    subPath: 'change-log/2023-08-10-embed-debugger',
  },
  {
    key: 'CHANGE_LOG_2023-08-23-TEAM-MEMBER-ROLES',
    name: '2023-08-23-team-member-roles',
    sidebarLabel: 'Team Member Roles',
    filename: '2023-08-23-team-member-roles.md',
    category: 'change_log',
    subPath: 'change-log/2023-08-23-team-member-roles',
  },
  {
    key: 'CHANGE_LOG_2023-09-22-DEFAULT-VALUE-IN-AUTO-POPULATED-SELECT-MENUS',
    name: '2023-09-22-default-value-in-auto-populated-select-menus',
    sidebarLabel: 'Default Value in Auto-populated Select Menus',
    filename: '2023-09-22-default-value-in-auto-populated-select-menus.md',
    category: 'change_log',
    subPath: 'change-log/2023-09-22-default-value-in-auto-populated-select-menus',
  },
  {
    key: 'CHANGE_LOG_2023-09-26-PREMIUM-APP-SUBSCRIPTIONS-AVAILABLE-IN-THE-US',
    name: '2023-09-26-premium-app-subscriptions-available-in-the-us',
    sidebarLabel: 'Premium App Subscriptions Available in the US',
    filename: '2023-09-26-premium-app-subscriptions-available-in-the-us.md',
    category: 'change_log',
    subPath: 'change-log/2023-09-26-premium-app-subscriptions-available-in-the-us',
  },
  {
    key: 'CHANGE_LOG_2023-10-17-GLOBAL-RATE-LIMIT-ADDED-TO-DISCORD',
    name: '2023-10-17-global-rate-limit-added-to-discord',
    sidebarLabel: 'Global Rate Limit added to discordapp.com/*',
    filename: '2023-10-17-global-rate-limit-added-to-discord.com.md',
    category: 'change_log',
    subPath: 'change-log/2023-10-17-global-rate-limit-added-to-discord',
  },
  {
    key: 'CHANGE_LOG_2023-10-19-PREMIUM-APP-SUBSCRIPTIONS-NOW-AVAILABLE-IN-THE-EU-AND-UK',
    name: '2023-10-19-premium-app-subscriptions-now-available-in-the-eu-and-uk',
    sidebarLabel: 'Premium App Subscriptions Now Available in the EU and UK',
    filename: '2023-10-19-premium-app-subscriptions-now-available-in-the-eu-and-uk.md',
    category: 'change_log',
    subPath: 'change-log/2023-10-19-premium-app-subscriptions-now-available-in-the-eu-and-uk',
  },
  {
    key: 'CHANGE_LOG_2023-11-01-FIX-MESSAGE-EDIT-INTERACTION-RESPONSE-PERMISSIONS',
    name: '2023-11-01-fix-message-edit-interaction-response-permissions',
    sidebarLabel: 'Fix Message Edit Interaction Response Permissions',
    filename: '2023-11-01-fix-message-edit-interaction-response-permissions.md',
    category: 'change_log',
    subPath: 'change-log/2023-11-01-fix-message-edit-interaction-response-permissions',
  },
  {
    key: 'CHANGE_LOG_2023-11-29-PREMIUM-APP-SUBSCRIPTIONS-NEW-WAYS-FOR-TESTING-APP-SUBSCRIPTIONS',
    name: '2023-11-29-premium-app-subscriptions-new-ways-for-testing-app-subscriptions',
    sidebarLabel: 'Premium App Subscriptions: New Ways for Testing App Subscriptions',
    filename: '2023-11-29-premium-app-subscriptions-new-ways-for-testing-app-subscriptions.md',
    category: 'change_log',
    subPath: 'change-log/2023-11-29-premium-app-subscriptions-new-ways-for-testing-app-subscriptions',
  },
  {
    key: 'CHANGE_LOG_2023-12-01-EXPERIMENTING-WITH-END-TO-END-ENCRYPTION-FOR-VOICE-VIDEO',
    name: '2023-12-01-experimenting-with-end-to-end-encryption-for-voice-video',
    sidebarLabel: 'Experimenting with End-to-End Encryption for Voice & Video',
    filename: '2023-12-01-experimenting-with-end-to-end-encryption-for-voice-video.md',
    category: 'change_log',
    subPath: 'change-log/2023-12-01-experimenting-with-end-to-end-encryption-for-voice-video',
  },
  {
    key: 'CHANGE_LOG_2023-12-15-CLARIFICATION-ON-PERMISSION-SPLITS-FOR-EXPRESSIONS-AND-EVENTS',
    name: '2023-12-15-clarification-on-permission-splits-for-expressions-and-events',
    sidebarLabel: 'Clarification on Permission Splits for Expressions and Events',
    filename: '2023-12-15-clarification-on-permission-splits-for-expressions-and-events.md',
    category: 'change_log',
    subPath: 'change-log/2023-12-15-clarification-on-permission-splits-for-expressions-and-events',
  },
  {
    key: 'CHANGE_LOG_2023-12-19-LIMIT-NUMBER-OF-FIELDS-IN-EMBEDS',
    name: '2023-12-19-limit-number-of-fields-in-embeds',
    sidebarLabel: 'Limit Number of Fields in Embeds',
    filename: '2023-12-19-limit-number-of-fields-in-embeds.md',
    category: 'change_log',
    subPath: 'change-log/2023-12-19-limit-number-of-fields-in-embeds',
  },
  {
    key: 'CHANGE_LOG_2024-02-12-ENFORCED-NONCES-ON-CREATE-MESSAGE-ENDPOINT',
    name: '2024-02-12-enforced-nonces-on-create-message-endpoint',
    sidebarLabel: 'Enforced Nonces on Create Message Endpoint',
    filename: '2024-02-12-enforced-nonces-on-create-message-endpoint.md',
    category: 'change_log',
    subPath: 'change-log/2024-02-12-enforced-nonces-on-create-message-endpoint',
  },
  {
    key: 'CHANGE_LOG_2024-03-15-GUILD-PRUNE-REQUIRING',
    name: '2024-03-15-guild-prune-requiring',
    sidebarLabel: 'Guild Prune Requiring',
    filename: '2024-03-15-guild-prune-requiring.md',
    category: 'change_log',
    subPath: 'change-log/2024-03-15-guild-prune-requiring',
  },
  {
    key: 'CHANGE_LOG_2024-03-18-DISCORD-ACTIVITIES-DEVELOPER-PREVIEW-OF-THE-EMBEDDED-APP-SDK',
    name: '2024-03-18-discord-activities-developer-preview-of-the-embedded-app-sdk',
    sidebarLabel: 'Discord Activities: Developer Preview of the Embedded App SDK',
    filename: '2024-03-18-discord-activities-developer-preview-of-the-embedded-app-sdk.md',
    category: 'change_log',
    subPath: 'change-log/2024-03-18-discord-activities-developer-preview-of-the-embedded-app-sdk',
  },
  {
    key: 'CHANGE_LOG_2024-03-18-USER-INSTALLABLE-APPS-PREVIEW',
    name: '2024-03-18-user-installable-apps-preview',
    sidebarLabel: 'User-Installable Apps Preview',
    filename: '2024-03-18-user-installable-apps-preview.md',
    category: 'change_log',
    subPath: 'change-log/2024-03-18-user-installable-apps-preview',
  },
  {
    key: 'CHANGE_LOG_2024-04-02-CSV-EXPORT-FOR-PREMIUM-APP-ANALYTICS',
    name: '2024-04-02-csv-export-for-premium-app-analytics',
    sidebarLabel: 'CSV Export for Premium App Analytics',
    filename: '2024-04-02-csv-export-for-premium-app-analytics.md',
    category: 'change_log',
    subPath: 'change-log/2024-04-02-csv-export-for-premium-app-analytics',
  },
  {
    key: 'CHANGE_LOG_2024-04-23-MODIFY-GUILD-MEMBER-FLAGS-FIELD-PERMISSIONS',
    name: '2024-04-23-modify-guild-member-flags-field-permissions',
    sidebarLabel: 'Modify Guild Member flags field permissions',
    filename: '2024-04-23-modify-guild-member-flags-field-permissions.md',
    category: 'change_log',
    subPath: 'change-log/2024-04-23-modify-guild-member-flags-field-permissions',
  },
  {
    key: 'CHANGE_LOG_2024-04-24-PREMIUM-APPS-ONE-TIME-PURCHASES-AND-STORE',
    name: '2024-04-24-premium-apps-one-time-purchases-and-store',
    sidebarLabel: 'Premium Apps: One-Time Purchases and Store',
    filename: '2024-04-24-premium-apps-one-time-purchases-and-store.md',
    category: 'change_log',
    subPath: 'change-log/2024-04-24-premium-apps-one-time-purchases-and-store',
  },
  {
    key: 'CHANGE_LOG_2024-05-31-AUTO-MODERATION-MEMBER-PROFILE-RULE',
    name: '2024-05-31-auto-moderation-member-profile-rule',
    sidebarLabel: 'Auto Moderation Member Profile Rule',
    filename: '2024-05-31-auto-moderation-member-profile-rule.md',
    category: 'change_log',
    subPath: 'change-log/2024-05-31-auto-moderation-member-profile-rule',
  },
  {
    key: 'CHANGE_LOG_2024-06-17-PREMIUM-APPS-NEW-PREMIUM-BUTTON-STYLE-DEEP-LINKING-URL-SCHEMES',
    name: '2024-06-17-premium-apps-new-premium-button-style-deep-linking-url-schemes',
    sidebarLabel: 'Premium Apps: New Premium Button Style & Deep Linking URL Schemes',
    filename: '2024-06-17-premium-apps-new-premium-button-style-deep-linking-url-schemes.md',
    category: 'change_log',
    subPath: 'change-log/2024-06-17-premium-apps-new-premium-button-style-deep-linking-url-schemes',
  },
  {
    key: 'CHANGE_LOG_2024-06-27-USER-INSTALLED-APPS-GENERAL-AVAILABILITY',
    name: '2024-06-27-user-installed-apps-general-availability',
    sidebarLabel: 'User-Installed Apps General Availability',
    filename: '2024-06-27-user-installed-apps-general-availability.md',
    category: 'change_log',
    subPath: 'change-log/2024-06-27-user-installed-apps-general-availability',
  },
  {
    key: 'CHANGE_LOG_2024-07-09-BANNERS-IN-GET-CURRENT-USER-GUILDS',
    name: '2024-07-09-banners-in-get-current-user-guilds',
    sidebarLabel: 'Banners in Get Current User Guilds',
    filename: '2024-07-09-banners-in-get-current-user-guilds.md',
    category: 'change_log',
    subPath: 'change-log/2024-07-09-banners-in-get-current-user-guilds',
  },
  {
    key: 'CHANGE_LOG_2024-07-15-MESSAGE-FORWARDING-ROLLOUT',
    name: '2024-07-15-message-forwarding-rollout',
    sidebarLabel: 'Message Forwarding rollout',
    filename: '2024-07-15-message-forwarding-rollout.md',
    category: 'change_log',
    subPath: 'change-log/2024-07-15-message-forwarding-rollout',
  },
  {
    key: 'CHANGE_LOG_2024-07-17-ACTIVITIES-PROXY-CSP-UPDATE',
    name: '2024-07-17-activities-proxy-csp-update',
    sidebarLabel: 'Activities Proxy CSP Update',
    filename: '2024-07-17-activities-proxy-csp-update.md',
    category: 'change_log',
    subPath: 'change-log/2024-07-17-activities-proxy-csp-update',
  },
  {
    key: 'CHANGE_LOG_2024-07-18-APPLICATION-EMOJI',
    name: '2024-07-18-application-emoji',
    sidebarLabel: 'Application Emoji',
    filename: '2024-07-18-application-emoji.md',
    category: 'change_log',
    subPath: 'change-log/2024-07-18-application-emoji',
  },
  {
    key: 'CHANGE_LOG_2024-07-25-SUPPORTED-ACTIVITY-TYPES-FOR-SET-ACTIVITY',
    name: '2024-07-25-supported-activity-types-for-set-activity',
    sidebarLabel: 'Supported Activity Types for SET_ACTIVITY',
    filename: '2024-07-25-supported-activity-types-for-set-activity.md',
    category: 'change_log',
    subPath: 'change-log/2024-07-25-supported-activity-types-for-set-activity',
  },
  {
    key: 'CHANGE_LOG_2024-08-05-VOICE-STATE-ENDPOINTS',
    name: '2024-08-05-voice-state-endpoints',
    sidebarLabel: 'Voice State Endpoints',
    filename: '2024-08-05-voice-state-endpoints.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-05-voice-state-endpoints',
  },
  {
    key: 'CHANGE_LOG_2024-08-09-USER-APP-INSTALL-COUNT',
    name: '2024-08-09-user-app-install-count',
    sidebarLabel: 'User App Install Count',
    filename: '2024-08-09-user-app-install-count.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-09-user-app-install-count',
  },
  {
    key: 'CHANGE_LOG_2024-08-12-GET-GUILD-ROLE-ENDPOINT',
    name: '2024-08-12-get-guild-role-endpoint',
    sidebarLabel: 'Get Guild Role Endpoint',
    filename: '2024-08-12-get-guild-role-endpoint.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-12-get-guild-role-endpoint',
  },
  {
    key: 'CHANGE_LOG_2024-08-13-VOICE-ENCRYPTION-MODES',
    name: '2024-08-13-voice-encryption-modes',
    sidebarLabel: 'Voice Encryption Modes',
    filename: '2024-08-13-voice-encryption-modes.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-13-voice-encryption-modes',
  },
  {
    key: 'CHANGE_LOG_2024-08-13-VOICE-GATEWAY-VERSION-8-AND-DEPRECATION-OF-VERSIONS-OLDER-THAN-4',
    name: '2024-08-13-voice-gateway-version-8-and-deprecation-of-versions-older-than-4',
    sidebarLabel: 'Voice Gateway Version 8 and Deprecation of Versions < 4',
    filename: '2024-08-13-voice-gateway-version-8-and-deprecation-of-versions-older-than-4.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-13-voice-gateway-version-8-and-deprecation-of-versions-older-than-4',
  },
  {
    key: 'CHANGE_LOG_2024-08-26-ENTRY-POINT-COMMANDS',
    name: '2024-08-26-entry-point-commands',
    sidebarLabel: 'Entry Point Commands',
    filename: '2024-08-26-entry-point-commands.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-26-entry-point-commands',
  },
  {
    key: 'CHANGE_LOG_2024-08-26-LAUNCHING-ACTIVITIES-VIA-INTERACTIONS',
    name: '2024-08-26-launching-activities-via-interactions',
    sidebarLabel: 'Launching Activities in Response to Interactions',
    filename: '2024-08-26-launching-activities-via-interactions.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-26-launching-activities-via-interactions',
  },
  {
    key: 'CHANGE_LOG_2024-08-28-SUBSCRIPTION-API-AND-ENTITLEMENT-MIGRATION',
    name: '2024-08-28-subscription-api-and-entitlement-migration',
    sidebarLabel: 'Premium Apps: Entitlement Migration and New Subscription API',
    filename: '2024-08-28-subscription-api-and-entitlement-migration.md',
    category: 'change_log',
    subPath: 'change-log/2024-08-28-subscription-api-and-entitlement-migration',
  },
  {
    key: 'DEVELOPER_TOOLS_COMMUNITY_RESOURCES',
    name: 'Community Resources',
    sidebarLabel: 'Community Resources',
    filename: 'Community_Resources.mdx',
    category: 'developer_tools',
    subPath: 'developer-tools/community-resources',
  },
  {
    key: 'DEVELOPER_TOOLS_EMBEDDED_APP_SDK',
    name: 'Embedded App SDK',
    sidebarLabel: 'Embedded App SDK',
    filename: 'Embedded_App_SDK.mdx',
    category: 'developer_tools',
    subPath: 'developer-tools/embedded-app-sdk',
  },
  {
    key: 'DEVELOPER_TOOLS_GAME_SDK',
    name: 'Game SDK',
    sidebarLabel: 'Game SDK',
    filename: 'Game_SDK.mdx',
    category: 'developer_tools',
    subPath: 'developer-tools/game-sdk',
  },
  {
    key: 'INTERACTIONS_APPLICATION_COMMANDS',
    name: 'Application Commands',
    sidebarLabel: 'Application Commands',
    filename: 'Application_Commands.mdx',
    category: 'interactions',
    subPath: 'interactions/application-commands',
  },
  {
    key: 'INTERACTIONS_MESSAGE_COMPONENTS',
    name: 'Message Components',
    sidebarLabel: 'Message Components',
    filename: 'Message_Components.md',
    category: 'interactions',
    subPath: 'interactions/message-components',
  },
  {
    key: 'INTERACTIONS_OVERVIEW',
    name: 'Overview',
    sidebarLabel: 'Overview',
    filename: 'Overview.mdx',
    category: 'interactions',
    subPath: 'interactions/overview',
  },
  {
    key: 'INTERACTIONS_RECEIVING_AND_RESPONDING',
    name: 'Receiving and Responding',
    sidebarLabel: 'Receiving and Responding',
    filename: 'Receiving_and_Responding.mdx',
    category: 'interactions',
    subPath: 'interactions/receiving-and-responding',
  },
  {
    key: 'MONETIZATION_ENABLING_MONETIZATION',
    name: 'Enabling Monetization',
    sidebarLabel: 'Enabling Monetization',
    filename: 'Enabling_Monetization.mdx',
    category: 'monetization',
    subPath: 'monetization/enabling-monetization',
  },
  {
    key: 'MONETIZATION_IMPLEMENTING_APP_SUBSCRIPTIONS',
    name: 'Implementing App Subscriptions',
    sidebarLabel: 'Implementing App Subscriptions',
    filename: 'Implementing_App_Subscriptions.mdx',
    category: 'monetization',
    subPath: 'monetization/implementing-app-subscriptions',
  },
  {
    key: 'MONETIZATION_IMPLEMENTING_ONE-TIME_PURCHASES',
    name: 'Implementing One-Time Purchases',
    sidebarLabel: 'Implementing One-Time Purchases',
    filename: 'Implementing_One-Time_Purchases.md',
    category: 'monetization',
    subPath: 'monetization/implementing-one-time-purchases',
  },
  {
    key: 'MONETIZATION_MANAGING_SKUS',
    name: 'Managing SKUs',
    sidebarLabel: 'Managing SKUs',
    filename: 'Managing_SKUs.mdx',
    category: 'monetization',
    subPath: 'monetization/managing-skus',
  },
  {
    key: 'MONETIZATION_OVERVIEW',
    name: 'Overview',
    sidebarLabel: 'Overview',
    filename: 'Overview.mdx',
    category: 'monetization',
    subPath: 'monetization/overview',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_DEVELOPER_POLICY',
    name: 'Developer Policy',
    sidebarLabel: 'Developer Policy',
    filename: 'Developer_Policy.md',
    category: 'policies_and_agreements',
    subPath: 'policies-and-agreements/developer-policy',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_DEVELOPER_TERMS_OF_SERVICE',
    name: 'Developer Terms of Service',
    sidebarLabel: 'Developer Terms of Service',
    filename: 'Developer_Terms_of_Service.md',
    category: 'policies_and_agreements',
    subPath: 'policies-and-agreements/developer-terms-of-service',
  },
  {
    key: 'POLICIES_AND_AGREEMENTS_STORE_DISTRIBUTION_AGREEMENT',
    name: 'Store Distribution Agreement',
    sidebarLabel: 'Discord Store Distribution Agreement for Developers (Self-Service)',
    filename: 'Store_Distribution_Agreement.md',
    category: 'policies_and_agreements',
    subPath: 'policies-and-agreements/store-distribution-agreement',
  },
  {
    key: 'QUICK_START_GETTING_STARTED',
    name: 'Getting Started',
    sidebarLabel: 'Getting Started',
    filename: 'Getting_Started.mdx',
    category: 'quick_start',
    subPath: 'quick-start/getting-started',
  },
  {
    key: 'QUICK_START_OVERVIEW_OF_APPS',
    name: 'Overview of Apps',
    sidebarLabel: 'Overview of Apps',
    filename: 'Overview_of_Apps.mdx',
    category: 'quick_start',
    subPath: 'quick-start/overview-of-apps',
  },
  {
    key: 'RESOURCES_APPLICATION',
    name: 'Application',
    sidebarLabel: 'Application',
    filename: 'Application.md',
    category: 'resources',
    subPath: 'resources/application',
  },
  {
    key: 'RESOURCES_APPLICATION_ROLE_CONNECTION_METADATA',
    name: 'Application Role Connection Metadata',
    sidebarLabel: 'Application Role Connection Metadata',
    filename: 'Application_Role_Connection_Metadata.md',
    category: 'resources',
    subPath: 'resources/application-role-connection-metadata',
  },
  {
    key: 'RESOURCES_AUDIT_LOG',
    name: 'Audit Log',
    sidebarLabel: 'Audit Log',
    filename: 'Audit_Log.md',
    category: 'resources',
    subPath: 'resources/audit-log',
  },
  {
    key: 'RESOURCES_AUTO_MODERATION',
    name: 'Auto Moderation',
    sidebarLabel: 'Auto Moderation',
    filename: 'Auto_Moderation.md',
    category: 'resources',
    subPath: 'resources/auto-moderation',
  },
  {
    key: 'RESOURCES_CHANNEL',
    name: 'Channel',
    sidebarLabel: 'Channel',
    filename: 'Channel.md',
    category: 'resources',
    subPath: 'resources/channel',
  },
  {
    key: 'RESOURCES_EMOJI',
    name: 'Emoji',
    sidebarLabel: 'Emoji',
    filename: 'Emoji.md',
    category: 'resources',
    subPath: 'resources/emoji',
  },
  {
    key: 'RESOURCES_ENTITLEMENT',
    name: 'Entitlement',
    sidebarLabel: 'Entitlement',
    filename: 'Entitlement.md',
    category: 'resources',
    subPath: 'resources/entitlement',
  },
  {
    key: 'RESOURCES_GUILD',
    name: 'Guild',
    sidebarLabel: 'Guild',
    filename: 'Guild.md',
    category: 'resources',
    subPath: 'resources/guild',
  },
  {
    key: 'RESOURCES_GUILD_SCHEDULED_EVENT',
    name: 'Guild Scheduled Event',
    sidebarLabel: 'Guild Scheduled Event',
    filename: 'Guild_Scheduled_Event.mdx',
    category: 'resources',
    subPath: 'resources/guild-scheduled-event',
  },
  {
    key: 'RESOURCES_GUILD_TEMPLATE',
    name: 'Guild Template',
    sidebarLabel: 'Guild Template',
    filename: 'Guild_Template.md',
    category: 'resources',
    subPath: 'resources/guild-template',
  },
  {
    key: 'RESOURCES_INVITE',
    name: 'Invite',
    sidebarLabel: 'Invite',
    filename: 'Invite.md',
    category: 'resources',
    subPath: 'resources/invite',
  },
  {
    key: 'RESOURCES_MESSAGE',
    name: 'Message',
    sidebarLabel: 'Message',
    filename: 'Message.md',
    category: 'resources',
    subPath: 'resources/message',
  },
  {
    key: 'RESOURCES_POLL',
    name: 'Poll',
    sidebarLabel: 'Poll',
    filename: 'Poll.md',
    category: 'resources',
    subPath: 'resources/poll',
  },
  {
    key: 'RESOURCES_SKU',
    name: 'SKU',
    sidebarLabel: 'SKU',
    filename: 'SKU.md',
    category: 'resources',
    subPath: 'resources/sku',
  },
  {
    key: 'RESOURCES_STAGE_INSTANCE',
    name: 'Stage Instance',
    sidebarLabel: 'Stage Instance',
    filename: 'Stage_Instance.md',
    category: 'resources',
    subPath: 'resources/stage-instance',
  },
  {
    key: 'RESOURCES_STICKER',
    name: 'Sticker',
    sidebarLabel: 'Sticker',
    filename: 'Sticker.md',
    category: 'resources',
    subPath: 'resources/sticker',
  },
  {
    key: 'RESOURCES_SUBSCRIPTION',
    name: 'Subscription',
    sidebarLabel: 'Subscription',
    filename: 'Subscription.md',
    category: 'resources',
    subPath: 'resources/subscription',
  },
  {
    key: 'RESOURCES_USER',
    name: 'User',
    sidebarLabel: 'User',
    filename: 'User.md',
    category: 'resources',
    subPath: 'resources/user',
  },
  {
    key: 'RESOURCES_VOICE',
    name: 'Voice',
    sidebarLabel: 'Voice',
    filename: 'Voice.md',
    category: 'resources',
    subPath: 'resources/voice',
  },
  {
    key: 'RESOURCES_WEBHOOK',
    name: 'Webhook',
    sidebarLabel: 'Webhook',
    filename: 'Webhook.md',
    category: 'resources',
    subPath: 'resources/webhook',
  },
  {
    key: 'RICH_PRESENCE_BEST_PRACTICES',
    name: 'Best Practices',
    sidebarLabel: 'Best Practices',
    filename: 'Best_Practices.md',
    category: 'rich_presence',
    subPath: 'rich-presence/best-practices',
  },
  {
    key: 'RICH_PRESENCE_OVERVIEW',
    name: 'Overview',
    sidebarLabel: 'Overview',
    filename: 'Overview.mdx',
    category: 'rich_presence',
    subPath: 'rich-presence/overview',
  },
  {
    key: 'RICH_PRESENCE_USING_WITH_THE_EMBEDDED_APP_SDK',
    name: 'Using with the Embedded App SDK',
    sidebarLabel: 'Using with the Embedded App SDK',
    filename: 'Using_with_the_Embedded_App_SDK.mdx',
    category: 'rich_presence',
    subPath: 'rich-presence/using-with-the-embedded-app-sdk',
  },
  {
    key: 'RICH_PRESENCE_USING_WITH_THE_GAME_SDK',
    name: 'Using with the Game SDK',
    sidebarLabel: 'Using with the Game SDK',
    filename: 'Using_with_the_Game_SDK.mdx',
    category: 'rich_presence',
    subPath: 'rich-presence/using-with-the-game-sdk',
  },
  {
    key: 'TOPICS_CERTIFIED_DEVICES',
    name: 'Certified Devices',
    sidebarLabel: 'Certified Devices',
    filename: 'Certified_Devices.md',
    category: 'topics',
    subPath: 'topics/certified-devices',
  },
  {
    key: 'TOPICS_GATEWAY',
    name: 'Gateway',
    sidebarLabel: 'Gateway',
    filename: 'Gateway.md',
    category: 'topics',
    subPath: 'topics/gateway',
  },
  {
    key: 'TOPICS_GATEWAY_EVENTS',
    name: 'Gateway Events',
    sidebarLabel: 'Gateway Events',
    filename: 'Gateway_Events.md',
    category: 'topics',
    subPath: 'topics/gateway-events',
  },
  {
    key: 'TOPICS_OAUTH2',
    name: 'OAuth2',
    sidebarLabel: 'OAuth2',
    filename: 'OAuth2.md',
    category: 'topics',
    subPath: 'topics/oauth2',
  },
  {
    key: 'TOPICS_OPCODES_AND_STATUS_CODES',
    name: 'Opcodes and Status Codes',
    sidebarLabel: 'Opcodes and Status Codes',
    filename: 'Opcodes_and_Status_Codes.md',
    category: 'topics',
    subPath: 'topics/opcodes-and-status-codes',
  },
  {
    key: 'TOPICS_PERMISSIONS',
    name: 'Permissions',
    sidebarLabel: 'Permissions',
    filename: 'Permissions.md',
    category: 'topics',
    subPath: 'topics/permissions',
  },
  {key: 'TOPICS_RPC', name: 'RPC', sidebarLabel: 'RPC', filename: 'RPC.md', category: 'topics', subPath: 'topics/rpc'},
  {
    key: 'TOPICS_RATE_LIMITS',
    name: 'Rate Limits',
    sidebarLabel: 'Rate Limits',
    filename: 'Rate_Limits.md',
    category: 'topics',
    subPath: 'topics/rate-limits',
  },
  {
    key: 'TOPICS_TEAMS',
    name: 'Teams',
    sidebarLabel: 'Teams',
    filename: 'Teams.md',
    category: 'topics',
    subPath: 'topics/teams',
  },
  {
    key: 'TOPICS_THREADS',
    name: 'Threads',
    sidebarLabel: 'Threads',
    filename: 'Threads.md',
    category: 'topics',
    subPath: 'topics/threads',
  },
  {
    key: 'TOPICS_VOICE_CONNECTIONS',
    name: 'Voice Connections',
    sidebarLabel: 'Voice',
    filename: 'Voice_Connections.mdx',
    category: 'topics',
    subPath: 'topics/voice-connections',
  },
  {
    key: 'TUTORIALS_CONFIGURING_APP_METADATA_FOR_LINKED_ROLES',
    name: 'Configuring App Metadata for Linked Roles',
    sidebarLabel: 'Configuring App Metadata for Linked Roles',
    filename: 'Configuring_App_Metadata_for_Linked_Roles.md',
    category: 'tutorials',
    subPath: 'tutorials/configuring-app-metadata-for-linked-roles',
  },
  {
    key: 'TUTORIALS_DEVELOPING_A_USER_INSTALLABLE_APP',
    name: 'Developing a User Installable App',
    sidebarLabel: 'Developing A User-Installable App',
    filename: 'Developing_a_User_Installable_App.mdx',
    category: 'tutorials',
    subPath: 'tutorials/developing-a-user-installable-app',
  },
  {
    key: 'TUTORIALS_HOSTING_ON_CLOUDFLARE_WORKERS',
    name: 'Hosting on Cloudflare Workers',
    sidebarLabel: 'Hosting on Cloudflare Workers',
    filename: 'Hosting_on_Cloudflare_Workers.md',
    category: 'tutorials',
    subPath: 'tutorials/hosting-on-cloudflare-workers',
  },
  {
    key: 'TUTORIALS_UPGRADING_TO_APPLICATION_COMMANDS',
    name: 'Upgrading to Application Commands',
    sidebarLabel: 'Upgrading to Application Commands',
    filename: 'Upgrading_to_Application_Commands.md',
    category: 'tutorials',
    subPath: 'tutorials/upgrading-to-application-commands',
  },
] as const;
