/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde mana assets sync -t icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';
import {getIconSize} from '../getIconSize';

import type {TwoColorIconProps} from '../IconTypes';

/**
 * ![CircleCheckIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABvklEQVR4nMWVz07CQBDGm3hQb8oRjW+gcvGgFz14KN3u7BKy3QkmIjyEIRgfSeQ11KhHMd7Ug2iiCN41UwiU/oG2GN1kkz20v9mZb+Zbw/jPpZTKgMQqCGxyiS2Q+ov24HwO4FQYKy2nAS+C1Ccg9SdI/J60ucAOF7pO/8SCA2AWBF5OA4M/kNS3tq3WJsILhYNVkPicFA7DbPQTY2oluiwCr9PCYZTJ1W65vBAIwIU+nRUOw61r/ttn4ggKsUuFnbHuclsx3U3fLK5MG3QtRI+jUQCBzcRwga+WpXKGYcxRkBAtGt4MHpLCGXM2XbilcpRJMAC2PAJj7zfh0NehNwogsev/gIGqMlbc40K3PXVtm7y4MQ0+yKDrzeA+GMDZJ1A+r9ZB6heC0zkOPFAi17jCOsTqi0jgJPBBKc88/uNUItrw3badLQIngtMG53Bs0Gg4onrdttW2JYo7ceFc6g8hykt+q6inGzYM66DjgBeRQZFRzQ7XF6FmR4usliw3NVziI70nofCR4JilWySH65upD463XGS50cKPC0o1N01zPhZ8vGSlZXJFd06EvqPxd22FzlI3uMRyoFv+ev0AMu/fOaWmhZcAAAAASUVORK5CYII=)
 */
export const CircleCheckIcon = ({
  size = 'md',
  width,
  height,
  secondaryColor = 'transparent',
  secondaryColorClass = '',
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: TwoColorIconProps) => {
  const iconSize = getIconSize(size);
  const iconWidth = iconSize?.width ?? width;
  const iconHeight = iconSize?.height ?? height;
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={typeof secondaryColor === 'string' ? secondaryColor : secondaryColor.css}
        className={secondaryColorClass}
      />
      <path
        fill={typeof color === 'string' ? color : color.css}
        fillRule="evenodd"
        d="M12 23a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm5.7-13.3a1 1 0 0 0-1.4-1.4L10 14.58l-2.3-2.3a1 1 0 0 0-1.4 1.42l3 3a1 1 0 0 0 1.4 0l7-7Z"
        clipRule="evenodd"
        className={colorClass}
      />
    </svg>
  );
};
