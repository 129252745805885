import {isNullOrEmpty} from './String';

import {Messages} from '@developers/i18n';

const PAGE_TITLE_SEPARATOR = ' — ';

interface MetadataContent {
  charset?: string;
  property?: string;
  content?: string;
  name?: string;
}

interface Metadata {
  title: string;
  meta: MetadataContent[];
}

export function getTitle(...segments: Array<string | null | undefined>): string {
  return [Messages.Common.METADATA_TITLE_PREFIX]
    .concat(segments.filter((segment): segment is string => segment != null))
    .join(PAGE_TITLE_SEPARATOR);
}

export function getMetaData(messages: Record<string, string>, imageUrl?: string) {
  if (process.env.NODE_ENV !== 'production' && isNullOrEmpty(messages.METADATA_TITLE)) {
    throw new Error(`Missing title metadata.`);
  }

  const metaData: Metadata = {
    title: `Discord - ${messages.METADATA_TITLE}`,
    meta: [{charset: 'utf-8'}],
  };

  if (!isNullOrEmpty(messages.METADATA_OGTITLE)) {
    metaData.meta.push({
      property: 'og:title',
      content: messages.METADATA_OGTITLE,
    });
  }

  if (!isNullOrEmpty(messages.METADATA_DESCRIPTION)) {
    metaData.meta.push({
      property: 'og:description',
      content: messages.METADATA_DESCRIPTION,
    });
  }

  if (!isNullOrEmpty(imageUrl)) {
    metaData.meta.push({
      property: 'og:image',
      content: imageUrl,
    });
  }

  if (!isNullOrEmpty(messages.METADATA_DESCRIPTION)) {
    metaData.meta.push({
      name: 'description',
      content: messages.METADATA_DESCRIPTION,
    });
  }

  return metaData;
}
