export const stringifyErrors = (errors?: any): string => {
  if (errors == null) return '';
  if (typeof errors === 'string') return errors;
  if (errors != null && errors.message) return errors.message;
  if (Array.isArray(errors)) {
    return errors.reduce((errorsArr, error) => errorsArr.concat(stringifyErrors(error)), []).join(', ');
  }
  if (typeof errors === 'object') {
    return Object.keys(errors)
      .reduce<string[]>((errorsArr, errorKey) => {
        const nextError = stringifyErrors(errors[errorKey]);
        if (nextError) return errorsArr.concat(`${nextError} (${errorKey})`);
        return errorsArr;
      }, [])
      .join(', ');
  }
  return '';
};
