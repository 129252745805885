import throttle from 'lodash/throttle';
import AppStartPerformance from '@discordapp/common/AppStartPerformance';
import {Storage} from '@discordapp/storage';

import {Store} from './Store';

import type {Dispatcher, ActionHandlers} from './Dispatcher';
import type {ActionBase} from './FluxTypes';

/*

If you have a store that needs to persist data to local storage, you should use
this!

## Benefits

- provides a migrations API. Never worry about version numbers again!
- writes to localStorage async so that we don't have a blocking call in the
critical path
- provides a way to clear _all_ cache state (useful when the user logs out)

## API

```js
type State = string[]
const initialState = []
let state: State

class MyStore extends Flux.PersistedStore<State> {
  static persistKey = 'MyStore'

  // initialize now accepts the cached state, do with it what you will
  initialize(cachedState = initialState) {
    state = cachedState
  }

  // getState is now a requiredMethod
  getState() {
    return state
  }
}
```

This will now automatically save to `localStorage` when the store emits a
change event. This is done in a `requestIdleCallback` so that we don't spam
`localStorage`.

Migrations are now a first-class citizen too. Migrations are run in order, the
store is initialized, and the migrated state is saved to storage. A nice
benefit of this is that rather than duck-typing our state data to determine if
we need to migrate, we have versions and can run through a series of migrations
if need-be.

```js
// changing from an array to an object, because we're adding a new key
// WAS type State = Array<Item>
type State = {arrayOfItems: Array<Item>, newProperty: string}
const initialState = {}
let state: State

class MyStore extends Flux.PersistedStore<State> {
  static persistKey = 'MyStore'

  // new property, only needs to be set if we need to migrate
  static migrations = [
    cachedState => {
      // If this is the first migration and there's no existing data in key, cachedState will be an empty object
      // see L302 spread operation for why.
      const arrayOfItems = Array.isArray(cachedState) ? cachedState : [];
      return {arrayOfItems, newProperty: 'something'}
    }
  ]

  // nothing else changes
  initialize(cachedState = initialState) {
    state = cachedState
  }

  getState() {
    return state
  }
}
```

## Migrating to PersistedStore

Tips to convert a `Store` that uses `Storage` to a `PersistedStore`:

Things you'll need to add:

1. If not already done, it's best to store all in-memory state in a single
state object. Ideally, it's flow-typed.
2. `s/Flux.Store<*>/Flux.PersistedStore<State>/`
3. Add a `getState()` method to the store.
4. The `initialize` method now receives the `cachedState`.
5. Add `static persistKey` to the store.

Things to remove:

1. Try removing the `Storage` import this should help you use lint errors to
find the places where storage is used.
2. If there's data migrations happening in the `initialize` method, it's best
to move this to `static migrations`. It might speed up store initialize time.
3. Ideally, we can remove many `__OVERLAY__` guards.

*/

declare global {
  type IdleCallbackID = number;
  // eslint-disable-next-line no-redeclare
  function requestIdleCallback(func: () => void, options: {timeout: number}): IdleCallbackID;
  // eslint-disable-next-line no-redeclare
  function cancelIdleCallback(id: IdleCallbackID): void;
}

type PersistKey = string;
interface ClearAllArgs {
  omit?: PersistKey[];
  type: 'all' | 'user-data-only';
}
interface StoredData {
  _state: any;
  _version: number | undefined;
}
const DefaultStoredData: StoredData = {_state: undefined, _version: undefined};

let lastClearAllArgs: ClearAllArgs | null = null;

export abstract class PersistedStore<State, Action extends Readonly<ActionBase>> extends Store<Action> {
  static allPersistKeys: Set<PersistKey> = new Set();
  static userAgnosticPersistKeys: Set<PersistKey> = new Set();
  static _writePromises: Map<PersistKey, Promise<void | false>> = new Map();
  static _writeResolvers: Map<PersistKey, [(resolveStatus: void | false) => void, IdleCallbackID]> = new Map();
  static _clearAllPromise: Promise<void> | null | undefined;
  static disableWrites: boolean = false;

  abstract getState(): State;
  abstract initialize(state?: State | null): void | boolean;

  // NOTE: it would be great to default to `this.constructor.name`, but our
  // build mangles classnames down to `t`, which makes that impossible.
  static persistKey: string;
  static disableWrite: boolean = false;
  static throttleDelay: number = 0;

  protected getClass() {
    return this.constructor as typeof PersistedStore;
  }

  static migrations: Array<(oldState: any) => any> | null;
  _version: number = this.getClass().migrations == null ? 0 : this.getClass().migrations!.length;

  static clearAll(options: ClearAllArgs): Promise<void> {
    lastClearAllArgs = options;

    if (PersistedStore._clearAllPromise == null) {
      PersistedStore._clearAllPromise = new Promise((resolve) => {
        requestIdleCallback(
          () => {
            PersistedStore.clearPersistQueue(options);
            PersistedStore.allPersistKeys.forEach((persistKey) => {
              if (PersistedStore.shouldClear(options, persistKey)) {
                Storage.remove(persistKey);
              }
            });

            // Re-initialize the persisted stores
            Store.getAll().forEach((store) => {
              if (store instanceof PersistedStore) {
                if (PersistedStore.shouldClear(options, store.getClass().persistKey)) {
                  store._isInitialized = false;
                  store.initializeIfNeeded();
                }
              }
            });

            PersistedStore._clearAllPromise = null;
            resolve();
          },
          {timeout: 500},
        );
      });
    }
    return PersistedStore._clearAllPromise;
  }

  private static shouldClear(options: ClearAllArgs, persistKey: string) {
    if (options.omit?.includes(persistKey)) return false;
    if (options.type === 'all') return true;
    return options.type === 'user-data-only' && !PersistedStore.userAgnosticPersistKeys.has(persistKey);
  }

  static clearPersistQueue(options: ClearAllArgs) {
    PersistedStore._writeResolvers.forEach(([resolve, callbackID], persistKey) => {
      if (!PersistedStore.shouldClear(options, persistKey)) return;

      // Deleting items here to be consistent with how resolvers normally
      // work - we remove the promises and resolvers before firing resolve
      PersistedStore._writePromises.delete(persistKey);
      PersistedStore._writeResolvers.delete(persistKey);
      cancelIdleCallback(callbackID);
      resolve(false);
    });
    // For safety, but should be unneccessary
    PersistedStore._writePromises.clear();
    PersistedStore._writeResolvers.clear();
  }

  // a little future-proofing; make this method async
  static getAllStates(): Promise<{[persistKey: string]: any}> {
    // We must wait for all persists to resolve before actually getting the values
    return Promise.all(Array.from(PersistedStore._writePromises.values())).then(() => {
      const allStates: Record<string, any> = {};
      PersistedStore.allPersistKeys.forEach((key) => {
        allStates[key] = (Storage.get<StoredData>(key) ?? DefaultStoredData)._state;
      });
      return allStates;
    });
  }

  // this is a hacky way to receive data from getAllStates and [re-]initialize
  // all registered PersistedStores with that state. This is designed for use
  // in the overlay which needs to receive it's data async from the main app.
  static initializeAll(states: {[persistKey: string]: any}) {
    Store.getAll().forEach((store) => {
      if (store instanceof PersistedStore) {
        const key = store.getClass().persistKey;
        if (!states.hasOwnProperty(key)) return;
        store.initializeFromState(states[key]);
      }
    });
  }

  initializeFromState(state: any) {
    if (this.initialize(state)) {
      this.asyncPersist();
    }
    if (this._isInitialized) {
      this.emitChange();
    } else {
      PersistedStore.allPersistKeys.add(this.getClass().persistKey);
      this._isInitialized = true;
    }
  }

  static destroy() {
    lastClearAllArgs = null;
    Store.destroy();
    PersistedStore.clearPersistQueue({type: 'all'});
    PersistedStore.allPersistKeys.clear();
    PersistedStore.userAgnosticPersistKeys.clear();
  }

  constructor(dispatcher: any extends Action ? never : Dispatcher<Action>, actionHandler?: ActionHandlers<Action>) {
    super(dispatcher, actionHandler);

    if (typeof this.getClass().persistKey !== 'string') {
      throw new Error(
        `${this.getClass().name} initialized without a \`persistKey\`. Add one so we know where to save your stuff!`,
      );
    }

    if (typeof (this as any).initialize !== 'function') {
      throw new Error(
        `${
          this.getClass().name
        } initialized without an \`initialize\` method. Add one that accepts the initial cached state.`,
      );
    }
    if (typeof (this as any).getState !== 'function') {
      throw new Error(
        `${
          this.getClass().name
        } initialized without a \`getState\` method. Add one that returns the full state of the store for persistance to work.`,
      );
    }
    this.addChangeListener(() => this.asyncPersist());
  }

  /**
   * Calls initialize if it has not yet been run.
   *
   * Overrides the method on Store
   */
  initializeIfNeeded() {
    if (!this._isInitialized) {
      const start = Date.now();

      PersistedStore.allPersistKeys.add(this.getClass().persistKey);
      const {state, requiresPersist} = PersistedStore.migrateAndReadStoreState<State>(
        this.getClass().persistKey,
        this.getClass().migrations,
      );
      if (this.initialize(state)) {
        this.asyncPersist();
      }
      if (requiresPersist) {
        this.asyncPersist();
      }
      this._isInitialized = true;

      const duration = Date.now() - start;
      if (duration > 5) {
        AppStartPerformance.mark('🦥', this.getName() + '.initialize()', duration);
      }
    }
  }

  static migrateAndReadStoreState<State>(
    persistKey: string,
    migrations: Array<(oldState: any) => any> | null,
  ): {state: State | undefined; requiresPersist: boolean} {
    // If PersistedStore.clearAll() was called before this store was loaded, then lets respect that
    // and clear all of its state.
    if (lastClearAllArgs != null && PersistedStore.shouldClear(lastClearAllArgs, persistKey)) {
      Storage.remove(persistKey);
      return {state: undefined, requiresPersist: false};
    }

    // prevent a race condition where we might be clearing state while
    // initializing (e.g. logging out)
    const storedState = PersistedStore._clearAllPromise != null ? null : Storage.get<StoredData>(persistKey);
    const {_state: cachedState, _version: cachedVersion, ...legacyState} = storedState ?? DefaultStoredData;
    const version = migrations == null ? 0 : migrations.length;

    // NOTE: even if we don't have a pervious cachedState, we may still want
    // to migrate. e.g. to combine/split stores together.
    //
    // If version == 0, there are no migrations to run.
    const requiresMigration = version === 0 ? false : cachedVersion !== version;

    // this migrations check isn't necessary, but it makes flow happy
    if (requiresMigration && migrations != null) {
      let migrationIndex = cachedVersion ?? 0;
      let migratedState = cachedState;
      // will happen the first time Stores are changed to PersistedStores
      if (cachedVersion == null) {
        migratedState = legacyState;
      }

      while (migrationIndex < version) {
        const migration = migrations[migrationIndex];
        migratedState = migration(migratedState);
        migrationIndex++;
      }
      return {state: migratedState, requiresPersist: true};
    } else if (Object.values(legacyState).length > 0) {
      return {state: legacyState as State, requiresPersist: true};
    } else {
      return {state: cachedState, requiresPersist: false};
    }
  }

  callback = (resolve: () => void) => {
    const {persistKey} = this.getClass();
    this.persist();
    PersistedStore._writePromises.delete(persistKey);
    PersistedStore._writeResolvers.delete(persistKey);
    resolve();
  };

  throttledCallback = throttle((resolve) => this.callback(resolve), this.getClass().throttleDelay, {
    leading: false,
  });

  asyncPersist(): Promise<void | false> {
    const {persistKey, disableWrite, throttleDelay} = this.getClass();
    if (PersistedStore.disableWrites || disableWrite) {
      // FIXME: we should probably reject here, but we don't have a good way of
      // catching that rejection from the dispatch change listener
      return Promise.resolve(false);
    }
    let promise = PersistedStore._writePromises.get(persistKey);
    if (promise != null) {
      return promise;
    }

    promise = new Promise((resolve) => {
      const _callback = throttleDelay > 0 ? () => this.throttledCallback(resolve) : () => this.callback(resolve);
      PersistedStore._writeResolvers.set(persistKey, [resolve, requestIdleCallback(_callback, {timeout: 500})]);
    });
    PersistedStore._writePromises.set(persistKey, promise);
    return promise;
  }

  persist() {
    const {persistKey} = this.getClass();
    const _state = this.getState();
    const _version = this._version;
    // use underscored name so that we don't confilict with legacy data
    Storage.set(persistKey, {_state, _version});
  }

  clear() {
    const {persistKey} = this.getClass();
    Storage.remove(persistKey);
  }
}

abstract class UserAgnosticStore<State, Action extends ActionBase> extends PersistedStore<State, Action> {
  initializeFromState(state: any) {
    PersistedStore.userAgnosticPersistKeys.add(this.getClass().persistKey);
    return super.initializeFromState(state);
  }

  initializeIfNeeded() {
    PersistedStore.userAgnosticPersistKeys.add(this.getClass().persistKey);
    return super.initializeIfNeeded();
  }

  abstract getUserAgnosticState(): State;

  getState() {
    return this.getUserAgnosticState();
  }
}

export abstract class DeviceSettingsStore<State, Action extends ActionBase> extends UserAgnosticStore<State, Action> {}
export abstract class OfflineCacheStore<State, Action extends ActionBase> extends UserAgnosticStore<State, Action> {}
