import * as React from 'react';
import {PopupWindow} from '@discordapp/common/lib/PopupWindow';
import {UserIdentityVerificationErrorTypes} from '@discordapp/common/shared-constants/UserIdentityVerificationErrorTypes';
import {
  UserIdentityVerificationStatusTypes,
  UserIdentityVerificationStatusTypesSets,
} from '@discordapp/common/shared-constants/UserIdentityVerificationStatusTypes';
import {useStateFromStores} from '@discordapp/flux';

import * as TeamActionCreators from '@developers/actions/TeamActions';
import * as UserActionCreators from '@developers/actions/UserActionCreators';
import useInterval from '@developers/hooks/useInterval';
import TeamStore from '@developers/stores/TeamStore';
import UserStore from '@developers/stores/UserStore';
import {getMessageFromAPIError} from '@developers/utils/ErrorUtils';
import {prependBasename} from '@developers/utils/RouterUtils';

import {APIErrorCodes, Routes} from '@developers/Constants';
import {Messages} from '@developers/i18n';

const UserIdentityVerificationErrorTypeI18nKeyMapping = {
  [UserIdentityVerificationErrorTypes.CONSENT_DECLINED]: 'ERROR_CONSENT_DECLINED',
  [UserIdentityVerificationErrorTypes.UNVERIFIED]: 'ERROR_UNVERIFIED',
  [UserIdentityVerificationErrorTypes.DEVICE_UNSUPPORTED]: 'ERROR_DEVICE_UNSUPPORTED',
  [UserIdentityVerificationErrorTypes.VERIFICATION_DOCUMENT_EXPIRED]: 'ERROR_VERIFICATION_DOCUMENT_EXPIRED',
  [UserIdentityVerificationErrorTypes.VERIFICATION_DOCUMENT_INVALID]: 'ERROR_VERIFICATION_DOCUMENT_INVALID',
  [UserIdentityVerificationErrorTypes.VERIFICATION_UNEXPECTED_DOCUMENT_COUNTRY]:
    'ERROR_VERIFICATION_UNEXPECTED_DOCUMENT_COUNTRY',
  [UserIdentityVerificationErrorTypes.VERIFICATION_UNEXPECTED_DOCUMENT_TYPE]:
    'ERROR_VERIFICATION_UNEXPECTED_DOCUMENT_TYPE',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_NOT_READABLE]: 'ERROR_VERIFICATION_SCAN_NOT_READABLE',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_MISSING_BACK]: 'ERROR_VERIFICATION_SCAN_MISSING_BACK',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_ID_TYPE_NOT_SUPPORTED]:
    'ERROR_VERIFICATION_SCAN_ID_TYPE_NOT_SUPPORTED',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_CORRUPT]: 'ERROR_VERIFICATION_SCAN_CORRUPT',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_FAILED_COPY]: 'ERROR_VERIFICATION_SCAN_FAILED_COPY',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_MANIPULATED_DOCUMENT]:
    'ERROR_VERIFICATION_SCAN_MANIPULATED_DOCUMENT',
  [UserIdentityVerificationErrorTypes.VERIFICATION_SCAN_FAILED_GRAYSCALE]: 'ERROR_VERIFICATION_SCAN_FAILED_GRAYSCALE',
  [UserIdentityVerificationErrorTypes.VERIFICATION_UNDER_SUPPORTED_AGE]: 'ERROR_VERIFICATION_UNDER_SUPPORTED_AGE',
};

function fetchIdentityVerification(ownerId: string, ownerIsTeam: boolean) {
  if (ownerIsTeam) {
    return TeamActionCreators.fetchTeamIdentityVerification(ownerId);
  } else {
    return UserActionCreators.fetchUserIdentityVerification();
  }
}

async function getHostedIdentityVerificationURL(
  ownerId: string,
  ownerIsTeam: boolean,
): Promise<string | null | undefined> {
  const {redirect_url: redirectURL} = await (ownerIsTeam
    ? TeamActionCreators.createTeamIdentityVerification(ownerId)
    : UserActionCreators.createUserIdentityVerification());
  return redirectURL;
}

export enum IdentityVerificationUiState {
  NEEDS_VERIFICATION,
  LOADING,
  PROCESSING,
  REQUIRES_ACTION,
  SUCCESS,
  SUCCESS_GRACE_PERIOD,
}

export default function useGetOrCreateVerificationState(
  ownerId: string | undefined,
  ownerIsTeam: boolean,
  onError: (error: React.ReactNode) => void,
) {
  const [isInitialFetchDone, setIsInitialFetchDone] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const identityVerification = useStateFromStores(
    [UserStore, TeamStore],
    () => {
      if (ownerId != null && ownerIsTeam) {
        return TeamStore.getIdentityVerification(ownerId);
      } else {
        return UserStore.identityVerification;
      }
    },
    [ownerId, ownerIsTeam],
  );

  const lastErrorMessage = React.useMemo(() => {
    if (
      identityVerification?.status === UserIdentityVerificationStatusTypes.REQUIRES_ACTION &&
      identityVerification.last_error != null
    ) {
      const i18nKey = UserIdentityVerificationErrorTypeI18nKeyMapping[
        identityVerification.last_error
      ] as keyof typeof Messages.Verification;
      if (i18nKey != null) {
        return Messages.Verification[i18nKey] as string;
      }
    }
    return undefined;
  }, [identityVerification?.status, identityVerification?.last_error]);

  React.useEffect(() => {
    if (ownerId == null) {
      return;
    }
    fetchIdentityVerification(ownerId, ownerIsTeam)
      .then((newIdentityVerification) => {
        if (newIdentityVerification?.status === UserIdentityVerificationStatusTypes.PROCESSING) {
          setIsProcessing(true);
        }
      })
      .finally(() => setIsInitialFetchDone(true));
  }, [ownerId, ownerIsTeam]);

  useInterval(() => {
    if (ownerId == null || !isProcessing || !isInitialFetchDone || isRefetching) {
      return;
    }

    fetchIdentityVerification(ownerId, ownerIsTeam)
      .then((newIdentityVerification) => {
        const wasProcessing = identityVerification?.status === UserIdentityVerificationStatusTypes.PROCESSING;
        const hasStoppedProcessing = newIdentityVerification?.status !== UserIdentityVerificationStatusTypes.PROCESSING;
        const hasSucceeded =
          newIdentityVerification != null &&
          UserIdentityVerificationStatusTypesSets.ALL_SUCCESS.has(newIdentityVerification.status);
        if ((wasProcessing && hasStoppedProcessing) || (!wasProcessing && hasSucceeded)) {
          setIsProcessing(false);
        }
      })
      .finally(() => setIsRefetching(false));
  }, 3000);

  const handleCreateIdentityVerification = async () => {
    if (ownerId == null) {
      return;
    }
    setIsSubmitting(true);

    const popupWindow = new PopupWindow(
      {url: `${location.origin}${prependBasename(Routes.POPUP_WINDOW_TRANSITION_LOADING)}`},
      async () => {
        try {
          await getHostedIdentityVerificationURL(ownerId, ownerIsTeam);
        } catch (error) {
          if (
            error.code != null &&
            [
              APIErrorCodes.USER_IDENTITY_VERIFICATION_PROCESSING,
              APIErrorCodes.USER_IDENTITY_VERIFICATION_ALREADY_SUCCEEDED,
            ].includes(error.code)
          ) {
            setIsProcessing(true);
          }
        }
        setIsSubmitting(false);
      },
    );

    try {
      const redirectURL = await getHostedIdentityVerificationURL(ownerId, ownerIsTeam);
      if (redirectURL != null) {
        popupWindow.redirectTo(redirectURL);
      }
    } catch (error) {
      popupWindow.close();
      if (error.code != null && error.code === APIErrorCodes.TEAM_OWNERSHIP_REQUIRED) {
        onError(getMessageFromAPIError(error, Messages.Errors.ERROR_SUBMITTING_FORM.format()));
      } else {
        fetchIdentityVerification(ownerId, ownerIsTeam);
      }
    }
  };

  let state;
  if (!isInitialFetchDone) {
    state = IdentityVerificationUiState.LOADING;
  } else if (isProcessing) {
    state = IdentityVerificationUiState.PROCESSING;
  } else if (identityVerification?.status === UserIdentityVerificationStatusTypes.REQUIRES_ACTION) {
    state = IdentityVerificationUiState.REQUIRES_ACTION;
  } else if (
    identityVerification != null &&
    UserIdentityVerificationStatusTypesSets.ALL_SUCCESS.has(identityVerification.status)
  ) {
    if (identityVerification.status === UserIdentityVerificationStatusTypes.SUCCEEDED_GRACE_PERIOD) {
      state = IdentityVerificationUiState.SUCCESS_GRACE_PERIOD;
    } else {
      state = IdentityVerificationUiState.SUCCESS;
    }
  } else {
    state = IdentityVerificationUiState.NEEDS_VERIFICATION;
  }

  return {handleCreateIdentityVerification, state, submitting: isSubmitting, lastErrorMessage};
}
