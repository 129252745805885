import type {Logger} from './Logger';

export interface Log {
  timestamp?: number;
  delta?: number;
  prefix: string;
  emoji: string;
  log: string;
}

interface LogGroup {
  index: number;
  timestamp: number;
  logs: Log[];
  serverTrace?: string;
  nativeLogs: NativeLog[];
}

export interface NativeLog {
  label: string;
  timestamp: number;
  tag?: string;
  autoGenerated: boolean;
}

// @ts-expect-error I am unable to figure out how to type this, it is (undefined | () => number)
const getTotalRequireTime = global.__getTotalRequireTime == null ? () => 0 : () => global.__getTotalRequireTime();

const canTrace = typeof performance !== 'undefined';
class AppStartPerformance {
  private isTracing_ = true;
  private endTime_ = Date.now() + 15000;
  private lastImportDuration = 0;

  logGroups: LogGroup[] = [
    {
      index: 0,
      timestamp: Date.now(),
      logs: [],
      nativeLogs: [],
    },
  ];
  private logs: Log[] = this.logGroups[0].logs;
  private prefix = '';

  get isTracing() {
    if (!canTrace || !this.isTracing_) {
      return false;
    }
    if (Date.now() > this.endTime_) {
      this.isTracing_ = false;
      return false;
    }
    return true;
  }

  get endTime() {
    return this.endTime_;
  }
  set endTime(endTime: number) {
    this.endTime_ = endTime;
    this.isTracing_ = true;
  }

  resumeTracing() {
    if (!this.isTracing) {
      this.logGroups.unshift({
        index: this.logGroups.length,
        timestamp: Date.now(),
        logs: [],
        nativeLogs: [],
      });
      this.logs = this.logGroups[0].logs;
    }
    this.endTime = Date.now() + 10000;
  }

  mark(emoji: string, log: string, delta?: number) {
    if (!this.isTracing) return;
    this.logs.push({emoji, prefix: `${this.prefix}`, log, delta, timestamp: Date.now()});
    this.addImportLogDetail();
  }

  markAndLog(logger: Logger, emoji: string, log: string, delta?: number) {
    logger.log(log);
    if (!this.isTracing) return;
    this.logs.push({emoji, prefix: this.prefix, log, delta, timestamp: Date.now()});
    this.addImportLogDetail();
  }

  addImportLogDetail() {
    const duration = getTotalRequireTime();
    if (duration - this.lastImportDuration > 25) {
      this.addDetail('JS Imports', Math.ceil(duration) + 'ms');
      this.lastImportDuration = duration;
    }
  }

  markWithDelta(emoji: string, log: string) {
    const lastLog = this.logs[this.logs.length - 1];
    this.mark(emoji, log, lastLog != null && lastLog.timestamp != null ? Date.now() - lastLog.timestamp : undefined);
  }

  markAt(emoji: string, log: string, time: number) {
    if (!this.isTracing) return;
    let i = 0;
    for (; i < this.logs.length; i++) {
      const {timestamp} = this.logs[i];
      if (timestamp != null && timestamp > time) {
        break;
      }
    }
    this.logs.splice(i, 0, {emoji, log, timestamp: time, prefix: this.logs[i]?.prefix ?? ''});
  }

  addDetail(log: string, value: number | string) {
    if (!this.isTracing) return;
    this.logs.push({emoji: this.logs[this.logs.length - 1].emoji, prefix: this.prefix, log: `  ↪ ${log} ${value}`});
  }

  time<T>(emoji: string, label: string, func: () => T): T {
    if (!this.isTracing) {
      return func();
    }

    const prefix = this.prefix;
    this.mark(emoji, `Start ${label}`);
    this.prefix += '| ';
    const start = Date.now();
    const value = func();
    const delta = Date.now() - start;
    this.prefix = prefix;
    this.mark(emoji, `Finish ${label}`, delta);

    return value;
  }

  async timeAsync<T>(emoji: string, label: string, func: () => Promise<T>): Promise<T> {
    if (!this.isTracing) {
      return func();
    }

    this.mark(emoji, `Start ${label}`);
    const start = Date.now();
    const value = await func();
    const delta = Date.now() - start;
    this.mark(emoji, `Finish ${label}`, delta);

    return value;
  }

  setServerTrace(trace: string) {
    this.logGroups[0].serverTrace = trace;
  }
}

export default new AppStartPerformance();
