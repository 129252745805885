import * as React from 'react';
import classNames from 'classnames';
import {CircleWarningIcon} from '@discordapp/design/components/Icon/generated/CircleWarningIcon.web';

import Button from '@developers/uikit/Button';
import Flex from '@developers/uikit/Flex';
import {ComponentDispatch} from '@developers/utils/ComponentDispatchUtils';

import {ComponentActions} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './UnsavedChangesNotice.module.css';

export enum UnsavedChangesNoticeSaveType {
  SAVE,
  PUBLISH,
}

export interface UnsavedChangesNoticeProps {
  saving: boolean;
  saveDisabled?: boolean;
  saveType?: UnsavedChangesNoticeSaveType;
  className?: string;
  onReset: () => void;
  onSave: () => void;
}

export default function UnsavedChangesNotice({
  saving,
  saveDisabled,
  saveType = UnsavedChangesNoticeSaveType.SAVE,
  className,
  onReset,
  onSave,
}: UnsavedChangesNoticeProps) {
  const [emphasized, setEmphasized] = React.useState(false);

  React.useEffect(() => {
    const emphasize = () => {
      setEmphasized(true);
      setTimeout(() => setEmphasized(false), 1000);
    };
    ComponentDispatch.subscribe(ComponentActions.EMPHASIZE_NOTICE, emphasize);
    return () => {
      ComponentDispatch.unsubscribe(ComponentActions.EMPHASIZE_NOTICE, emphasize);
    };
  }, []);

  let actionMessage;
  switch (saveType) {
    case UnsavedChangesNoticeSaveType.SAVE:
      actionMessage = Messages.Actions.SAVE_CHANGES;
      break;
    case UnsavedChangesNoticeSaveType.PUBLISH:
      actionMessage = Messages.Actions.PUBLISH_CHANGES;
      break;
  }

  return (
    <div className={classNames(styles.container, emphasized ? styles.emphasized : null, className)}>
      <Flex align={Flex.Align.CENTER} className={styles.contentWrap}>
        <Flex.Child>
          <div className={styles.message}>
            <CircleWarningIcon size="md" color="var(--yellow-260)" />
            {Messages.Common.SETTINGS_NOTICE_MESSAGE}
          </div>
        </Flex.Child>
        <Flex className={styles.buttonWrap}>
          {onReset != null && (
            <Button
              className={styles.resetButton}
              height={Button.Height.SHORT}
              color={Button.Color.PRIMARY}
              look={Button.Look.LINK}
              onClick={onReset}>
              <span>{Messages.Actions.RESET}</span>
            </Button>
          )}
          <Button
            disabled={saveDisabled}
            className={styles.button}
            color={Button.Color.GREEN}
            submitting={saving}
            onClick={onSave}>
            {actionMessage}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}
