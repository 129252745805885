import * as React from 'react';
import {FocusRing} from 'react-focus-rings';
import {ThemeDarkIcon} from '@discordapp/design/components/Icon/generated/ThemeDarkIcon.web';
import {ThemeLightIcon} from '@discordapp/design/components/Icon/generated/ThemeLightIcon.web';
import {Text} from '@discordapp/design/components/Text/Text.web';
import {useStateFromStores, useStateFromStoresObject} from '@discordapp/flux';

import * as UIActions from '@developers/actions/UIActions';
import * as UserActions from '@developers/actions/UserActionCreators';
import {BuildOverrideMenuItem, useFetchBuildOverride} from '@developers/components/build_override/BuildOverride';
import {useTheme} from '@developers/hooks/useTheme';
import UIStore from '@developers/stores/UIStore';
import UserStore from '@developers/stores/UserStore';
import Avatar, {AvatarSizes} from '@developers/uikit/Avatar';
import Button from '@developers/uikit/Button';
import ContextMenu from '@developers/uikit/ContextMenu';
import Flex from '@developers/uikit/Flex';
import HamburgerButton from '@developers/uikit/HamburgerButton';
import {getUserAvatarURL} from '@developers/utils/AvatarUtils';
import {hasUniqueUsername} from '@developers/utils/UserUtils';
import AppLogo from './AppLogo';

import {NOOP} from '@developers/Constants';
import {Messages} from '@developers/i18n';
import styles from './AppHeader.module.css';

export const UserMenu = ({children}: {children: React.ReactNode}) => {
  const {user} = useStateFromStoresObject([UserStore], () => ({
    user: UserStore.user,
  }));

  if (user == null) return null;

  return (
    <>
      <Flex align={Flex.Align.CENTER}>
        <Flex.Child grow={1} shrink={1}>
          <Text variant="text-sm/normal" color="text-primary">
            {Messages.Common.LOGGED_IN_HEADER}
          </Text>
          <Flex align={Flex.Align.CENTER} wrap={Flex.Wrap.NO_WRAP}>
            <Flex.Child className={styles.username} grow={0} shrink={1}>
              <Text variant="text-md/medium">{user.username}</Text>
            </Flex.Child>
            {!hasUniqueUsername(user) ? (
              <Flex.Child grow={0} shrink={0}>
                <Text variant="text-md/medium" color="text-normal" tag="span">
                  #{user.discriminator}
                </Text>
              </Flex.Child>
            ) : null}
          </Flex>
        </Flex.Child>
        <Flex.Child grow={0} shrink={0} wrap>
          <Button
            className={styles.userMenuLogoutButton}
            color={Button.Color.RED}
            height={Button.Height.SHORT}
            look={Button.Look.LINK}
            onClick={() => UserActions.logOut()}>
            {Messages.Actions.LOG_OUT}
          </Button>
        </Flex.Child>
      </Flex>
      {children}
    </>
  );
};

const AppHeader = () => {
  const user = useStateFromStores([UserStore], () => UserStore.user);
  const isMobileMenuOpen = useStateFromStores([UIStore], () => UIStore.isMenuOpen);

  const {theme, toggleTheme} = useTheme();

  React.useEffect(() => {
    UserActions.fetchCachedUser().catch(NOOP);
  }, []);

  const handleHamburgerClick = React.useCallback(() => {
    if (isMobileMenuOpen) {
      UIActions.closeMobileMenu();
    } else {
      UIActions.openMobileMenu();
    }
  }, [isMobileMenuOpen]);
  const buildOverride = useFetchBuildOverride();

  return (
    <div className={styles.wrapper}>
      <HamburgerButton onClick={handleHamburgerClick} className={styles.mobileMenu} />
      <AppLogo color={buildOverride != null ? 'var(--text-warning)' : undefined} />
      <div className={styles.rightItems}>
        <div className={styles.themeToggleContainer}>
          <button
            onClick={() => toggleTheme()}
            className={styles.themeToggle}
            aria-label={`Toggle to ${theme === 'light' ? 'dark' : 'light'} theme`}>
            {theme === 'light' ? <ThemeLightIcon /> : <ThemeDarkIcon />}
          </button>
        </div>
        {user == null ? null : (
          <ContextMenu
            menuContent={
              <UserMenu>{buildOverride != null ? <BuildOverrideMenuItem override={buildOverride} /> : null}</UserMenu>
            }>
            {(onTriggerClick) => {
              return (
                <FocusRing>
                  <button className={styles.userMenuButton} type="button" onClick={onTriggerClick}>
                    <Avatar src={getUserAvatarURL(user)} size={AvatarSizes.LARGE} />
                  </button>
                </FocusRing>
              );
            }}
          </ContextMenu>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
