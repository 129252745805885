import {matchPath} from 'react-router-dom';
import {trackMaker} from '@discordapp/analytics-utils';

import Dispatcher from '@developers/Dispatcher';
import ActionTypes from '@developers/actions/ActionTypes';
import APIStore from '@developers/stores/APIStore';
import ApplicationStore from '@developers/stores/ApplicationStore';

import {AnalyticsEvents, Routes} from '@developers/Constants';
import type {Location} from 'react-router-dom';

type Tracker = (event: string, properties?: unknown) => void;

interface ApplicationAnalyticsProperties {
  application_id: string;
  bot_id: string | null;
  application_name: string;
  has_bot: boolean;
  has_redirect_uri: boolean;
  monetization_eligibility_flags: number | null;
  verification_eligibility_flags: number | null;
}

export const track: Tracker = trackMaker({
  analyticEventConfigs: {},
  dispatcher: Dispatcher,
  TRACK_ACTION_NAME: ActionTypes.TRACK,
});

export function trackOutboundLink(url: string) {
  try {
    // Should try/catch this to ensure no errors if ga never loads
    window.ga!('send', 'event', 'outbound', 'click', url, {
      transport: 'beacon',
      hitCallback: function () {
        document.location.assign(url);
      },
    });
  } catch (e) {}
}

// Marketing AnalyticsUtils
let previousPageName: string | null = null;

export function trackPageLoad(
  newPage: string,
  previousLinkLocation?: string | null,
  additionalProps?: Record<string, unknown> | null,
): void {
  // Don't track a page view event if we're not actually on a different page.
  // This can happen when replacing an existing page in thie history stack (e.g. updating query args in the analytics
  // date filter).
  if (previousPageName === newPage) return;
  track(AnalyticsEvents.PAGE_VIEWED, {
    page_name: newPage,
    previous_page_name: previousPageName,
    previous_link_location: previousLinkLocation,
    has_session: Boolean(APIStore.token),
    ...additionalProps,
  });
  previousPageName = newPage;
}

export function getApplicationProperties(location: Location): ApplicationAnalyticsProperties | null | undefined {
  const match = matchPath({path: Routes.EDIT_APPLICATION(':id'), end: false}, location.pathname);
  if (match?.params?.id == null) return null;
  const application = ApplicationStore.getApplication(match.params.id);
  if (application == null) return null;

  return {
    application_id: application.id,
    bot_id: application.bot != null ? application.bot.id : null,
    application_name: application.name,
    has_bot: application.bot != null,
    has_redirect_uri: application.redirect_uris != null && application.redirect_uris.length > 0,
    monetization_eligibility_flags: application.monetization_eligibility_flags ?? null,
    verification_eligibility_flags: application.verification_eligibility_flags ?? null,
  };
}

export function getGuildProperties(location: Location): {
  guild_id: string | null | undefined;
} | null {
  const match = matchPath(Routes.GUILD_ANALYTICS(':guildId'), location.pathname);
  if (match?.params?.guildId == null) return null;
  return {
    guild_id: match.params.guildId,
  };
}

export const getPageName = (location: Location): string => {
  return location.pathname === '/' ? 'landing' : location.pathname.substring(1);
};
