/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
 * To add new icons to the system, or to update existing icons, run `clyde mana assets sync -t icons`.
 */

import * as React from 'react';
import tokens from '@discordapp/tokens/web';

import addDefaultIconProps from '../addDefaultIconProps';
import {getIconSize} from '../getIconSize';

import type {TwoColorIconProps} from '../IconTypes';

/**
 * ![CircleWarningIcon](data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABvklEQVR4nMVVu04CQRTdxEJNNBFKNP6A+gM0lEKWuTPELPcGixX9B2P0P/wB7VQg/ICPTg3YaEDt1IYKDLZGc1eF4eHOQKGbTDLFzDlzzr33rOP85+d5XhQUbYKkklBUB4VvvL73RYBs3nVzkXGAp0HhHih8BUUfYUtIagmJu3zHChyAYiDpygQM/UQKb9JpbzEUPJNZXwBFL6OCQ0cNPruuN/+7LZIq44JDV8l1wvenBq0JPA993XkikZiJx+OzQtFFOBHu9L8+aiyozO47jjPBi/emwvd0l5C4ZZQv8eCHQEg8tKjHRtceSSULb8sdAoVli/MFzX96NF+gU03BmcX5umYRtS0kVzSCqvk8tUciAIkPnSJbKOam0QnuLRQ0NAUNiwfV9BkoWhC8r4q1JdfNLPPeTEAnWv5k82bJFJBYgX8V2e8ZNB4OC4IjofDYokWbUvpzPdPMkWvw9FabgztDB20PZBEHFAdVyKVWKuWt8ApTKyReDg07/jhqOXJtPIbhvj/x/2QoeLfgFONXjA6OVeMPR7eLI9eq8Aqb7HkymZy0Au+1LBfhVAzmRGKNJz6Yet4rLHArDnTLX3+fBW/deyqMzDYAAAAASUVORK5CYII=)
 */
export const CircleWarningIcon = ({
  size = 'md',
  width,
  height,
  secondaryColor = 'transparent',
  secondaryColorClass = '',
  color = tokens.colors.INTERACTIVE_NORMAL,
  colorClass = '',
  ...props
}: TwoColorIconProps) => {
  const iconSize = getIconSize(size);
  const iconWidth = iconSize?.width ?? width;
  const iconHeight = iconSize?.height ?? height;
  return (
    <svg
      {...addDefaultIconProps(props)}
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="10"
        fill={typeof secondaryColor === 'string' ? secondaryColor : secondaryColor.css}
        className={secondaryColorClass}
      />
      <path
        fill={typeof color === 'string' ? color : color.css}
        fillRule="evenodd"
        d="M12 23a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm1.44-15.94L13.06 14a1.06 1.06 0 0 1-2.12 0l-.38-6.94a1 1 0 0 1 1-1.06h.88a1 1 0 0 1 1 1.06Zm-.19 10.69a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
        clipRule="evenodd"
        className={colorClass}
      />
    </svg>
  );
};
